import { Rewards as RewardsSnippet } from '@snippets';

export const Rewards = ({ cms }) => {
  return <RewardsSnippet cms={cms} />;
};

const RewardsHeroCms = {
  label: 'Hero',
  name: 'hero',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'description',
      component: 'text',
      label: 'Description',
    },
    {
      name: 'subDescription',
      component: 'text',
      label: 'Sub Description',
    },
    {
      name: 'image',
      label: 'Image',
      component: 'image',
    },
    {
      name: 'imagePosition',
      component: 'select',
      label: 'Image Position',
      options: [
        {
          value: 'left top',
          label: 'Left Top',
        },
        {
          value: 'left center',
          label: 'Left Center',
        },
        {
          value: 'left bottom',
          label: 'Left Bottom',
        },
        {
          value: 'right top',
          label: 'Right Top',
        },
        {
          value: 'right center',
          label: 'Right Center',
        },
        {
          value: 'right bottom',
          label: 'Right Bottom',
        },
        {
          value: 'center top',
          label: 'Center Top',
        },
        {
          value: 'center center',
          label: 'Center Center',
        },
        {
          value: 'center bottom',
          label: 'Center Bottom',
        },
      ],
      defaultValue: 'center center',
    },
    {
      name: 'mobileImage',
      label: 'Image on Mobile',
      component: 'image',
      description: 'If empty, mobile will use main image.',
    },
    {
      name: 'mobileImagePosition',
      component: 'select',
      label: 'Image Position on Mobile',
      options: [
        {
          value: 'left top',
          label: 'Left Top',
        },
        {
          value: 'left center',
          label: 'Left Center',
        },
        {
          value: 'left bottom',
          label: 'Left Bottom',
        },
        {
          value: 'right top',
          label: 'Right Top',
        },
        {
          value: 'right center',
          label: 'Right Center',
        },
        {
          value: 'right bottom',
          label: 'Right Bottom',
        },
        {
          value: 'center top',
          label: 'Center Top',
        },
        {
          value: 'center center',
          label: 'Center Center',
        },
        {
          value: 'center bottom',
          label: 'Center Bottom',
        },
      ],
      defaultValue: 'center center',
    },
  ],
};

const RewardsCampaignsCms = {
  label: 'Campaigns',
  name: 'campaigns',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'description',
      component: 'text',
      label: 'Description',
    },
  ],
};

const RewardsReferAFriendCms = {
  label: 'Refer A Friend',
  name: 'referAFriend',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'superHeading',
      component: 'text',
      label: 'Super Heading',
    },
    {
      name: 'description',
      component: 'text',
      label: 'Description',
    },
    {
      name: 'image',
      label: 'Image',
      component: 'image',
    },
    {
      name: 'buttonText',
      component: 'text',
      label: 'Refer Button Text',
    },
  ],
};

const RewardsTiersCms = {
  label: 'VIP Tiers',
  name: 'tiers',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'description',
      component: 'text',
      label: 'Description',
    },
    {
      name: 'silverRequirements',
      component: 'textarea',
      label: 'Silver Requirements',
    },
    {
      name: 'goldRequirements',
      component: 'textarea',
      label: 'Gold Requirements',
    },
    {
      name: 'platinumRequirements',
      component: 'textarea',
      label: 'Platinum Requirements',
    },
  ],
};

const RewardsReedemCms = {
  label: 'Redeem',
  name: 'redeem',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'description',
      component: 'text',
      label: 'Description',
    },
    {
      name: 'subDescription',
      component: 'text',
      label: 'Sub Description',
    },
    {
      name: 'footnote',
      component: 'text',
      label: 'Footnote',
    },
  ],
};

Rewards.Schema = {
  label: 'Rewards',
  key: 'rewards',
  fields: [
    RewardsHeroCms,
    RewardsCampaignsCms,
    RewardsReferAFriendCms,
    RewardsTiersCms,
    RewardsReedemCms,
  ],
};
