import React from 'react';

/*
  A hook that allows you to temporarily hide the tina toolbar during development
  use: include useHideTinaToolbar() in any component
  useHideTinaToolbar(false) <-- show the toolbar
*/
export const useHideTinaToolbar = (hideToolbar = true) => {
  const killTinaToolBar = () => {
    const _next = document.getElementById('__next');
    console.groupCollapsed('Hiding editor toolbar');

    for (let i = 0; i < _next.children.length; i++) {
      const child = _next.children[i];
      for (var y = 0, l = child.classList.length; y < l; ++y) {
        const childClass = child.classList[y];
        const classesToHide = /ToolbarPlaceholder*|StyledToolbar*/;
        if (classesToHide.test(childClass)) {
          console.log('Hiding element with class', childClass);
          child.style.display = 'none';
          break;
        }
      }
    }
    console.groupEnd('Hiding editor toolbar');
  };

  const hideTinaToolbarEffect = () => {
    hideToolbar && killTinaToolBar();
    return () => {};
  };

  React.useEffect(hideTinaToolbarEffect, []);
};
