export const quantityFromOption = (option) => {
  // extracts the number value in a product option e.g 2 - in "2 Bottles"
  const quantityString =
    typeof option !== 'undefined' && option?.split(' ')?.[0];
  if (!quantityString) return 1; // no good

  const quantity = quantityString ? parseInt(quantityString) : 1; // no good

  return quantity;
};
