import { Grid } from 'theme-ui';
import { StyledInput, StyledSelect } from '@snippets';

export const AddressFormFields = ({
  selectedCountryIndex,
  setSelectedCountryIndex,
  addressForm,
  setAddressForm,
  provinceNames,
  countryNames,
}) => {
  return (
    <Grid
      data-comp="AddressFormFields"
      columns={[1]}
      sx={{
        gridGap: 6,
        flexDirection: 'column',
      }}
    >
      {fields.map((field, index, arr) => {
        const isProvince = field.name === 'province';
        const isCountry = field.name === 'country';
        return isCountry || isProvince ? (
          <StyledSelect
            key={index}
            placeholder={field.label}
            label={field.label}
            id={`address-form-${field.name}`}
            action={({ value, index }) => {
              if (isCountry) {
                if (index === selectedCountryIndex) return;
                setSelectedCountryIndex(index);
              }
              setAddressForm({
                ...addressForm,
                [field.name]: value,
              });
            }}
            defaultValue={addressForm[field.name]}
            listAsArrayOfStrings={true}
            smallPadding={true}
            isCountryProvinceSelector={true}
            list={isCountry ? countryNames : provinceNames}
            zIndex={20 + (arr.length - index)}
            sx={{
              mb: 0,
              display: isProvince
                ? provinceNames.length > 0
                  ? 'flex !important'
                  : 'none !important'
                : 'flex !important',
            }}
          />
        ) : (
          <StyledInput
            key={index}
            placeholder={field.label}
            label={field.label}
            id={`address-form-${field.name}`}
            value={addressForm[field.name]}
            sx={{ mb: 0 }}
            action={(value) =>
              setAddressForm({
                ...addressForm,
                [field.name]: value,
              })
            }
            required={field.name !== 'address2' && field.name !== 'phone'}
          />
        );
      })}
    </Grid>
  );
};

const fields = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'address1',
    label: 'Address Line 1',
  },
  {
    name: 'address2',
    label: 'Address Line 2',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'province',
    label: 'State/Province',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'zip',
    label: 'Zip/Postal Code',
  },
  {
    name: 'phone',
    label: 'Phone Number',
  },
];
