import React from 'react';

export const useMountAfter = ({ delay }) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    let cancelled = false;

    if (typeof delay !== 'undefined') {
      const t0 = requestTimeout(() => {
        if (!cancelled) {
          setReady(true);
        }
        clearRequestTimeout(t0);
      }, delay);
    } else {
      console.error('useMountAfter: delay prop not provided');
    }

    return () => {
      cancelled = true;
    };
  }, [delay]);

  return [ready];
};
