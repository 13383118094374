import { Flex, Embed } from 'theme-ui';
import Script from 'next/script';

export const DestiniWidget = ({ section }) => {
  return (
    <>
      <style>{`
        #store-locator-widget {
          height: 50vw;
          min-height: 500px;
          width: 100vw;
          margin: 0 auto;
        }

        @media only screen and (min-width: 767px) {
          #store-locator-widget {
            width: 75vw;
            height: 75vh;
            min-height: 700px;
          }
        }
      `}</style>
      <Script
        type="text/javascript"
        id="destini-gtm"
        strategy="afterInteractive"
        src="//destinilocators.com/control/gtm.js"
      />
      <Script
        type="text/javascript"
        id="destini-pscript"
        strategy="afterInteractive"
        src="//destinilocators.com/control/pscript.js"
      />
      {/*
        <script type="text/javascript" src="//destinilocators.com/control/gtm.js"></script>
        <script type="text/javascript" src=""></script>
      */}

      <Flex
        sx={{
          py: [11, null, null, 14],
          mx: 'auto',
        }}
      >
        <iframe
          id="store-locator-widget"
          src="https://destinilocators.com/truffhotsauce/site/locator.php?RFR=https%3A%2F%2Fwww.truff.com"
          height="500px"
          width="100%"
        />
      </Flex>
    </>
  );
};

DestiniWidget.Schema = {
  label: 'Destini Widget',
  key: 'destini-widget',
  fields: [],
};
