import store, { useRecoilCallback, useRecoilValue } from '@store';

export const useModal = () => {
  const modal = useRecoilValue(store.modal);

  const closeModal = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot
            .map(({ set }) => set(store.modal, null))
            .map(({ set }) => set(store.overlay, false));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  const openModal = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (modalChildren) => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot
            .map(({ set }) => set(store.modal, modalChildren))
            .map(({ set }) => set(store.overlay, true));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  return [
    // state
    {
      modal,
    },
    // actions
    {
      closeModal,
      openModal,
    },
  ];
};
