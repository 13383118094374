export const getDiscountPrice = ({ price, quantity }) => {
  const fullPrice = typeof price === 'string' ? parseFloat(price) : price;

  if (!fullPrice) return 0;

  let discountPercent = 0;
  let discountPrice = 0;
  switch (quantity) {
    case 1:
      // no discount on 1 of anything
      break;
    case 2:
      // no discount on 1 of anything
      discountPercent = 0.05;
      break;

    default:
      discountPercent = 0.1;
      break;
  }

  if (discountPercent) {
    discountPrice = fullPrice * discountPercent;
  }

  return (fullPrice - discountPrice).toFixed(2);
};
