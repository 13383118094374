import { Button, Flex, Paragraph } from 'theme-ui';

export const AddressBookConfirmation = ({
  setShowConfirmation,
  customer,
  id,
}) => {
  return (
    <Flex
      data-comp="AddressBookConfirmation"
      variant="flex.column.center.center"
      sx={{
        position: 'absolute',
        bg: 'rgba(255,255,255,0.9)',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        p: [6, 10],
        textAlign: 'center',
        zIndex: 30,
      }}
    >
      <Paragraph variant="sm" sx={{ mb: 8 }}>
        Are you sure you want to remove this address?
      </Paragraph>
      <Flex>
        <Button
          variant="underlineLink"
          sx={{
            color: 'black',
            fontSize: 1,
            mr: 8,
            '&:before, &:after': {
              height: '1px',
            },
          }}
          onClick={() => setShowConfirmation(false)}
        >
          Cancel
        </Button>
        <Button
          variant="underlineLink"
          sx={{
            color: 'black',
            fontSize: 1,
            '&:before, &:after': {
              height: '1px',
            },
          }}
          onClick={() => customer.deleteAddress({ id })}
        >
          Remove
        </Button>
      </Flex>
    </Flex>
  );
};
