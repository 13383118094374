import { Flex, Heading } from 'theme-ui';
import { containerTinaSettings } from '@utils';
import { Accordion } from '@snippets';

export const AccordionBlock = ({
  cms: { heading, subheading, accordions, onePanelOpen, containerSettings },
}) => {
  return (
    <Flex
      data-comp="AccordionBlock"
      variant="flex.section"
      sx={{
        bg: 'white',
        py: [13, null, null, 18],
        px: [6, 9, null, 11],
      }}
    >
      <Flex
        variant="flex.column.center"
        sx={{
          width: '100%',
          maxWidth: '600px',
        }}
      >
        <Heading
          variant="24.r"
          sx={{
            textAlign: 'center',
            mb: [8, 9],
          }}
        >
          {heading}
        </Heading>
        <Heading
          variant="18.r"
          sx={{
            textAlign: 'center',
            mb: [8, 9],
          }}
        >
          {subheading}
        </Heading>

        <Accordion content={accordions} onePanelOpen={onePanelOpen} />
      </Flex>
    </Flex>
  );
};

AccordionBlock.displayName = 'AccordionBlock';

AccordionBlock.Schema = {
  label: 'Accordion Block',
  key: 'accordion-block',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'subheading',
      label: 'Subheading',
      component: 'text',
    },
    {
      name: 'accordions',
      label: 'Accordion Panels',
      component: 'group-list',
      itemProps: {
        label: '{{item.heading}}',
      },
      fields: [
        {
          name: 'heading',
          label: 'Panel Heading',
          component: 'text',
          defaultValue: 'Item heading',
        },
        {
          name: 'body',
          label: 'Panel Body',
          component: 'markdown',
          defaultValue: 'Donec sed odio dui.',
        },
        // {
        //   name: 'images',
        //   label: 'Images',
        //   component: 'group-list',
        //   fields: [
        //     {
        //       name: 'image',
        //       label: 'Image',
        //       component: 'image',
        //     },
        //   ]
        // }
      ],
    },
    {
      name: 'onePanelOpen',
      label: 'One Panel Open At A Time',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    containerTinaSettings,
  ],
};
