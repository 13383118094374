import { Flex, Box, Paragraph } from 'theme-ui';

export const OprahLovesTruffTextBlock = ({ cms }) => {
  return (
    <Flex
      data-comp="OprahLovesTruffTextBlock"
      variant="flex.section"
      sx={{
        bg: 'white',
        py: ['28px', '24px', null, 10],
        px: [6, 11, null, 0],
      }}
    >
      <Flex
        variant="flex.section"
        sx={{
          width: '100%',
          maxWidth: '1440px',
          px: ['12px', null, null, '100px'],
          flexDirection: ['column', null, null, 'row'],
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            width: ['100%', null, null, '35%'],
            flexBasis: ['100%', null, null, '35%'],
          }}
        >
          <Box
            as="video"
            sx={{ maxWidth: '700px', width: '100%' }}
            playsInline
            loop
            autoPlay
            muted
            controls={false}
          >
            <source type="video/mp4" src={cms?.video_url} />
          </Box>
        </Box>

        <Box
          sx={{
            flexShrink: 0,
            pl: [null, null, null, '100px'],
            width: ['100%', null, null, '65%'],
            flexBasis: ['100%', null, null, '65%'],
          }}
        >
          <Paragraph
            variant="label.2"
            sx={{
              color: 'gold',
              display: 'block',
              mb: [6, null, null, null, 9],
              textAlign: ['center', null, null, 'left'],
            }}
          >
            {cms?.heading}
          </Paragraph>
          <Paragraph
            variant="base.r2"
            sx={{
              color: 'black',
              textAlign: ['center', null, null, 'left'],
            }}
          >
            {cms?.text}
          </Paragraph>
        </Box>
      </Flex>
    </Flex>
  );
};

OprahLovesTruffTextBlock.Schema = {
  label: 'Oprah Loves Truff Text Block',
  key: 'oprah-loves-truff-text-block',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'text',
      component: 'text',
      label: 'Text',
    },
    {
      name: 'video_url',
      component: 'text',
      label: 'Video Url',
    },
  ],
};
