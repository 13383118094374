import { useMemo } from 'react';
import { Box } from 'theme-ui';
import { mediaCmsSettings } from '@utils';
import { HeroImage, HeroVideo } from '@snippets';

export const MediaHero = ({ cms }) => {
  const {
    activeMediaType,
    activeMobileMediaType,
    imageSettings,
    videoSettings,
  } = cms;

  const mediaType = useMemo(
    () => ({
      main: activeMediaType || 'image',
      mobile: activeMobileMediaType || 'image',
    }),
    [activeMediaType, activeMobileMediaType]
  );

  const image = useMemo(
    () => ({
      src: imageSettings?.image?.src || imageSettings?.image?.fileName || '',
      mobileSrc:
        imageSettings?.mobileImage?.src ||
        imageSettings?.mobileImage?.fileName ||
        '',
      position: imageSettings?.imagePosition || 'center center',
      mobilePosition: imageSettings?.mobileImagePosition || 'center center',
      overlay: imageSettings?.overlay || false,
    }),
    [imageSettings]
  );

  const video = useMemo(
    () => ({
      src: videoSettings?.video || '',
      mobileSrc: videoSettings?.mobileVideo || '',
      poster: videoSettings?.poster?.src || videoSettings?.poster || '',
      mobilePoster:
        videoSettings?.mobilePoster?.src || videoSettings?.mobilePoster || '',
      overlay: videoSettings?.overlay || false,
    }),
    [videoSettings]
  );

  return (
    <Box
      data-comp="MediaHero"
      sx={{
        width: '100%',
        height: '70vw',
        minHeight: '260px',
        maxHeight: '600px',
        position: 'relative',
        // mb: [10, null, null, null, 12, 14]
      }}
    >
      <HeroImage image={image} mediaType={mediaType} lazy={false} />
      <HeroVideo video={video} mediaType={mediaType} />
    </Box>
  );
};

MediaHero.Schema = {
  label: 'Media Hero',
  key: 'media-hero',
  fields: [
    ...mediaCmsSettings({
      overlay: false,
    }),
  ],
};
