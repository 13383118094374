import { useState, useEffect } from 'react';
import { Flex, Paragraph } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { StyledButton, StyledInput } from '@snippets';

export const CreateAccountForm = ({ action, ...props }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPasssword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const customer = useCustomer();
  // const isLoading = customer?.loading['customer.create'];

  const createCustomer = (e) => {
    e.preventDefault();
    if (password !== confirmPasssword) {
      setMessage('Your passwords do not match.');
      return;
    }
    setMessage('');
    customer.createCustomer({
      firstName,
      lastName,
      email,
      password,
    });
  };

  useEffect(() => {
    const errorArray = customer?.errors?.['customer.create'];
    if (!errorArray) return;
    let errors = [];
    if (errorArray?.length > 0) {
      customer?.errors?.['customer.create'].forEach((err) => {
        let error = err.message;
        if (!error) return;
        return errors.push(error + `.<br/>`);
      });
      errors.length > 0 ? setMessage(errors.join('')) : null;
      return;
    } else {
      setMessage('');
    }
  }, [customer]);

  return (
    <Flex variant="flex.column" {...props}>
      <Paragraph
        variant="text.24.r"
        sx={{
          mb: 8,
        }}
      >
        Create An Account
      </Paragraph>

      <Flex variant="flex.column" onSubmit={createCustomer} as="form">
        <StyledInput
          name="firstName"
          placeholder="First Name"
          label="First Name"
          required
          value={firstName}
          action={setFirstName}
          id="signup-first-name"
        />

        <StyledInput
          name="lastName"
          placeholder="Last Name"
          label="Last Name"
          required
          value={lastName}
          action={setLastName}
          id="signup-last-name"
        />

        <StyledInput
          name="email"
          placeholder="Email Address"
          label="Email Address"
          type="email"
          required
          value={email}
          action={setEmail}
          id="signup-email"
        />

        <StyledInput
          name="password"
          placeholder="Password"
          label="Password"
          type="password"
          required
          value={password}
          action={setPassword}
          id="signup-password"
        />

        <StyledInput
          name="confirmPassword"
          placeholder="Confirm Password"
          label="Confirm Password"
          type="password"
          required
          value={confirmPasssword}
          action={setConfirmPassword}
          id="signup-confirm-password"
        />

        <Paragraph
          variant="sm"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          sx={{
            color: 'red',
            lineHeight: 26,
          }}
        />

        <StyledButton
          text="Sign Up"
          isLoading={isLoading}
          variant="solid"
          sx={{
            width: '100%',
            mt: 9,
          }}
        />
      </Flex>
    </Flex>
  );
};
