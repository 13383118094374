import { useCallback } from 'react';
import { useRouter } from '@backpackjs/storefront';

export const useYotpoScrollToReviews = () => {
  const router = useRouter();

  // used by YotpoStars to scroll to the main reviews section on PDPs
  const scrollToReviews = useCallback(() => {
    try {
      const isProductPage = /\/products\//.test(router.asPath);
      if (!isProductPage) return;

      const reviewsContainer = document.querySelector(
        `[data-comp="YotpoWidget"]`
      );
      reviewsContainer.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    } catch (error) {
      // throw new Error(error)
      console.warn('Error while scrolling to reviews form', error.message);
    }
  });

  return {
    scrollToReviews,
  };
};
