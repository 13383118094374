export const styles = {
  root: {
    fontFamily: 'body',
    lineHeight: 20,
    fontWeight: 400,
  },
  h1: {
    variant: 'text.48.r',
  },
  h2: {
    variant: 'text.40.r',
  },
  h3: {
    variant: 'text.32.r',
  },
  h4: {
    variant: 'text.28.r',
  },
  h5: {
    variant: 'text.24.r',
  },
  h6: {
    variant: 'text.18.r',
  },
  pre: {
    fontFamily: 'monospace',
    overflowX: 'auto',
    code: {
      color: 'inherit',
    },
  },
  code: {
    fontFamily: 'monospace',
    fontSize: 'inherit',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  th: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  td: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
};
