import { useMemo } from 'react';
import { Flex } from 'theme-ui';

import { MediaTextBlockText } from './MediaTextBlockText';
import { MediaTextBlockMedia } from './MediaTextBlockMedia';
import { WaveSvg } from './WaveSvg';

export const MediaTextBlock = ({ cms }) => {
  const {
    mediaSide = 'left',
    activeMediaType,
    activeMobileMediaType,
    imageSettings,
    videoSettings,
    textSettings,
    primaryButtonSettings,
    secondaryButtonSettings,
    topWave,
    bottomWave = true,
  } = cms;

  const mediaType = useMemo(
    () => ({
      main: activeMediaType || 'video',
      mobile: activeMobileMediaType || 'video',
    }),
    [activeMediaType, activeMobileMediaType]
  );

  const image = useMemo(
    () => ({
      src: imageSettings?.image?.src || imageSettings?.image || '',
      mobileSrc:
        imageSettings?.mobileImage?.src || imageSettings?.mobileImage || '',
      position: imageSettings?.imagePosition || 'center center',
      mobilePosition: imageSettings?.mobileImagePosition || 'center center',
      overlay: imageSettings?.overlay || false,
    }),
    [imageSettings]
  );

  return (
    <Flex
      data-comp="MediaTextBlock"
      variant="flex.section"
      sx={{
        bg: 'black',
        pt: [13, null, null, 17],
        pb: [11, null, null, '60px'],
        px: [6, 11, null, 0],
        mb: [7, null, null, 9],
      }}
    >
      {topWave && <WaveSvg isTop={true} />}
      <Flex
        sx={{
          flexDirection: [
            'column-reverse',
            null,
            null,
            mediaSide === 'left' ? 'row' : 'row-reverse',
          ],
          justifyContent: ['flex-start', null, null, 'center'],
          alignItems: 'center',
          width: '100%',
          maxWidth: '1440px',
          position: 'relative',
        }}
      >
        <MediaTextBlockMedia
          mediaSide={mediaSide}
          image={image}
          video={videoSettings}
          mediaType={mediaType}
        />
        <MediaTextBlockText
          mediaSide={mediaSide}
          textSettings={textSettings}
          primaryButtonSettings={primaryButtonSettings}
          secondaryButtonSettings={secondaryButtonSettings}
        />
      </Flex>
      {bottomWave && <WaveSvg />}
    </Flex>
  );
};
