import { Box, Paragraph, Flex, Button } from "theme-ui";
import { useSettings } from "@backpackjs/storefront";

import { LinkWrapper } from "@snippets";

import { FooterSocial } from "./FooterSocial";

export function FooterMenus() {
  const siteSettings = useSettings();
  const {
    footer: { footerMenu },
  } = siteSettings;

  return (
    <Flex data-comp="FooterMenus" sx={{ flexWrap: ["wrap", null, "nowrap"] }}>
      {footerMenu?.map((menu, index, arr) => (
        <Flex
          key={menu.name + index}
          variant="flex.column"
          sx={{
            mr: [
              index % 2 === 0 ? 5 : 0,
              null,
              index !== arr.length - 1 ? 17 : 0,
            ],
            width: ["calc(50% - 5px)", null, "auto"],
            maxWidth: [null, null, "120px", null, null, "100px", "140px"],
            mb:
              arr.length % 2 === 0
                ? index < arr.length - 2
                  ? [11, null, 0]
                  : 0
                : index < arr.length - 1
                ? [11, null, 0]
                : 0,
          }}
        >
          <Flex variant="flex.row.start.center" mb={6}>
            <Paragraph variant="label.3" sx={{ color: "white" }}>
              {menu.name}
            </Paragraph>
            <Box
              sx={{
                display: ["block", null, "none"],
                height: "1px",
                flex: 1,
                bg: "gray.6",
                ml: 2,
              }}
            />
          </Flex>
          <Box
            as="ul"
            sx={{
              p: 0,
            }}
            aria-label={`Footer ${menu.name} menu list containing ${menu.childMenuItems.length} items`}
          >
            {menu.childMenuItems?.map((item, index, arr) => (
              <Flex
                as="li"
                key={index}
                sx={{
                  mb: index !== arr.length - 1 ? [3, 6] : 0,
                }}
              >
                <LinkWrapper
                  aria-label={
                    item.link?.newTab ? `Go to ${item.link?.text} page.` : null
                  }
                  href={item.link?.url}
                  newTab={item.link?.newTab}
                  variant="buttons.link"
                >
                  {item.link?.text}
                </LinkWrapper>
              </Flex>
            ))}
          </Box>
        </Flex>
      ))}
      <FooterSocial
        sx={{
          display: ["flex !important", null, "none !important"],
          width: "calc(50% - 5px)",
        }}
      />
    </Flex>
  );
}
