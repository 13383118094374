import { store } from '@backpackjs/storefront';

const itemSelectedVariant = store.recoil.atomFamily({
  key: 'item/selectedVariant',
  default: null,
});

const itemActiveQuantity = store.recoil.atomFamily({
  key: 'product/activeQuantity',
  default: 2,
});

export default {
  itemActiveQuantity,
  itemSelectedVariant,
};
