import React, { useEffect, useRef, useState } from "react";
import { Flex, Box } from "theme-ui";
import { Svg } from "@snippets/Svg/Svg.jsx";

function BundleBuilderMenu({ menuItems, selectedMenu, setSelectedMenu }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const ref = useRef(null);
  const [boxWidth, setBoxWidth] = useState(20);

  useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      setBoxWidth(ref.current.offsetWidth > 60 ? 65 : ref.current.offsetWidth);
    }
  }, [selectedMenu, mobileMenuOpen]);

  return (
    <Box>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          padding: [4, "12px 36px 0 36px"],
          backgroundColor: "black",
          gap: "24px",
          fontFamily: "heading",
        }}
      >
        <Flex
          ref={ref}
          sx={{
            minWidth: "30px",
            gap: "12px",
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {menuItems.map((menuItem, index) => {
            return (
              <Box
                onClick={() => setSelectedMenu(menuItem)}
                key={index}
                sx={{
                  cursor: "pointer",
                  color: selectedMenu === menuItem ? "gold" : "white",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: "14px",
                  "&:hover": {
                    color: "gold",
                  },
                  display: [
                    selectedMenu === menuItem ? "flex" : "none",
                    "flex",
                  ],
                  flexDirection: "column",
                  pb: [0, "6px", "0"],
                }}
              >
                <Box as="span" className="bundle-builder-menu-button">
                  {menuItem}
                </Box>
                <Box
                  as="span"
                  sx={{
                    pt: "6px",
                    mt: "auto",
                    display: ["none", "none", "inline-block"],
                    borderBottom:
                      selectedMenu === menuItem
                        ? "2px solid #CAB683"
                        : "2px solid transparent",
                    width: "100%",
                  }}
                />
              </Box>
            );
          })}
        </Flex>
        <Box
          className="caret"
          sx={{
            width: "18px",
            height: "18px",
            position: "absolute",
            cursor: "pointer",
            color: "gold",
            display: ["block", "none", "none"],
            transform: `translate(${boxWidth}px,-2px )`,
          }}
        >
          {mobileMenuOpen ? (
            <Svg
              src="/svgs/chevron-up.svg#chevron-up"
              alt="Chevron Up"
              viewBox="0 0 24 24"
              onClick={() => setMobileMenuOpen(false)}
            />
          ) : (
            <Svg
              src="/svgs/chevron-down.svg#chevron-down"
              alt="Caret Down"
              viewBox="0 0 24 24"
              onClick={() => setMobileMenuOpen(true)}
            />
          )}
        </Box>
      </Flex>

      {mobileMenuOpen && (
        <Flex
          sx={{
            backgroundColor: "gold",
            gap: "12px",
            padding: "12px 8px",
            alignItems: "center",
            justifyContent: "center",
            display: [null, "none", "none"],
          }}
        >
          {menuItems.map((menuItem, index) => {
            return (
              <Box
                key={index}
                sx={{
                  cursor: "pointer",
                  color: "#1A1A1A",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: "12px",

                  display: selectedMenu === menuItem ? "none" : "block",
                }}
              >
                <Box
                  as="span"
                  className="bundle-builder-menu-button"
                  onClick={() => setSelectedMenu(menuItem)}
                >
                  {menuItem}
                </Box>
              </Box>
            );
          })}
        </Flex>
      )}
    </Box>
  );
}
export default BundleBuilderMenu;
