export const colors = {
  background: '#ffffff',
  text: '#1a1a1a',
  black: '#1a1a1a',
  white: '#ffffff',
  gold: '#cab683',
  darkGold: '#88723A',
  red: '#ac1b2e',
  gray: [
    '#FBFBFB', // 0 'Near White'
    '#F2F3F4', // 1
    '#E6E7E9', // 2 'Light Gray'
    '#D7D9DB', // 3
    '#AFAFAF', // 4 'Gray'
    '#868686', // 5
    '#6F6F70', // 6 'Dark Gray'
    '#4E4F50', // 7
    '#333333', // 8 'Off Black'
  ],
};
