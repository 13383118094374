export function Schema({ collection, blog, article }) {
  if (collection || blog || article) return null;

  return {
    label: 'Icon Row',
    key: 'icon-row',
    fields: [
      {
        name: 'heading',
        component: 'text',
        label: 'Heading',
        defaultValue: 'Time To Subscribe',
      },
      {
        name: 'description',
        component: 'markdown',
        label: 'Description',
        defaultValue:
          'Never run out of TRUFF again! Subscribe to receive your favorite truffle-infused pantry staples straight to your doorstep every month.',
      },
      {
        name: 'icons',
        label: 'Icon Values',
        description: 'Three icons across',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: () => ({
          icon: 'save',
          heading: 'Lorem Ipsum dolor sit',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        }),
        fields: [
          {
            name: 'icon',
            label: 'Icon',
            component: 'select',
            options: iconOptions,
            defaultValue: 'truffle',
          },
          {
            name: 'heading',
            component: 'text',
            label: 'Heading',
            defaultValue: 'Heading here.',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
            defaultValue: 'Description here.',
          },
        ],
        defaultValue: [
          {
            icon: 'save',
            heading: 'Save Big',
            description: `Never pay full price. Take 15% off every single order.`,
          },
          {
            icon: 'cancel',
            heading: 'Cancel Whenever',
            description: `Pause or cancel your subscription anytime. No strings attached.`,
          },
          {
            icon: 'sms',
            heading: 'Manage With Ease',
            description: `Customize or inquire about your subscription via text. Your contact is saved under our favorites.`,
          },
          {
            icon: 'truck',
            heading: 'Free Shipping',
            description: `Forget about pesky shipping fees. Shipping is always free for subscribers.`,
          },
        ],
      },
    ],
  };
}

export const ICON_SVG = {
  save: '/svg/subscribe-save.svg',
  cancel: '/svg/subscribe-cancel.svg',
  sms: '/svg/subscribe-sms.svg',
  truck: '/svg/subscribe-truck.svg',
};

const iconOptions = ['save', 'cancel', 'sms', 'truck'];
