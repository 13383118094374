import { useMemo } from 'react';
import { Flex, Heading, Paragraph, Grid, Box } from 'theme-ui';
import {
  entryAnimation,
  containerTinaSettings,
  parseContainerSettings,
} from '@utils';
import { useMounted, useBreakpointValue } from '@hooks';
import { LifestyleGridMainImage } from './LifestyleGridMainImage';
import { LifestyleGridSecondaryImages } from './LifestyleGridSecondaryImages';

export function LifestyleGrid({ cms }) {
  const [mounted] = useMounted();

  const {
    heading,
    description,
    mainImage,
    secondaryImage,
    video,
    hideMobile,
    bgColor,
    containerSettings,
    mobileSxSettings,
  } = cms;

  const containerSx = useMemo(
    () => parseContainerSettings(containerSettings),
    [containerSettings]
  );

  const showContainerSx = useBreakpointValue([
    mobileSxSettings ? containerSx : null,
    mobileSxSettings ? containerSx : null,
    mobileSxSettings ? containerSx : null,
    containerSx,
    containerSx,
  ]);

  if (!mounted) return null;

  return (
    <Flex
      data-comp="LifestyleGrid"
      variant="flex.section"
      sx={{
        display: hideMobile ? ['none', null, null, 'flex'] : 'flex',
        py: [13, null, null, 17],
        bg: bgColor || 'white',
        mx: 'auto',
        ...showContainerSx,
      }}
    >
      <Flex
        variant="flex.column.center"
        sx={{
          width: '100%',
          px: [6, null, null, 9],
        }}
      >
        {heading || description ? (
          <Flex
            variant="flex.column.center"
            sx={{
              width: '100%',
              maxWidth: '710px',
              mb: [11, null, null, 13],
            }}
          >
            <Heading
              variant="32.r"
              sx={{
                textAlign: 'center',
                opacity: 0,
                ...entryAnimation({
                  entered: mounted,
                  fadeIn: true,
                  slideIn: true,
                  slidePercent: '3%',
                  direction: 'up',
                  duration: 400,
                  index: 1,
                  delay: 100,
                }),
              }}
            >
              {heading}
            </Heading>

            <Paragraph
              variant="lg"
              sx={{
                display: description ? 'block' : 'none',
                textAlign: 'center',
                mt: [4, null, null, 6],
                opacity: 0,
                ...entryAnimation({
                  entered: mounted,
                  fadeIn: true,
                  slidePercent: '3%',
                  direction: 'up',
                  slideIn: true,
                  duration: 400,
                  index: 2,
                  delay: 200,
                }),
              }}
            >
              {description}
            </Paragraph>
          </Flex>
        ) : null}
        <Grid
          columns={['1fr', null, null, '1fr 1fr']}
          sx={{
            width: '100%',
            height: '100%',
            maxHeight: ['auto', null, null, '683px'],
            maxWidth: '1130px',
            gridGap: [6, 9],
            gridTemplateAreas: [
              `'primary' 'video' 'secondary'`,
              null,
              null,
              `'primary video' 'primary secondary'`,
            ],
          }}
        >
          <LifestyleGridMainImage
            mainImage={mainImage}
            imageSx={imageSx}
            boxShadow={boxShadow}
          />
          <LifestyleGridSecondaryImages
            video={video}
            secondaryImage={secondaryImage}
            boxShadow={boxShadow}
            imageSx={imageSx}
          />
        </Grid>
      </Flex>
    </Flex>
  );
}

const boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.25)';

const imageSx = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'scale(1.01)',
  transition: 'all 0.25s ease-in-out',
};

LifestyleGrid.Schema = {
  label: 'Lifestyle Grid',
  key: 'lifestyle-grid',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'description',
      component: 'textarea',
      label: 'Description',
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'color',
      defaultValue: '#ffffff',
    },
    {
      label: 'Primary Image',
      name: 'mainImage',
      description: 'Image, Heading, Button.',
      component: 'group',
      fields: [
        {
          name: 'image',
          label: 'Image',
          description: 'Image should be portrait orientation.',
          component: 'image',
        },
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
        },
        {
          name: 'textColor',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          label: 'Text Color',
          options: [
            {
              value: 'white',
              label: 'White',
            },
            {
              value: 'black',
              label: 'Black',
            },
          ],
          defaultValue: 'white',
        },
        {
          name: 'buttonStyle',
          label: 'Button Style',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            {
              value: 'primary',
              label: 'On Dark',
            },
            {
              value: 'secondary',
              label: 'On Light',
            },
          ],
          defaultValue: 'primary',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Image Link',
        },
      ],
    },
    {
      label: 'Secondary Image',
      name: 'secondaryImage',
      description: 'Image, Link.',
      component: 'group',
      fields: [
        {
          name: 'image',
          label: 'Image',
          description: 'Image should be landscape orientation.',
          component: 'image',
        },
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
        },
        {
          name: 'textColor',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          label: 'Text Color',
          options: [
            {
              value: 'white',
              label: 'White',
            },
            {
              value: 'black',
              label: 'Black',
            },
          ],
          defaultValue: 'white',
        },
        {
          name: 'buttonStyle',
          label: 'Button Style',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            {
              value: 'primary',
              label: 'On Dark',
            },
            {
              value: 'secondary',
              label: 'On Light',
            },
          ],
          defaultValue: 'primary',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Image Link',
        },
        {
          name: 'hideSecondaryMobile',
          label: 'Hide on Mobile',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
      ],
    },
    {
      label: 'Video',
      name: 'video',
      description: 'Video URL, Link.',
      component: 'group',
      fields: [
        {
          name: 'video',
          component: 'text',
          label: 'Vimeo Video File Link*',
          description: '*Required. Max quality: Standard def (mp4, 540x540).',
        },
        {
          name: 'poster',
          label: 'Video Thumbnail*',
          component: 'image',
          description:
            '*Required. Usually the first frame or best frame of the video.',
        },
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
        },
        {
          name: 'textColor',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          label: 'Text Color',
          options: [
            {
              value: 'white',
              label: 'White',
            },
            {
              value: 'black',
              label: 'Black',
            },
          ],
          defaultValue: 'white',
        },
        {
          name: 'buttonStyle',
          label: 'Button Style',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            {
              value: 'primary',
              label: 'On Dark',
            },
            {
              value: 'secondary',
              label: 'On Light',
            },
          ],
          defaultValue: 'primary',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Video Link',
        },
        {
          name: 'hideVideoMobile',
          label: 'Hide on Mobile',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
      ],
    },
    containerTinaSettings,
    {
      name: 'mobileSxSettings',
      label: 'Use Container Settings On Mobile',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'hideMobile',
      label: 'Hide Section on Mobile',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
  ],
};
