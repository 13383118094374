import { useMemo, useRef } from 'react';
import useMatchMedia from 'use-match-media-hook';
import { useThemeUI } from 'theme-ui';

export const useBreakpoint = () => {
  const { theme } = useThemeUI();

  // breakpoint values conversion from em to (px - 1)
  const [minuS, minusM, minusL, minusXl] = useMemo(
    () =>
      theme.breakpoints.map((bpEm) => {
        const [valueStr] = bpEm.split('em');
        return `${parseInt(valueStr) * 16 - 1}px`;
      }),
    [theme.breakpoints.join(',')]
  );

  const queries = useRef([
    `(max-width: ${minuS})`, // s: -> 767

    `(max-width: ${minusM})`, // _m: <-- 1023
    `(min-width: ${theme.breakpoints[0]}) and (max-width: ${minusM})`, // m: 768 -> 1023
    `(min-width: ${theme.breakpoints[0]})`, // m_: 768 -->

    `(max-width: ${minusL})`, // _l: -> 1279
    `(min-width: ${theme.breakpoints[1]}) and (max-width: ${minusL})`, //l: 1024 -> 1279
    `(min-width: ${theme.breakpoints[1]})`, // l_: 1024 ->

    `(max-width: ${minusXl})`, // _xl: -> 1439
    `(min-width: ${theme.breakpoints[2]}) and (max-width: ${minusXl})`, //xl: 1280 -> 1439px
    `(min-width: ${theme.breakpoints[2]})`, // xl_: 1280 ->
  ]);

  const [s, _m, m, m_, _l, l, l_, _xl, xl, xl_] = useMatchMedia(
    queries.current,
    [true]
  );
  return { s, m, _m, m_, _l, l, l_, _xl, xl, xl_ };
};
