import { store } from '@backpackjs/storefront';
import cmsStore from './Cms';

const cartTotal$ = store.recoil.selector({
  key: 'cart/cartTotal$',
  get: ({ get }) => {
    const _cart = get(store.state.cart);
    if (!_cart) return 0;
    return parseFloat(_cart.estimatedCost.subtotalAmount.amount);
  },
});

/*
  Tiered/steps discounts
*/
const tiers$ = store.recoil.selector({
  key: 'cart/tiers$',
  get: ({ get }) => {
    const settings = get(cmsStore.siteSettings);
    if (!settings) return [];
    return settings?.cart?.discount?.steps;
  },
});

const activeTier$ = store.recoil.selector({
  key: 'cart/activeTier$',
  get: ({ get }) => {
    const tiers = get(tiers$);
    const cartTotal = get(cartTotal$);
    const settings = get(cmsStore.siteSettings);

    if (!settings?.cart?.discount?.enabled) return null;

    const activeTier = tiers.reduce((_activeTier, tier) => {
      if (cartTotal > tier.minimum) {
        _activeTier = tier;
      }
      return _activeTier;
    }, null);

    return activeTier;
  },
});

const activeTierAppliesDiscount$ = store.recoil.selector({
  key: 'cart/activeTierAppliesDiscount$',
  get: ({ get }) => {
    const activeTier = get(activeTier$);
    if (!activeTier) return false;
    const tierDiscountPercent = parseInt(activeTier.discount.split('%')[0]);
    return Number.isInteger(tierDiscountPercent);
  },
});

/*
  Gift wrap product / note
*/
const giftWrapNote = store.recoil.atom({
  key: 'cart/giftWrapNote',
  default: '',
});

const giftWrapAttribute = store.recoil.selector({
  key: 'cart/giftWrapAttribute',
  get: ({ get }) => {
    const _attributes = get(store.state.cartAttributes$);
    return _attributes?.find((attr) => attr.key === 'Gift note') || null;
  },
});

const giftWrapLineItemId$ = store.recoil.selectorFamily({
  key: 'cart/giftWrapLineItemId$',
  get: (handle) =>
    function ({ get }) {
      const _cart = get(store.state.cart);
      const { lines } = _cart;

      if (!lines?.length) return null;

      const giftWrapLineItem = lines?.find((item) => {
        return item.variant.product.handle === handle;
      });
      return giftWrapLineItem?.id || null;
    },
});

/*
  Gift with purchase
*/
const giftWithPurchaseEligibleInCart$ = store.recoil.selector({
  key: 'cart/giftWithPurchaseEligibleInCart$',
  get: ({ get }) => {
    const cart = get(store.state.cart);
    if (!cart?.lines?.length) return null;

    const settings = get(cmsStore.siteSettings);
    const gwpEnabled = settings?.cart?.gwp?.enabled;
    const gwpHandle = settings?.cart?.gwp?.giftProduct?.[0]?.product?.handle;
    const eligibleProducts = settings?.cart?.gwp?.eligibleProducts;

    if (!gwpEnabled || !gwpHandle || !eligibleProducts?.length) return null;

    const eligibleProductHandles = eligibleProducts
      .map((eligible) => eligible?.product?.handle)
      .filter(Boolean);

    // is any of the gwp eligible products in the cart?
    const eligibleProductInCart = cart.lines.find((line) => {
      return eligibleProductHandles.includes(line.variant.product.handle);
    });

    return eligibleProductInCart || null;
  },
});

const giftWithPurchaseMinimumQualifies$ = store.recoil.selector({
  key: 'giftWithPurchaseMinimumQualifies$',
  get: ({ get }) => {
    const cart = get(store.state.cart);
    if (!cart?.lines?.length) return null;

    const settings = get(cmsStore.siteSettings);
    const gwpEnabled = settings?.cart?.gwp?.enabled;
    const gwpHandle = settings?.cart?.gwp?.giftProduct?.[0]?.product?.handle;
    const gwpCartMinimum = settings?.cart?.gwp?.gwpCartMinimum;
    const cartSubtotal = parseFloat(
      cart?.estimatedCost?.subtotalAmount?.amount
    );

    if (!gwpEnabled || !gwpHandle || !gwpCartMinimum) return null;

    const gwpMinimumQualifies = cartSubtotal >= gwpCartMinimum;

    return gwpMinimumQualifies || null;
  },
});

const giftWithPurchaseLine$ = store.recoil.selector({
  key: 'cart/giftWithPurchaseLine$',
  get: ({ get }) => {
    const cart = get(store.state.cart);
    if (!cart?.lines?.length) return null;

    const settings = get(cmsStore.siteSettings);
    const gwpEnabled = settings?.cart?.gwp?.enabled;
    const gwpHandle = settings?.cart?.gwp?.giftProduct?.[0]?.product?.handle;

    if (!gwpEnabled || !gwpHandle) return null;

    const gwpAttribute = (attr) => attr.key === '_giftWithPurchase';

    const giftWithPurchaseLineItem = (line) => {
      const handlesMatch = line.variant.product.handle === gwpHandle;
      const hasGwpAttribute = line.attributes.find(gwpAttribute);
      return handlesMatch && hasGwpAttribute;
    };

    const gwpLine = cart?.lines?.find(giftWithPurchaseLineItem);

    return gwpLine || null;
  },
});

const isGiftWithPurchaseLine$ = store.recoil.selectorFamily({
  key: 'cart/giftWithPurchaseLine$',
  get:
    (lineId) =>
    ({ get }) => {
      const giftWithPurchaseLine = get(giftWithPurchaseLine$);
      if (!giftWithPurchaseLine) return false;

      return giftWithPurchaseLine.id === lineId;
    },
});

/*
  Upsell
*/
const upsellInCart = store.recoil.selectorFamily({
  key: 'cart/upsellInCart',
  get:
    (upsellProductHandle) =>
    ({ get }) => {
      const _cart = get(store.state.cart);
      const { lines } = _cart;

      return lines?.length
        ? lines.filter(
            (item) => item.variant.product.handle === upsellProductHandle
          )?.length > 0
        : false;
    },
});

/*
  Fondue Cashback
*/
const cashbackInCart = store.recoil.atom({
  key: 'cart/cashbackInCart',
  default: true, // set to true until cart is ready
});

/**
 * Totals
 */
// total value amount discounted by discount code applied (if any)
const totalDiscountAmount$ = store.recoil.selector({
  key: 'cart/totalDiscountAmount$',
  get: ({ get }) => {
    const _cart = get(store.state.cart);

    if (!_cart?.lines?.length) return 0;

    // sum up all discount allocations applied to cart items
    const totalDiscounts = _cart.lines.reduce((_totalDiscount, line) => {
      if (!line?.discountAllocations?.length) return _totalDiscount;
      return (
        _totalDiscount +
        parseFloat(line.discountAllocations[0].discountedAmount.amount)
      );
    }, 0);

    return totalDiscounts;
  },
});

// Total after applying tiering discounts
const totalTieredPrice$ = store.recoil.selector({
  key: 'cart/totalTieredPrice$',
  get: ({ get }) => {
    const _cart = get(store.state.cart);
    if (!_cart?.lines?.length) return 0;

    const activeTierAppliesDiscount = get(activeTierAppliesDiscount$);

    // if no tiers discount applied return 0
    if (!activeTierAppliesDiscount) return 0;

    return _cart?.lines.reduce((tieredTotal, line) => {
      const lineTieredPrice = parseFloat(
        line.estimatedCost.subtotalAmount.amount
      );
      return tieredTotal + lineTieredPrice;
    }, 0);
  },
});

const totalsRegular$ = store.recoil.selector({
  key: 'cart/totalsRegular$',
  get: ({ get }) => {
    const _cart = get(store.state.cart);
    if (!_cart) return 0;
    const cartTotal = get(cartTotal$);
    const activeTierAppliesDiscount = get(activeTierAppliesDiscount$);
    const totalDiscountAmount = get(totalDiscountAmount$);

    // these totals are only when there are NO tier discounts applied
    if (activeTierAppliesDiscount) {
      return {
        subtotal: 0,
        discount: 0,
        total: 0,
      };
    }

    // regular + discount code
    if (totalDiscountAmount) {
      return {
        subtotal: cartTotal + totalDiscountAmount, // tiered discount
        discount: totalDiscountAmount, // amount discounted off (code)
        total: cartTotal, // total after amount discount off
      };
    }

    // regular NO discount code applied
    return {
      subtotal: 0, //
      discount: 0,
      total: cartTotal,
    };
  },
});

// Totals when no tiered discount are applied
const totalsTiered$ = store.recoil.selector({
  key: 'cart/totalsTiered$',
  get: ({ get }) => {
    const _cart = get(store.state.cart);
    if (!_cart) return 0;
    const cartTotal = get(cartTotal$);
    const activeTierAppliesDiscount = get(activeTierAppliesDiscount$);
    const totalDiscountAmount = get(totalDiscountAmount$);
    const totalTieredPrice = get(totalTieredPrice$);

    // these totals are only when theres a tier discount applied
    if (!activeTierAppliesDiscount) {
      return {
        subtotal: 0,
        discount: 0,
        total: 0,
      };
    }

    // tiered + discount code
    if (totalDiscountAmount) {
      return {
        fullPrice: totalTieredPrice, // before tiered discount
        subtotal: totalTieredPrice, // tiered discount
        discount: totalDiscountAmount, // amount discounted off (code)
        total: cartTotal, // total after amount discount off
      };
    }

    // tiered with NO discount code applied
    return {
      fullPrice: 0,
      subtotal: cartTotal, //
      discount: 0,
      total: totalTieredPrice,
    };
  },
});

export default {
  tiers$,
  activeTier$,
  activeTierAppliesDiscount$,
  cartTotal$,
  giftWrapNote,
  giftWrapAttribute,
  giftWrapLineItemId$,

  isGiftWithPurchaseLine$,
  giftWithPurchaseEligibleInCart$,
  giftWithPurchaseMinimumQualifies$,
  giftWithPurchaseLine$,
  upsellInCart,
  cashbackInCart,

  totalDiscountAmount$,
  totalTieredPrice$,
  totalsTiered$,
  totalsRegular$,
};
