/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Flex, Box, Image, Spinner } from "theme-ui";
import {
  useRouter,
  useProductByHandle,
  useLocalizedVariant,
} from "@backpackjs/storefront";

import { isBrowser } from "@utils";

import { useDataLayerActions } from "@hooks";
import { ProductItemImage } from "./ProductItemImage";
import { ProductItemDetails } from "./ProductItemDetails";
import { ProductItemQuickShop } from "./ProductItemQuickShop";

export function ProductItem({
  handle,
  disableHover,
  index,
  itemsCount,
  imageSizes = "(max-width: 640px) 640px, (max-width: 1022px) 828px, (min-width: 1023px) 1110px",
  sx,
  isSwiper = false,
  featuredTag = null,
  altImage = null,
  hideQuickAdd,
  isSearchResults = false,
  isCollection = false,
  ...props
}) {
  const router = useRouter();
  const isCollectionPage =
    isCollection || router.asPath.includes("/collections");
  const productByHandle = useProductByHandle({ handle });
  const product = productByHandle?.product;

  const { sendClickProductItemEvent } = useDataLayerActions();

  const [onLoadSx, setOnLoadSx] = useState({
    opacity: 0,
    transform: "translateY(6px)",
  });
  const [isHover, setIsHover] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isFocused, setIsFocused] = useState(false);

  const [selectedVariant, setSelectedVariant] = useState(
    product?.variants?.[0]
  );
  const { localized } = useLocalizedVariant({
    variant: selectedVariant || productByHandle?.variants?.[0],
  });

  React.useEffect(() => {
    setSelectedVariant(product?.variants?.[0]);
  }, [product?.handle]);

  return !product?.handle ? (
    <Flex>
      <Spinner />
    </Flex>
  ) : (
    <Flex
      data-comp="ProductItem"
      variant="flex.column.center"
      onMouseEnter={() => {
        setIsHover(true);
        setIsFocused(false);
      }}
      onMouseLeave={() => setIsHover(false)}
      // onFocus={() => setIsHover(true)}
      tabIndex={0}
      onClick={() => {
        PubSub.publish("COLLECTION_PRODUCT_SELECTED", product);
        sendClickProductItemEvent({
          isSearchResults,
          listIndex: index,
          localized,
          product: productByHandle,
          selectedVariant,
        });
      }}
      sx={{
        justifyContent: "space-between",
        width: "100%",
        minHeight: isSwiper ? ["400px", "460px"] : null,
        bg: "white",
        border: "1px solid",
        borderColor: "gray.3",
        borderRadius: isCollectionPage
          ? ["8px", null, null, null, "6px"]
          : "10px",
        position: "relative",
        // transition: 'all 0.25s ease-in-out',
        zIndex: isCollectionPage
          ? [null, null, null, null, itemsCount - index]
          : itemsCount - index, // ensure drawer does not overlap with bottom item in 2x2 grids
        // transitionDelay: `${index * 0.1}s`,
        opacity: onLoadSx.opacity,
        // transform: onLoadSx.transform,
        "&:hover": {
          boxShadow: disableHover
            ? null
            : [null, "0px 4px 12px rgba(0, 0, 0, 0.16)"],
          ".product-item-shopify-image": {
            transform: isCollectionPage
              ? [null, null, null, "scale(.83)"]
              : "scale(.83)",
          },
          ".product-item-quick-shop-slider": {
            maxHeight: isCollectionPage
              ? "284px"
              : disableHover
              ? "16px"
              : ["16px", "284px"],
            opacity: null,
            boxShadow: disableHover
              ? null
              : [null, "0px 4px 12px rgba(0, 0, 0, 0.16)"],
          },
          ".product-item-image-wrap > *:last-child": {
            borderTop: isCollectionPage ? "0" : null,
            marginTop: isCollectionPage ? "1px" : null,
          },
          ".product-item-alt-image": {
            opacity: "1",
          },
          ".product-item-quick-shop-options": {
            visibility: disableHover
              ? "hidden"
              : isCollectionPage
              ? ["visible"]
              : ["hidden", "hidden", "hidden", null, "visible"],
            // transition: 'all 0.25s ease-in-out',
            // transitionDelay: '0.2s',
            opacity: 1,
          },
          ".product-item-starting-price": {
            display: isCollectionPage ? [null, "flex"] : "flex",
          },
        },
        ...sx,
      }}
    >
      {featuredTag ? (
        <Box
          sx={{
            position: "absolute",
            top: ["10px", null, null, null, "20px"],
            left: ["10px", null, null, null, "20px"],
            backgroundColor: "#333",
            borderRadius: "6px",
            color: "#cab683",
            fontSize: ["11px", null, null, null, "15px"],
            fontFamily: "heading",
            textTransform: "uppercase",
            padding: "4px 12px",
            minWidth: ["60px", null, null, null, "75px"],
            textAlign: "center",
            zIndex: "1",
          }}
        >
          {featuredTag}
        </Box>
      ) : null}
      <Box
        className="product-item-image-wrap"
        sx={{
          position: "relative",
          width: "100%",
          border: null,
          borderRadius: isCollectionPage
            ? ["8px 8px 0 0", null, null, null, "6px 6px 0 0"]
            : null,
          overflow: isCollectionPage ? ["hidden", null, null, null, ""] : null,
          background: isCollectionPage ? ["white", null, null, null, ""] : null,
        }}
      >
        <ProductItemImage
          handle={handle}
          title={product?.title || ""}
          src={product?.images[0]?.originalSrc}
          imageSizes={imageSizes}
          isSwiper={isSwiper}
          withViewProduct={isCollectionPage}
          isCollectionPage={isCollectionPage}
          onLoad={() => {
            try {
              setOnLoadSx({
                opacity: 1,
                transform: "translateY(0)",
              });
              isBrowser && window.yotpo?.refreshWidgets();
            } catch (error) {
              console.log("Yotpo refreshWidgets error", error.message);
            }
          }}
        />
        {altImage && (
          <Image
            className="product-item-alt-image"
            src={altImage?.src || altImage}
            sx={{
              borderRadius: "6px 6px 0 0",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              opacity: "0",
              pointerEvents: "none",
              transition: "all .3s ease-in-out",
            }}
          />
        )}
      </Box>

      <Flex
        variant="flex.column"
        sx={{
          width: "100%",
          minHeight: "80px",
          borderTopWidth: isCollectionPage ? [null, null, null, "1px"] : "1px",
          borderTopStyle: isCollectionPage
            ? [null, null, null, "solid"]
            : "solid",
          borderTopColor: "gray.3",
          px: isCollectionPage ? ["10px", null, null, 11] : [9, null, null, 11],
          pt: isCollectionPage
            ? ["15px", null, null, 11]
            : [10, null, null, 11],
          position: "relative",
        }}
      >
        <ProductItemDetails
          productItem={product}
          selectedVariant={selectedVariant}
          quantity={quantity}
          setQuantity={setQuantity}
          handle={handle}
          isCollectionPage={isCollectionPage}
          sx={{ visibility: "hidden" }}
          setIsHover={setIsHover}
          isHover={isHover}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
        />

        <ProductItemDetails
          productItem={product}
          selectedVariant={selectedVariant}
          quantity={quantity}
          setQuantity={setQuantity}
          handle={handle}
          isCollectionPage={isCollectionPage}
          setIsHover={setIsHover}
          isHover={isHover}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          sx={{
            bg: "white",
            width: "100%",
            height: isCollectionPage
              ? [null, null, null, null, "calc(100% - 15px)"]
              : "calc(100% - 15px)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            px: isCollectionPage
              ? ["10px", null, null, 11]
              : [9, null, null, 11],
            pt: isCollectionPage ? ["0px", null, null, 6] : 6,
          }}
        />
        {/* Desktop add to cart drawer */}
        {!hideQuickAdd && (
          <ProductItemQuickShop
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            productItem={product}
            quantity={quantity}
            setQuantity={setQuantity}
            isHover={isHover || isFocused}
            setIsHover={setIsHover}
            isCollectionPage={isCollectionPage}
          />
        )}
      </Flex>
    </Flex>
  );
}
