import { useEffect, useState, useMemo } from 'react';

import store, { useRecoilState } from '@store';
import { Dropdown } from '@snippets';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

export function ProductDropdownQuantity({ selectedVariant }) {
  const [activeQuantity, setActiveQuantity] = useRecoilState(
    store.activeQuantity(selectedVariant?.product?.handle)
  );
  const [activeIndex, setActiveIndex] = useState(activeQuantity - 1);

  useEffect(() => {
    setActiveQuantity(1);
  }, [selectedVariant?.product?.handle]);

  useEffect(() => {
    setActiveIndex(activeQuantity - 1);
  }, [activeQuantity]);

  const handleSelectedQuantity = (value) => {
    setActiveQuantity(parseInt(value));
  };

  // use live inventory data
  const { inventory, ...status } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
  });

  const variantInventory = inventory
    ? inventory?.variants?.[selectedVariant?.storefrontId]
    : null;

  const isSoldOut = variantInventory
    ? variantInventory.quantityAvailable <= 0 &&
      variantInventory.product?.handle !== 'truff-gift-card'
    : false;

  const quantities = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ];

  return (
    <Dropdown
      isSoldOut={isSoldOut}
      id="product-quantity-dropdown"
      onClick={handleSelectedQuantity}
      title="qty"
      items={quantities}
      initialIndex={activeIndex}
      sx={{
        height: '100%',
        zIndex: 15,
        pointerEvents: status?.success ? 'auto' : 'none',
      }}
    />
  );
}
