import { Image } from 'theme-ui';

export function SubCollectionSort({ toggleFilterDrawer, filterDrawerIndex, setFilterDrawerIndex, stopProp, collectionSortOptionsUnique, toggleSelectedSort, isSelectedSort, index, checkmarkSVG }) {
    return (
        <div
          sx={{
            marginLeft: 'auto',
            backgroundColor: '#fff',
            border: '1px solid currentColor',
            color: '#1a1a1a',
            position: 'relative',
            minWidth: [null, null, null, null, '210px'],
            width: ['calc(50% - 8px)', null, null, null, 'auto'],
            maxWidth: ['210px', null, null, null, 'none'],
          }}
        >
          <div
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 20px',
              cursor: 'pointer',
            }}
            onClick={() => toggleFilterDrawer(index, 'sort')}
          >
            <div
              sx={{
                fontSize: '15px',
                fontFamily: 'heading',
                letterSpacing: '0.02em',
                textTransform: 'uppercase',
                fontWeight: '700',
                lineHeight: '1.0667',
              }}
            >Sort By</div>
            <Image
              src={`/svg/chevron-down.svg`}
              sx={{
                width: '12px',
                transform: filterDrawerIndex == index+'-sort' ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          </div>
          <div
            onClick={() => setFilterDrawerIndex(null)}
            sx={{
              height: ['100%', null, null, null, '0'],
              width: ['100%', null, null, null, 'auto'],
              overflow: filterDrawerIndex == index+'-sort' ? null : 'hidden',
              position: filterDrawerIndex == index+'-sort' ? ['fixed', null, null, null, 'static'] : 'relative',
              left: ['0', null, null, null, 'auto'],
              top: ['0', null, null, null, 'auto'],
              backgroundColor: ['rgba(26, 26, 26, 0.6)', null, null, null, 'transparent'],
              zIndex: '1',
            }}
          >
            <div
              onClick={() => setFilterDrawerIndex(null)}
              sx={{
                display: filterDrawerIndex == index+'-sort' ? null : 'none',
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
            ></div>
            <div
              onClick={stopProp}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                backgroundColor: '#fff',
                borderColor: 'currentColor',
                borderStyle: 'solid',
                borderWidth: ['0', null, null, null, '0 1px 1px'],
                padding: ['20px 12px 30px', null, null, null, '20px'],
                margin: '0',
                position: 'absolute',
                zIndex: '1',
                top: [null, null, null, null, '100%'],
                bottom: ['0', null, null, null, 'auto'],
                left: ['0', null, null, null, '-1px'],
                width: ['100%', null, null, null, 'calc(100% + 2px)'],
                backgroundColor: ['#000', null, null, null, '#fff'],
                color: ['#fff', null, null, null, 'inherit'],
                '&:before': {
                  content: [null, null, null, null, '""'],
                  display: 'block',
                  height: '1px',
                  background: 'currentcolor',
                  position: 'absolute',
                  top: '0',
                  left: '20px',
                  right: '20px',
                },
              }}
            >
              <div
                sx={{
                  display: [null, null, null, null, 'none'],
                  width: '12px',
                  height: '12px',
                  position: 'relative',
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  '&:before, &:after': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#cab683',
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    transformOrigin: 'center',
                  },
                  '&:before': {
                    transform: 'rotate(45deg)',
                  },
                  '&:after': {
                    transform: 'rotate(-45deg)',
                  },
                }}
                onClick={() => setFilterDrawerIndex(null)}
              ></div>
              <div
                sx={{
                  display: ['flex', null, null, null, 'none'],
                  gap: '14px',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  sx={{
                    fontFamily: 'heading',
                    fontSize: '18px',
                    lineHeight: '1.22',
                    letterSpacing: '0.02em',
                    textTransform: 'uppercase',
                  }}
                >Sort By</div>
                <div
                  sx={{
                    height: '1px',
                    flexGrow: '1',
                    flexShrink: '1',
                    backgroundColor: 'currentColor',
                  }}
                ></div>
              </div>
              {collectionSortOptionsUnique.map((sortOption,idx) => {
                return (<div key={`${sortOption}-${idx}`}>
                  <button
                    onClick={() => toggleSelectedSort(sortOption)}
                    sx={{
                      color: 'inherit',
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      position: 'relative',
                      background: 'none',
                      border: '0',
                      width: '100%',
                      textAlign: 'left',
                      padding: '0',
                      fontSize: '15px',
                      lineHeight: '1.47',
                      letterSpacing: '0.02em',
                      cursor: 'pointer',
                      '&:before, &:after': {
                        content: '""',
                        display: 'block',
                        width: '16px',
                        height: '16px',
                      },
                      '&:before': {
                        border: (isSelectedSort(sortOption)) ? null : ['1px solid #fff', null, null, null, '1px solid #d7d9db'],
                        background: (isSelectedSort(sortOption)) ? '#cab683' : [null, null, null, null, '#fff'],
                      },
                      '&:after': {
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        backgroundImage: (isSelectedSort(sortOption)) ? `url('${checkmarkSVG}')` : '',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '10px',
                      },
                    }}
                  >{sortOption}</button>
                </div>);
              })}
            </div>
          </div>
        </div>);
}