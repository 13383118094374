export const Schema = () => {
  return {
    label: "Bundle Builder",
    key: "bundlebuilder",
    fields: [
      {
        component: "text",
        name: "bundleHeading",
        label: "Bundle Heading",
        defaultValue: "Build Your Bundle",
      },
      {
        component: "group-list",
        name: "bundleCollections",
        label: "Bundle Collections",
        itemProps: {
          label: "Collection: {{item.bundleCollectionTitle}}",
        },
        fields: [
          {
            component: "text",
            name: "bundleCollectionTitle",
            label: "Collection Title",
          },
          {
            component: "collections",
            name: "bundleCollection",
            label: "Bundle Collection",
          },
        ],
        defaultValue: [
          {
            bundleCollectionTitle: "My Collection",
            bundleCollection: "Collection",
          },
        ],
      },
    ],
  };
};
