import { Flex, Paragraph } from 'theme-ui';

export const OrderHistoryColumnHeadings = () => {
  return (
    <Flex
      data-comp="OrderHistoryColumnHeadings"
      variant="flex.row.between"
      sx={{
        pb: 5,
        borderBottom: '1px solid',
        borderColor: 'black',
      }}
    >
      {headingsOrder.map((heading, index) => (
        <Paragraph
          key={index}
          variant="label.2"
          sx={{
            flexBasis: [heading.flexBasis, null, 'calc(25% - 6px)'],
            textAlign: [heading.textAlign, null, 'left'],
          }}
        >
          {heading.title}
        </Paragraph>
      ))}
    </Flex>
  );
};

const headingsOrder = [
  {
    title: 'Order',
    flexBasis: '75px',
    textAlign: 'left',
  },
  {
    title: 'Date',
    flexBasis: '85px',
    textAlign: 'center',
  },
  {
    title: 'Status',
    flexBasis: '70px',
    textAlign: 'center',
  },
  {
    title: 'Total',
    flexBasis: '60px',
    textAlign: 'right',
  },
];
