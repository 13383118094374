import { useCallback } from 'react';
import { useStartFinishErrors } from '@backpackjs/storefront';

export const useBackInStock = () => {
  const { setStarted, setFinished, setErrors, reset, ...status } =
    useStartFinishErrors();

  const subscribeToBackInStock = useCallback(async ({ email, variantId }) => {
    try {
      if (!email) {
        return console.error('Missing required parameter: email');
      }
      if (!variantId) {
        return console.error('Missing required parameter: variant');
      }

      reset();
      setStarted(true);
      const endpoint = '/api/klaviyo';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'backInStockSubscribe',
          email,
          variant: variantId,
        }),
      };

      const response = await fetch(endpoint, options);

      if (!response.ok) {
        setErrors(['Error with subscribing to back in stock.']);
        const data = await response.json();
        console.error(`Response back from API not successful: ${data?.detail}`);
        throw new Error('No data returned from subscribe');
      }

      setFinished(true);
      return true;
    } catch (error) {
      setErrors(['Error with subscribing to back in stock.']);
      console.error(`Error with subscribeToBackInStock: ${error}`);
    }
  }, []);

  return {
    subscribeToBackInStock,
    setErrors,
    reset,
    ...status,
  };
};
