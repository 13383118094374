import { useEffect } from 'react';

import { Flex, Paragraph } from 'theme-ui';

import store, { useRecoilState, useRecoilValue } from '@store';
import { ProductSellingPlanRadio } from './ProductSellingPlanRadio';
import { KlarnaWidget } from './KlarnaWidget';

export function ProductSellingPlanOnetime({
  isSelected,
  plan,
  selectedVariant,
  isSingleBottlePdp,
}) {
  const price = plan?.pricing?.price?.amount;

  const [activeQuantityPrice, setActiveQuantityPrice] = useRecoilState(
    store.activeQuantityPrice(selectedVariant?.product?.handle)
  );

  const activeQuantity = useRecoilValue(
    store.activeQuantity(selectedVariant?.product?.handle)
  );

  useEffect(() => {
    const _price = price * activeQuantity;
    setActiveQuantityPrice(_price.toFixed(2));
  }, [activeQuantity, price]);

  return (
    <Flex
      data-comp={ProductSellingPlanOnetime.displayName}
      variant="flex.row.start"
      key={plan.type}
      sx={{
        mb: 5,
        mt: 2,
      }}
    >
      <ProductSellingPlanRadio
        isSelected={isSelected}
        plan={plan}
        selectedVariant={selectedVariant}
      />

      <Flex variant="flex.column" sx={{ ml: 6 }}>
        <Flex variant="flex.row.start.center" sx={{ color: 'white' }}>
          <Paragraph variant="label.3" sx={{ mt: 1 }}>
            {plan.message}
          </Paragraph>

          {isSingleBottlePdp && activeQuantityPrice && (
            <Paragraph sx={{ ml: 4 }}>${activeQuantityPrice}</Paragraph>
          )}

          {!isSingleBottlePdp && price && (
            <Paragraph sx={{ ml: 4 }}>${price}</Paragraph>
          )}

          {isSelected ? (
            <Paragraph
              sx={{
                fontSize: 1,
                color: 'gray.3',
                ml: 3,
              }}
            >
              {plan.save}
            </Paragraph>
          ) : null}
        </Flex>

        <KlarnaWidget price={activeQuantityPrice || price} />
      </Flex>
    </Flex>
  );
}

ProductSellingPlanOnetime.displayName = 'ProductSellingPlanOnetime';
