import { useMemo } from "react";
import { Grid, Container } from "theme-ui";
import { withInView, entryAnimation } from "@utils";
import { HalfBlock } from "@snippets";
import { Schema } from "./HalfBlocks.schema";

export const HalfBlocks = withInView(
  ({ cms, inView }) => {
    const { section, blocks, backgroundColor, color, py, columnDirection } =
      cms;

    const _blocks = blocks?.slice(0, 2);

    const oneBlockOnMobile = _blocks?.find(
      (block) => block?.hideMobile === true
    );

    const isNutritionBlock = useMemo(
      () => _blocks?.some((item) => item._template === "nutrition-block"),
      [_blocks]
    );

    const disabledPy = py === "off";

    const isCenterSingle =
      blocks?.length === 1 && section?.desktop?.centerSingle;

    return (
      <Container
        className={isNutritionBlock ? "product-nutrition" : ""}
        data-comp="HalfBlocks"
        sx={{
          mx: "auto",
          position: "relative",
          overflow: "hidden",
          width: "100%",
          bg: backgroundColor || "white",
          py: disabledPy ? 0 : [13, null, null, 17],
          px: [6, 11, null, 0],
          ...entryAnimation({
            entered: inView,
            fadeIn: true,
            slideIn: false,
            duration: 500,
            index: 1,
            delay: 0,
          }),
        }}
      >
        <Grid
          sx={{
            gridTemplateColumns: [
              "1fr",
              null,
              null,
              isCenterSingle ? "1fr 2fr 1fr" : "1fr 1fr",
            ],
            gridTemplateAreas: [
              columnDirection === "column-reverse"
                ? '"grid-area-2" "grid-area-1"'
                : '"grid-area-1" "grid-area-2"',
              null,
              null,
              isCenterSingle
                ? '"grid-area-2 grid-area-1 grid-area-3"'
                : '"grid-area-1 grid-area-2"',
            ],
            columnGap: 9,
            rowGap: oneBlockOnMobile ? 0 : [10, 11],
            position: "relative",
            width: "100%",
            mx: "auto",
            maxWidth: [
              section?.mobile?.maxWidth || "unset",
              null,
              null,
              section?.desktop?.maxWidth || "unset",
            ],
          }}
        >
          {_blocks?.map((block, index) => (
            <HalfBlock
              key={index}
              block={block}
              blocks={_blocks}
              index={index}
              disabledPy={disabledPy}
              columnDirection={columnDirection}
              bgColor={backgroundColor}
              color={color}
              isCenterSingle={isCenterSingle}
            />
          ))}
        </Grid>
      </Container>
    );
  },
  { triggerOnce: true }
);

HalfBlocks.Schema = Schema;
