import { forwardRef } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export const PhoneField = forwardRef(({ required, setPhoneError }, ref) => {
  const updateState = (isValid, number, country) => {
    if (!isValid) {
      setPhoneError("Phone number is invalid");
    } else {
      setPhoneError(null);
    }

    if (number?.length >= 1) {
      ref.current.value = `+${country.dialCode}${number}`;
    } else {
      ref.current.value = undefined;
    }
  };

  return (
    <>
      <style>
        {`
          .intlTelInputClass {
            font-size: 16px;
            font-family: Montserrat, Helvetica, Arial, sans-serif !important;
            font-weight: 600;
            height: 38px;
            width: 100%;
            background-color: #000000;
            border: 1px solid #b4bbc3;
            border-radius: 2px;
            color: #cab683;
          }

          .intlTelInputClass:focus {
            box-shadow: rgb(202, 182, 131) 0 0 5px;
            outline: none;
          }

          .intlTelInputClass::placeholder {
            font-size: 16px;
            font-family: Montserrat, Helvetica, Arial, sans-serif !important;
            font-weight: 600;
            color: #D0C3AA;
          }

          .intl-tel-input .selected-flag {
            display: flex !important;
            justify-content: flex-start;
            margin-left: 10px !important;
          }

          .intl-tel-input .selected-dial-code {
            position: relative;
            top: 1px;
            font-family: Montserrat, Helvetica, Arial, sans-serif !important;
            font-size: 16px;
            font-weight: 600;
            color: #D0C3AA;
            padding-left: 5px !important;
            order: 1;
            pointer-events: none;
          }

          .intl-tel-input .arrow.down, .intl-tel-input .arrow.up {
            position: relative;
            top: 1px;
            pointer-events: none;
          }

          .intl-tel-input .arrow.down::after, .intl-tel-input .arrow.up::after {
            color: #b4bbc3;
            font-size: 12px;
          }
        `}
      </style>
      <IntlTelInput
        fieldId="phone"
        inputClassName="intlTelInputClass"
        autoPlaceholder={false}
        onPhoneNumberBlur={(isValid, number, country) => {
          updateState(isValid, number, country);
        }}
        onPhoneNumberChange={(isValid, number, country) => {
          updateState(isValid, number, country);
        }}
        onSelectFlag={(number, country, _, isValid) => {
          updateState(isValid, number, country);
        }}
        placeholder="Phone Number"
        ref={ref}
        separateDialCode
        telInputProps={{
          required,
        }}
      />
    </>
  );
});

PhoneField.displayName = "PhoneField";
