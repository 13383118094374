export default {
  label: 'Account Details Settings',
  name: 'details',
  component: 'group',
  fields: [
    {
      component: 'text',
      name: 'passwordHeading',
      label: 'Reset Password Confirmation Heading',
      description: 'Heading for the confirmation message modal',
      defaultValue: 'New Password Sent',
    },
    {
      component: 'textarea',
      name: 'passwordMessage',
      label: 'Reset Password Confirmation Message',
      description: 'Message for the confirmation message modal',
      defaultValue:
        'Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua.',
    },
  ],
};
