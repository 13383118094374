import { useRef, useState, useCallback, Fragment } from "react";
import {
  Container,
  Checkbox,
  Box,
  Flex,
  Label,
  Button,
  Paragraph,
  Input,
} from "theme-ui";

import { Markdown } from "@snippets";
import { addToKlaviyoList, withInView, trackKlaviyoEvent } from "@utils";

import { PhoneField } from "./PhoneField";
import { themed } from "./KlaviyoForm.theme";
import { Schema } from "./KlaviyoForm.schema";

export const KlaviyoForm = withInView(
  themed(({ theme, cms }) => {
    const {
      listId,
      listName,
      formFields,
      submitText,
      formSuccessMessage,
      useKlaviyoEmbedForm,
      formId,
    } = cms;

    const {
      capturePhoneNumber,
      consentText,
      consentIsRequired,
      phoneNumberIsRequired,
    } = formFields;

    const emailRef = useRef();
    const phoneRef = useRef();

    const [errors, setErrors] = useState([]);
    const [phoneError, setPhoneError] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        const phoneNumber = phoneRef.current?.value;

        if (phoneError && phoneNumber) {
          setErrors([phoneError]);
          return;
        }
        setErrors([]);

        try {
          const addToListResponse = await addToKlaviyoList({
            email,
            phoneNumber,
            listId: listId || "SpcGLB",
          });

          if (addToListResponse?.errors) {
            setErrors([addToListResponse.errors]);
            return;
          }

          const addEventResponse = await trackKlaviyoEvent({
            email,
            phoneNumber,
            listName,
            eventType: "list",
          });

          if (addEventResponse?.errors) {
            setErrors([addEventResponse.errors]);
          }

          setFormSubmitted(true);
        } catch (error) {
          console.error("Klaviyo:trackKlaviyoEvent error", error.message);
          setErrors([error.message]);
        }
      },
      [listId, listName, phoneError]
    );

    return (
      <Container data-comp={KlaviyoForm.displayName} sx={theme.wrapper}>
        {useKlaviyoEmbedForm ? (
          <div className={`klaviyo-form-${formId}`} />
        ) : formSubmitted ? (
          <Box sx={theme.successMessageBox}>
            <Markdown
              text={formSuccessMessage || "Submission sent!"}
              sx={theme.successMessage}
            />
          </Box>
        ) : (
          <Box
            as="form"
            sx={theme.form}
            method="POST"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
          >
            <Label htmlFor="email" sx={theme.inputWrapper}>
              <Box as="span" sx={theme.label}>
                EMAIL
              </Box>
              <Input
                id="email"
                placeholder="Email Address"
                ref={emailRef}
                required
                type="email"
                sx={theme.input}
              />
            </Label>

            {/* DO NOT CHANGE htmlFor value. Needs to be id - not sure why */}
            {capturePhoneNumber && (
              <Label htmlFor="id" sx={theme.inputWrapper}>
                <Box as="span" sx={theme.label}>
                  PHONE {!phoneNumberIsRequired && "(Optional)"}
                </Box>
                <PhoneField
                  ref={phoneRef}
                  required={phoneNumberIsRequired}
                  setPhoneError={setPhoneError}
                />
              </Label>
            )}

            {consentText && (
              <>
                <Markdown
                  text={consentText || "Submission sent!"}
                  sx={theme.consentText}
                />
                {consentIsRequired && (
                  <Label
                    htmlFor="consent-checkbox"
                    sx={{
                      ...theme.inputWrapper,
                      flexDirection: "row",
                      my: 0,
                    }}
                  >
                    <Checkbox
                      id="consent-checkbox"
                      required
                      sx={{
                        color: "#b4bbc3",
                      }}
                    />
                    <Box as="span" sx={{ ...theme.label, mt: "1px" }}>
                      {`I agree with TRUFF's Privacy Policy.`}
                    </Box>
                  </Label>
                )}
              </>
            )}

            <Flex
              sx={{
                ...theme.submitWrapper,
              }}
            >
              <Button type="submit" sx={theme.submit}>
                {submitText || "Submit"}
              </Button>

              <Flex sx={theme.errors}>
                {errors.map((error, idx) => (
                  <Paragraph key={idx} sx={theme.error}>
                    {error}
                  </Paragraph>
                ))}
              </Flex>
            </Flex>
          </Box>
        )}
      </Container>
    );
  }),
  { triggerOnce: true }
);

KlaviyoForm.displayName = "KlaviyoForm";
KlaviyoForm.Schema = Schema;
