import { useEffect, useState } from 'react';
import { Box } from 'theme-ui';

import store, { useRecoilState, useRecoilValue } from '@store';
import { ProductMain } from '@templates/product/ProductMain';
import { BackInStockModal } from '@snippets/BackInStockModal';

export function Modal({ ...props }) {
  const [fullProduct, setFullProduct] = useState(null);
  const [overlay, setOverlay] = useRecoilState(store.overlay);
  const [modal, setModal] = useRecoilState(store.modal);

  const isProductModal = modal?.type === 'product';
  const isBackInStockModal = modal?.type === 'back-in-stock';

  const haveProductHandle = isProductModal && modal?.handle;
  const loadFullJsonProduct = () => {
    let cancelled = false;
    if (!isProductModal || !haveProductHandle) return;

    const fetchJsonProduct = async (handle) => {
      let product = null;

      try {
        const jsonApiUrl = `${window.location.protocol}/json/products/${handle}.json`;
        const response = await fetch(jsonApiUrl);
        if (response?.ok) {
          product = await response.json();
        }
        return product;
      } catch (error) {
        console.log('error', error);
        return null;
      }
    };

    fetchJsonProduct(modal.handle)
      .then((product) => {
        if (product) {
          if (!cancelled) {
            setFullProduct(product);
          }
        }
      })
      .catch((error) => {
        console.log('loadFullJsonProduct:error', error);
        if (!cancelled) {
          setFullProduct(null);
        }
      });

    return () => {
      cancelled = true;
    };
  };

  const closeModal = () => {
    setModal(null);
    setOverlay(false);
  };

  useEffect(() => {
    const maybeCloseProductDrawer = ({ target }) => {
      // auto close product modal if open bellow 1024px
      if (isProductModal && target.innerWidth <= 1024) {
        closeModal();
      }
    };

    window.addEventListener('resize', maybeCloseProductDrawer, false);

    return () => {
      window.removeEventListener('resize', maybeCloseProductDrawer, false);
    };
  }, []);

  useEffect(loadFullJsonProduct, [modal?.handle]);

  const bisSelectedVariant = useRecoilValue(store.bisSelectedVariant);
  const isGear =
    bisSelectedVariant?.product?.productType.toLowerCase() === 'fashion';
  const variantTitle = isGear ? bisSelectedVariant?.title : null;

  return (
    <Box
      as="aside"
      data-comp="Modal"
      {...props}
      sx={{
        zIndex: 40,
        position: 'fixed',
        border: isBackInStockModal ? (t) => `3px solid ${t.colors.gold}` : null,
        top: isBackInStockModal
          ? '50%'
          : 'calc(calc(var(--promoBar-height) * 2) + var(--header-height))',
        left: isBackInStockModal ? '50%' : 0,
        transform: isBackInStockModal
          ? 'translateX(-50%) translateY(-50%)'
          : 'none',
        right: isBackInStockModal ? 'unset' : 0,
        mx: 'auto',
        minHeight: isBackInStockModal ? 'unset' : '700px',
        maxWidth: isBackInStockModal ? '92%' : '1200px',
        width: isBackInStockModal
          ? 'max-content'
          : ['100%', null, null, null, '83.33%'],
        boxShadow: '0px -3px 30px rgb(0 0 0 / 8%)',
        pt: [10, null, null, null, 13],
        pb: [10, null, null, null, 13],
        px: [10, null, 12, 13, 14, 15, 17],
        backgroundColor: 'white',
        display:
          modal && modal?.type !== 'rewards'
            ? isProductModal
              ? ['none', 'none', 'none', 'none', 'flex']
              : 'flex'
            : 'none',
        opacity: modal ? 1 : 0,
        willChange: 'opacity',
        transition: 'opacity .3s ease-in-out',
        ...props.sx,
      }}
    >
      {/* close modal */}
      <Box
        sx={{
          position: 'absolute',
          cursor: 'pointer',
          right: '20px',
          top: '20px',
        }}
        onClick={closeModal}
      >
        <img
          alt="Close Modal"
          src="/svg/close.svg"
          width="20px"
          height="20px"
        />
      </Box>

      {isProductModal &&
      modal?.handle &&
      fullProduct &&
      fullProduct?.handle === modal.handle ? (
        <ProductMain product={fullProduct} inQuickAdd />
      ) : null}

      {isBackInStockModal && (
        <BackInStockModal
          legacyResourceId={bisSelectedVariant?.legacyResourceId}
          title={bisSelectedVariant?.product?.title || ''}
          variantTitle={variantTitle}
        />
      )}
    </Box>
  );
}
