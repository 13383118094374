import { menuItemLink, menuItemsWithImages, imagesWithAlt } from './common.js';

export default {
  label: 'Header Settings',
  name: 'header',
  component: 'group',
  fields: [
    {
      label: 'Promobar',
      name: 'promoBar',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable Promobar',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          component: 'group-list',
          name: 'promobarItems',
          label: 'Promobars',
          itemProps: (item) => ({
            label:
              (item?.pages?.length > 0
                ? item?.pages?.length +
                  (item?.pages?.length > 1 ? ' pages' : ' page')
                : 'All pages') +
                ' | ' +
                (item.carouselType == 'smooth'
                  ? 'Smooth Scroll'
                  : item.carouselType == 'carousel'
                  ? 'Carousel'
                  : '') +
                ' | ' +
                item.messages?.length +
                ' messages' || 'New promobar',
          }),
          defaultItem: (item) => ({
            pages: [],
            carouselType: 'carousel',
            messages: [],
          }),
          fields: [
            {
              component: 'group-list',
              name: 'pages',
              label: 'Pages',
              description:
                'The series of pages to display this promobar on. Available on every page if there are no links assigned.',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  component: 'link',
                  name: 'link',
                  label: 'Link',
                },
              ],
            },
            {
              component: 'toggle',
              name: 'forReturnVisitors',
              label: 'Return Visitors',
              description:
                'Only display this promobar set if the user is a return visitor.',
              toggleLabels: {
                true: 'Return Visitors Only',
                false: 'All Visitors',
              },
              defaultValue: false,
            },
            {
              component: 'select',
              name: 'carouselType',
              label: 'Carousel Type',
              options: [
                {
                  label: 'Carousel',
                  value: 'carousel',
                },
                {
                  label: 'Smooth scroll',
                  value: 'smooth',
                },
              ],
              defaultValue: 'carousel',
            },
            {
              component: 'group-list',
              name: 'messages',
              label: 'Messages',
              itemProps: {
                label: '{{item.content}}',
              },
              fields: [
                {
                  component: 'markdown',
                  name: 'content',
                  label: 'Message Content',
                },
                {
                  component: 'link',
                  name: 'link',
                  label: 'Message Link',
                },
                {
                  component: 'select',
                  name: 'linkType',
                  label: 'Link Type',
                  options: [
                    {
                      label: 'Text Link',
                      value: 'textlink',
                    },
                    {
                      label: 'Button',
                      value: 'button',
                    },
                  ],
                  defaultValue: 'textlink',
                },
                {
                  component: 'toggle',
                  name: 'linkIsCode',
                  label: 'Link Function',
                  toggleLabels: {
                    true: 'Copy Code',
                    false: 'Link',
                  },
                  defaultValue: false,
                },
                {
                  component: 'select',
                  name: 'textLinkColor',
                  label: 'Text Link Color',
                  options: [
                    {
                      label: 'White',
                      value: 'white',
                    },
                    {
                      label: 'Gold',
                      value: 'gold',
                    },
                  ],
                  defaultValue: 'white',
                },
                {
                  component: 'toggle',
                  name: 'isCountdown',
                  label: 'Countdown',
                  toggleLabels: {
                    true: 'Enabled',
                    false: 'Disabled',
                  },
                },
                {
                  component: 'select',
                  name: 'countdownPosition',
                  label: 'Countdown Position',
                  options: [
                    {
                      label: 'Top',
                      value: 'top',
                    },
                    {
                      label: 'Right',
                      value: 'right',
                    },
                  ],
                  defaultValue: 'top',
                },
                {
                  component: 'number',
                  name: 'countdownTimestamp',
                  label: 'Countdown Timestamp',
                  defaultValue: 86400000,
                },
              ],
            },
          ],
        },
        {
          component: 'number',
          name: 'duration',
          label: 'Autoplay duration',
          description: 'Time on each message. (sec)',
          step: 1,
          defaultValue: 3,
        },
        {
          component: 'number',
          name: 'speed',
          label: 'Autoplay speed',
          description: 'Time between message transitions. (sec)',
          step: 0.25,
          defaultValue: 0.75,
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message.text}}',
          },
          defaultItem: (item) => ({
            name: 'New message',
            id: Math.random().toString(36).substr(2, 9),
            message: {
              url: '',
              text: '',
            },
          }),
          fields: [
            {
              label: 'Message',
              description:
                'A promo bar message and optional link. i.e Free shipping on orders..',
              name: 'message',
              component: 'link',
            },
          ],
        },
      ],
    },

    // header.headerMenu
    {
      label: 'Collections',
      name: 'collectionLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: (item) => ({
        name: 'New links group',
        id: Math.random().toString(36).substr(2, 9),
        link: {
          url: '',
          text: '',
        },
      }),
      fields: menuItemsWithImages,
    },

    // header.headerLinks -> Account / Contact Us..
    {
      label: 'Explore',
      name: 'exploreLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: (item) => ({
        name: 'New item',
        id: Math.random().toString(36).substr(2, 9),
        link: {
          url: '',
          text: '',
        },
      }),
      fields: [
        // level 2
        ...menuItemLink,
        imagesWithAlt,
      ],
    },
  ],
};
