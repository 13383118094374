import { useMemo, useCallback, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Flex, Box, Button, Paragraph, Heading } from "theme-ui";
import { useSettings } from "@backpackjs/storefront";

import { UnderlineLink, YotpoStars, AddToCart, InlineHeading } from "@snippets";
import store, {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from "@store";

import { useRouter } from "next/router";
import { ProductWhatsIncluded } from "./ProductWhatsIncluded";
import { ProductDropdownFlavors } from "./ProductDropdownFlavors";
import { ProductTabFlavors } from "./ProductTabFlavors";
import { ProductDropdownGiftCards } from "./ProductDropdownGiftCards";
import { ProductDropdownSizes } from "./ProductDropdownSizes";
import { ProductSellingPlans } from "./ProductSellingPlans";
import { ProductDropdownQuantity } from "./ProductDropdownQuantity";
import { ProductOptionSelector } from "./ProductOptionSelector";
import { ProductQuantitySelector } from "./ProductQuantitySelector";
import { HeatScale } from "./HeatScale";

export function ProductDetails({
  groupingProducts,
  product,
  selectedProductIndex,
  selectedVariant,
  inQuickAdd = false,
  whatsIncluded,
  selectOption,
  isSubscriptionOnly,
  ...props
}) {
  const router = useRouter();
  const isPage = router.route.startsWith("/pages");

  const settings = useSettings();
  const [nutritionEl, setNutritionEl] = useState(null);

  const setOverlay = useSetRecoilState(store.overlay);
  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const country = useRecoilValue(store.country);
  const [activeQuantity, setActiveQuantity] = useRecoilState(
    store.activeQuantity(selectedVariant?.product?.handle)
  );

  const isGrouped = product?.isGrouped;
  const isGiftCard = product?.productType === "Gift Cards";
  const isFashion = product?.productType === "Fashion";
  const hasSizeOptions = product?.variants?.length > 1;

  const nutritionFactsOverride = useMemo(() => {
    const overrideTag = selectedVariant?.product?.tags?.find((tag) =>
      tag.startsWith("nutrition-facts-override::")
    );
    return overrideTag?.split("::")[1]?.trim();
  }, [selectedVariant?.product?.tags]);

  const isSingleBottlePdp = useMemo(() => {
    return selectedVariant?.product?.tags?.find((tag) =>
      tag.startsWith("single-bottle-pdp")
    );
  }, [selectedVariant?.product?.tags]);

  const heatScale = useMemo(() => {
    const rating = selectedVariant?.product?.tags?.find((tag) =>
      tag.startsWith("rating")
    );
    return rating?.split(":")[1]?.trim();
  }, [selectedVariant?.product?.tags]);

  const sellingPlans = useRecoilValue(
    store.sellingPlans({ product, handle: selectedVariant.product.handle })
  );

  const [selectedPlan, setSelectedPlan] = useRecoilState(
    store.selectedPlan(selectedVariant?.product?.handle)
  );

  const setAddToCartMounted = useSetRecoilState(store.addToCartMounted);

  // if isSubscriptionOnly set plan to subscribe
  useEffect(() => {
    if (!sellingPlans?.length || !isSubscriptionOnly) return;

    const subscribePlan = sellingPlans?.find(
      (plan) => plan.type === "subscribe"
    );
    setSelectedPlan(subscribePlan);
  }, [
    sellingPlans?.length,
    selectedVariant?.product?.handle,
    isSubscriptionOnly,
  ]);

  const setSelectedFreq = useSetRecoilState(
    store.selectedFreq(selectedVariant.product.handle)
  );

  // if isSubscriptionOnly set frequency to 30 days
  useEffect(() => {
    if (!sellingPlans?.length || !selectedPlan || !isSubscriptionOnly) return;

    const selectFreq = selectedPlan?.options.find((option) =>
      option.label.includes("30")
    );
    setSelectedFreq(selectFreq);
  }, [
    sellingPlans?.length,
    selectedVariant?.product?.handle,
    isSubscriptionOnly,
    selectedPlan?.message,
  ]);

  const closeSideDrawer = useCallback(() => {
    setSideDrawer(null);
    setOverlay(false);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const nutritionEls = document.getElementsByClassName("product-nutrition");
      if (nutritionEls?.[0]) {
        setNutritionEl(nutritionEls?.[0]);
        clearInterval(interval);
      }
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setAddToCartMounted(true);
  }, []);

  const isOneTime = selectedPlan?.type === "onetime" || !selectedPlan;

  useEffect(() => {
    if (isSingleBottlePdp) {
      setActiveQuantity(2);
    }
  }, [selectedVariant?.product?.handle, isSingleBottlePdp]);

  const updateBWPQuantity = useCallback(() => {
    console.log(
      "logs bwp updating widget for quantity",
      selectedVariant?.sku,
      activeQuantity,
      window.bwp?.updateWidget
    );

    window.bwp?.updateWidget(selectedVariant?.sku, activeQuantity);
  }, [selectedVariant?.sku, activeQuantity]);

  useEffect(() => {
    document.getElementById("bwp-script")?.remove();
    if (!selectedVariant?.sku || !activeQuantity) {
      return;
    }
    const script = document.createElement("script");
    script.src = "https://code.buywithprime.amazon.com/bwp.v1.js";
    script.id = "bwp-script";
    script.async = true;
    script.fetchpriority = "high";
    script.onload = () => {
      if (
        window.bwp?.quantity !== activeQuantity &&
        typeof window.bwp?.updateWidget === "function"
      ) {
        updateBWPQuantity();
      }
    };

    document.head.appendChild(script);
  }, [selectedVariant?.sku, activeQuantity]);

  return (
    <Flex
      data-comp="ProductDetails"
      variant="flex.row.center.center"
      {...props}
      sx={{
        width: ["100%", null, null, "50%"],
        position: "relative",
        px: [6, 11],
        py: inQuickAdd ? [0, 9] : [10, 11],
        ...props.sx,
      }}
    >
      <Flex
        variant="flex.column"
        sx={{
          width: "100%",
          maxWidth: ["100%", null, null, "440px"],
          mt: inQuickAdd ? 9 : 0,
          gap: 4,
        }}
      >
        {product?.legacyResourceId ? (
          <YotpoStars legacyResourceId={product.legacyResourceId} />
        ) : null}

        <Heading
          as="h1"
          variant="24.r"
          sx={{
            color: "white",
            mt: 3,
          }}
        >
          {selectedVariant?.product?.title}
        </Heading>

        <Flex
          sx={{
            display:
              !sellingPlans?.length || isSubscriptionOnly ? "flex" : "none",
            minHeight: "24px",
            mt: 3,
            mb: [2, null, null, 2],
          }}
        >
          <Paragraph
            variant="18"
            sx={{
              color: "white",
              letterSpacing: 0.03,
              my: 2,
            }}
          >
            ${selectedVariant?.price}
          </Paragraph>
        </Flex>

        {whatsIncluded ? (
          <ProductWhatsIncluded whatsIncluded={whatsIncluded} />
        ) : null}
        {heatScale && <HeatScale heatRating={heatScale} />}
        {isGrouped && selectedVariant ? (
          <ProductDropdownFlavors
            groupingProducts={groupingProducts}
            isSubscriptionOnly={isSubscriptionOnly}
            selectedProductIndex={selectedProductIndex}
            selectedVariant={selectedVariant}
          />
        ) : (
          <Box
            sx={{
              minHeight: 16,
              my: [2, 4],
            }}
          />
        )}

        {isGrouped && !isGiftCard ? (
          <ProductOptionSelector product={product} />
        ) : null}

        {!isGrouped && isGiftCard ? (
          <ProductDropdownGiftCards
            product={product}
            selectedVariant={selectedVariant}
          />
        ) : null}

        {!isGrouped && isFashion && hasSizeOptions ? (
          <ProductDropdownSizes
            product={product}
            selectedVariant={selectedVariant}
          />
        ) : null}

        {/* onetime & subscribe */}
        {sellingPlans?.length && selectedVariant?.product?.handle ? (
          <ProductSellingPlans
            sellingPlans={sellingPlans}
            selectedVariant={selectedVariant}
            isSubscriptionOnly={isSubscriptionOnly}
            isSingleBottlePdp={isSingleBottlePdp}
          />
        ) : null}

        {isSingleBottlePdp && (
          <ProductQuantitySelector selectedVariant={selectedVariant} />
        )}

        <Flex id="pdpAtc">
          {selectedVariant && country && (
            <>
              {!isSingleBottlePdp && (
                <ProductDropdownQuantity selectedVariant={selectedVariant} />
              )}
              <AddToCart
                selectedVariant={selectedVariant}
                isPdp
                isSubscriptionOnly={isSubscriptionOnly}
              />
            </>
          )}
        </Flex>

        {/* <!-- Beginning of Buy With Prime Widget --> */}
        <Flex
          id="bwp-wrapper"
          onMouseEnter={() => {
            updateBWPQuantity();
          }}
          sx={{
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
            display: isOneTime ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <Flex
            id="amzn-buy-now"
            data-site-id="41kuv0254b"
            data-widget-id="w-Xbtep0opMG8HyrrQDTjPF3"
            data-sku={selectedVariant?.sku}
          />

          {/* Prime Ratings */}
          <Flex variant="flex.section">
            <Flex sx={{ maxWidth: "260px", width: "260px" }}>
              <Flex
                id="amzn-roa-rating"
                data-site-id="41kuv0254b"
                data-roa-instance-id="v5buglwozl0w4"
                data-sku={selectedVariant?.sku}
              />
            </Flex>
          </Flex>
        </Flex>

        {/* <!-- End of Buy With Prime Widget --> */}

        <InlineHeading theme="dark" text="Product Description" sx={{ mt: 9 }} />

        {product?.descriptionHtml && (
          <Box
            sx={{
              color: "white",
              mt: 0,
              mb: nutritionEl ? 5 : 10,
              p: {
                fontSize: 3,
                lineHeight: 20,
                letterSpacing: 0,
                fontWeight: 400,
                fontFamily: "body",
                mt: 0,
              },
              ul: {
                listStyle: "disc",
              },
            }}
          >
            {ReactHtmlParser(product.descriptionHtml)}
          </Box>
        )}

        {!nutritionFactsOverride && nutritionEl && (
          <Button
            variant="underlineLink.sm"
            sx={{
              fontSize: 0,
              color: "gold",
              mb: 10,
              alignSelf: "flex-start",
            }}
            onClick={() => {
              const el =
                document.getElementsByClassName("product-nutrition")?.[0];
              el.scrollIntoView({
                behavior: "smooth",
                inline: "nearest",
              });
            }}
          >
            View Nutrition Facts
          </Button>
        )}

        {nutritionFactsOverride && (
          <Paragraph
            sx={{
              fontSize: 0,
              color: "white",
              mt: 5,
              mb: 5,
              alignSelf: "flex-start",
              textTransform: "uppercase",
              fontFamily: "heading",
              fontWeight: 600,
              lineHeight: 12,
              letterSpacing: 0.02,
            }}
          >
            {nutritionFactsOverride}
          </Paragraph>
        )}

        {((!isPage && settings?.product?.message) || inQuickAdd) && (
          <Flex variant="flex.row.between.center" sx={{ my: 10 }}>
            {!isPage && (
              <Paragraph variant="label.3" sx={{ color: "white" }}>
                {settings?.product.message}
              </Paragraph>
            )}

            {inQuickAdd ? (
              <UnderlineLink
                text="View Full Product"
                href={`/products/${product?.handle}`}
                newTab={false}
                onClick={closeSideDrawer}
              />
            ) : null}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
