import { Flex, Image } from 'theme-ui';

import { CartCounterToggle, LinkWrapper } from '@snippets';

export const MenuCtas = (props) => {
  return (
    <Flex
      variant="flex.row.end.center"
      data-comp="MenuCtas"
      {...props}
      sx={{
        ...props.sx,
      }}
    >
      <LinkWrapper
        aria-label="Go to account page."
        href="/account/login"
        data-comp="AccountIcon"
        sx={{
          mr: [8, null, null, null, 10],
        }}
      >
        <Flex
          variant="flex.row.center.center"
          sx={{
            width: [21, null, null, null, 24],
            height: [21, null, null, null, 24],
          }}
        >
          <Image
            src="/svg/account-logo-v3.svg"
            alt="Account icon"
            sx={{ width: '100%', cursor: 'pointer' }}
          />
        </Flex>
      </LinkWrapper>

      <CartCounterToggle />
    </Flex>
  );
};
