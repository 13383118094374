import { useState } from 'react';
import { Box, Button, Flex, Paragraph } from 'theme-ui';

import { AddressBookConfirmation } from './AddressBookConfirmation';
import { AddressForm } from './AddressForm';

export const AddressBookItem = ({
  customer,
  address,
  defaultAddress,
  onEditClick,
  addressFormVisible,
  isActiveAddress,
  ...props
}) => {
  const {
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    id,
    lastName,
    phone,
    province,
    provinceCode,
    zip,
  } = address;

  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <Box
      data-comp="AddressBookItem"
      sx={{
        border: '1px solid',
        borderColor: 'gray.3',
        p: 9,
        // mb: 8,
        position: 'relative',
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          mb: isActiveAddress ? 9 : 0,
        }}
      >
        <Box sx={{ flex: 1 }}>
          {defaultAddress.id == id && (
            <Paragraph
              variant="14"
              sx={{
                fontSize: 2,
                fontWeight: 600,
                mb: 5,
              }}
            >
              Default Address
            </Paragraph>
          )}
          <Box sx={{ p: { variant: 'text.sm' } }}>
            <Paragraph>
              {firstName} {lastName}
            </Paragraph>
            <Paragraph>{company}</Paragraph>
            <Paragraph>{address1}</Paragraph>
            <Paragraph>{address2}</Paragraph>
            <Paragraph>
              {city}, {provinceCode || province} {zip}
            </Paragraph>
            <Paragraph>{country}</Paragraph>
            <Paragraph>{phone}</Paragraph>
          </Box>
        </Box>

        <Flex
          sx={{
            justifyContent: ['space-between', 'flex-end'],
            mt: [10, 0],
            ml: [0, 8],
          }}
        >
          <Button
            variant="underlineLink"
            sx={{
              color: 'black',
              mr: 8,
              fontSize: 1,
              '&:before, &:after': {
                height: '1px',
              },
            }}
            onClick={onEditClick}
          >
            {isActiveAddress ? 'Cancel' : 'Edit'}
          </Button>
          <Button
            variant="underlineLink"
            sx={{
              color: 'black',
              fontSize: 1,
              '&:before, &:after': {
                height: '1px',
              },
            }}
            onClick={() => setShowConfirmation(true)}
          >
            Remove
          </Button>
        </Flex>
      </Flex>

      {isActiveAddress && (
        <AddressForm
          address={address}
          action={customer.updateAddress}
          isDefault={defaultAddress?.id === id}
          onSuccess={onEditClick}
        />
      )}

      {showConfirmation && (
        <AddressBookConfirmation
          setShowConfirmation={setShowConfirmation}
          customer={customer}
          id={id}
        />
      )}
    </Box>
  );
};
