import { Box, Flex, Heading, Paragraph, Button } from 'theme-ui';

import { StyledButton, UnderlineLink, LinkWrapper } from '@snippets';

export const MediaTextBlockText = ({
  mediaSide,
  textSettings,
  primaryButtonSettings,
  secondaryButtonSettings,
}) => {
  const showPrimaryButton =
    primaryButtonSettings?.cta?.text && !primaryButtonSettings.hideButton;
  const showSecondaryButton =
    secondaryButtonSettings?.cta?.text && !secondaryButtonSettings.hideButton;

  return (
    <Flex
      variant="flex.column"
      sx={{
        justifyContent: 'center',
        alignItems: [
          'flex-start',
          null,
          null,
          mediaSide === 'left'
            ? 'flex-start !important'
            : 'flex-end !important',
        ],
        flexBasis: [null, null, null, 'calc(50% - 10px)'],
        height: ['auto', null, null, 'calc(35vw - 10px)'],
        maxHeight: [null, null, null, '504px'],
        width: ['100%', null, null, 'auto'],
        mb: [8, null, null, 0],
      }}
    >
      <Flex
        variant="flex.column"
        sx={{
          ml:
            mediaSide === 'left'
              ? [0, null, null, 9, 13, '80px', '110px']
              : [0, null, null, 13],
          mr:
            mediaSide === 'left'
              ? [0, null, null, 13]
              : [0, null, null, 9, 13, '80px', '110px'],
          maxWidth: '470px',
        }}
      >
        <Paragraph
          variant="label.2"
          sx={{
            color: 'gold',
            mb: [6, null, null, null, 9],
            display: textSettings?.superheading ? 'block' : 'none',
          }}
        >
          {textSettings?.superheading}
        </Paragraph>
        <Heading
          variant="36"
          sx={{
            fontSize: [11, null, null, null, 12],
            lineHeight: [38, null, null, null, 40],
            color: 'white',
            mb: 6,
          }}
        >
          {textSettings?.heading}
        </Heading>
        <Box
          sx={{
            mb: !showPrimaryButton && !showSecondaryButton ? 8 : 0,
          }}
        >
          <Paragraph
            variant="base"
            sx={{
              display: textSettings?.description ? 'inline' : 'none',
              color: 'white',
              mt: 6,
            }}
          >
            {textSettings?.description}{' '}
          </Paragraph>
          {textSettings?.cta?.text && (
            <LinkWrapper
              href={textSettings?.cta?.url}
              newTab={textSettings?.cta?.newTab}
            >
              <Button
                sx={{
                  fontFamily: 'body',
                  fontSize: 4,
                  lineHeight: 20,
                  textDecoration: 'underline',
                  display: 'inline',
                  color: 'white',
                  cursor: 'pointer',
                }}
                tabIndex="-1"
              >
                {textSettings.cta.text}
              </Button>
            </LinkWrapper>
          )}
        </Box>
        <Flex
          variant="flex.row.start.center"
          sx={{
            flexWrap: 'wrap',
            mt:
              showPrimaryButton || showSecondaryButton
                ? [9, null, null, 11]
                : 0,
          }}
        >
          {showPrimaryButton && (
            <Box
              sx={{
                mr: 10,
                mb: 8,
              }}
            >
              <StyledButton
                text={primaryButtonSettings?.cta?.text}
                href={
                  primaryButtonSettings?.scrollToTop
                    ? ''
                    : primaryButtonSettings?.cta?.url
                }
                newTab={primaryButtonSettings?.cta?.newTab}
                innerColor="black"
                onClick={() => {
                  if (primaryButtonSettings?.scrollToTop) {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
              />
            </Box>
          )}
          {showSecondaryButton && (
            <UnderlineLink
              text={secondaryButtonSettings?.cta?.text}
              href={secondaryButtonSettings?.cta?.url}
              newTab={secondaryButtonSettings?.cta?.newTab}
              sx={{ mb: 8 }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
