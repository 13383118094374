import { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useCart } from '@backpackjs/storefront';

function Libs() {
  const cart = useCart();
  useEffect(() => {
    if (!cart) return;
    const cartTotal = cart.estimatedCost.subtotalAmount.amount;
    window.VWO = window.VWO || [];
    window._vis_opt_queue = window._vis_opt_queue || [];
    window._vis_opt_queue.push(function () {
      _vis_opt_revenue_conversion(cartTotal, {
        allowMultipleConversions: true,
      });
    });
    window.VWO.push(['track.revenueConversion', cartTotal]);
  }, [cart?.updatedAt]);

  return (
    <>
      <Head>
        <script
          id="postscript-sdk"
          async
          src="https://sdk.postscript.io/sdk.bundle.js?shopId=40983"
        />
        {/* VWO Async SmartCode */}
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
        <script
          id="vwoCode"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window._vwo_code=window._vwo_code || (function() {
              var account_id=780371,
              version = 1.5,
              settings_tolerance=2000,
              library_tolerance=2500,
              use_existing_jquery=false,
              is_spa=1,
              hide_element='body',
              hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
              /* DO NOT EDIT BELOW THIS LINE */
              f=false,w=window,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;w.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.currentScript,t=d.createElement('style'),i=e&&!e.async?hide_element?hide_element+'{'+hide_element_style+'}':'':code.lA=1,n=d.getElementsByTagName('head')[0];t.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&t.setAttribute('nonce',vwoCodeEl.nonce);t.setAttribute('type','text/css');if(t.styleSheet)t.styleSheet.cssText=i;else t.appendChild(d.createTextNode(i));n.appendChild(t);var o=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(o?'&c='+o:''));return settings_timer}};w._vwo_settings_timer = code.init();return code;}());
          `,
          }}
        />
      </Head>

      <Script
        id="yotpo-referral-loader"
        type="text/javascript"
        src="https://cdn-widgetsrepository.yotpo.com/v1/loader/yIanDe8wBrDQPP_zPFi5Cg"
        async
      />

      <Script
        type="text/javascript"
        id="gorgias-chat-script"
        src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=17541"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          var introMessage = "By using this chat feature, you consent to the recording and use of information about you and this chat session by TRUFF or any authorized service provider acting solely on TRUFF\'s behalf. TRUFF may use or disclose this information in accordance with its <a href='https://www.truff.com/pages/privacy-policy' target='_blank'>Privacy Policy</a>. If you do not consent to these terms, please do not use this chat feature and reach out to us via email or by phone. Your continued interaction with, or submission of information to, this chat feature will be deemed to be your acceptance of TRUFFs <a href='https://www.truff.com/pages/privacy-policy' target='_blank'>Privacy Policy</a>."

          var initGorgiasChatPromise = (window.GorgiasChat) ? window.GorgiasChat.init() : new Promise(function (resolve) { window.addEventListener('gorgias-widget-loaded', function () { resolve();})});
          initGorgiasChatPromise.then(async () => {
          GorgiasChat.updateTexts({
          requireEmailCaptureIntro: 'Hey there! ' + introMessage,
          contactFormIntro: 'Hey there! ' + introMessage,
          contactFormSSPUnsuccessfulAskAdditionalMessage: 'Hey there! ' + introMessage,
          waitTimeLongNoEmail: 'Thank you for your patience. ' + introMessage,
          waitTimeMediumNoEmail: 'Thanks for reaching out! Leave us your email and an agent will get back to you in about {waitTime} minutes. ' + introMessage,
          waitTimeShortNoEmail: 'Thanks for reaching out! ' + introMessage
          })

          GorgiasChat.updateSSPTexts({
          sorryToHearThatEmailRequired: introMessage
          })
          })
        `,
        }}
      />

      <Script
        type="text/javascript"
        id="yotpo-script"
        strategy="afterInteractive"
        src={`https://staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_KEY}/widget.js`}
      />
      <Script
        type="text/javascript"
        id="klaviyo-script"
        src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_ACCOUNT_ID}`}
      />
      <Script
        type="text/javascript"
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WW2T8B3');`,
        }}
      />
      <Script
        type="text/javascript"
        id="yotpo-loader"
        src="https://cdn-widgetsrepository.yotpo.com/v1/loader/llULpyQCs0VjVddGeutYx6ff9gvveNdePfAyRdpW"
      />
    </>
  );
}

export default Libs;
