import { Flex, Paragraph, Image } from 'theme-ui';
import { StyledButton } from '@snippets';
import store, { useSetRecoilState, useRecoilValue } from '@store';
import { useYotpoLoyalty } from '@hooks';

export const RewardsRefer = ({ cms }) => {
  const { superHeading, heading, description, buttonText, image } =
    cms.referAFriend;

  useYotpoLoyalty();
  const setModal = useSetRecoilState(store.modal);
  const setOverlay = useSetRecoilState(store.overlay);
  const setActiveRewardsModal = useSetRecoilState(store.activeRewardsModal);
  const { yotpoCustomer } = useRecoilValue(store.yotpoLoyaltyData);

  return (
    <Flex
      data-comp="RewardsReferAFriend"
      variant="flex.section"
      sx={{
        py: [11, null, null, 14],
        px: [6, 11],
      }}
    >
      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={yotpoCustomer?.email}
        data-id={yotpoCustomer?.third_party_id}
        data-tags='"[]"'
        style={{ display: 'none' }}
      />
      <Flex
        sx={{
          maxWidth: '1440px',
          flexDirection: ['column-reverse', null, null, 'row'],
        }}
      >
        <Flex
          variant="flex.row.center.center"
          sx={{
            flexBasis: [null, null, null, 'calc(50% - 10px)'],
            height: ['auto', null, null, 'calc(35vw - 10px)'],
            width: ['100%', null, null, 'auto'],
            maxHeight: [null, null, null, '504px'],
            mr: [0, null, null, 9],
            mt: [11, null, null, 0],
          }}
        >
          <Flex
            variant="flex.column.center"
            sx={{
              maxWidth: '480px',
            }}
          >
            <Paragraph
              variant="label.2"
              sx={{ textAlign: 'center', mb: [5, null, null, 8] }}
            >
              {superHeading}
            </Paragraph>
            <Paragraph variant="32" sx={{ textAlign: 'center', mb: 4 }}>
              {heading}
            </Paragraph>
            <Paragraph
              variant="base.r"
              sx={{
                textAlign: 'center',
                maxWidth: '380px',
                mb: 10,
              }}
            >
              {description}
            </Paragraph>
            {buttonText && (
              <StyledButton
                text={buttonText}
                sx={{
                  width: 'auto',
                }}
                onClick={() => {
                  setModal({ type: 'rewards' });
                  setActiveRewardsModal('rewards-refer');
                  setOverlay(true);
                }}
              />
            )}
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexBasis: [null, null, null, 'calc(50% - 10px)'],
            height: ['auto', null, null, 'calc(35vw - 10px)'],
            width: ['100%', null, null, 'auto'],
            maxHeight: [null, null, null, '504px'],
          }}
        >
          <Image
            src={image?.src || image}
            sx={{
              objectFit: 'contain',
              height: '100%',
              width: '100%',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

RewardsRefer.Schema = {
  label: 'RewardsRefer',
  key: 'rewards-refer',
  fields: [
    {
      label: 'Refer A Friend',
      name: 'referAFriend',
      component: 'group',
      fields: [
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
        },
        {
          name: 'superHeading',
          component: 'text',
          label: 'Super Heading',
        },
        {
          name: 'description',
          component: 'text',
          label: 'Description',
        },
        {
          name: 'image',
          label: 'Image',
          component: 'image',
        },
        {
          name: 'buttonText',
          component: 'text',
          label: 'Refer Button Text',
        },
      ],
      defaultValue: {
        heading: 'Give $5, Get $5',
        superHeading: 'Refer A Friend',
        description:
          'Give your friends $5 off on their first order and get $5 for each successful referral.',
        image:
          'https://res.cloudinary.com/backpack-cms/image/upload/c_fill,q_auto,w_1440/v1622852391/fb577c08-6bb0-4ca1-84b2-933c402beff8/images/image%2011%20(1).jpg.jpg',
        buttonText: 'Start Referring Now',
      },
    },
  ],
};
