import { useEffect } from 'react';
import { Box } from 'theme-ui';

export const HalfBlockKlayvioForm = ({ block }) => {
  useEffect(() => {
    window._klOnsite = window._klOnsite || [];
    window._klOnsite.push(['openForm', block?.formId]);
  }, [block]);

  return (
    <Box
      as="div"
      className={`klaviyo-form-${block?.formId}`}
      sx={{
        width: '100%',
      }}
    />
  );
};
