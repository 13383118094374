import { Box } from "theme-ui";

import { useProduct } from "@backpackjs/storefront";
import { useYotpoRefreshOnMount } from "@utils";

export function YotpoProductReviews({ cms }) {
  const product = useProduct();

  useYotpoRefreshOnMount({ delay: 2000 });

  return product ? (
    <Box
      id="YoptoProductReviews"
      data-comp="YoptoProductReviews"
      className="yotpo-widget-instance"
      data-yotpo-instance-id="637805"
      data-yotpo-product-id={product?.legacyResourceId}
      data-yotpo-name={product?.title}
      data-yotpo-url={product?.onlineStoreUrl}
      data-yotpo-image-url={product?.images?.[0]?.src}
      data-yotpo-price={product?.variants[0]?.price}
      data-yotpo-currency={product?.priceRange?.maxVariantPrice?.currencyCode}
      data-yotpo-description={product?.description}
      sx={{
        width: "100%",
        minHeight: cms?.sectionMinHeights || "initial",
        ".yotpo-head": { p: 0, margin: "2px 0 !important" },
        ".avg-score": {
          display: "none !important",
        },
        ".bottom-line-items-container .reviews-qa-labels-container .reviews-qa-label":
          {
            fontFamily: "heading",
            color: "#333333 !important",
            textTransform: "uppercase",
            fontWeight: 600,
            textDecoration: "underline",
            fontSize: 2,
            ml: 2,
          },
        ".main-widget .reviews-qa-labels-container": {
          ml: "0 !important",
        },
      }}
    />
  ) : null;
}
