import { ImageComponent } from './ImageComponent';

export const HeroImage = ({
  image,
  mediaType,
  children,
  lazy = false,
  sx,
  ...props
}) => {
  const sectionProps = {
    image,
    children,
    lazy,
    sx,
    ...props,
  };

  return mediaType.main === 'image' && mediaType.mobile === 'image' ? (
    <>
      <ImageComponent
        display={[image.mobileSrc ? 'none' : 'block', null, null, 'block']}
        {...sectionProps}
      />
      {image.mobileSrc && (
        <ImageComponent
          display={['block', null, null, 'none']}
          isMobile={true}
          {...sectionProps}
        />
      )}
    </>
  ) : mediaType.main === 'image' ? (
    <ImageComponent display={['none', null, null, 'block']} {...sectionProps} />
  ) : mediaType.mobile === 'image' ? (
    <ImageComponent
      display={['block', null, null, 'none']}
      isMobile={true}
      {...sectionProps}
    />
  ) : null;
};
