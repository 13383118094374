import hero from './hero';
import subscriptions from './subscriptions';
import help from './help';
import details from './details';

// account settings
export default {
  label: 'Account Settings',
  name: 'account',
  component: 'group',
  fields: [
    {
      component: 'text',
      label: 'Default Route',
      name: 'defaultRoute',
      defaultValue: 'overview',
    },
    hero,
    subscriptions,
    details,
    help,
  ],
};
