import { Paragraph, Flex, Button } from 'theme-ui';
import store, { useSetRecoilState } from '@store';
import { useSettings, useCart } from '@backpackjs/storefront';

import { LinkWrapper } from '@snippets';

export const CartEmptyState = ({ ...props }) => {
  const cart = useCart();
  const siteSettings = useSettings();
  const emptyCart = siteSettings?.cart?.emptyCart;
  const setCartDrawer = useSetRecoilState(store.cartDrawer);

  return (
    <Flex
      data-comp="CartEmptyState"
      variant="flex.column.center.center"
      sx={{
        height: 'calc(100% - 141px)',
      }}
      {...props}
    >
      <Paragraph
        variant="18"
        sx={{
          mb: 10,
          color: 'white',
          maxWidth: '250px',
          textAlign: 'center',
        }}
      >
        {emptyCart?.text}
      </Paragraph>

      <LinkWrapper
        href={emptyCart?.link?.url}
        newTab={emptyCart?.link?.newTab}
        variant="buttons.underlineLink"
        onClick={() => {
          if (cart?.lines?.length) return;
          setCartDrawer(false);
        }}
        sx={{
          color: 'gold',
        }}
      >
        {emptyCart?.link?.text}
      </LinkWrapper>
    </Flex>
  );
};
