import { Box, Flex, Image, Paragraph } from 'theme-ui';

import { LinkWrapper } from '@snippets';

export const UnderlineLink = ({
  text = '',
  href = '#',
  variant = 'primary',
  mobileVariant = 'primary',
  newTab = false,
  noLink,
  headingSx,
  sx,
  ...props
}) => {
  const content = () => {
    return (
      <Flex
        variant="flex.row.start.center"
        sx={{
          ...buttonSx({ variant, mobileVariant, headingSx }),
          ...sx,
        }}
        {...props}
      >
        <Flex variant="flex.column.start.center" className="link-text-wrapper">
          <Paragraph
            className="link-text"
            sx={{
              textTransform: 'uppercase',
              letterSpacing: '0.08em',
            }}
          >
            {text}
          </Paragraph>
          <Box className="link-underline" />
        </Flex>

        <Box className="link-arrow-wrapper">
          <Flex variant="flex.row.end.center" className="link-arrow">
            <Image
              src={
                headingSx
                  ? '/svg/arrow-right-black.svg'
                  : '/svg/arrow-right.svg'
              }
              alt="Arrow Right"
            />
          </Flex>
        </Box>
      </Flex>
    );
  };

  if (noLink) {
    return <Box>{content()}</Box>;
  }

  return (
    <LinkWrapper aria-label={`Go to ${text} page.`} newTab={newTab} href={href}>
      {content()}
    </LinkWrapper>
  );
};

const transition = 'all 0.3s ease-in-out';

const buttonSx = ({ variant, mobileVariant, headingSx }) => ({
  position: 'relative',
  cursor: 'pointer',
  '.link-text-wrapper': {
    '.link-text': {
      color: headingSx?.color || [
        mobileVariant === 'primary' ? 'gray.0' : 'gold',
        null,
        null,
        variant === 'primary' ? 'gray.0' : 'gold',
      ],
      fontSize: headingSx?.fontSize || 1,
      lineHeight: headingSx?.lineHeight || 18,
      fontWeight: headingSx?.fontWeight || 600,
      letterSpacing: headingSx?.letterSpacing || 0.08,
    },
    '.link-underline': {
      mt: headingSx?.underlineMarginTop || 1,
      height: headingSx?.underlineHeight || '2px',
      width: '100%',
      backgroundColor: 'gold',
      backgroundImage: 'linear-gradient(#cab683, #cab683)',
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      transition,
      '&:before': {
        backgroundColor: '#B58D28',
        backgroundImage: `linear-gradient(
          87.65deg,
          #CDAF62 0.99%,
          #EEDBAB 18%,
          #B58D28 50%
        )`,
        width: '200%',
        height: '100%',
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translateX(-50%)',
        zIndex: -100,
        opacity: 0,
        transition,
      },
    },
  },
  '.link-arrow-wrapper': {
    width: headingSx?.arrowWrapperWidth || '20px',
    height: '100%',
    position: 'relative',
    '.link-arrow': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: `translateX(0)`,
      transition: 'transform 0.4s ease-in-out',
      img: {
        width: headingSx?.arrowWidth || null,
      },
    },
  },
  '&:hover': {
    '.link-text-wrapper .link-underline': {
      '&:before': {
        transform: 'translateX(0)',
        opacity: 1,
      },
    },
    '.link-arrow-wrapper .link-arrow': {
      transform: `translateX(${headingSx?.translateX || '40%'})`,
    },
  },
});
