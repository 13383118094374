export const entryAnimation = ({
  fadeIn = true,
  slideIn = true,
  entered = false,
  duration = 300,
  slidePercent = '5%',
  delay = 300,
  index = 0,
  direction = 'right', // left, right, up, down
}) => ({
  transform: slideIn
    ? direction === 'right'
      ? entered
        ? 'translateX(0%)'
        : `translateX(-${slidePercent})`
      : direction === 'left'
      ? entered
        ? 'translateX(0%)'
        : `translateX(${slidePercent})`
      : direction === 'up'
      ? entered
        ? 'translateY(0%)'
        : `translateY(${slidePercent})`
      : direction === 'down'
      ? entered
      : 'translateY(0%)'
      ? `translateY(-${slidePercent})`
      : null
    : null,

  // if fadeIn
  opacity: fadeIn ? (entered ? 1 : 0) : 1,

  willChange:
    slideIn && fadeIn
      ? 'opacity, transform'
      : slideIn
      ? 'transform'
      : 'opacity',

  transition:
    fadeIn && slideIn
      ? `opacity ${duration}ms ease-in ${
          delay * index
        }ms, transform ${duration}ms ease-in ${delay * index}ms`
      : !fadeIn && slideIn
      ? `transform ${duration}ms ease-in ${delay * index}ms`
      : fadeIn && !slideIn
      ? `opacity ${duration}ms ease-in ${delay * index}ms`
      : null,
});
