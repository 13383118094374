export default {
  label: 'Footer Settings',
  name: 'footer',
  component: 'group',
  fields: [
    {
      label: 'Email Marketing',
      name: 'emailSignup',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
        },
      ],
    },
    {
      label: 'SMS Marketing',
      name: 'smsSignup',
      component: 'group',
      fields: [
        {
          label: 'List ID (desktop)',
          name: 'listId',
          component: 'text',
          description: 'Please provide a Klaviyo LIST ID to be used on desktop',
          defaultValue: 'VEyPxA',
        },
        {
          label: 'List ID (mobile)',
          name: 'listIdMobile',
          component: 'text',
          description:
            'Please provide a Klaviyo LIST ID to be used on mobile. If left empty, it will default to desktop list id',
          defaultValue: 'VEyPxA',
        },
        {
          label: 'Heading',
          name: 'heading',
          description: 'Optional',
          component: 'text',
          defaultValue: 'Prefer texts? Sign up with your phone number here!',
        },
        {
          label: 'Placeholder text',
          name: 'placeholder',
          component: 'text',
          description:
            'Placeholder text that appears in the input field before the user types anything',
          defaultValue: 'Enter your number...',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
          description: 'Appears below the input field',
          defaultValue: 'SMS is only available for us domestic numbers',
        },
      ],
    },
    {
      label: 'Footer Menu',
      name: 'footerMenu',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      fields: [
        {
          label: 'Sub Menu Name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Sub Menu',
          name: 'childMenuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
      ],
    },
    {
      label: 'Site Copyright Notice',
      name: 'copyrightNotice',
      component: 'text',
    },
  ],
};
