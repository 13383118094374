import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { Paragraph, Flex, Button, Image, Textarea } from 'theme-ui';
import {
  useProductItemByHandle,
  useSettings,
  useCart,
  useCartRemoveItem,
  useCartAddItem,
  useCartUpdateItem,
  useCartUpdateAttributes,
} from '@backpackjs/storefront';

import store, { useRecoilValue, useRecoilState } from '@store';

export function CartGiftWrap({ setToggleHeight }) {
  const textareaRef = useRef(null);

  const settings = useSettings();

  const enabled = settings?.cart?.gifting?.enabled;
  const giftWrapHandle =
    settings?.cart?.gifting?.product?.handle || 'gift-note-wrapping';

  const { productItem: giftWrapProduct } = useProductItemByHandle({
    handle: giftWrapHandle,
  });
  const giftWrapProductId = giftWrapProduct?.variants?.[0]?.storefrontId;

  const cart = useCart();
  const { cartRemoveItem, ...removeItemStatus } = useCartRemoveItem();
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const { cartUpdateItem, ...updateStatus } = useCartUpdateItem();
  const { cartUpdateAttributes, ...attrStatus } = useCartUpdateAttributes();

  const giftWrapAttribute = useRecoilValue(store.giftWrapAttribute);
  const giftWrapLineItemId = useRecoilValue(
    store.giftWrapLineItemId$(giftWrapHandle)
  );

  const [open, setOpen] = useState(false);
  const [note, setNote] = useRecoilState(store.giftWrapNote);
  const [saveText, setSaveText] = useState('Save Note');

  const numberOfValidGiftWrapping = useMemo(() => {
    return (
      cart?.lines?.reduce((carry, line) => {
        const qty = line?.quantity || 0;
        const excludeProduct =
          line?.variant?.product?.tags.includes('no-gift-wrap') ||
          line?.variant?.id === giftWrapProductId;
        if (!excludeProduct) {
          return carry + qty;
        }
        return carry;
      }, 0) || 0
    );
  }, [cart?.updatedAt]);

  const saveGiftNote = useCallback(async () => {
    const attributes = [
      {
        key: 'Gift note',
        value: note,
      },
    ];

    await cartUpdateAttributes({ attributes });

    setOpen(false);
  }, [note]);

  const updatedGiftNote = useCallback((e) => {
    if (e.target.value.length > 200) return;
    setNote(e.target.value);
  }, []);

  const addRemoveGiftWrap = useCallback(async () => {
    // if gift wrap already in the cart remove it
    if (giftWrapLineItemId) {
      return await cartRemoveItem({ lineId: giftWrapLineItemId });
    }

    // gift wrap not yet added, add it
    if (giftWrapProductId) {
      return await cartAddItem({
        merchandiseId: giftWrapProductId,
        quantity: numberOfValidGiftWrapping,
      });
    }
  }, [giftWrapLineItemId, giftWrapProductId, numberOfValidGiftWrapping]);

  // effects
  const updateNoteWithCartAttribute = useCallback(() => {
    setNote(giftWrapAttribute?.value || '');
  }, [giftWrapAttribute?.value]);

  const updateSaveTextOnNoteChange = useCallback(() => {
    const noteChanged = giftWrapAttribute?.value !== note;
    setSaveText(noteChanged ? 'Save Note' : 'Saved');
  }, [giftWrapAttribute?.value, note, open]);

  const setFocusOnView = useCallback(() => {
    if (open) textareaRef.current.focus();
  }, [open]);

  useEffect(updateNoteWithCartAttribute, [giftWrapAttribute?.value]);
  useEffect(updateSaveTextOnNoteChange, [giftWrapAttribute?.value, note]);
  useEffect(setFocusOnView, [open]);

  useEffect(() => {
    if (!cart) return;
    if (giftWrapLineItemId) {
      const giftWrapLineItem = cart?.lines?.find(
        (line) => line?.variant?.id === giftWrapProductId
      );

      if (
        giftWrapLineItem &&
        numberOfValidGiftWrapping !== 0 &&
        giftWrapLineItem?.quantity !== numberOfValidGiftWrapping
      ) {
        cartUpdateItem({
          lineId: giftWrapLineItemId,
          quantity: numberOfValidGiftWrapping,
        });
      } else if (numberOfValidGiftWrapping === 0) {
        cartRemoveItem({ lineId: giftWrapLineItemId });
      }
    }
  }, [cart?.updatedAt, giftWrapLineItemId, numberOfValidGiftWrapping]);

  return enabled ? (
    <Flex
      data-comp={CartGiftWrap.displayName}
      variant="flex.column.start.start"
      sx={{
        width: '100%',
        height: 'auto',
        minHeight: '48px',
        maxHeight: open ? '230px' : '48px',
        bg: 'gray.8',
        px: 9,
        py: 8,
        overflow: 'hidden',
        transition: 'max-height 0.2s ease-in-out',
      }}
    >
      <Flex
        variant="flex.row.between.start"
        sx={{
          width: '100%',
          mb: 7,
        }}
      >
        <Flex variant="flex.column">
          <Paragraph
            variant="label.2"
            sx={{
              color: 'white',
            }}
          >
            Gifting this order?
          </Paragraph>
          <Paragraph
            variant="xxs"
            sx={{
              color: 'white',
              transition: 'opacity 0.15s ease-in-out',
              opacity: open ? 1 : 0,
              userSelect: open ? 'auto' : 'none',
            }}
          >
            No prices will be included on the receipt.
          </Paragraph>
        </Flex>
        {open ? (
          <Button
            onClick={() => {
              setOpen(false);
              setTimeout(() => setToggleHeight(false), 200);
            }}
            sx={{
              height: '23px',
              width: '23px',
              position: 'absolute',
              top: 7,
              right: 7,
            }}
          >
            <Image src="/svg/close-cart-item.svg" className="close-icon" />
          </Button>
        ) : (
          <Button
            sx={{
              textDecoration: 'underline',
              fontSize: 0,
              lineHeight: 16,
              letterSpacing: 0.02,
              color: 'gold',
              textAlign: 'right',
            }}
            onClick={() => {
              setOpen(true);
              setTimeout(() => setToggleHeight(true), 200);
            }}
          >
            Add Note & Wrapping
          </Button>
        )}
      </Flex>
      <Textarea
        ref={textareaRef}
        value={note}
        placeholder="Add a note (up to 200 character limit)"
        maxLength="200"
        onChange={updatedGiftNote}
        sx={{
          width: '100%',
          height: '100px',
          borderRadius: 0,
          borderColor: 'white',
          fontFamily: 'body',
          borderColor: 'gray.3',
          p: 6,
          fontSize: 5,
          resize: 'none',
          color: 'white',
          mb: 8,
          ':placeholder': {
            fontFamily: 'body',
            color: 'gray.3',
          },
        }}
      />
      <Flex variant="flex.row.between.center" sx={{ width: '100%' }}>
        <Flex variant="flex.row.start.center">
          <Button
            aria-label="Add Gift Wrapping for $5"
            role="checkbox"
            aria-selected={!!giftWrapLineItemId}
            sx={{
              width: '24px',
              height: '24px',
              border: '1px solid',
              borderColor: 'gold',
              mr: 4,
              p: 2,
              transition: 'all 0.1s ease-in-out',
              bg: giftWrapLineItemId ? 'gold' : 'transparent',
              position: 'relative',
            }}
            onClick={addRemoveGiftWrap}
          >
            <Image
              src="/svg/check-mark-black.svg"
              alt="Check Mark"
              draggable="false"
              sx={{
                opacity: giftWrapLineItemId ? 1 : 0,
                transition: 'opacity 0.1s ease-in-out',
              }}
            />
          </Button>
          <Paragraph
            variant="label.2"
            sx={{
              color: 'white',
            }}
          >
            Add Gift Wrapping for $5
          </Paragraph>
        </Flex>

        {/*  Save note */}
        <Button
          sx={{
            textDecoration: 'underline',
            fontSize: 0,
            lineHeight: 16,
            letterSpacing: 0.02,
            color: 'gold',
            textAlign: 'right',
          }}
          onClick={saveGiftNote}
        >
          {saveText}
        </Button>
      </Flex>
    </Flex>
  ) : null;
}

CartGiftWrap.displayName = 'CartGiftWrap';
