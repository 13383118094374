export const requestYotpoLoyalty = async ({ action, data }) => {
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : 'https://www.truff.com';

  try {
    const url = `${baseUrl}/api/yotpo-loyalty`;
    const options = {
      headers: {
        action,
        ...data,
      },
    };
    const request = await fetch(url, options);

    if (!request.ok) {
      throw new Error(`${action} request failed!`);
    }

    const response = await request.json();
    // console.log(`YotpoLoyalty:${action} response`, response)
    return response;
  } catch (err) {
    console.log(`YotpoLoyalty:${action} failed due`, err.message);
    throw new Error(err);
  }
};
