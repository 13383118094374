import { useState, useEffect } from "react";
import { useCollectionByHandle } from "@backpackjs/storefront";
import { Flex, Box, Grid, Heading, Button } from "theme-ui";

import BundleBox from "./BundleBox.jsx";
import BundleProductModal from "./BundleProductModal.jsx";

function BundleCollection({ bundleCollection, bundleHeading }) {
  const [bundleProducts, setBundleProducts] = useState([]);
  const [showModal, setShowModal] = useState({
    show: false,
    productId: null,
  });
  const [showCloseButton, setShowCloseButton] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const { title, handle } = bundleCollection;
  const collection = useCollectionByHandle({ handle });

  useEffect(() => {
    const cachedBundleProducts = JSON.parse(
      localStorage.getItem("bundle_products")
    );
    if (cachedBundleProducts) {
      setBundleProducts(cachedBundleProducts);
    }
  }, []);

  let products = collection?.collection?.products;

  products = products?.filter((product) => {
    return product.status === "ACTIVE" && product.totalInventory > 0;
  });

  const handleAddToBundle = (e, product) => {
    let newBundleProducts = [];

    const cachedBundleProducts = JSON.parse(
      localStorage.getItem("bundle_products")
    );

    if (cachedBundleProducts) {
      newBundleProducts = cachedBundleProducts;
    } else {
      newBundleProducts = bundleProducts;
    }

    if (newBundleProducts.length >= 4) {
      console.log("Max 4 products in bundle");
      alert("Max 4 products in bundle");
      return;
    }

    setBundleProducts([...newBundleProducts, product]);
    localStorage.setItem(
      "bundle_products",
      JSON.stringify([...newBundleProducts, product])
    );

    setShowCloseButton({
      0: false,
      1: false,
      2: false,
      3: false,
    });
  };

  const handleRemoveFromBundle = (e, product, index) => {
    // console.log('Remove from bundle', product, index)
    const newBundleProducts = bundleProducts?.filter((bundleProduct, i) => {
      // console.log('bundleProduct', bundleProduct, product, index, i)
      return index !== i;
    });
    setBundleProducts(newBundleProducts);
    localStorage.setItem("bundle_products", JSON.stringify(newBundleProducts));
    setShowCloseButton({
      0: false,
      1: false,
      2: false,
      3: false,
    });
  };

  //   console.log('bundleProducts', bundleProducts);

  const handleShowCloseButton = (e, index) => {
    setShowCloseButton({ ...showCloseButton, [index]: true });
  };

  const handleHideCloseButton = (e, index) => {
    setShowCloseButton({ ...showCloseButton, [index]: false });
  };

  const handleProductModal = (product) => {
    // console.log('handleProductModal', product, showModal)
    setShowModal({
      show: true,
      productId: product.id,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        padding: ["0", "0", "12px", "24px"],
        fontFamily: "Brown",
      }}
    >
      <Box
        sx={{
          maxWidth: "1408px",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Heading
          sx={{
            margin: "0",
            textTransform: "uppercase",
            fontWeight: "400",
            fontSize: ["13px", "18px", "21px"],
            position: "relative",
            zIndex: "10",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            as="span"
            sx={{
              display: "block",
              pt: "12px",
              pb: "8px",
              pl: ["16px", "0"],
              pr: "16px",
              position: "relative",
              zIndex: "10",
            }}
          >
            {bundleHeading}
          </Box>
          <Box
            as="hr"
            sx={{
              boxSizing: "border-box",
              border: "0",
              height: "1px",
              backgroundColor: "#d7d9db",
              flexGrow: 1,
              marginBottom: "0",
              marginTop: "0",
              marginRight: ["1em", "1em", 0],
            }}
          />
        </Heading>
        <Flex
          sx={{
            gap: "48px",
            flexWrap: "nowrap",
            py: ["8px", "8px", "16px"],
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: ["100%", "100%", "75%", "80%"],
              px: ["16px", "16px", "0", "0"],
            }}
          >
            <Grid columns={[2, 2, 3, 3]} gap={[6, 12]}>
              {products?.map((product, index) => {
                const isAdded = bundleProducts.some(
                  (productInBundle) => productInBundle.id === product.id
                );
                let price;
                let compareAtPrice;
                if (product.priceRange.min === product.priceRange.max) {
                  price = product.priceRange.max;
                } else {
                  price = product.priceRange.min;
                  compareAtPrice = product.priceRange.max;
                }
                return (
                  <Box
                    sx={{
                      backgroundColor: "white",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                    key={index}
                    className="collection-product"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: ["10px", "16px"],
                        right: ["10px", "16px"],
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        zIndex: "1",
                      }}
                      className="info-button"
                      onClick={() => handleProductModal(product)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 36 36"
                        id="infoCircle"
                      >
                        <path
                          fill="#000000"
                          d="M18 0C8.06 0 0 8.062 0 18c0 9.943 8.06 18 18 18s18-8.057 18-18c0-9.938-8.06-18-18-18zm0 7.984a3.048 3.048 0 110 6.097 3.048 3.048 0 010-6.097zm4.064 18.435c0 .481-.39.871-.87.871h-6.387a.871.871 0 01-.872-.87v-1.743c0-.48.39-.87.871-.87h.871V19.16h-.87a.871.871 0 01-.872-.87v-1.743c0-.48.39-.87.871-.87h4.646c.48 0 .87.39.87.87v7.259h.872c.48 0 .87.39.87.87v1.742z"
                        />
                      </svg>
                    </Box>
                    {showModal.show && showModal.productId === product.id && (
                      <BundleProductModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        product={product}
                        handleAddToBundle={handleAddToBundle}
                        isAdded={isAdded}
                      />
                    )}
                    <Box
                      sx={{
                        aspectRatio: "1/1",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: ["16px", "24px", "36px", "48px"],
                      }}
                      className="collection-product-image"
                    >
                      <img
                        src={product?.featuredImage?.src}
                        alt={product.title}
                      />
                    </Box>
                    <Box
                      sx={{
                        padding: ["16px", "24px"],
                        borderTop: "1px solid #F5F5F5",
                        display: "flex",
                        flexDirection: ["column", null, null, "column", "row"],
                        gap: ["16px", "24px"],
                        justifyContent: "space-around",
                      }}
                      className="product-bottom"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          height: "fit-content",
                          alignItems: [
                            "center",
                            null,
                            null,
                            "center",
                            "flex-start",
                          ],
                        }}
                        className="product-title-price"
                      >
                        <Heading
                          as="h3"
                          sx={{
                            margin: "0",
                            textTransform: "uppercase",
                            fontWeight: "600",
                            fontSize: ["14px", "16px"],
                            marginBottom: "12px",
                            textAlign: ["center", "left"],
                            lineHeight: "1.2",
                            minHeight: ["52px", 0],
                          }}
                        >
                          {product.title}
                        </Heading>
                        <Box
                          sx={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: ["14px", "16px"],
                          }}
                        >
                          ${price}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 0.5,
                          justifyContent: [
                            "center",
                            null,
                            null,
                            "center",
                            null,
                          ],
                          alignItems: "center",
                        }}
                        className="product-add-to-bundle"
                      >
                        <Button
                          onClick={(e) => {
                            handleAddToBundle(e, product);
                          }}
                          sx={{
                            alignItems: "center",
                            backgroundColor: "#CAB683",
                            color: "#1A1A1A",
                            height: ["40px", "48px"],
                            width: ["100%", null, "100px", "120px"],
                            border: "none",
                            cursor: "pointer",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            paddingTop: 2,
                            "&:hover": {
                              backgroundColor: "#1A1A1A",
                              color: "white",
                            },
                          }}
                        >
                          {isAdded ? "Added" : "Add "}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Box>
          <BundleBox
            bundleProducts={bundleProducts}
            setBundleProducts={setBundleProducts}
            showCloseButton={showCloseButton}
            handleShowCloseButton={handleShowCloseButton}
            handleHideCloseButton={handleHideCloseButton}
            handleRemoveFromBundle={handleRemoveFromBundle}
          />
        </Flex>
      </Box>
    </Box>
  );
}

export default BundleCollection;
