export const Schema = () => {
  return {
    label: 'Klaviyo Form Builder',
    key: 'form-builder',
    fields: [
      {
        label: 'Klaviyo List ID',
        name: 'listId',
        component: 'text',
        description: 'Please provide a Klaviyo LIST ID',
        defaultValue: 'VEyPxA',
      },
      {
        name: 'useKlaviyoEmbedForm',
        label: 'Use Klaviyo Embed Form',
        component: 'toggle',
        description: 'Use Klaviyo embed form instead of custom form',
        toggleLabels: {
          true: 'Yes',
          false: 'No',
        },
        defaultValue: false,
      },
      {
        label: 'Klaviyo Form ID',
        name: 'formId',
        component: 'text',
        description: 'Please provide a Form ID',
        defaultValue: 'Tw9kCk',
      },
      {
        label: 'Klaviyo Name',
        name: 'listName',
        component: 'text',
        description:
          'Please provide a Klaviyo LIST Name (Required for Custom Event Tracking)',
        defaultValue: '',
      },
      {
        label: 'Form Fields Settings',
        name: 'formFields',
        component: 'group',
        fields: [
          {
            label: 'Capture Phone Number',
            name: 'capturePhoneNumber',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Phone Number Is Required',
            name: 'phoneNumberIsRequired',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Consent Text',
            name: 'consentText',
            component: 'markdown',
          },
          {
            label: 'Consent Is Required',
            name: 'consentIsRequired',
            component: 'toggle',
            description:
              'Enables consent checkbox to be required to submit form',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
        ],
      },
      {
        label: 'Submit Button Text',
        name: 'submitText',
        component: 'text',
        defaultValue: 'Submit',
      },
      {
        label: 'Form Success Message',
        name: 'formSuccessMessage',
        component: 'markdown',
        defaultValue: 'Submission sent!',
      },
    ],
  };
};
