import React from 'react';
import { Flex } from 'theme-ui';
import { UnderlineTab } from '@snippets';

export function ProductGroupTabs({
  productGroups,
  activeIndex,
  setActiveIndex,
}) {
  return (
    <Flex
      data-comp="ProductGroupTabs"
      variant="flex.row.center"
      role="tablist"
      aria-label="Product groups"
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'gray.3',
        width: '100%',
        maxWidth: '1130px',
        mx: 'auto',
        mb: 10,
        height: ['24px', null, null, '36px'],
        position: 'relative',
      }}
    >
      <Flex
        variant="flex.row.center"
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          bottom: -0.5,
        }}
      >
        {productGroups?.map((group, index, arr) => (
          <UnderlineTab
            key={`${index}-${group.name}`}
            id={`tab-${index}`}
            controls={`tabpanel-${index}`}
            index={index}
            isActive={index === activeIndex}
            setActive={setActiveIndex}
            isLast={index === arr.length - 1}
            text={group.name}
          />
        ))}
      </Flex>
    </Flex>
  );
}
