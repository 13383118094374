import { store } from '@backpackjs/storefront';
export { useGotoRecoilSnapshot } from 'recoil';

// share storefront recoil within the project
export const useRecoilState = store.recoil.useRecoilState;
export const useRecoilStateLoadable = store.recoil.useRecoilStateLoadable;
export const useSetRecoilState = store.recoil.useSetRecoilState;
export const useResetRecoilState = store.recoil.useResetRecoilState;
export const useRecoilValue = store.recoil.useRecoilValue;
export const useRecoilValueLoadable = store.recoil.useRecoilValueLoadable;
export const useRecoilSnapshot = store.recoil.useRecoilSnapshot;
export const useRecoilCallback = store.recoil.useRecoilCallback;
// export const useGotoRecoilSnapshot = store.recoil.useGotoRecoilSnapshot;
export const atom = store.recoil.atom;
export const atomFamily = store.recoil.atomFamily;
export const selector = store.recoil.selector;
export const selectorFamily = store.recoil.selectorFamily;

import Cart from './Cart';
import Cms from './Cms';
import Global from './Global';
import Menu from './Menu';
import Product from './Product';
import ProductItem from './ProductItem';
import Recharge from './Recharge';
import SelectedSellingPlan from './SelectedSellingPlan';
import SelectedVariant from './SelectedVariant';
import Yotpo from './Yotpo';
import YotpoLoyalty from './YotpoLoyalty';
import Search from './Search';
import PLP from './PLP';

export default {
  ...Cart,
  ...Cms,
  ...Global,
  ...Menu,
  ...Product,
  ...ProductItem,
  ...Recharge,
  ...SelectedSellingPlan,
  ...SelectedVariant,
  ...Yotpo,
  ...YotpoLoyalty,
  ...Search,
  ...PLP,
};
