import { VideoComponent } from './VideoComponent';

export const HeroVideo = ({
  video,
  mediaType,
  children,
  sx,
  ...props
}) => {
  const sectionProps = {
    children,
    sx,
    overlay: video.overlay,
    ...props,
  };
  const desktopVideoProps = { src: video.src, poster: video.poster };
  const mobileVideoProps = { src: video.mobileSrc || video.src, poster: video.mobilePoster || video.poster };

  return mediaType.main === 'video' && mediaType.mobile === 'video' ? (
    <>
      <VideoComponent
        display={['none', null, null, 'block']}
        {...sectionProps}
        {...desktopVideoProps}
      />
      <VideoComponent
        display={['block', null, null, 'none']}
        {...sectionProps}
        {...mobileVideoProps}
      />
    </>
  ) : mediaType.main === 'video' ? (
      <VideoComponent
        display={['none', null, null, 'block']}
        {...sectionProps}
        {...desktopVideoProps}
      />
  ) : mediaType.mobile === 'video' ? (
      <VideoComponent
        display={['block', null, null, 'none']}
        {...sectionProps}
        {...mobileVideoProps}
      />
  ) : null;
};
