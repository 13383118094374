import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Container, Flex, Image } from 'theme-ui';

import { EmailSignup, LinkWrapper } from '@snippets';

import { FooterCopyright } from './FooterCopyright';
import { FooterSocial } from './FooterSocial';
import { FooterMenus } from './FooterMenus';

export function Footer() {
  const { asPath } = useRouter();

  const isHome = useMemo(() => {
    return asPath?.split('?')[0] === '/';
  }, [asPath]);

  return (
    <Container
      sx={{
        display: 'flex',
        bg: 'black',
        flexDirection: ['column', null, null, 'row'],
        px: [6, 13],
        pt: [11, null, null, 16],
        pb: [13, null, null, 16],
        minHeight: '274px',
      }}
    >
      <Flex
        variant="flex.column.start.between"
        sx={{
          mr: 17,
          maxWidth: '440px',
        }}
      >
        <LinkWrapper aria-label="Go to home page." href={isHome ? null : '/'}>
          <Image
            onClick={() => {
              isHome
                ? window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                : null;
            }}
            src="/svg/logo.svg"
            alt="Truff Logo"
            sx={{
              height: '30px',
              minHeight: '30px',
              cursor: 'pointer',
              mb: [10, 13],
            }}
          />
        </LinkWrapper>
        <FooterCopyright
          sx={{ display: ['none', null, null, 'block', null, 'none'] }}
        />
      </Flex>
      <Flex
        variant="flex.row"
        sx={{
          flex: 1,
          justifyContent: ['flex-start', null, null, 'center'],
          minHeight: '100%',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', null, null, null, null, 'row'],
            width: ['100%', null, null, 'auto', null, '100%'],
            mr: [0, null, null, null, 19, 0],
            maxWidth: '1080px',
            minHeight: '100%',
          }}
        >
          <Flex mb={[13, null, null, null, null, 0]} sx={{ flex: 1 }}>
            <FooterSocial
              sx={{
                display: ['none !important', null, 'flex !important'],
                minHeight: '100%',
                mr: 17,
              }}
            />
            <Flex
              sx={{
                flexDirection: 'column',
                mr: [0, null, null, null, null, 17],
                flex: 1,
                maxWidth: ['100%', null, '380px', '300px', null, '440px'],
              }}
            >
              <EmailSignup />
            </Flex>
          </Flex>
          <FooterMenus />
        </Flex>
      </Flex>
      <FooterCopyright
        sx={{ display: ['none', null, 'block', 'none'], mt: 9 }}
      />
    </Container>
  );
}
