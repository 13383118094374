import { useSettings } from '@backpackjs/storefront';
import { LinkWrapper } from '@snippets';
import { Box, Flex, Image } from 'theme-ui';

function formatHeatLevel(text) {
  const cleanedText = text.replace(/-\d+(-\d+)*$/, '');

  const words = cleanedText.split('-');

  const formattedText = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedText;
}
const getPepperImages = (rating) => {
  const filledPeppers = Math.floor(rating);
  const halfPeppers = rating % 1 !== 0 ? 1 : 0;
  const emptyPeppers = 5 - filledPeppers - halfPeppers;

  const filledImages = Array.from({ length: filledPeppers }, (_, i) => (
    <Box>
      <Image
        src="/svgs/peppers/pepper-filled.svg"
        alt="pepper-filled"
        sx={{
          height: [28, null, null, null, 30],
        }}
      />
    </Box>
  ));
  const halfImages = Array.from({ length: halfPeppers }, (_, i) => (
    <Box>
      <Image
        src="/svgs/peppers/pepper-half-filled.svg#pepper-half-filled"
        alt="pepper-filled"
        sx={{
          height: [28, null, null, null, 30],
        }}
      />
    </Box>
  ));

  const emptyImages = Array.from({ length: emptyPeppers }, (_, i) => (
    <Box>
      <Image
        src="/svgs/peppers/pepper-no-fill.svg#pepper-no-fill"
        alt="pepper-no-fill"
        sx={{
          height: [28, null, null, null, 30],
        }}
      />
    </Box>
  ));

  return [...filledImages, ...halfImages, ...emptyImages];
};

export const HeatScale = ({ heatRating }) => {
  const { product } = useSettings() || {};
  if (!product) return null;
  const { enable, redirect, scale } = product?.heatScale || {};
  const rating = scale.find((r) => r.name === heatRating);
  if (!enable) return null;

  return (
    <Flex
      sx={{
        mt: 8,
        gap: 2,
        alignItems: 'center',
      }}
      className="pepper-rating"
    >
      <LinkWrapper
        aria-label="Go to https://www.truff.com/articles/heat-rating-scale page"
        href={redirect?.url}
        newTab
        variant="buttons.link"
        sx={{
          mr: 4,
        }}
      >
        {formatHeatLevel(heatRating)}
      </LinkWrapper>

      {getPepperImages(rating ? rating.value : 0)}
    </Flex>
  );
};
