import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      minWidth: '300px',
      maxWidth: '500px',
      '@media only screen and (max-width: 479px)': {
        minWidth: '250px',
      },
      textAlign: 'center',
      p: [6, null, 10],
    };

    this.title = {
      variant: 'text.24.r',
    };

    this.variant = {
      variant: 'text.13',
      textTransform: 'uppercase',
      mt: 10,
    };

    this.description = {
      variant: 'text.sm',
      mt: 10,
    };

    this.input = {
      variant: 'text.base',
      fontSize: 5,
      mb: 11,
      px: 11,
      '&::placeholder': {
        variant: 'text.base',
      },
    };

    this.button = {
      width: '100%',
      height: '56px',
    };
  })()
);
