import { Flex, Image } from 'theme-ui';

export const PressBanner = () => {
  return (
    <Flex
      data-comp="PressBanner"
      variant="flex.section"
      sx={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        bg: 'gray.1',
        p: [9, null, null, 11],
        flexWrap: 'wrap',
      }}
    >
      {logos.map((logo, index) => (
        <Flex
          variant="flex.row.center.center"
          key={index}
          sx={{
            flexBasis: ['50%', 'auto'],
          }}
        >
          <Image
            alt={logo.alt}
            key={index}
            src={`/svg/press-${logo.name}-logo.svg`}
            sx={{
              width: ['100%', logo.width],
              maxWidth: logo.width,
              mx: 10,
              my: 8,
            }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

const logos = [
  {
    name: 'today',
    width: 70,
    alt: 'Today logo',
  },
  {
    name: 'gma',
    width: 78,
    alt: 'Good Morning America logo',
  },
  {
    name: 'forbes',
    width: 114,
    alt: 'Forbes Logo',
  },
  {
    name: 'entrepreneur',
    width: 127,
    alt: 'Entrepreneur logo',
  },
  {
    name: 'food-and-wine',
    width: 128,
    alt: 'Food and Wine logo',
  },
  {
    name: 'highsnobiety',
    width: 126,
    alt: 'Highsnobiety logo',
  },
  {
    name: 'cnn',
    width: 87,
    alt: 'CNN logo',
  },
  {
    name: 'real-simple',
    width: 126,
    alt: 'Real Simple logo',
  },
];

PressBanner.Schema = {
  label: 'Press Banner',
  key: 'press-banner',
  fields: [],
};
