import { useCallback, useEffect } from 'react';

import store, { useRecoilState, useSetRecoilState } from '@store';

// import { useSearchDrawer } from './useSearchDrawer';
import { useDebounce } from './useDebounce';

export const useSearch = () => {
  const [rawTerm, setRawTerm] = useRecoilState(store.rawTerm);
  const [term, setTerm] = useRecoilState(store.term);
  const setSearchIsFocused = useSetRecoilState(store.searchIsFocused);
  const debouncedTerm = useDebounce(rawTerm, 300);
  // const [{ searchDrawer }] = useSearchDrawer();

  const isSearching = rawTerm && rawTerm !== '';

  const resetRawTerm = useCallback(() => {
    setRawTerm('');
  }, []);

  // update the search term, with the debounced value
  useEffect(() => {
    if (term !== debouncedTerm) {
      setTerm(debouncedTerm);
    }
  }, [debouncedTerm, term]);

  return [
    { term, rawTerm, isSearching },
    { setTerm, setRawTerm, resetRawTerm, setSearchIsFocused },
  ];
};
