import React from 'react';
import { ShopifyImage, LinkWrapper } from '@snippets';
import { isBrowser } from '@utils';
import { useProductItemByHandle } from '@backpackjs/storefront';
import { jsx, Box, Spinner, Flex, Button, Paragraph } from 'theme-ui';

export function ShopRecipeProductItem({ handle, ...props }) {
  const { productItem: product } = useProductItemByHandle({ handle });
  const productTitle =
    product?.title?.length > 27
      ? `${product?.title.slice(0, 26)}...`
      : product?.title;

  const variablePricing =
    product &&
    product?.priceRange?.minVariantPrice?.amount !==
      product?.priceRange?.maxVariantPrice?.amount;

  return isBrowser ? (
    <Box
      data-comp="ShopRecipeProductItem"
      sx={{
        border: (t) => `1px solid ${t.colors.gray[3]}`,
        padding: 6,
        borderRadius: '4px',
      }}
    >
      <React.Suspense fallback={ProductLoader}>
        {product ? (
          <Flex variant="flex.row">
            <Box sx={{ width: '80px', position: 'relative' }}>
              <ShopifyImage
                ratio={8 / 10}
                src={product?.images?.[0]?.originalSrc}
                layout="fill"
                objectFit="cover"
                sx={{
                  width: '80px',
                  objectFit: 'cover',
                }}
              />
            </Box>

            <Flex variant="flex.column" sx={{ pl: 6 }}>
              <Paragraph variant="text.18" sx={{ mb: 7 }}>
                {productTitle}
              </Paragraph>

              <Flex variant="flex.row.between" sx={{ mb: 7 }}>
                <Paragraph variant="text.xs">
                  {variablePricing ? 'Starting at' : ''}
                </Paragraph>
                <Paragraph
                  variant="text.18"
                  sx={{ pl: 4, mt: '-2px', ml: 'auto' }}
                >
                  ${product.priceRange.minVariantPrice.amount}
                </Paragraph>
              </Flex>

              <LinkWrapper
                href={`/products/${product.handle}`}
                variant="buttons.underlineLink"
                sx={{ color: 'gold', mr: 'auto', mt: 1 }}
              >
                SHOP NOW
              </LinkWrapper>
            </Flex>
          </Flex>
        ) : (
          'Product not found'
        )}
      </React.Suspense>
    </Box>
  ) : null;
}

function ProductLoader() {
  return (
    <Flex
      data-comp="ProductLoader"
      variant="flex.row.center"
      sx={{
        height: `400px`,
      }}
    >
      <Spinner />
    </Flex>
  );
}
