import { Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';

export const VideoComponent = ({
  display,
  children,
  sx,
  overlay,
  poster,
  src,
  ...props
}) => {

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Box
      data-comp="HeroVideo"
      ref={ref}
      sx={{
        display,
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        bg: 'ghostwhite',
        ...sx,
      }}
      {...props}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          as="video"
          tabIndex={-1}
          muted
          playsInline
          loop
          autoPlay={inView}
          poster={poster}
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
          }}
        >
          {inView && (
            <source key={src} src={src} type="video/mp4" />
          )}
        </Box>
        <Box
          sx={{
            display: overlay ? 'block' : 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            bg: 'black',
            opacity: 0.7,
          }}
        />
      </Box>
      {children}
    </Box>
  );
};
