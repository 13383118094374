export const fontSizes = [
  /*
    DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closest or just add it inline e.g. "40px"
  */
  11, // 0
  12, // 1
  13, // 2
  14, // 3
  15, // 4
  16, // 5
  18, // 6
  20, // 7
  24, // 8
  28, // 9
  30, // 10
  32, // 11
  36, // 12
  40, // 13
  48, // 14
  56, // 15
  60, // 16
  64, // 17
  80, // 18
  88, // 19
  96, // 20
  /* DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER */
];
