import { Box, Flex, Image, Button } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';
import { entryAnimation } from '@utils';
import { useMounted } from '@hooks';
import { LinkWrapper } from '@snippets';

const networksMap = (network) =>
  [
    {
      key: 'instagram',
      label: 'Instagram',
      icon: '/svg/instagram.svg',
      url: `https://www.instagram.com/${network.handle}`,
    },
    {
      key: 'facebook',
      label: 'Facebook',
      icon: '/svg/facebook.svg',
      url: `https://www.facebook.com/${network.handle}`,
    },
    {
      key: 'tiktok',
      label: 'Tik Tok',
      icon: '/svg/tiktok.svg',
      url: `https://www.tiktok.com/${network.handle}`,
    },
    {
      key: 'youtube',
      label: 'YouTube',
      icon: '/svg/youtube.svg',
      url: `https://www.youtube.com/c/${network.handle}`,
    },
    {
      key: 'pinterest',
      label: 'Pinterest',
      icon: '/svg/pinterest.svg',
      url: `https://www.pinterest.com/${network.handle}`,
    },
    {
      key: 'twitter',
      label: 'Twitter',
      icon: '/svg/twitter.svg',
      url: `https://www.twitter.com/${network.handle}`,
    },
    {
      key: 'vimeo',
      label: 'Vimeo',
      icon: '/svg/vimeo.svg',
      url: `https://www.vimeo.com/${network.handle}`,
    },
  ].find((socialNetwork) => socialNetwork.key === network.network);

export const SocialLinks = ({
  linkMarginBottom = [4, 8],
  inHeaderDrawer = false,
  animated = false,
  ...props
}) => {
  const siteSettings = useSettings();
  const { socialLinks } = siteSettings;
  const setOverlay = useSetRecoilState(store.overlay);
  const setMenuDrawer = useSetRecoilState(store.menuDrawer);
  const [mounted] = useMounted();

  return (
    <Flex
      data-comp="SocialLinks"
      variant="flex.column"
      as="ul"
      sx={{
        p: 0,
      }}
      aria-label={`Social Links list containing ${socialLinks.length} items`}
      {...props}
    >
      {socialLinks?.map((networkLink, index) => {
        const network = networksMap(networkLink);
        if (!network?.url) return null;

        return (
          <Box
            as="li"
            data-comp="SocialLink"
            key={index}
            sx={{
              mb: inHeaderDrawer ? 5 : linkMarginBottom,
              ...entryAnimation({
                entered: animated ? mounted : true,
                fadeIn: true,
                slideIn: false,
                duration: 50,
                index,
                delay: 50,
              }),
            }}
          >
            <LinkWrapper
              aria-label={`Go to Truff ${network?.label} page.`}
              href={network.url}
              newTab
              onClick={() => {
                if (inHeaderDrawer) {
                  setMenuDrawer(false);
                  setOverlay(false);
                }
              }}
            >
              <Flex variant="flex.row.start.center">
                <Image
                  alt={`${network?.label} logo`}
                  src={network.icon?.src || network.icon}
                  sx={{
                    width: '16px',
                    mr: 4,
                  }}
                />
                <Button variant="link" pt={1} tabIndex="-1">
                  {network.label}
                </Button>
              </Flex>
            </LinkWrapper>
          </Box>
        );
      })}
    </Flex>
  );
};
