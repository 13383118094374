import { Box, Button, Flex } from 'theme-ui';

import { InlineHeading } from '@snippets';
import { useSelectedOptions } from '@hooks';

export function ProductOptionSelector({ product }) {
  return (
    <Box data-comp="ProductOptionSelector">
      {Object.keys(product.optionsMap).map((optionName) => (
        <ProductOption
          key={optionName}
          optionName={optionName}
          options={product.optionsMap}
        />
      ))}
    </Box>
  );
}
// eslint-disable-next-line react/prop-types
function ProductOption({ optionName, options }) {
  const [selectedOptions, setSelectedOptions] = useSelectedOptions();
  // eslint-disable-next-line react/prop-types
  if (options?.[optionName]?.length === 1) return null;
  return (
    <Box
      data-comp="ProductOption"
      sx={{
        mb: 10,
      }}
    >
      <InlineHeading text={optionName} theme="dark" />
      <Flex sx={{ flexWrap: 'wrap', gap: 9 }}>
        {/* eslint-disable-next-line react/prop-types */}
        {options?.[optionName]?.map((option) => {
          const isActive = selectedOptions[optionName] === option;
          return (
            <Button
              key={option}
              onClick={() => {
                setSelectedOptions({ [optionName]: option });
              }}
              sx={{
                border: '1px solid',
                borderColor: isActive ? 'gold' : 'gray.5',
                color: isActive ? 'white' : 'gray.5',
                px: 6,
                py: 4,
                textTransform: 'none',
                ':hover': {
                  borderColor: 'gold',
                },
              }}
            >
              {option}
            </Button>
          );
        })}
      </Flex>
    </Box>
  );
}
