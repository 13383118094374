import { useCallback } from "react";
import {
  useCartAddItem,
  useProductInventoryByHandle,
} from "@backpackjs/storefront";
import { StyledButton } from "@snippets";
import store, { useSetRecoilState, useRecoilValue } from "@store";

const DEBUG = false;

export function AddToCart({
  selectedVariant,
  quantity,
  isQuickShop,
  isPdp,
  isCollectionPage = null,
  isCollectionModal = null,
  setIsOpen = null,
  ...props
}) {
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const setModal = useSetRecoilState(store.modal);
  const setBisSelectedVariant = useSetRecoilState(store.bisSelectedVariant);
  const setCartDrawer = useSetRecoilState(store.cartDrawer);
  const setOverlay = useSetRecoilState(store.overlay);
  const { handle } = { ...selectedVariant?.product };

  const activeQuantity = useRecoilValue(store.activeQuantity(handle));
  const selectedPlan = useRecoilValue(store.selectedPlan(handle));
  const selectedFreq = useRecoilValue(store.selectedFreq(handle));

  const isSubscriptionPlan = selectedPlan?.type === "subscribe";

  // use live inventory data
  const { inventory, ...status } = useProductInventoryByHandle({
    handle,
  });

  const variantInventory = inventory
    ? inventory?.variants?.[selectedVariant.storefrontId]
    : null;

  const isSoldOut = variantInventory
    ? variantInventory.quantityAvailable <= 0 &&
      variantInventory.product?.handle !== "truff-gift-card"
    : false;

  const addToCartText = status?.success
    ? isSoldOut
      ? "Notify Me When Back In Stock"
      : isSubscriptionPlan
      ? "Add to Cart"
      : "Add to Bag"
    : "Loading...";

  const toggleCart = useCallback(() => {
    setCartDrawer(true);
    const t0 = requestTimeout(() => {
      setOverlay(true);
      clearRequestTimeout(t0);
    }, 175);
  }, [setCartDrawer, setOverlay]);

  // add oneTime and subs
  const addToCart = async () => {
    if (!selectedVariant) {
      console.log("Cant add to cart missing selected variant");
      return;
    }

    const item = {
      merchandiseId: selectedVariant.storefrontId,
      quantity: quantity || activeQuantity,
      attributes: [{ key: "_plan", value: "onetime" }],
    };

    // if selling plan is selected we the subscription
    if (isSubscriptionPlan && selectedFreq?.id) {
      item.sellingPlanId = selectedFreq.id;
      item.attributes = [{ key: "_plan", value: "subscription" }];
    }

    if (item.sellingPlanId) {
      DEBUG &&
        console.log(
          "Adding to cart subscription",
          item,
          selectedVariant.displayName
        );
    } else {
      DEBUG &&
        console.log(
          "Adding to cart one time",
          item,
          selectedVariant.displayName
        );
    }

    const cart = await cartAddItem(item);

    if (!cart) {
      console.log("Error adding to cart", addItemStatus?.errors);
      return;
    }

    toggleCart();
    setModal(false);
    if (setIsOpen && isCollectionModal) {
      setIsOpen(false);
    }
  };

  if (!selectedVariant) return null;

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" || event.key === " " || event.key === "Space") {
      event.preventDefault();
      if (isSoldOut) {
        setModal({ type: "back-in-stock" });
        setOverlay(true);
        setBisSelectedVariant(selectedVariant);
      } else {
        addToCart();
      }
    }
  };

  return (
    <StyledButton
      data-comp="AddToCart"
      variant="solid"
      text={addToCartText}
      disableClipping={isPdp}
      onClick={async () => {
        if (isSoldOut) {
          setModal({ type: "back-in-stock" });
          setOverlay(true);
          setBisSelectedVariant(selectedVariant);
        } else {
          addToCart();
        }
      }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      sx={{
        height: isCollectionPage
          ? isCollectionModal
            ? "40px"
            : ["30px", null, null, null, "40px"]
          : "56px",
        minWidth: isCollectionPage ? ["0", null, null, null, ""] : null,
        lineHeight: isCollectionPage ? ["normal", null, null, null, ""] : null,
        '[data-comp="InnerStyledButton"]': {
          fontSize: isCollectionPage ? ["10px", "12px"] : null,
        },
      }}
      {...props}
    />
  );
}
