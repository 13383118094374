export function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj),
        })
    }, {})
}

export const mergedArraysByKey = (array, key) => {
    let map = {}
    array.forEach((val) => {
        if (map[val[key]]) {
            map[val[key]] = { ...map[val[key]], ...val }
        } else {
            map[val[key]] = val
        }
    })
    return Object.keys(map).map((val) => map[val])
}

export function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) {
            memo[x[property]] = []
        }
        memo[x[property]].push(x)
        return memo
    }, {})
}

export const countValuesByKey = (arr, key) =>
    arr.reduce((r, c) => {
        r[c[key]] = (r[c[key]] || 0) + 1
        return r
    }, {})
