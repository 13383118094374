import { YouTubeEmbed } from './YouTubeEmbed';

export const VideoPlayer = ({
  video,
  mediaType,
  lazy = true,
  id = 'featured-media-video',
  ...props
}) => {
  return mediaType.main === 'video' && mediaType.mobile === 'video' ? (
    <YouTubeEmbed display={['block']} video={video} />
  ) : mediaType.main === 'video' ? (
    <YouTubeEmbed display={['none', null, null, 'block']} video={video} />
  ) : mediaType.mobile === 'video' ? (
    <YouTubeEmbed display={['block', null, null, 'none']} video={video} />
  ) : null;
};
