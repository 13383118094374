import { useMemo } from 'react';

import { HeroVideo } from '@snippets';

export function HalfBlockVideo({ block, blocks, index, ...props }) {
  const mediaType = { main: 'video', mobile: 'video' };

  const video = useMemo(
    () => ({
      src: block?.video || '',
      mobileSrc: block?.mobileVideo || '',
      poster: block?.poster?.src || block?.poster?.filename || '',
      mobilePoster:
        block?.mobilePoster?.src || block?.mobilePoster?.filename || '',
      overlay: false,
      width: 1420,
    }),
    [block]
  );

  return (
      <HeroVideo
        data-comp="HalfBlockVideo"
        video={video}
        mediaType={mediaType}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: 'black',
          borderRadius: [
            '6px',
            null,
            null,
            '6px',
          ],
        }}
        {...props}
      />
  );
}
