
export default {
  label: 'Product Settings',
  name: 'product',
  component: 'group',
  fields: [
    {
      label: 'Back In Stock Modal',
      name: 'backInStock',
      component: 'group',
      fields: [
        {
          label: 'Description',
          name: 'description',
          component: 'textarea',
        },
        {
          label: 'Submit Button Text',
          name: 'modalButtonText',
          component: 'text',
        },
      ],
      defaultValue: {
        description: `Register to receive a notification when this item comes back in stock.`,
        modalButtonText: 'Notify When Available',
      },
    },
    {
      label: 'Box products',
      name: 'box',
      component: 'group',
      fields: [
        {
          name: 'quantities',
          label: 'Box quantities',
          description:
            'The first character(s) must be a number followed by a space — 1 Box ✅ , 1Box 🚫, One Box 🚫',
          component: 'list',
          field: {
            component: 'text',
          },
        },
      ],
    },
    {
      label: 'Bottle products',
      name: 'bottle',
      component: 'group',
      fields: [
        {
          name: 'quantities',
          label: 'Bottle quantities',
          component: 'list',
          description:
            'The first character(s) must be a number followed by a space — 1 Bottle ✅ , 1Bottle 🚫, One Bottle 🚫',
          field: {
            component: 'text',
          },
        },
      ],
    },
    {
      label: 'Pack products',
      name: 'pack',
      component: 'group',
      fields: [
        {
          name: 'quantities',
          label: 'Pack quantities',
          description:
            'The first character(s) must be a number followed by a space — 1 Pack ✅ , 1Pack 🚫, One Pack 🚫',
          component: 'list',
          field: {
            component: 'text',
          },
        },
      ],
    },
    {
      label: 'Sticky CTA',
      name: 'stickyCTA',
      component: 'group',
      fields: [
        {
          name: 'enable',
          label: 'Enable Sticky CTA',
          component: 'toggle',
        },
        {
          name: 'products',
          label: 'Disable CTA - Products',
          description: 'Disables Sticky CTA per product',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          fields: [
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
          defaultValue: [],
        },
        {
          name: 'productContentOverrides',
          label: 'Override Images - Products',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          fields: [
            {
              name: 'image',
              component: 'image',
              label: 'Image',
            },
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
          defaultValue: [],
        },
        {
          name: 'position',
          label: 'Sticky CTA Position',
          component: 'radio-group',
          options: [
            {
              label: 'Top',
              value: 'top',
            },
            {
              label: 'Bottom',
              value: 'bottom',
            },
          ],
          defaultValue: 'bottom',
          variant: 'radio',
          direction: 'horizontal',
        },
      ],
    },
    {
      label: 'Message',
      name: 'message',
      component: 'text',
      defaultValue: 'Spend $50 to unlock Free Shipping.',
    },
    {
      label: 'Heat Scale',
      name: 'heatScale',
      component: 'group',
      fields: [
        {
          name: 'enable',
          label: 'Enable Heat Scale',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          name: 'scale',
          label: 'Heat Scale ',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          fields: [
            {
              label: 'Name',
              name: 'name',
              description:
                'Add the name of the heat scale, e.g. Mild, Medium, Hot (must correspond to the product tag rating:heat-scale)',
              component: 'text',
            },
            {
              name: 'value',
              label: 'Heat Scale Value',
              component: 'number',
              defaultValue: 0,
              step: 0.5,
            },
          ],
          defaultValue: [
            { name: 'no-heat', value: 0 },
            { name: 'mild-heat', value: 0.5 },
            { name: 'mild-heat-1', value: 1 },
            { name: 'mild-heat-1-5', value: 1.5 },
            { name: 'medium-heat', value: 2.5 },
            { name: 'hot', value: 3.5 },
          ],
        },

        {
          name: 'redirect',
          label: 'Redirect URL',
          component: 'link',
          defaultValue: {
            url: '/articles/heat-rating-scale',
          },
        },
      ],
    },
  ],
  defaultValue: {
    box: {
      quantities: ['1 Box', '2 Boxes', '3 Boxes', '4 Boxes', '5 Boxes'],
    },
    bottle: {
      quantities: [
        '1 Bottle',
        '2 Bottles',
        '3 Bottles',
        '4 Bottles',
        '5 Bottles',
      ],
    },
    pack: {
      quantities: ['1 Pack', '2 Packs', '3 Packs', '4 Packs', '5 Packs'],
    },
    heatScale: {
      enable: true,
      scale: [
        { name: 'no-heat', value: 0 },
        { name: 'mild-heat', value: 0.5 },
        { name: 'mild-heat-1', value: 1 },
        { name: 'mild-heat-1-5', value: 1.5 },
        { name: 'medium-heat', value: 2.5 },
        { name: 'hot', value: 3.5 },
      ],
      redirect: {
        url: '/articles/heat-rating-scale',
        newTab: true,
      },
    },
  },
};
