import { useState, useEffect } from 'react';
import { Box, Flex, Grid, Paragraph } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { StyledInput, StyledButton } from '@snippets';

export const EditProfileMain = () => {
  const { errors, loading, customer, ...rest } = useCustomer();

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [acceptsMarketing, setAcceptsMarketing] = useState(false);

  useEffect(() => {
    setFirstName(customer?.firstName);
    setLastName(customer?.lastName);
    setEmail(customer?.email);
    setPhone(customer?.phone);
    setAcceptsMarketing(customer?.acceptsMarketing);
  }, [customer]);

  useEffect(() => {
    const hasErrors = errors?.addressCreate?.length;
    const hasFinished = !loading['customer.update'];

    if (isLoading && hasFinished) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [loading, errors]);

  return (
    <Box
      data-comp="EditProfileMain"
      as="form"
      onSubmit={(event) => {
        event.preventDefault();

        setIsLoading(true);
        rest.updateCustomer({
          customer: {
            firstName,
            lastName,
            email,
            phone,
            acceptsMarketing,
          },
        });
      }}
    >
      <Grid
        columns={['1fr', null, null, '1fr 1fr']}
        sx={{
          columnGap: 10,
          rowGap: 2,
          gridTemplateAreas: [
            `
              "first-name"
              "last-name"
              "email"
              "company"
            `,
            null,
            null,
            `
              "first-name last-name"
              "email email"
              "company company"
            `,
          ],
        }}
      >
        <StyledInput
          name="firstName"
          placeholder="First Name"
          label="First Name"
          id="edit-profile-first-name"
          required
          value={firstName}
          action={setFirstName}
          sx={{ gridArea: 'first-name' }}
        />

        <StyledInput
          name="lastName"
          placeholder="Last Name"
          label="Last Name"
          id="edit-profile-last-name"
          required
          value={lastName}
          action={setLastName}
          sx={{ gridArea: 'last-name' }}
        />

        <StyledInput
          name="email"
          placeholder="Email Address"
          label="Email Address"
          id="edit-profile-email"
          type="email"
          required
          value={email}
          action={setEmail}
          sx={{ gridArea: 'email' }}
          readOnly={true}
        />
      </Grid>

      <Flex variant="flex.row.start.center">
        <input
          type="checkbox"
          name="default"
          label="Make Default"
          onClick={() => setAcceptsMarketing(!acceptsMarketing)}
          checked={acceptsMarketing}
        />

        <Paragraph variant="base" sx={{ ml: 2 }}>
          Accept marketing material via email
        </Paragraph>
      </Flex>

      <StyledButton
        text="Save"
        isLoading={isLoading}
        variant="solid"
        sx={{
          maxWidth: ['100%', '300px'],
          mt: 9,
        }}
      />
    </Box>
  );
};
