import { useEffect } from 'react';
import { Flex } from 'theme-ui';

import Script from 'next/script';

export const KlarnaWidget = ({ price }) => {
  const _price = (parseFloat(price) * 100).toFixed(0);

  useEffect(() => {
    window.Klarna?.OnsiteMessaging?.refresh();
  }, [price, _price]);

  return (
    <>
      <Script
        async
        src="https://na-library.klarnaservices.com/lib.js"
        data-client-id="e3bad107-af10-5262-82ea-ef6477664b24"
      />

      <Flex data-comp="KlarnaWidget" sx={{ mt: 1 }}>
        <klarna-placement
          data-key="credit-promotion-badge"
          data-locale="en-US"
          data-purchase-amount={_price}
        ></klarna-placement>
      </Flex>
    </>
  );
};
