import { useEffect, useRef, useState } from 'react';
import { Box } from 'theme-ui';
import { useCartCount } from '@backpackjs/storefront';

import { isBrowser } from '@utils';

import { CartTotals } from './CartTotals';
import { CartGiftWrap } from './CartGiftWrap';

export const CartFooter = () => {
  const cartFooterRef = useRef(null);
  const cartCount = useCartCount();
  const [toggleHeight, setToggleHeight] = useState(false);

  useEffect(() => {
    if (!isBrowser) return;
    const setCartFooterHeightCssVar = () => {
      document.documentElement.style.setProperty(
        '--cart-footer-height',
        cartFooterRef.current.clientHeight + 'px'
      );
    };
    setCartFooterHeightCssVar();
  }, [cartCount, toggleHeight]);

  return (
    <Box
      ref={cartFooterRef}
      data-comp={CartFooter.displayName}
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: '1px solid',
        borderColor: 'gray.6',
      }}
    >
      {cartCount > 0 && <CartGiftWrap setToggleHeight={setToggleHeight} />}

      <CartTotals />
    </Box>
  );
};

CartFooter.displayName = 'CartFooter';
