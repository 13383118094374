import { store } from '@backpackjs/storefront';
import { getDiscountPrice } from '@utils';
import effects from './effects';

const product = store.recoil.atom({
  key: 'product',
  default: null,
});

const activeQuantity = store.recoil.atomFamily({
  key: 'product/activeQuantity',
  default: 1,
  effects_UNSTABLE: [
    // effects.logStateChange('activeQuantity')
  ],
});

const activeQuantityPrice = store.recoil.atomFamily({
  key: 'product/activeQuantityPrice',
  default: null,
});

const activeQuantityPriceSubscription = store.recoil.atomFamily({
  key: 'product/activeQuantityPriceSubscription',
  default: null,
});

const buyboxInView = store.recoil.atom({
  key: 'product/buyboxInView',
  default: null,
});

// the active variant's discount price taking into consideration the activeQuantity
const discountedPrice = store.recoil.selector({
  key: 'product/discountedPrice',
  get: ({ get }) => {
    const _selectedVariant = get(selectedVariant);
    const _activeQuantity = get(activeQuantity);
    const _discountedPrice = _selectedVariant?.price
      ? getDiscountPrice({
          price: _selectedVariant.price,
          quantity: _activeQuantity,
        })
      : 0;
    return _discountedPrice;
  },
});

const addToCartMounted = store.recoil.atom({
  key: 'product/addToCartMounted',
  default: false,
});

export default {
  product,
  activeQuantity,
  activeQuantityPrice,
  activeQuantityPriceSubscription,
  buyboxInView,
  discountedPrice,
  addToCartMounted,
};
