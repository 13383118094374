import { Flex, Paragraph } from 'theme-ui';

export const OrderDetailsPrices = ({ prices, ...props }) => {
  return (
    <Flex variant="flex.row.end">
      <Flex
        data-comp="OrderDetailsPrices"
        variant="flex.column"
        sx={{
          flexBasis: ['100%', null, null, null, 'calc(50% - 10px)'],
          mt: [9, 15],
        }}
        {...props}
      >
        {priceItems.map((item, index, arr) => {
          if (!prices?.[item.key]) return null;
          return (
            <Flex
              variant="flex.row.between"
              key={item.key}
              sx={{
                mb: 5,
                mt: index === arr.length - 1 ? 9 : 0,
                width: '100%',
                p: { variant: 'text.label.2' },
              }}
            >
              <Paragraph>{item.title}</Paragraph>
              <Paragraph sx={{ fontWeight: '400 !important' }}>
                {prices?.[item.key]}
              </Paragraph>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

const priceItems = [
  {
    title: 'Subtotal',
    key: 'subtotal',
  },
  {
    title: 'Discount',
    key: 'discount',
  },
  {
    title: 'Shipping',
    key: 'shipping',
  },
  {
    title: 'Tax',
    key: 'tax',
  },
  {
    title: 'Total',
    key: 'total',
  },
];
