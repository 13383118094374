import { useProductItemByHandle } from '@backpackjs/storefront';
import { useMounted, useBreakpointValue } from '@hooks';
import { InlineHeading, Markdown } from '@snippets';
import { Box, Flex } from 'theme-ui';

export const NutritionFactsProduct = ({
  nutritionData,
  isMulti,
  productIndex,
  activeIndex,
  setActiveIndex,
  activeMobileIndex,
  setActiveMobileIndex,
}) => {
  const { productItem: product } = useProductItemByHandle({
    handle: nutritionData?.product?.handle,
  });
  const currentIndex = useBreakpointValue([
    activeMobileIndex,
    activeMobileIndex,
    activeIndex,
    activeIndex,
  ]);
  const [mounted] = useMounted();
  const isActive = productIndex + 1 == currentIndex;

  if (!mounted) return null;

  if (!isMulti) {
    return (
      <Flex
        data-comp="NutritionFactsProduct"
        sx={{
          width: '100%',
          gap: ['30px', null, null, '135px'],
          flexDirection: ['column', null, null, 'row'],
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            flexBasis: [null, null, null, '55%'],
          }}
        >
          <ProductHeading product={product} nutritionData={nutritionData} />
          <NutritionChart product={product} nutritionData={nutritionData} />
        </Box>
        <Box
          sx={{
            flexBasis: [null, null, null, '45%'],
          }}
        >
          {nutritionData?.image && (
            <img
              src={nutritionData.image?.src || nutritionData.image}
              alt=""
              sx={{ display: 'block', borderRadius: '6px' }}
            />
          )}
        </Box>
      </Flex>
    );
  } else {
    return (
      <Box
        data-comp="NutritionFactsProductAccordion"
        sx={{
          borderTop: productIndex == 0 ? '1px solid #fff' : null,
          borderBottom: '1px solid #fff',
          py: '40px',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isActive) {
            setActiveIndex(null);
            setActiveMobileIndex(null);
          } else {
            setActiveIndex(productIndex + 1);
            setActiveMobileIndex(productIndex + 1);
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            pr: ['20px', null, null, '28px'],
          }}
        >
          <ProductHeading product={product} nutritionData={nutritionData} />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              width: ['12px', null, null, '20px'],
              height: ['12px', null, null, '20px'],
              transition: 'transform .5s ease-in',
              transform: isActive
                ? 'rotate(45deg) translateY(-50%)'
                : 'translateY(-50%)',
              transformOrigin: 'top',
              '&:before, &:after': {
                content: '""',
                display: 'block',
                height: '2px',
                width: ['12px', null, null, '20px'],
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
              },
              '&:before': {},
              '&:after': {
                transform: 'translate(-50%, -50%) rotate(90deg)',
              },
            }}
          ></Box>
        </Box>
        <Flex
          sx={{
            width: '100%',
            gap: ['30px', null, null, '135px'],
            flexDirection: ['column', null, null, 'row'],
            alignItems: 'flex-start',
            overflow: 'hidden',
            cursor: 'default',
            height: isActive ? 'auto' : 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box
            sx={{
              flexBasis: [null, null, null, '55%'],
            }}
          >
            <NutritionChart product={product} nutritionData={nutritionData} />
          </Box>
          <Box
            sx={{
              flexBasis: [null, null, null, '45%'],
            }}
          >
            {nutritionData?.image && (
              <img
                src={nutritionData.image?.src || nutritionData.image}
                alt=""
                sx={{
                  display: 'block',
                  mt: [null, null, null, '40px'],
                  borderRadius: '6px',
                }}
              />
            )}
          </Box>
        </Flex>
      </Box>
    );
  }
};
const ProductHeading = ({ product, nutritionData }) => {
  const firstAvailableVariant = product?.variants?.find(
    ({ availableForSale }) => availableForSale
  );
  let selectedVariant = null;
  if (firstAvailableVariant) {
    selectedVariant = firstAvailableVariant;
  } else if (product?.variants?.length) {
    selectedVariant = product.variants[0];
  }
  return (
    <Flex
      data-comp="NutritionFactsProductHeading"
      sx={{
        width: '100%',
        height: '100%',
        gap: ['16px', null, null, '20px'],
      }}
    >
      <Box
        sx={{
          width: ['80px', null, null, '95px'],
          height: ['80px', null, null, '95px'],
          flexShrink: 0,
          flexGrow: 0,
          position: 'relative',
        }}
      >
        <img
          src={
            nutritionData?.imageProduct?.src ||
            selectedVariant?.image?.src ||
            product?.images?.[0]?.src
          }
          alt={product?.title || ''}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'top center',
            position: 'absolute',
            borderRadius: '50%',
          }}
        />
      </Box>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            fontFamily: 'heading',
            fontSize: ['18px', null, null, '30px'],
            lineHeight: '1.2',
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
          }}
        >
          {product && <>{product.title}</>}
        </Box>
        {nutritionData.whatsIncluded && (
          <Box
            sx={{
              fontSize: ['13px', null, null, '15px'],
              color: 'gold',
              mt: ['12px', null, null, '16px'],
              letterSpacing: '0.02em',
            }}
          >
            {nutritionData.whatsIncluded}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
const NutritionChart = ({ product, nutritionData }) => {
  return (
    <Flex
      data-comp="NutritionFactsChart"
      sx={{
        pl: [null, null, null, '115px'],
        flexDirection: 'column',
        gap: ['13px', null, null, '16px'],
      }}
    >
      <InlineHeading
        theme="dark"
        text="Nutritional Facts"
        sx={{ mt: ['24px', null, null, '40px'] }}
      />
      {nutritionData.servingSize && (
        <Box
          sx={{
            fontSize: ['10px', null, null, '15px'],
          }}
        >
          Serving Size: {nutritionData.servingSize}
        </Box>
      )}
      {nutritionData.servingSizePerContainer && (
        <Box
          sx={{
            fontSize: ['10px', null, null, '15px'],
          }}
        >
          Serving Per Container: {nutritionData.servingSizePerContainer}
        </Box>
      )}
      {nutritionData?.nutritionFieldsInfo && (
        <Box
          sx={{
            fontFamily: 'heading',
            fontSize: '11px',
            lineHeight: '1',
            fontWeight: '700',
            textTransform: 'uppercase',
            borderTop: '1px solid #6f6f70',
            mb: '4px',
          }}
        >
          {nutritionData.nutritionFieldsInfo.map(
            (nutritionField, index, arr) => (
              <Flex
                key={index}
                sx={{
                  borderBottom: '1px solid #6f6f70',
                  py: '10px',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    flexGrow: '1',
                  }}
                >
                  {nutritionField.label}
                </Box>
                <Box
                  sx={{
                    textAlign: 'right',
                  }}
                >
                  {nutritionField.value}
                </Box>
              </Flex>
            )
          )}
        </Box>
      )}
      {nutritionData.description && (
        <Markdown
          style={{
            fontSize: '10px',
          }}
          text={nutritionData.description}
        />
      )}
    </Flex>
  );
};
