import { useState } from "react";
import { Flex } from "theme-ui";
import { useMounted } from "@hooks";
import { entryAnimation } from "@utils";
import { OptionsGrid, MountAfter } from "@snippets";

import { CollectionProductLinks } from "./CollectionProductLinks";

export const CollectionsMenu = ({ collectionLinks }) => {
  const collections =
    collectionLinks?.map((collection) => {
      return {
        id: collection.id,
        title: collection.link.text,
        subItems: collection.subItems,
        link: collection.link,
      };
    }) || [];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState(
    collections[selectedIndex]
  );

  const [mounted] = useMounted();
  return (
    <Flex
      sx={{
        maxWidth: 1160,
        paddingX: "15px",
        margin: "auto",
        gap: "10%",
        alignItems: "flex-start",
        display: ["none", null, null, null, "flex"],
      }}
    >
      {/* Collection Selector */}
      {collections?.length ? (
        <OptionsGrid
          options={collections}
          selectedIndex={selectedIndex}
          onClick={({ selectedIndex }) => {
            setSelectedIndex(selectedIndex);
            setSelectedCollection(collections[selectedIndex]);
          }}
          onMouseOver={({ selectedIndex }) => {
            setSelectedIndex(selectedIndex);
            setSelectedCollection(collections[selectedIndex]);
          }}
          sx={{
            mb: 8,
            flexGrow: 0,
            width: "auto",
            ...entryAnimation({
              entered: mounted,
              fadeIn: true,
              slideIn: false,
              duration: 500,
              index: 1,
              delay: 300,
            }),
          }}
        />
      ) : null}

      {/* Selected Collection Links} */}
      {selectedCollection ? (
        <MountAfter delay={0}>
          <CollectionProductLinks
            title={selectedCollection.title}
            links={selectedCollection?.subItems || []}
          />
        </MountAfter>
      ) : null}
    </Flex>
  );
};
