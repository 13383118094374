import { Flex, Paragraph, Button } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { LinkWrapper } from '@snippets';

export const OrderHistoryEmptyState = () => {
  const siteSettings = useSettings();
  const settings = siteSettings?.accountSettings?.orderHistory;

  console.log('settings', settings);
  return (
    <Flex
      data-comp="OrderHistoryEmptyState"
      variant="flex.column.center.center"
      sx={{
        width: '100%',
        height: '200px',
      }}
    >
      {settings?.emptyStateText && (
        <Paragraph
          variant="base"
          sx={{
            maxWidth: '300px',
            textAlign: 'center',
            mt: 14,
            mb: 6,
          }}
        >
          {settings?.emptyStateText}
        </Paragraph>
      )}
      {settings?.emptyStateButton?.text && (
        <LinkWrapper
          href={settings?.emptyStateButton?.url}
          newTab={settings?.emptyStateButton?.newTab}
          variant="buttons.underlineLink"
          sx={{ color: 'black' }}
        >
          {settings?.emptyStateButton?.text}
        </LinkWrapper>
      )}
    </Flex>
  );
};
