import { Box, Flex } from 'theme-ui';

import store, {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
} from '@store';

import { RewardsModalRefer } from './RewardsModalRefer/RewardsModalRefer';
import { RewardsModalHistory } from './RewardsModalHistory/RewardsModalHistory';
import { RewardsModalCampaign } from './RewardsModalCampaign/RewardsModalCampaign';

export const RewardsModal = () => {
  const [modal, setModal] = useRecoilState(store.modal);
  const setOverlay = useSetRecoilState(store.overlay);
  const [activeRewardsModal, setActiveRewardsModal] = useRecoilState(
    store.activeRewardsModal
  );
  const yotpoLoyaltyData = useRecoilValue(store.yotpoLoyaltyData);
  const { activeCampaigns, yotpoCustomer } = yotpoLoyaltyData || {};
  const email = yotpoCustomer?.email;
  const rewardsModalOpen = modal?.type === 'rewards';

  return rewardsModalOpen ? (
    <Flex
      as="aside"
      data-comp="RewardsModal"
      variant="flex.column.center.start"
      sx={modalSx({ activeRewardsModal, rewardsModalOpen })}
    >
      <Box
        sx={{
          position: 'absolute',
          cursor: 'pointer',
          right: '20px',
          top: '20px',
        }}
        onClick={() => {
          setModal(null);
          setOverlay(null);
          setActiveRewardsModal(null);
        }}
      >
        <img
          alt="Close Modal"
          src="/svg/close.svg"
          width="20px"
          height="20px"
        />
      </Box>

      {activeRewardsModal === 'rewards-refer' ? (
        <RewardsModalRefer email={email} yotpoCustomer={yotpoCustomer} />
      ) : activeRewardsModal === 'rewards-history' ? (
        <RewardsModalHistory yotpoCustomer={yotpoCustomer} />
      ) : (
        <RewardsModalCampaign
          campaign={activeCampaigns?.find(
            (item) => item.id === activeRewardsModal
          )}
          email={email}
          yotpoCustomer={yotpoCustomer}
        />
      )}
    </Flex>
  ) : null;
};

const modalSx = ({ activeRewardsModal, rewardsModalOpen }) => ({
  position: 'fixed',
  top: 'calc(50% - 20px)',
  left: 'calc(50% - 20px)',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 40px)',
  maxWidth:
    activeRewardsModal === 'rewards-refer' ||
    activeRewardsModal === 'rewards-history'
      ? '700px'
      : '500px',
  height: 'auto',
  maxHeight: '90%',
  minHeight: '150px',
  m: 9,
  py: 13,
  px: [9, 13],
  bg: 'white',
  zIndex: 50,
  boxShadow: '0px -3px 30px rgb(0 0 0 / 8%)',
  opacity: rewardsModalOpen ? 1 : 0,
  willChange: 'opacity',
  transition: 'opacity .3s ease-in-out',
  overflowY: 'scroll',
  overflowX: 'hidden',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
