import { Paragraph, Button, Flex, Spinner } from 'theme-ui';

import { useCartAddItem, useProductItemByHandle } from '@backpackjs/storefront';

import { Picture, LinkWrapper } from '@snippets';

export const CartUpsellItem = ({
  upsellProductHandle,
  upsellProductImageOverride,
  ...props
}) => {
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const { productItem } = useProductItemByHandle({
    handle: upsellProductHandle,
  });

  // first variant is the one with "quantity 1"
  const variant = productItem?.variants[0] || null;

  return !variant ? (
    <Flex variant="flex.row.center.center">
      <Spinner />
    </Flex>
  ) : (
    <Flex
      data-comp={CartUpsellItem.displayName}
      variant="flex.row.between.center"
      {...props}
      sx={{
        borderColor: 'gray.6',
        pt: 8,
        overflow: 'hidden',
        position: 'relative',
        ':hover .close-icon': {
          opacity: 1,
          visibility: 'visible',
        },
      }}
    >
      <Picture
        alt={variant?.title}
        images={[
          {
            src:
              upsellProductImageOverride?.src ||
              variant?.image?.originalSrc ||
              productItem?.images?.[0]?.src ||
              '/svg/default-image.svg',
            ratio:
              upsellProductImageOverride?.ratio ||
              variant?.image?.ratio ||
              productItem?.images[0]?.ratio ||
              1,
            width: 96,
          },
        ]}
        sx={{
          height: '120px',
          width: '96px',
          mr: 8,
          borderRadius: '3px',
        }}
      />

      <Flex
        variant="flex.column.start.between"
        sx={{ flex: 1, minHeight: 'auto' }}
      >
        <Flex
          variant="flex.row.between.start"
          sx={{ width: '100%', minHeight: '44px' }}
        >
          <Flex
            variant="flex.column.start"
            sx={{
              mr: 4,
              flex: 1,
              minHeight: '44px',
            }}
          >
            <Paragraph variant="10" sx={{ mb: 6, color: 'white' }}>
              BEST SELLER
            </Paragraph>

            <LinkWrapper href={`/products/${productItem.handle}`}>
              <Paragraph variant="18" sx={{ mb: 10, color: 'white' }}>
                {productItem.title}
              </Paragraph>
            </LinkWrapper>
          </Flex>
        </Flex>

        <Flex variant="flex.row.between.center" sx={{ width: '100%' }}>
          <Button
            variant="underlineLink.sm"
            sx={{
              fontSize: 1,
              color: 'gold',
              alignSelf: 'flex-start',
            }}
            onClick={async () => {
              await cartAddItem({
                merchandiseId: variant.storefrontId,
                quantity: 1,
              });
            }}
          >
            ADD TO BAG
          </Button>

          <Flex variant="flex.column" sx={{ mb: 2 }}>
            <Paragraph
              variant="label.2"
              sx={{ textAlign: 'right', color: 'white' }}
            >
              ${variant.price}
            </Paragraph>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

CartUpsellItem.propTypes = {};
