import { Paragraph, Box } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

export const FooterCopyright = (props) => {
  const siteSettings = useSettings();
  const {
    footer: { copyrightNotice },
  } = siteSettings;

  return (
    <Box data-comp="FooterCopyright" {...props}>
      <Paragraph
        variant="label.3"
        sx={{
          fontSize: 1,
          color: 'gray.4',
          fontWeight: 400,
        }}
      >
        © {new Date().getFullYear()} {copyrightNotice}
      </Paragraph>
    </Box>
  );
};
