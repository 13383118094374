import { store } from '@backpackjs/storefront';

const filterDrawerIndex = store.recoil.atom({
  key: 'plp/filterDrawerIndex',
  default: null,
});

const activeCollectionIndex = store.recoil.atom({
  key: 'plp/activeCollectionIndex',
  default: 0,
});

export default {
  filterDrawerIndex,
  activeCollectionIndex,
};
