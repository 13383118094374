import { useEffect } from "react";
import { Flex } from "theme-ui";
import { useProductByHandle } from "@backpackjs/storefront";

import { ProductMain as ProductMainComponent } from "@templates/product/ProductMain";

export function ProductMain({ cms, section, ...props }) {
  const product = useProductByHandle({
    handle: cms?.handle,
  });

  useEffect(() => {
    const tryNowButtons = document.querySelectorAll(
      '[href="#product-main-section"]'
    );

    const handleOnClick = (event) => {
      event.preventDefault();
      const productMainSection = document.getElementById(
        "product-main-section"
      );
      productMainSection?.scrollIntoView({ behavior: "smooth", block: "end" });
    };

    Array.from(tryNowButtons).forEach((element, i) => {
      element.addEventListener("click", handleOnClick);
    });
  }, []);

  if (!product) return null;

  const selectedVariant = product.product?.variants?.find((variant) => {
    return variant.product.handle === product.product.handle;
  });

  return (
    <Flex
      id="product-main-section"
      data-comp="ProductMainSection"
      variant="flex.section"
      sx={{
        bg: "white",
        py: ["28px", "24px", null, 17],
        px: [6, 11, null, 0],
      }}
    >
      <ProductMainComponent
        product={product?.product}
        whatsIncluded={cms?.products}
        passedSelectedVariant={selectedVariant}
        isSubscriptionOnly={false}
        inQuickAdd
        sx={{
          bg: "white",
          "#product-flavors-dropdown": {
            display: "none",
          },
        }}
      />
    </Flex>
  );
}

ProductMain.Schema = {
  label: "Product Main",
  key: "product-main",
  fields: [
    {
      name: "handle",
      label: "handle",
      component: "text",
      defaultValue: "variety-pack",
    },
    {
      name: "products",
      label: "What's Included",
      component: "group-list",
      itemProps: {
        label: "{{item.product.handle}}",
      },
      defaultItem: () => ({
        product: {
          handle: "truff",
        },
      }),
      fields: [
        {
          name: "product",
          component: "productSearch",
          label: "Product",
        },
      ],
      defaultValue: [
        {
          product: {
            handle: "truff",
          },
        },
        {
          product: {
            handle: "hotter-truff-hot-sauce",
          },
        },
        {
          product: {
            handle: "white-truffle-hot-sauce",
          },
        },
      ],
    },
  ],
};
