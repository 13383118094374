import { Flex, Paragraph, Box } from 'theme-ui';
import { YotpoProductReviews } from '@snippets';

import { withInView } from '@utils';

export const YotpoWidget = withInView(
  ({ cms, inView }) => {
    const { heading } = cms;

    return (
      <Flex
        data-comp="YotpoWidget"
        variant="flex.section"
        sx={{
          py: [13, null, null, 17],
          px: [6, 11],
        }}
      >
        <Paragraph
          variant="24.r"
          sx={{
            maxWidth: '700px',
            textAlign: ['left', 'center'],
            alignSelf: 'center',
            mb: [2, 9],
          }}
        >
          {heading}
        </Paragraph>
        <Box sx={{ width: '100%' }}>
          {inView && <YotpoProductReviews cms={cms} />}
        </Box>
      </Flex>
    );
  },
  {
    triggerOnce: true,
  }
);

YotpoWidget.Schema = {
  label: 'Yotpo Widget',
  key: 'yotpo-widget',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
      defaultValue: 'People Love Truff',
    },
  ],
};
