import { useEffect, useCallback } from 'react';
import { Button, Image, Paragraph } from 'theme-ui';
import { useCart, useCartCount, useRouter } from '@backpackjs/storefront';

import store, { useRecoilState, useSetRecoilState } from '@store';

export const CartCounterToggle = (props) => {
  const router = useRouter();
  const cart = useCart();
  const cartCount = useCartCount();

  const setModal = useSetRecoilState(store.modal);
  const [cartDrawer, setCartDrawer] = useRecoilState(store.cartDrawer);
  const setOverlay = useSetRecoilState(store.overlay);

  const showCart = () => {
    setCartDrawer(true);
    setModal(null);
    const t0 = requestTimeout(() => {
      setOverlay(true);
      clearRequestTimeout(t0);
    }, 175);
  };

  const hideCart = () => {
    setCartDrawer(false);
    setOverlay(false);
  };

  const toggleCart = useCallback(() => {
    if (cartDrawer) {
      hideCart();
    } else {
      showCart();
    }
  }, [cartDrawer]);

  // when redirected from /cart page toggle cart
  useEffect(() => {
    if (router?.query?.togglerCart) {
      showCart();
      router.push('/', undefined, { shallow: true });
    }
  }, [router?.asPath]);

  return (
    <Button
      aria-label={cartDrawer ? 'Close cart drawer.' : 'Open cart drawer.'}
      data-comp="CartCounterToggle"
      variant="buttons.plain"
      onClick={() => {
        toggleCart();
        if (cart?.lines) {
          PubSub.publish('VIEW_MINI_CART', cart);
        }
      }}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 30,
        cursor: 'pointer',
      }}
    >
      {/* count */}
      <Paragraph
        key="CartCount"
        variant="text.14"
        sx={{
          color: 'white',
          mr: 4,
          pt: '3px',
        }}
      >
        {cartCount}
      </Paragraph>

      {/*  el truffle */}
      <Image
        src={`/svg/cart-icon.svg`}
        alt="Truffle cart icon"
        sx={{
          height: [28, null, null, null, 30],
        }}
      />
    </Button>
  );
};
