import { useState, useEffect } from 'react';
import { Box, Button, Flex, Paragraph } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { StyledButton, StyledInput } from '@snippets';

export const RecoverPasswordForm = ({ action = () => null }) => {
  const customer = useCustomer();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [mounted, setMounted] = useState(false);
  const [success, setSuccess] = useState(false);
  const isLoading = customer?.loading['password.recover'];

  const recoverPassword = (e) => {
    e.preventDefault();
    customer.recoverPassword({
      email,
    });
  };

  useEffect(() => {
    if (!customer || isLoading) return;
    !mounted ? setMounted(true) : null;
    const errorArray = customer?.errors?.['password.recover'];
    let errors = [];
    if (errorArray?.length > 0) {
      customer?.errors?.['password.recover'].forEach((err) => {
        if (!err.message) return;
        return errors.push(err.message + `<br/>`);
      });
      errors.length > 0 ? setMessage(errors.join('')) : null;
      return;
    }

    if (mounted && errorArray?.length < 1) {
      setSuccess(true);
      setEmail('');
      setMessage('Your email has been sent');
      setTimeout(() => action('login'), 2000);
    }
  }, [customer, isLoading]);

  return (
    <Flex
      variant="flex.column"
      sx={{
        width: '100%',
        maxWidth: '500px',
      }}
    >
      <Box>
        <Paragraph
          variant="text.24.r"
          sx={{
            mb: 8,
            textAlign: 'center',
          }}
        >
          Reset Account Password
        </Paragraph>

        <Paragraph variant="base" sx={{ textAlign: 'center' }}>
          Enter your email and we'll send you a link to reset your password.
        </Paragraph>
      </Box>

      <Flex
        variant="flex.column"
        as="form"
        sx={{
          mt: 11,
          justifyContent: 'center',
        }}
        onSubmit={recoverPassword}
      >
        <StyledInput
          variant="account.input"
          name="email"
          type="email"
          placeholder="Email Address"
          label="Email Address"
          id="recover-pw-email"
          required
          value={email}
          action={setEmail}
        />

        <Paragraph
          variant="base"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          sx={{
            color: success ? 'black' : 'red',
            lineHeight: 26,
            textAlign: 'center',
          }}
        />

        <StyledButton
          text="Reset Password"
          isLoading={isLoading}
          variant="solid"
          sx={{
            maxWidth: '300px',
            mx: 'auto',
            mt: 9,
          }}
        />
      </Flex>
      <Button
        variant="underlineLink"
        sx={{
          color: 'black',
          mt: 10,
          mx: 'auto',
          alignSelf: 'flex-start',
          fontWeight: 400,
          '&:before, &:after': {
            height: '1px',
          },
        }}
        onClick={() => action('login')}
      >
        Cancel
      </Button>
    </Flex>
  );
};
