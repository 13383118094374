export default {
  label: 'Cart Settings',
  name: 'cart',
  component: 'group',
  fields: [
    {
      label: 'Discounts',
      name: 'discount',
      component: 'group',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          description: 'Toggle the discounts progress bar on or off',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          default: true,
        },
        {
          label: 'Steps',
          name: 'steps',
          description: 'Settings for each of the 4 upsell stages',
          component: 'group-list',
          validate(list) {
            if (list?.length > 4) {
              if (typeof window !== 'undefined') {
                window.__bpCMS.alerts.error(
                  'A max of 4 upsell steps must be defined'
                );
              }
              return list.slice(0, 4);
            }
            if (list?.length < 4) {
              if (typeof window !== 'undefined') {
                window.__bpCMS.alerts.error(
                  'A min of 4 upsell steps must be defined'
                );
              }
              return list;
            }
          },
          itemProps: {
            label: '{{item.discount}}',
          },
          defaultItem: {
            id: Math.random().toString(36).substr(2, 9),
            minimum: 0,
            message: 'Spend $ to unlock Free Shipping.',
            discount: 'None',
          },
          fields: [
            {
              label: 'Minimum Spend Amount (USD)',
              name: 'minimum',
              component: 'number',
              description:
                'Dollar amount must be equal to the minimum spend amount configured in the published Shopify cart script.',
            },
            {
              name: 'message',
              label: 'Step message',
              component: 'markdown',
              description:
                'The step upsell message e.g Spend $ to unlock Free Shipping... IMPORTANT: Use "$" to denote the placement of remaining dollar amount.',
            },
            {
              name: 'discount',
              label: 'Step discount %',
              component: 'text',
            },
            {
              label: 'Is Free Shipping Step?',
              name: 'isFreeShippingStep',
              description:
                'Helps determine if this discount is free shipping in PDP',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
              default: false,
            },
            {
              name: 'pdpMessage',
              label: 'PDP Message',
              description: 'Appears in the PDP, right above the ATC button',
              component: 'text',
            },
          ],
        },
      ],
    },
    {
      label: 'Empty Cart',
      name: 'emptyCart',
      component: 'group',
      fields: [
        {
          label: 'Empty Cart Text',
          name: 'text',
          component: 'text',
        },
        {
          label: 'Empty Cart Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Empty Cart Checkout Button',
          name: 'checkout',
          component: 'link',
        },
      ],
      defaultValue: {
        text: 'TOO MUCH SAUCE, SAID NO ONE EVER.',
        link: {
          text: 'Shop Hot Sauce',
          url: '/collections/all',
        },
        checkout: {
          text: 'Shop Now',
          url: '/collections/all',
        },
      },
    },
    {
      label: 'Gifting',
      name: 'gifting',
      component: 'group',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          description: 'Toggle the gifting on or off',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Product',
          name: 'product',
          description: 'The gift wrapping product',
          component: 'productSearch',
          defaultValue: {
            handle: 'gift-note-wrapping',
          },
        },
      ],
      defaultValue: {
        enabled: true,
      },
    },
    {
      label: 'Upsell',
      name: 'upsell',
      component: 'group',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          description: 'Toggle the upsell offering on or off',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          default: true,
        },
        {
          label: 'Product',
          name: 'product',
          description: 'The product that will be offered as upsell',
          component: 'productSearch',
          defaultValue: {
            handle: 'truff-black-truffle-oil',
          },
        },
        {
          label: 'Override Product Image',
          name: 'image',
          component: 'image',
        },
      ],
    },
    {
      label: 'Gift With Purchase',
      name: 'gwp',
      component: 'group',
      fields: [
        {
          label: 'Gift With Purchase Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Cart Spend To Qualify For Gift (USD)',
          name: 'gwpCartMinimum',
          component: 'number',
        },
        {
          label: 'Eligible Products',
          name: 'eligibleProducts',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          defaultItem: () => ({
            id: Math.random().toString(36).substr(2, 9),
            product: {
              handle: 'best-seller-pack',
            },
          }),
          fields: [
            {
              label: 'Eligible Product',
              name: 'product',
              component: 'productSearch',
            },
          ],
        },
        {
          label: 'Gift Product',
          name: 'giftProduct',
          description: 'Max of 1 product.',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          defaultItem: () => ({
            id: Math.random().toString(36).substr(2, 9),
            product: {
              handle: 'truff-black-truffle-oil',
            },
          }),
          fields: [
            {
              label: 'Gift Product',
              name: 'product',
              component: 'productSearch',
            },
          ],
        },
      ],
    },
  ],
};
