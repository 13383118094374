import { useState, useEffect } from 'react';
import { Box, Flex } from 'theme-ui';

export const Countdown = ({ setTime }) => {
    const relativeTime = !(setTime > 86400000000); //over 1000 days
    const calculateTimeLeft = (d) => {
      let timeLeft = {};
      if(d) {
        const startDate = d.setHours(0,0,0,0);
        const targetTime = relativeTime ? (startDate + setTime) : setTime;
        const difference = +new Date(targetTime).getTime() - +new Date().getTime();
  
        if(difference > 0) {
            if(difference > 86400000) { //over 1 day difference
                timeLeft = {
                    days: Math.floor((difference / 86400 / 1000)),
                    hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    mins: Math.floor((difference / 1000 / 60) % 60),
                }
            } else {
                timeLeft = {
                    hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    mins: Math.floor((difference / 1000 / 60) % 60),
                    secs: Math.floor((difference / 1000) % 60),
                }
            }
        } else {
            timeLeft = {
                hrs: 0,
                mins: 0,
                secs: 0,
            }
        }
      }
      return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const d = new Date();
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(d));
      }, 1000);

      return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        timerComponents.push(
            <Box className='countdown-timeleft'>
                <Box className='countdown-timeleft-value'>{timeLeft[interval] <= 9 ? '0' + timeLeft[interval] : timeLeft[interval]}</Box>
                <Box>{interval}</Box>
            </Box>
        );
    });

    return (<Box className='countdown-container'>
        {timerComponents.length ? (
            <Flex
                className='countdown-inner'
                sx={{
                    fontSize: '10px',
                    lineHeight: '1.4',
                    gap: '10px',
                    rowGap: '7px',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    '.countdown-timeleft-value' : {
                        position: 'relative',
                    },
                    '.countdown-timeleft:not(:last-child) .countdown-timeleft-value:after': {
                        content: '":"',
                        position: 'absolute',
                        top: '50%',
                        right: '-5px',
                        transform: 'translate(50%, -50%)',
                    },
                }}
            >
                {timerComponents}
            </Flex>
        ) : <></>}
    </Box>);
}