import { Paragraph, Flex, Box } from 'theme-ui';
import { useCart, useCartDiscounts } from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

/*
  Cart totals when no tiering based discount are applied
  Supports discount codes
*/
export const CartTotalAmountRegular = () => {
  const cart = useCart();
  const cartDiscount = useCartDiscounts()[0];
  const totalsRegular = useRecoilValue(store.totalsRegular$);

  if (!cart) return null;

  return (
    <Box data-comp="CartTotalAmountRegular">
      {cartDiscount ? (
        <>
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Subtotal
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              ${totalsRegular.subtotal.toFixed(2)}
            </Paragraph>
          </Flex>

          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Discount
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              -${totalsRegular.discount.toFixed(2)}
            </Paragraph>
          </Flex>
        </>
      ) : null}

      <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
        <Paragraph variant="label.2" sx={{ color: 'white' }}>
          Total
        </Paragraph>
        <Paragraph variant="label.2" sx={{ color: 'white' }}>
          ${totalsRegular.total.toFixed(2)}
        </Paragraph>
      </Flex>
    </Box>
  );
};
