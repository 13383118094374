import { Paragraph, Flex, Box } from 'theme-ui';
import { useCart, useCartDiscounts } from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

/**
 * Tiered discounted totals
 * 5% over $75, 10% over $100 applied to all items
 */
export function CartTotalAmountTiered() {
  const cart = useCart();
  const cartDiscount = useCartDiscounts()[0];

  const totalsTiered = useRecoilValue(store.totalsTiered$);

  if (!cart) return null;

  return (
    <Box data-comp="CartTotalAmountTiered">
      {/* tiered AND discount code applied */}
      {cartDiscount ? (
        <>
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              FullPrice
            </Paragraph>
            <Paragraph
              variant="label.2"
              sx={{ color: 'white', textDecoration: 'line-through' }}
            >
              ${totalsTiered.fullPrice.toFixed(2)}
            </Paragraph>
          </Flex>

          {/* Price after tiered discounts */}
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              SubTotal
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              ${totalsTiered.subtotal.toFixed(2)}
            </Paragraph>
          </Flex>

          {/* discount code reduction */}
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Discount
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              -${totalsTiered.discount.toFixed(2)}
            </Paragraph>
          </Flex>

          {/* price after discount reduction */}
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Total
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              ${totalsTiered.total.toFixed(2)}
            </Paragraph>
          </Flex>
        </>
      ) : (
        // tiered but NO discount code applied
        <>
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Subtotal
            </Paragraph>
            <Paragraph
              variant="label.2"
              sx={{ color: 'white', textDecoration: 'line-through' }}
            >
              ${totalsTiered.subtotal.toFixed(2)}
            </Paragraph>
          </Flex>

          {/* Price after tiered discounts */}
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Total
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              ${totalsTiered.total.toFixed(2)}
            </Paragraph>
          </Flex>
        </>
      )}
    </Box>
  );
}

export function CartTotalAmountRegular() {
  const cart = useCart();
  const cartDiscount = useCartDiscounts()[0];
  const totalsRegular = useRecoilValue(store.totalsRegular$);

  if (!cart) return null;

  return (
    <Box data-comp="CartTotalAmountRegular">
      {cartDiscount ? (
        <>
          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Subtotal
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              ${totalsRegular.subtotal.toFixed(2)}
            </Paragraph>
          </Flex>

          <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              Discount
            </Paragraph>
            <Paragraph variant="label.2" sx={{ color: 'white' }}>
              -${totalsRegular.discount.toFixed(2)}
            </Paragraph>
          </Flex>
        </>
      ) : null}

      <Flex variant="flex.row.between.center" sx={{ mb: 1 }}>
        <Paragraph variant="label.2" sx={{ color: 'white' }}>
          Total
        </Paragraph>
        <Paragraph variant="label.2" sx={{ color: 'white' }}>
          ${totalsRegular.total.toFixed(2)}
        </Paragraph>
      </Flex>
    </Box>
  );
}
