import { BackpackImage, Markdown, VideoPlayer } from '@snippets';
import { Box, Heading, AspectRatio } from 'theme-ui';

export function ArticleContent({ content, ...props }) {
  const haveBody = content?.body?.length;

  return (
    <Box
      data-comp="ArticleContent"
      {...props}
      sx={{
        pt: [10, 12, 14, 15],
        pb: [8, null, null, 0],
        borderBottom: (t) => [
          `1px solid ${t.colors.gray[3]}`,
          null,
          null,
          'none',
        ],
        ...props.sx,
      }}
    >
      <Heading as="h1" variant="text.24.r2" sx={{ mb: [3, null, 8, 11] }}>
        {content?.title}
      </Heading>

      <Markdown
        text={content.subTitle}
        textAlign="left"
        style={{
          p: {
            fontSize: '16px !important',
            lineHeight: '140% !important',
          },
          '@media screen and (min-width: 40em)': {
            fontSize: '24px !important',
            lineHeight: '140% !important',
          },
        }}
        sx={{
          mb: [11],
        }}
      />

      {haveBody
        ? content.body.map((bodyBlock, index) => {
            const isParagraph = bodyBlock._template === 'markdown-block';
            const isVideo = bodyBlock._template === 'video-block';
            const isImage = bodyBlock._template === 'image-block';

            return isParagraph ? (
              <Markdown
                key={`body${index}`}
                text={bodyBlock?.paragraph}
                textAlign="left"
                style={{
                  p: {
                    fontSize: '16px !important',
                    lineHeight: '140% !important',
                  },
                  '@media screen and (min-width: 40em)': {
                    fontSize: '24px !important',
                    lineHeight: '140% !important',
                  },
                }}
                sx={{
                  mb: [7, null, null, 10],
                  'ul, ol': {
                    ml: 0,
                  },
                }}
              />
            ) : isVideo ? (
              <AspectRatio
                ratio={16 / 9}
                sx={{
                  bg: 'black',
                  mb: [7, null, null, 11],
                  iframe: { position: 'absolute' },
                }}
              >
                <VideoPlayer
                  video={{ videoId: bodyBlock?.video }}
                  mediaType={{ main: 'video' }}
                />
              </AspectRatio>
            ) : isImage ? (
              <Box sx={{ mb: [7, null, null, 11] }}>
                <BackpackImage
                  src={
                    bodyBlock?.image?.src || bodyBlock?.image?.filename || ''
                  }
                  alt={bodyBlock?.image?.alt || 'Article body image'}
                  ratio={16 / 9}
                  sx={{
                    display: ['block'],
                  }}
                />
              </Box>
            ) : null;
          })
        : null}
    </Box>
  );
}
