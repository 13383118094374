export default {
  label: 'NoIndex Settings',
  name: 'noindex',
  component: 'group',
  fields: [
    {
      label: 'URL Path List',
      name: 'urlPathList',
      component: 'group-list',
      description: 'No-Index/No-Follow URL path list.',
      itemProps: {
        label: '{{item.urlPath}}',
      },
      fields: [
        {
          label: 'URL Path',
          name: 'urlPath',
          description:
            'Add a url path to keep site creawlers from indexing it. Example: /collections/replacements',
          component: 'text',
        },
      ],
    },
  ],
  defaultValue: {
    urlPathList: [
      { urlPath: '/collections/replacements' },
      { urlPath: '/collections/collection-group' },
      { urlPath: '/collections/fashion' },
      { urlPath: '/collections/snappy' },
      { urlPath: '/collections/subscriptions' },
      { urlPath: '/pages/text-club' },
      {
        urlPath:
          '/products/truff-original-black-truffle-hot-sauce-gourmet-hot-sauce-with-ripe-chili-peppers-black-truffle-oil-organic-agave-nectar-unique-flavor-experience-in-a-bottle-6-oz',
      },
      { urlPath: '/pages/our-story' },
      { urlPath: '/collections/mini-truff' },
      {
        urlPath:
          '/products/truff-hot-sauce-4-pack-mini-set-portable-travel-bottles-of-gourmet-hot-sauce-black-truffle-and-chili-peppers-gift-idea-for-the-hot-sauce-fans-an-ultra-unique-flavor-experience-1-5-oz-4-count',
      },
      { urlPath: '/pages/hot-sauce' },
      { urlPath: '/pages/truff-mayo' },
    ],
  },
};
