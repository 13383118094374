import { AspectRatio, Image } from 'theme-ui';

export const BackpackImage = ({ ratio, src, sx, ...props }) => {
  return typeof ratio !== 'undefined' ? (
    <AspectRatio data-comp="BackpackImageRatio" ratio={ratio}>
      <Image src={src || '/svg/default-image.svg'} {...props} />
    </AspectRatio>
  ) : (
    <Image
      data-comp="BackpackImage"
      src={src || '/svg/default-image.svg'}
      sx={{
        ...sx,
        maxHeight: '100%',
        maxWidth: '100%',
      }}
      {...props}
    />
  );
};
