export const forms = {
  label: {
    variant: 'text.14.1',
    mb: 5,
  },
  account: {
    input: {
      variant: 'text.base',
      fontSize: 5,
      p: '11px',
      mb: 6,
      fontFamily:
        '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'gray.3',
      borderRadius: 0,
      ':focus': {
        outline: 'none !important',
        borderColor: 'gold',
      },
      ':valid': {
        borderColor: 'gold',
      },
      '&::placeholder': {
        fontFamily:
          '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        fontSize: 4,
        color: 'gray.5',
      },
    },
  },
  input: {
    variant: 'text.base',
    fontSize: 5,
    pt: 8,
    pb: 7,
    mb: ['40px'],
    pl: '40px',
    pr: 8,
    border: '1px solid',
    borderColor: 'gray.3',
    borderRadius: 0,
    ':focus': {
      outline: 'none !important',
      borderColor: 'gold',
    },
    ':valid': {
      borderColor: 'gold',
    },
    '&::placeholder': {
      fontFamily:
        '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      fontSize: 4,
      color: 'gray.5',
    },
  },
  search: {
    variant: 'text.16.1',
    pt: 8,
    pb: 7,
    mb: ['40px'],
    pl: '40px',
    pr: 8,
    border: '1px solid',
    borderColor: 'gray.3',
    borderRadius: 0,
    ':focus': {
      outline: 'none !important',
      borderColor: 'gold',
    },
    ':valid': {
      borderColor: 'gold',
    },
    '&::placeholder': {
      fontFamily:
        '"Open Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      fontSize: 4,
      color: 'gray.5',
    },
  },
};
