import { Box, Flex } from 'theme-ui';
import { withInView } from '@utils';

export const SpiceMeter = withInView( ({ cms, inView }) => {
  const {
    heading,
    spiceLevel,
  } = cms;

  return (
    <Box
      data-comp="SpiceMeterBlock"
      sx={{
        pt: [15, null, null, 18],
        pb: [13, null, null, 17],
        px: [6, 11],
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1440px',
          position: 'relative',
        }}
      >
        {heading && (
            <Box
                sx={{
                    fontFamily: 'heading',
                    fontSize: ['32px', null, null,'48px'],
                    fontWeight: '400',
                    lineHeight: '1.12',
                    letterSpacing: '0.02em',
                    color: 'black',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                }}
            >
                {heading}
            </Box>
        )}
        <Box
            sx={{
                height: '20px',
                width: '100%',
                maxWidth: '670px',
                mx: 'auto',
                mt: ['50px', null, null, '60px'],
                mb: '50px',
                position: 'relative',
                backgroundColor: '#f2f3f4',
                border: '2px solid #1a1a1a',
                borderRadius: '100px',
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    width: inView ? spiceLevel + '%' : '0%',
                    background: 'linear-gradient(270deg, #ac1b2e 0%, #f00000 100%)',
                    borderRadius: '100px',
                    transition: 'all 2.25s ease-in-out',
                }}
            />
            <Flex
                sx={{
                    backgroundColor: 'black',
                    border: '3px solid #cab683',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '50%',
                    left: inView ? spiceLevel + '%' : '0%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'all 2.25s ease-in-out',
                    overflow: 'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                
                <img src={`/svg/geologo.svg`} alt="" sx={{
                    display: 'block',
                    width: '90%',
                    height: '90%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }} />
            </Flex>
        </Box>
        <Flex
            sx={{
                width: '100%',
                maxWidth: '670px',
                mx: 'auto',
                textTransform: 'uppercase',
                fontFamily: 'heading',
                fontSize: ['18px', null, null, '24px'],
                color: 'black',
                lineHeight: '1.33',
                letterSpacing: '0.05em',
            }}
        >
            <Box
                sx={{
                    opacity: inView ? (spiceLevel == 0 ? 1 : 0.3) : 0.3,
                    transition: 'all 2.25s ease-in-out',
                }}
            >No Heat</Box>
            <Box
                sx={{
                    flexGrow: '1',
                    textAlign: 'center',
                    opacity: inView ? (spiceLevel == 50 ? 1 : 0.3) : 0.3,
                    transition: 'all 2.25s ease-in-out',
                }}
            >🌶️ Mild</Box>
            <Box
                sx={{
                    opacity: inView ? (spiceLevel == 100 ? 1 : 0.3) : 0.3,
                    transition: 'all 2.25s ease-in-out',
                }}
            >🌶️🌶️ Spicy</Box>
        </Flex>
      </Box>
    </Box>
  );
},
{}
);
