import { Flex } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { OrderHistoryRowItem } from './OrderHistoryRowItem';
import { OrderHistoryEmptyState } from './OrderHistoryEmptyState';
import { OrderHistoryColumnHeadings } from './OrderHistoryColumnHeadings';

export const OrderHistoryMain = () => {
  const { customer } = useCustomer();
  const orders = customer?.orders?.edges?.reverse();

  return (
    <Flex
      variant="flex.column"
      data-comp="OrderHistoryMain"
      sx={{ width: '100%' }}
    >
      <OrderHistoryColumnHeadings />
      {orders?.map(({ node: order }, index) => (
        <OrderHistoryRowItem order={order} key={index} />
      ))}
      {orders?.length < 1 && <OrderHistoryEmptyState />}
    </Flex>
  );
};
