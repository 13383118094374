import { useState, useEffect } from 'react';
import { Box, Input, Label } from 'theme-ui';

export const StyledInput = ({
  name = '',
  placeholder = '',
  label = '',
  type = null,
  value = null,
  id = '',
  action,
  required = true,
  readOnly = false,
  defaultValue,
  sx,
  ...props
}) => {
  const [valid, setValid] = useState(false);
  const inputId = `styled-input-${id}`;

  useEffect(() => {
    !valid && value?.length > 0 ? setValid(true) : null;
    const field = document.getElementById(inputId);
    const onFocus = () => setValid(true);
    const onBlur = () => (!value ? setValid(false) : null);
    field.addEventListener('focus', onFocus);
    field.addEventListener('blur', onBlur);
    return () => {
      field.removeEventListener('focus', onFocus);
      field.removeEventListener('blur', onBlur);
    };
  }, [value]);

  return (
    <Box
      data-comp="StyledInput"
      sx={{
        position: 'relative',
        height: '44px',
        width: 'auto',
        mb: 6,
        ...sx,
      }}
      {...props}
    >
      <Label
        htmlFor={inputId}
        sx={{
          position: 'absolute',
          pointerEvents: 'none',
          top: '9px',
          left: 6,
          variant: 'text.10',
          color: 'gold',
          visibility: valid ? 'block' : 'hidden',
        }}
      >
        {label || placeholder}
      </Label>

      <Input
        variant="account.input"
        id={inputId}
        name={name}
        placeholder={valid ? '' : placeholder}
        type={type}
        required={required}
        value={value}
        readOnly={readOnly}
        defaultValue={defaultValue}
        onChange={({ target: { value } }) => {
          if (typeof action === 'function') {
            action(value);
          }
        }}
        sx={{
          height: '100%',
          width: '100%',
          mb: 0,
          px: '11px',
          pt: valid ? '17px' : '11px',
          pb: valid ? '5px' : '11px',
          ':valid': {
            borderColor: valid ? 'gold' : 'gray.3',
          },
          borderColor: valid ? 'gold' : 'gray.3',
          color: readOnly ? 'gray.5' : 'black',
          fontSize: '16px',
        }}
      />
    </Box>
  );
};
