import { Flex, Box } from "theme-ui";

import { AddToCart } from "@snippets";
import store, { useRecoilValue } from "@store";

import { ProductItemQuantitySelect } from "./ProductItemQuantitySelect";
import { ProductItemGiftCards } from "./ProductItemGiftCards";
import { ProductItemSizes } from "./ProductItemSizes";

export function ProductItemQuickShop({
  selectedVariant,
  setSelectedVariant,
  quantity,
  setQuantity,
  isHover,
  sx,
  isCollectionPage = null,
  productItem = null,
  ...props
}) {
  const isGiftCard = productItem?.productType === "Gift Cards";
  const isFashion = productItem?.productType === "Fashion";
  const hasSizeOptions = productItem?.variants?.length > 1;

  const country = useRecoilValue(store.country);

  if (!isCollectionPage && productItem?.tags.includes("hide-quick-add"))
    return null;
  return (
    <Box
      data-comp={ProductItemQuickShop.displayName}
      variant="flex.column"
      className="product-item-quick-shop-slider"
      sx={{
        display: isCollectionPage ? "flex" : ["none", "flex"],
        px: isCollectionPage ? ["10px", null, null, 0] : 8,
        pb: isCollectionPage ? ["15px", null, null, 0] : 8,
        mb: isCollectionPage ? [null, null, null, 8] : 0,
        position: isCollectionPage ? "static" : "absolute",
        zIndex: 1,
        bg: isCollectionPage ? [null, null, null, null, "white"] : "white",
        width: isCollectionPage
          ? ["100%", null, null, null, "calc(100% + 2px)"]
          : "calc(100% + 2px)",
        height: "auto",
        maxHeight: isCollectionPage ? "auto" : "16px",
        opacity: null,
        top: isCollectionPage
          ? [null, null, null, null, "calc(100% - 16px)"]
          : "calc(100% - 16px)",
        transform: null,
        left: "-1px",
        right: "0",
        borderRadius: isCollectionPage ? "none" : "0 0 6px 6px",
        borderWidth: isCollectionPage ? [null, null, null, null, "1px"] : "1px",
        borderStyle: isCollectionPage
          ? [null, null, null, null, "solid"]
          : "solid",
        borderTopWidth: 0,
        borderColor: "gray.3",
        transition: "max-height 0.25s ease-in-out",
        ...sx,
      }}
      {...props}
    >
      <Flex
        className="product-item-quick-shop-options"
        variant="flex.column"
        sx={{
          mt: isCollectionPage ? null : 8,
          visibility: isCollectionPage
            ? [null, null, null, null, "visible"]
            : "hidden",
          opacity: isCollectionPage ? [null, null, null, null, 1] : 0,
        }}
      >
        {isCollectionPage && (
          <Box>
            {isGiftCard && (
              <ProductItemGiftCards
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                productItem={productItem}
                quantity={quantity}
                isCollectionPage={isCollectionPage}
              />
            )}

            {isFashion && hasSizeOptions && (
              <ProductItemSizes
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                productItem={productItem}
                quantity={quantity}
                isCollectionPage={isCollectionPage}
              />
            )}
          </Box>
        )}
        {selectedVariant && country && (
          <Flex variant="flex.row">
            <ProductItemQuantitySelect
              selectedVariant={selectedVariant}
              quantity={quantity}
              setQuantity={setQuantity}
              isHover={isHover}
              isCollectionPage={isCollectionPage}
            />

            <AddToCart
              selectedVariant={selectedVariant}
              quantity={quantity}
              isPdp
              isCollectionPage={isCollectionPage}
              sx={{
                display:
                  (isCollectionPage && isGiftCard) ||
                  (isFashion && hasSizeOptions && isCollectionPage)
                    ? ["none", null, null, null, "block"]
                    : null,
              }}
            />
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
