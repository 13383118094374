import { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';

import { requestYotpoLoyalty } from '@utils';

import { ReferHistory } from './ReferHistory';
import { ReferShareLinks } from './ReferShareLinks';
import { ReferInputFields } from './ReferInputFields';

export const RewardsModalRefer = ({ email, yotpoCustomer }) => {
  const [referralData, setReferralData] = useState(null);
  const [customEmail, setCustomEmail] = useState(null);
  const [emails, setEmails] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const showReferrals = referralData?.referral_receipts?.length > 0;
  const isYotpoCustomer = !!email && email !== 'undefined';

  const getReferralLink = useCallback(async () => {
    try {
      const { data } = await requestYotpoLoyalty({
        action: 'getReferralData',
        data: { email },
      });

      if (!data) {
        return;
      }

      setReferralData(data);
    } catch (err) {
      throw err;
    }
  }, [email]);

  const handleSendReferralEmail = useCallback(() => {
    if ((!isYotpoCustomer && !customEmail) || !emails || isLoading) return;
    setIsLoading(true);
    const sendReferralEmail = async () => {
      try {
        const response = await requestYotpoLoyalty({
          action: 'sendReferralEmail',
          data: {
            email: email || customEmail.trim(),
            emails: emails.trim(),
          },
        });
        setSubmitted(!response?.errors);
        if (!response?.errors) {
          setSubmitted(true);
          setEmails('');
          setCustomEmail('');
          setTimeout(() => {
            setSubmitted(false);
            setOpacity(0);
          }, 3000);
          getReferralLink();
        } else {
          setSubmitted(false);
        }
        setIsLoading(false);
      } catch (err) {
        throw err;
      }
    };
    sendReferralEmail();
  }, [isYotpoCustomer, email, customEmail, emails]);

  useEffect(() => {
    if (!isYotpoCustomer) return;
    getReferralLink();
  }, [isYotpoCustomer]);

  return (
    <Flex
      data-comp="RewardsModalRefer"
      variant="flex.column.center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Paragraph variant="24" sx={{ mb: 8 }}>
        Refer A Friend
      </Paragraph>

      <ReferInputFields
        isYotpoCustomer={isYotpoCustomer}
        setCustomEmail={setCustomEmail}
        customEmail={customEmail}
        setEmails={setEmails}
        emails={emails}
        submitted={submitted}
        handleSendReferralEmail={handleSendReferralEmail}
        setOpacity={setOpacity}
        opacity={opacity}
        isLoading={isLoading}
      />

      {isYotpoCustomer && (
        <>
          <ReferShareLinks referralData={referralData} />
          <Flex
            variant="flex.column"
            sx={{
              width: '100%',
            }}
          >
            <Box sx={{ mb: showReferrals ? 11 : 0 }}>
              <Paragraph variant="label.2" sx={{ textAlign: 'center', mb: 4 }}>
                How do I redeem my points?
              </Paragraph>
              <Paragraph variant="base" sx={{ textAlign: 'center' }}>
                Now, the fun part! Simply apply your discount at checkout.
              </Paragraph>
            </Box>
            {showReferrals && <ReferHistory referralData={referralData} />}
          </Flex>
        </>
      )}
    </Flex>
  );
};
