import { useEffect, useRef } from 'react';
import { Box, Spinner, Alert, Paragraph, Flex, Text } from 'theme-ui';

import { useYotpoStars, useYotpoScrollToReviews } from '@hooks/yotpo';
// import animations from '@animations';

import { YotpoStarsIcons } from './YotpoStarsIcons';

// Fetches stars data from yotpo and then renders them
export const YotpoStars = ({
  legacyResourceId,
  bottomline = null,
  isProductItem = false,
  withCta = true,
  isSelectable = false,
  withScroll = true,
  onClickedStar = () => {},
  textColor = 'white',
  fullColor = 'gold',
  emptyColor = 'darkGold',
  isCollectionPage = false,
  ...props
}) => {
  const { stars, ...starsStatus } = useYotpoStars({
    legacyResourceId,
    bottomline,
  });
  const { scrollToReviews } = useYotpoScrollToReviews();

  switch (true) {
    case Boolean(stars): {
      // product stars info returned by yotpo
      const { averageScore, totalReviews } = stars;
      const noReviews = totalReviews === 0;

      return (
        <Box
          data-comp={YotpoStars.displayName}
          key={averageScore + totalReviews}
        >
          <Flex
            variant="flex.row.start.center"
            tabIndex="0"
            {...props}
            sx={{
              minHeight: '28px',
              my: 3,
              ...props.sx,
            }}
          >
            <YotpoStarsIcons
              totalReviews={totalReviews}
              averageScore={averageScore}
              onClickedStar={onClickedStar}
              isSelectable={isSelectable}
              fullColor={fullColor}
              emptyColor={emptyColor}
            />

            {withCta ? (
              <Paragraph
                data-comp={`${YotpoStars.displayName}Total`}
                aria-label={`${totalReviews} reviews`}
                onClick={withScroll ? scrollToReviews : null}
                variant="link2"
                sx={{
                  ml: 4,
                  mt: 2,
                  mb: 1,
                  textTransform: 'uppercase',
                  minWidth: ['auto'],
                  cursor: withScroll || isSelectable ? 'pointer' : 'initial',
                  textDecoration:
                    withScroll || isSelectable ? 'underline' : 'none',
                  mr: 'auto',
                  color: textColor,
                }}
              >
                {totalReviews ? (
                  <>
                    <Text
                      sx={{
                        display: ['inline-block', 'none'],
                        fontFamily: isCollectionPage ? 'heading' : '',
                        fontSize: isCollectionPage ? '11px' : '12px',
                        fontWeight: isCollectionPage ? '700' : '',
                        letterSpacing: isCollectionPage ? '0.01em' : '',
                        textDecoration:
                          isCollectionPage ? 'underline' :
                          (withScroll || isSelectable)
                            ? 'underline'
                            : 'none',
                      }}
                    >
                      {totalReviews}
                      {totalReviews === 1 ? ' Review' : ' Reviews'}
                    </Text>
                    <Text
                      sx={{
                        display: ['none', 'inline-block'],
                        fontFamily: isCollectionPage ? 'heading' : '',
                        fontSize: isCollectionPage ? '11px' : '12px',
                        fontWeight: isCollectionPage ? '700' : '',
                        letterSpacing: isCollectionPage ? '0.01em' : '',
                        textDecoration:
                          isCollectionPage ? 'underline' : 
                          (withScroll || isSelectable)
                            ? 'underline'
                            : 'none',
                      }}
                    >
                      {totalReviews}
                      {totalReviews === 1 ? ' Review' : ' Reviews'}
                    </Text>
                  </>
                ) : isSelectable ? (
                  <Text
                    sx={{
                      display: ['none', 'inline-block'],
                      fontFamily: isCollectionPage ? 'heading' : '',
                      fontSize: isCollectionPage ? '11px' : '12px',
                      fontWeight: isCollectionPage ? '700' : '',
                      letterSpacing: isCollectionPage ? '0.01em' : '',
                      textDecoration:
                        isCollectionPage ? 'underline' :
                        (withScroll || isSelectable)
                          ? 'underline'
                          : 'none',
                    }}
                  >
                    Write a Review
                  </Text>
                ) : (
                  <Text
                    sx={{
                      display: ['none', 'inline-block'],
                      fontFamily: isCollectionPage ? 'heading' : '',
                      fontSize: isCollectionPage ? '11px' : '12px',
                      fontWeight: isCollectionPage ? '700' : '',
                      letterSpacing: isCollectionPage ? '0.01em' : '',
                      textDecoration: isCollectionPage ? 'underline' : '',
                    }}
                  >
                    0 reviews
                  </Text>
                )}
              </Paragraph>
            ) : null}
          </Flex>
        </Box>
      );
    }

    case starsStatus.errors.length > 0:
      return <Alert>{starsStatus.errors[0]}</Alert>;

    default:
      return (
        <Spinner
          sx={{ width: '16px', minHeight: '28px', my: 3, color: 'gold' }}
        />
      );
  }
};

YotpoStars.displayName = 'YotpoStars';
