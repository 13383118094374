import { Box, Paragraph, Flex, Image } from 'theme-ui';

import { LinkWrapper } from '@snippets';

export const AccountMenuItem = ({ router, link, navItemSx, ...props }) => {
  const isActive =
    router.query.route === link.value ||
    (link.value === 'orders' && !!router.query.id);

  return (
    <Box data-comp="AccountMenuItem" sx={{ mb: 8 }} {...props}>
      <LinkWrapper
        href={
          link.value === 'rewards'
            ? `/pages/${link.value}`
            : `/account/${link.value}`
        }
      >
        <Flex variant="flex.row.between.center" sx={navItemSx(isActive)}>
          <Paragraph
            variant="14"
            sx={{
              color: isActive ? 'gold' : 'black',
              fontWeight: 400,
            }}
          >
            {link.label}
          </Paragraph>
          {isActive && (
            <Image
              src={`/svg/chevron-down-gold.svg`}
              sx={{
                width: '12px',
                transform: 'rotate(-90deg)',
                mr: 1,
                userSelect: 'none',
              }}
              draggable={false}
            />
          )}
        </Flex>
      </LinkWrapper>
    </Box>
  );
};
