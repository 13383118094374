import { useState, useEffect } from "react";
import { isBrowser } from "./isBrowser";

const DEBUG = false;

const refreshYotpoWidgets = ({ delay = 250 }) => {
  // refresh yotpo on page navigation
  if (isBrowser && window.yotpo) {
    const t0 = requestTimeout(() => {
      if (typeof window.yotpo.refreshWidgets === "function") {
        // eslint-disable-next-line no-unused-expressions
        DEBUG &&
          console.log("🔄 Refreshing Yotpo widgets", window.yotpo.widgets);
        window.yotpo.refreshWidgets();
      }
      window.clearRequestTimeout(t0);
    }, delay);
  }
};

export const useYotpoRefreshOnMount = ({ delay = 100 }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    if (!window?.yotpoWidgetsContainer) return;
    window?.yotpoWidgetsContainer.initWidgets();
  }, []);

  useEffect(() => {
    if (!mounted) return;
    // eslint-disable-next-line no-unused-expressions
    DEBUG && console.log("🔄 Yotpo element mounted");
    refreshYotpoWidgets({ delay });
  }, [mounted]);
};
