import { store } from '@backpackjs/storefront';
import effects from './effects';

const overlay = store.recoil.atom({
  key: 'global/overlay',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('overlay'),
  ],
});

const sideDrawer = store.recoil.atom({
  key: 'global/sideDrawer',
  // { type: 'product', data: product.handle } or any other contexts you need in the future..
  default: null, // null === drawer closed
  effects_UNSTABLE: [
    // effects.logStateChange('sideDrawer'),
  ],
});

const cartDrawer = store.recoil.atom({
  key: 'global/cartDrawer',
  default: false,
  effects_UNSTABLE: [
    // effects.logStateChange('cartDrawer'),
  ],
});

const modal = store.recoil.atom({
  key: 'global/modal',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('modal'),
  ],
});

const sideDrawerIsProduct$ = store.recoil.selector({
  key: 'global/sideDrawerIsProduct$',
  get: ({ get }) => {
    const _sideDrawer = get(sideDrawer);
    return _sideDrawer?.type === 'product' || false;
  },
});

const bisSelectedVariant = store.recoil.atom({
  key: 'global/bisSelectedVariant',
  default: null,
});

const country = store.recoil.atom({
  key: 'global/country',
  default: null,
});

const blogType = store.recoil.atom({
  key: 'global/blogType',
  default: null,
});

const cashbackModalOpen = store.recoil.atom({
  key: 'global/cashbackModal',
  default: false,
});

export default {
  overlay,
  modal,
  sideDrawer,
  cartDrawer,
  sideDrawerIsProduct$,
  bisSelectedVariant,
  country,
  blogType,
  cashbackModalOpen,
};
