import { useState, useEffect } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';
import { useCustomer, useRouter } from '@backpackjs/storefront';

import { StyledButton, StyledInput, LinkWrapper } from '@snippets';

export const ResetPasswordForm = ({ params }) => {
  const router = useRouter();
  const customer = useCustomer();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [message, setMessage] = useState('');
  const [mounted, setMounted] = useState(false);
  const [success, setSuccess] = useState(false);
  const isLoading = customer?.loading['password.reset'];

  const { customerId, resetToken } = params;
  const resetUrl = `https://7diamonds.com/account/reset/${customerId}/${resetToken}`;

  const resetPassword = (e) => {
    e.preventDefault();

    if (passwordConfirm !== password) {
      setMessage('Passwords do not match. Please try again.');
      return;
    }

    customer.resetPassword({
      password,
      url: resetUrl,
    });
  };

  useEffect(() => {
    if (!customer || isLoading) return;
    !mounted ? setMounted(true) : null;
    const errorArray = customer?.errors?.['password.reset'];
    let errors = [];
    if (errorArray?.length > 0) {
      customer?.errors?.['password.reset'].forEach((err) => {
        if (!err.message) return;
        return errors.push(err.message + `<br/>`);
      });
      errors.length > 0 ? setMessage(errors.join('')) : null;
      return;
    }

    if (mounted && errorArray?.length < 1) {
      setSuccess(true);
      setPassword('');
      setPasswordConfirm('');
      setMessage('Success');
      setTimeout(() => router.push('/account/login'), 2000);
    }
  }, [customer, isLoading]);

  return (
    <Flex
      variant="flex.column"
      sx={{
        width: '100%',
        maxWidth: '500px',
      }}
    >
      <Box>
        <Paragraph
          variant="text.24.r"
          sx={{
            mb: 8,
            textAlign: 'center',
          }}
        >
          Reset Account Password
        </Paragraph>

        <Paragraph variant="base" sx={{ textAlign: 'center' }}>
          Please enter your new password.
        </Paragraph>
      </Box>

      <Flex
        variant="flex.column"
        as="form"
        sx={{
          mt: 11,
          justifyContent: 'center',
        }}
        onSubmit={resetPassword}
      >
        <StyledInput
          variant="account.input"
          name="password"
          type="password"
          placeholder="Password"
          label="Password"
          id="reset-pw-password"
          required
          value={password}
          action={setPassword}
        />

        <StyledInput
          variant="account.input"
          name="passwordConfirm"
          type="password"
          placeholder="Confirm Password"
          label="Confirm Password"
          id="reset-pw-confirm-password"
          required
          value={passwordConfirm}
          action={setPasswordConfirm}
        />

        <Paragraph
          variant="base"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          sx={{
            color: success ? 'black' : 'red',
            lineHeight: 26,
            textAlign: 'center',
          }}
        />

        <StyledButton
          text="Reset Password"
          isLoading={isLoading}
          variant="solid"
          sx={{
            maxWidth: '300px',
            mx: 'auto',
            mt: 9,
            mb: 10,
          }}
        />
      </Flex>
      <LinkWrapper
        href="/account/login"
        variant="buttons.underlineLink"
        sx={{
          alignSelf: 'center',
          color: 'black',
          mx: 'auto',
          fontWeight: 400,
          '&:before, &:after': {
            height: '1px',
          },
        }}
      >
        Cancel
      </LinkWrapper>
    </Flex>
  );
};
