import React, { useEffect, useCallback, useState } from 'react';
import { BackpackImage, LinkWrapper } from '@snippets';
import { Box, Flex, Container, Paragraph } from 'theme-ui';
import { useBlog } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { DropdownSubNav } from './DropdownSubNav';
import { DropdownFilters } from './DropdownFilters';

export function BlogGrid({ cms }) {
  const { subNav, filters } = cms;

  const blog = useBlog();
  const router = useRouter();
  const urlCategory = router?.query?.category || null;

  const [isReady, setIsReady] = useState(false);
  const [initialArticles, setInitialArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);

  const handleFilter = useCallback(
    (option) => {
      if (!initialArticles?.length) return [];

      const articles = initialArticles.filter((article) =>
        article?.tags?.toLowerCase().includes(option.toLowerCase())
      );
      return option.toLowerCase() === 'all'
        ? setFilteredArticles(initialArticles)
        : setFilteredArticles(articles);
    },
    [initialArticles]
  );

  useEffect(() => {
    if (!blog?.articles?.length) return;
    const articlesMap = blog.articles.reduce((map, article) => {
      if (map[article.id]) return map;
      map[article.id] = article;
      return map;
    }, {});
    const articles = Object.values(articlesMap).sort((a, b) => {
      return new Date(b.publishedAt) - new Date(a.publishedAt);
    });

    console.log('articles:', articles);

    const categoryArticles = urlCategory
      ? articles.filter(
          ({ category }) => category.toLowerCase() === urlCategory.toLowerCase()
        )
      : articles;

    setInitialArticles(categoryArticles);
    setFilteredArticles(categoryArticles);
    setIsReady(true);
  }, [router.asPath, blog]);

  if (!isReady) return null;

  return (
    <>
      <Flex
        sx={{
          maxWidth: '1130px',
          px: '40px',
          mx: 'auto',
          width: '100%',
          mt: '48px',
        }}
      >
        {!subNav?.hideSubNav && <DropdownSubNav cms={subNav} />}
        {!filters?.hideFilters && (
          <DropdownFilters cms={filters} onClick={handleFilter} />
        )}
      </Flex>
      <Container
        variant="layout.collectionGrid"
        data-comp="BlogGrid"
        sx={{
          zIndex: 10,
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: initialArticles?.length
            ? ['repeat(2, 1fr)', null, null, 'repeat(3, 1fr)']
            : ['1fr'],
          columnGap: [6, null, null, 10, 13],
          py: [12, null, null, null, 14],
          px: [6, null, null, 10, 13],
        }}
      >
        {filteredArticles?.length
          ? filteredArticles.map((article, index, arr) => {
              if (!article?.handle) return null;

              const { title, image, id, handle } = {
                ...article.seo,
                ...article,
              };

              const _title = title?.split('|')?.[0];

              return (
                <LinkWrapper href={`/articles/${handle}`} key={id}>
                  <Flex
                    variant="flex.column"
                    data-comp="ArticleItem"
                    key={id}
                    sx={{
                      cursor: 'pointer',
                      zIndex: arr.length - index, // ensure productItem drawer is above bellow subcollection
                      position: 'relative',
                      mb: [11, null, null, 17],
                    }}
                  >
                    <Box sx={{ position: 'relative', borderRadius: '10px' }}>
                      <BackpackImage
                        alt={title}
                        src={image}
                        ratio={1}
                        sx={{
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>

                    {/* Category & title */}
                    <Box
                      sx={{
                        px: [8, null, null, 10, 11],
                        py: [8, null, null, 10],
                        bg: 'white',
                        border: '1px solid red',
                        borderColor: 'gray.3',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}
                    >
                      <Paragraph
                        variant="text.label.2"
                        sx={{ mb: 1, color: 'gray.5' }}
                      >
                        {article?.category || 'CATEGORY'}
                      </Paragraph>
                      <Paragraph variant="text.18" sx={{ lineHeight: 1.25 }}>
                        {_title}
                      </Paragraph>
                    </Box>
                  </Flex>
                </LinkWrapper>
              );
            })
          : urlCategory
          ? `${blog?.seo?.title || 'Blog'} has no ${urlCategory} articles`
          : `No articles found in ${blog?.seo?.title || 'blog'}`}
      </Container>
    </>
  );
}

BlogGrid.Schema = () => {
  const subNavSettings = {
    label: 'Sub Nav Dropdown',
    name: 'subNav',
    component: 'group',
    fields: [
      {
        name: 'hideSubNav',
        label: 'Hide Sub Nav',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        name: 'subNavHeading',
        component: 'text',
        label: 'Sub Nav Heading',
      },
      {
        label: 'Nav Items',
        name: 'navItems',
        component: 'group-list',
        itemProps: {
          label: '{{item.navItem.text}}',
        },
        defaultItem: (item) => ({
          navItem: {
            text: '',
            url: '#',
          },
        }),
        fields: [
          {
            name: 'navItem',
            component: 'link',
            label: 'Navigation Item',
          },
        ],
        defaultValue: [
          {
            navItem: {
              text: 'View All',
              url: '/blogs/truff-kitchen',
            },
          },
        ],
      },
    ],
  };

  const filterSettings = {
    label: 'Filters Dropdown',
    name: 'filters',
    component: 'group',
    fields: [
      {
        name: 'hideFilters',
        label: 'Hide Filters',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        name: 'filtersHeading',
        component: 'text',
        label: 'Filters Heading',
      },
      {
        name: 'filterItems',
        label: 'Filter Items',
        description:
          'Filter using available article tags. Add new tags to in article settings.',
        component: 'list',
        field: {
          component: 'select',
          options: ['all'],
          defaultValue: 'all',
        },
        defaultValue: ['all'],
      },
    ],
  };

  return {
    label: 'Blog Grid',
    key: 'blog-grid',
    fields: [subNavSettings, filterSettings],
  };
};
