import { isBrowser } from '@utils';
import { useRouter } from 'next/router';

export const useEditForm = () => {
  const router = useRouter();
  // cms context won't be available outside preview mode / tina
  const cms = isBrowser && window.__bpCMS ? window.__bpCMS : null;

  const editForm = cms
    ? cms.plugins
        .all('form')
        .find(({ id }) => id === `EditPageForm-${router.query.handle}`)
    : null;

  return editForm;
};
