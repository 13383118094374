// <Container> variants

export const layout = {
  container: {
    mx: 'auto',
  },
  menu: {
    px: [8, null, 10, null, 11],
  },
  pdp: {
    // maxWidth: 1200,
    // px: [9, null, null, null, null, null, 0],
    mx: 'auto',
  },
  megaNav: {
    maxWidth: ['100%', null, null, null, 792],
    mx: 'auto',
  },
  collectionGrid: {
    maxWidth: ['1408px'],
  },
  articleOuter: {
    py: [11, null, null, 15, 16, 17],
    bg: 'gray.1',
  },
  articleInner: {
    maxWidth: ['100%', null, null, null, 1130],
    mx: 'auto',
    px: [6, null, 7, null, null, 0],
  },
  login: {
    maxWidth: 800,
    px: [9],
    mx: 'auto',
  },
  account: {
    maxWidth: 1440,
    px: [9],
    mx: 'auto',
  },
};
