import React from 'react';
import { Flex, Paragraph } from 'theme-ui';
import {
  LinkWrapper,
  BackpackImage,
  HeroVideo,
  UnderlineLink,
} from '@snippets';

export function LifestyleGridSecondaryImages({
  secondaryImage,
  video,
  boxShadow,
  imageSx,
}) {
  const hideImgMobile = secondaryImage?.hideSecondaryMobile;
  const hideVideoMobile = video?.hideVideoMobile;
  return (
    <>
      <LinkWrapper
        aria-label={`Go to ${video?.cta?.text}`}
        newTab={video?.cta?.newTab}
        href={video?.cta?.url}
        sx={{
          gridArea: 'video',
          display: hideVideoMobile ? ['none', null, null, 'block'] : 'block',
        }}
      >
        <Flex
          variant="flex.row.center.center"
          sx={{
            minHeight: ['calc(100vw - 24px)', null, null, 'auto'],
            maxHeight: ['calc(100vw - 24px)', null, null, 'auto'],
            pb: [0, null, null, '70%'],
            ...imageBoxSx({ url: video?.cta?.url, boxShadow }),
          }}
        >
          <Flex
            variant="flex.row.center.center"
            sx={{
              borderRadius: '6px',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              transform: 'translateZ(0)',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <HeroVideo
              className="lifestyle-image"
              video={{
                src: video?.video,
                poster: video?.poster?.src,
              }}
              mediaType={{
                main: 'video',
                mobile: 'video',
              }}
              sx={{
                transform: 'scale(1.01)',
                transition: 'all 0.25s ease-in-out',
              }}
              isLifestyleGrid
            />
          </Flex>
          <Flex
            variant="flex.column.start.end"
            sx={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              left: 0,
              bottom: 0,
              pb: [13, null, null, 14],
              px: [9, null, null, 13],
            }}
          >
            <Paragraph
              variant="30"
              sx={{
                color: video?.textColor || 'white',
              }}
            >
              {video?.heading}
            </Paragraph>

            {video?.cta?.text && (
              <UnderlineLink
                noLink
                text={video?.cta?.text}
                variant={video?.buttonStyle || 'primary'}
                mobileVariant={video?.buttonStyle || 'primary'}
                sx={{ mt: [6, null, null, 4] }}
              />
            )}
          </Flex>
        </Flex>
      </LinkWrapper>

      <LinkWrapper
        aria-label={`Go to ${secondaryImage?.cta?.text}`}
        newTab={secondaryImage?.cta?.newTab}
        href={secondaryImage?.cta?.url}
        sx={{
          gridArea: 'secondary',
          display: hideImgMobile ? ['none', null, null, 'block'] : 'block',
        }}
      >
        <Flex
          variant="flex.row.center.center"
          sx={{
            minHeight: ['calc(60vw - 24px)', null, null, 'auto'],
            maxHeight: ['calc(60vw - 24px)', null, null, 'auto'],
            pb: [0, null, null, '50%'],
            ...imageBoxSx({ url: secondaryImage?.cta?.url, boxShadow }),
          }}
        >
          <Flex
            variant="flex.row.center.center"
            sx={{
              borderRadius: '6px',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <BackpackImage
              className="lifestyle-image"
              sx={imageSx}
              src={secondaryImage?.image?.src || secondaryImage?.image}
              alt={secondaryImage?.cta?.text}
            />
          </Flex>
          <Flex
            variant="flex.column.start.end"
            sx={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              left: 0,
              bottom: 0,
              pb: [13, null, null, 14],
              px: [9, null, null, 13],
            }}
          >
            <Paragraph
              variant="30"
              sx={{
                color: secondaryImage?.textColor || 'white',
              }}
            >
              {secondaryImage?.heading}
            </Paragraph>

            {secondaryImage?.cta?.text && (
              <UnderlineLink
                noLink
                text={secondaryImage?.cta?.text}
                variant={secondaryImage?.buttonStyle || 'primary'}
                mobileVariant={secondaryImage?.buttonStyle || 'primary'}
                sx={{ mt: [6, null, null, 4] }}
              />
            )}
          </Flex>
        </Flex>
      </LinkWrapper>
    </>
  );
}

const imageBoxSx = ({ url, boxShadow }) => ({
  bg: 'ghostwhite',
  position: 'relative',
  borderRadius: '6px',
  cursor: url ? 'pointer' : 'default',
  transition: 'box-shadow 0.25s ease-in-out',
  '&:hover': {
    boxShadow: url ? boxShadow : null,
    '.lifestyle-image': {
      transform: url ? 'scale(1.05)' : null,
    },
  },
});
