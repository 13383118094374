import { useMemo } from 'react';
import { Flex, Paragraph } from 'theme-ui';

import { LinkWrapper } from '@snippets';

export const OrderHistoryRowItem = ({ order, ...props }) => {
  const date = useMemo(
    () =>
      `${order.date.slice(5, 7)}/${order.date.slice(8, 10)}/${order.date.slice(
        0,
        4
      )}`,
    [order]
  );

  const capitalize = (str) => {
    return str
      .split(' ')
      .map((v) => {
        return v.slice(0, 1).toUpperCase() + v.slice(1).toLowerCase();
      })
      .join(' ');
  };

  const status = useMemo(() => capitalize(order.status), [order]);

  const total = useMemo(
    () => parseFloat(order.totalPrice.amount).toFixed(2),
    [order]
  );

  return (
    <Flex
      key={order.id}
      variant="flex.row.between"
      sx={{
        py: 9,
        borderBottom: '1px solid',
        borderColor: 'gray.3',
      }}
      {...props}
    >
      <Flex
        sx={{
          flexBasis: ['75px', null, 'calc(25% - 6px)'],
        }}
      >
        <LinkWrapper href={`/account/orders/${order.orderNumber}`}>
          <Paragraph
            variant="sm"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            #{order.orderNumber}
          </Paragraph>
        </LinkWrapper>
      </Flex>
      <Paragraph
        variant="sm"
        sx={{
          flexBasis: ['85px', null, 'calc(25% - 6px)'],
          textAlign: ['center', null, 'left'],
        }}
      >
        {date}
      </Paragraph>
      <Paragraph
        variant="sm"
        sx={{
          flexBasis: ['70px', null, 'calc(25% - 6px)'],
          textAlign: ['center', null, 'left'],
        }}
      >
        {status}
      </Paragraph>
      <Paragraph
        variant="sm"
        sx={{
          flexBasis: ['60px', null, 'calc(25% - 6px)'],
          textAlign: ['right', null, 'left'],
        }}
      >
        ${total}
      </Paragraph>
    </Flex>
  );
};
