export const Schema = () => {
  return {
    label: 'Banner Image',
    key: 'bannerImage',
    fields: [
      {
        name: 'alt',
        component: 'text',
        description:
          'Provide a brief description of the image for accessibility compliance',
        label: 'Alt',
      },
      {
        name: 'imageDt',
        label: 'Image (desktop)',
        component: 'image',
        description:
          'The section’s aspect ratio is based off the image’s aspect ratio.',
        parse: (image) => image,
      },
      {
        name: 'image',
        label: 'Image (mobile)',
        component: 'image',
        parse: (image) => image,
      },
      {
        name: 'link',
        component: 'link',
        label: 'Image Link',
      },
    ],
  };
};
