import { useCallback, useState } from 'react';
import { Flex, Paragraph, Select, Button } from 'theme-ui';

import store, { useRecoilState } from '@store';
import { requestYotpoLoyalty } from '@utils';

export const RewardsBirthday = ({ campaign, email }) => {
  const [birthdayDay, setBirthdayDay] = useState(1);
  const [birthdayMonth, setBirthdayMonth] = useState(1);
  const [yotpoLoyaltyData, setYotpoLoyaltyData] = useRecoilState(
    store.yotpoLoyaltyData
  );
  const { yotpoCustomer } = yotpoLoyaltyData;
  const hasBirthday = yotpoCustomer?.birth_day && yotpoCustomer?.birthday_month;

  const handleSetCustomerBirthday = useCallback(() => {
    if ((!email && !birthdayDay) || !birthdayMonth) return;
    const getCustomer = async () => {
      try {
        const response = await requestYotpoLoyalty({
          action: 'getCustomer',
          data: { email },
        });
        if (response?.data) {
          setYotpoLoyaltyData({
            ...yotpoLoyaltyData,
            yotpoCustomer: response?.data,
          });
        }
      } catch (err) {
        throw err;
      }
    };
    const setCustomerBirthday = async () => {
      try {
        const response = await requestYotpoLoyalty({
          action: 'setCustomerBirthday',
          data: {
            email,
            day: birthdayDay.toString(),
            month: birthdayMonth.toString(),
          },
        });
        if (response?.data) {
          getCustomer();
        }
      } catch (err) {
        throw err;
      }
    };
    setCustomerBirthday();
  }, [email, birthdayDay, birthdayMonth]);

  return (
    <Flex
      data-comp="RewardsBirthday"
      variant="flex.column.center"
      sx={{
        width: '100%',
        mt: 8,
      }}
    >
      {!hasBirthday && (
        <Flex variant="flex.column.center">
          <Flex sx={{ mb: 8 }}>
            <Select
              sx={{
                width: '120px',
                borderRadius: 0,
                borderColor: 'gray.3',
                fontFamily: 'body',
                p: 5,
                fontSize: 4,
              }}
              defaultValue={1}
              onChange={(e) => setBirthdayMonth(e.target.value)}
            >
              {months?.map((month, index) => (
                <option key={month} value={index + 1}>
                  {month}
                </option>
              ))}
            </Select>
            <Select
              sx={{
                width: '50px',
                ml: 4,
                borderRadius: 0,
                borderColor: 'gray.3',
                fontFamily: 'body',
                p: 5,
                fontSize: 4,
              }}
              defaultValue={1}
              onChange={(e) => setBirthdayDay(e.target.value)}
            >
              {[...Array(daysPerMonth[months[birthdayMonth - 1]]).keys()].map(
                (day) => (
                  <option key={day} value={day + 1}>
                    {day + 1}
                  </option>
                )
              )}
            </Select>
          </Flex>
          <Button
            sx={{
              color: 'black',
              fontSize: 1,
              lineHeight: 18,
              letterSpacing: 0.08,
              textAlign: 'center',
              border: '1px solid',
              borderColor: 'gray.3',
              px: 8,
              py: 6,
              mb: 8,
            }}
            onClick={handleSetCustomerBirthday}
          >
            Confirm Birthday
          </Button>
        </Flex>
      )}
      <Paragraph
        variant="base"
        sx={{
          textAlign: 'center',
        }}
      >
        {hasBirthday ? campaign?.extra_copy2 : campaign?.extra_copy1}
      </Paragraph>
    </Flex>
  );
};

const daysPerMonth = {
  January: 31,
  February: 29,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
