import { useRef, useEffect } from "react";
import dynamic from "next/dynamic";
import { useSwipeable } from "react-swipeable";
import { Paragraph, Flex, Image, Box } from "theme-ui";

import store, { useSetRecoilState, useRecoilState } from "@store";
import { CartCounterToggle } from "@snippets";

const Cart = dynamic(() => import("../Cart").then((mod) => mod.Cart), {
  ssr: false,
  suspense: false,
});

export const CartDrawer = (props) => {
  const setOverlay = useSetRecoilState(store.overlay);
  const [cartDrawer, setCartDrawer] = useRecoilState(store.cartDrawer);
  const cartDrawerRef = useRef();

  const closeCartDrawer = () => {
    setCartDrawer(false);
    setOverlay(false);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      closeCartDrawer();
    },
    delta: 10,
    preventDefaultTouchmoveEvent: false,
    trackTouch: true,
    trackMouse: false,
    rotationAngle: 0,
  });

  const refPassthrough = (el) => {
    swipeHandlers.ref(el);
    cartDrawerRef.current = el;
  };

  const handleKeyUp = (e) => {
    if (e.key === "Escape") {
      closeCartDrawer();
    }
  };

  useEffect(() => {
    if (cartDrawer && cartDrawerRef.current) {
      cartDrawerRef.current.focus();
    }
  }, [cartDrawer]);

  return (
    <Box
      as="aside"
      data-comp="CartDrawer"
      onKeyUp={handleKeyUp}
      {...props}
      {...swipeHandlers}
      ref={refPassthrough}
      sx={{
        zIndex: 61,
        position: "fixed",
        top: 0,
        right: 0,
        height: "100%",
        width: ["100%", null, null, null, "375px"],
        backgroundColor: "black",
        boxShadow: "10px 0px 30px rgb(0 0 0 / 8%)",
        transform: cartDrawer ? "translateX(0)" : "translateX(100%)",
        willChange: "transform",
        transition: "transform .3s ease-in-out",
        ...props.sx,
      }}
      tabIndex={-1} // Make the Box focusable
    >
      {/* content area */}
      <Box
        data-comp="cartDrawerScroll"
        sx={{
          height: `calc(100vh - 210px)`,
          overflowY: "scroll",
          scrollbarWidth: "thin",
          webkitOverflowScrolling: "touch",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* Head */}
        <Flex
          variant="flex.column"
          sx={{
            position: "sticky",
            backgroundColor: "black",
            zIndex: 60,
            top: 0,
            minHeight: "48px",
            borderBottom: "1px solid",
            borderColor: "gray.6",
          }}
        >
          <Flex
            variant="flex.row.between.center"
            sx={{
              minHeight: "48px",
              pr: 9,
              pl: 8,
            }}
          >
            <Image
              sx={{ mr: 5, cursor: "pointer", height: "auto" }}
              onClick={closeCartDrawer}
              alt="Close Cart"
              src="/svg/arrow-left.svg"
              width="20px"
            />

            <Paragraph
              variant="label.2"
              sx={{
                color: "white",
                mr: "auto",
                fontSize: 3,
                pt: "3px",
              }}
            >
              My Cart
            </Paragraph>

            <CartCounterToggle />
          </Flex>
        </Flex>

        {/* Render the cart */}
        {cartDrawer ? <Cart isDrawer /> : null}
      </Box>
    </Box>
  );
};
