import { Flex, Grid, Image, Paragraph } from 'theme-ui';

import { Markdown } from '@snippets';

import { Schema, ICON_SVG } from './IconRow.schema.js';

export function IconRow({ cms }) {
  const { icons, heading, description } = cms;

  return (
    <Flex
      data-comp={IconRow.displayName}
      variant="flex.section"
      sx={{
        width: '100%',
        maxWidth: '1440px',
        mx: 'auto',
        py: [14, null, null, 17],
        px: 8,
      }}
    >
      {heading && (
        <Flex
          variant="flex.column.center"
          sx={{
            maxWidth: '800px',
          }}
        >
          <Paragraph variant="24.r" sx={{ textAlign: 'center', mb: 6 }}>
            {heading}
          </Paragraph>
          <Markdown
            text={description}
            textAlign="center"
            textSx={{
              variant: 'text.base.r',
              fontWeight: 500,
              '&:last-of-type': {
                mb: 0,
              },
            }}
            sx={{
              maxWidth: ['500px', '600px'],
              mb: [10, null, null, 13],
            }}
          />
        </Flex>
      )}
      {icons?.length > 0 && (
        <Grid
          sx={{
            width: '100%',
            justifyContent: 'center',
            justifyItems: 'center',
            gridGap: [11, 13],
            gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(4, 1fr)'],
          }}
        >
          {icons?.map((icon, index) => (
            <Flex
              variant="flex.column.center.start"
              key={index}
              sx={{
                maxWidth: '275px',
              }}
            >
              <Flex
                variant="flex.column.center.center"
                sx={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  mb: 8,
                }}
              >
                <Image
                  src={ICON_SVG[icon.icon]}
                  alt={icon.icon}
                  sx={{ minWidth: '100%' }}
                />
              </Flex>
              <Paragraph
                variant="18"
                sx={{
                  letterSpacing: '.03em !important',
                  textAlign: 'center',
                }}
              >
                {icon?.heading}
              </Paragraph>
              <Markdown
                text={icon.description}
                textAlign="center"
                textSx={{
                  variant: 'text.base',
                  fontWeight: 500,
                  '&:last-of-type': {
                    mb: 0,
                  },
                }}
              />
            </Flex>
          ))}
        </Grid>
      )}
    </Flex>
  );
}

IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
