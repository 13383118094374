import Script from 'next/script';

export const AccessiBeApp = () => {
  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `(function() {
            var s = document.createElement('script');
            var h = document.querySelector('head') || document.body;
            s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async = true;
            s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#cab683', triggerColor : '#cab683', triggerRadius : '10px', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'medium', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '10px' } }); };
            h.appendChild(s);
          }());`,
      }}
    />
  );
};
