import React from 'react';
import { Box } from 'theme-ui';

import store, { useRecoilState, useSetRecoilState } from '@store';
import { useBodyScrollLock } from '@hooks';

export const Overlay = (props) => {
  const [overlayZindex, setOverlayZindex] = React.useState(-1),
    { lockScroll, unlockScroll } = useBodyScrollLock(),
    [sideDrawer, setSideDrawer] = useRecoilState(store.sideDrawer),
    [cartDrawer, setCartDrawer] = useRecoilState(store.cartDrawer),
    setMenuItem = useSetRecoilState(store.menuItem),
    [menuDrawer, setMenuDrawer] = useRecoilState(store.menuDrawer),
    [menuDrawerIndex, setMenuDrawerIndex] = useRecoilState(
      store.menuDrawerIndex
    ),
    setActiveRewardsModal = useSetRecoilState(store.activeRewardsModal),
    [modal, setModal] = useRecoilState(store.modal),
    [overlay, setOverlay] = useRecoilState(store.overlay),
    setActiveMobileMenuDrawer = useSetRecoilState(store.activeMobileMenuDrawer);
  React.useEffect(() => {
    if (overlay) {
      // console.log('Overlay: setting z-index to 40')
      // console.log('Overlay: locking scroll')
      setOverlayZindex(40);
      lockScroll();
    } else {
      const resetOverlayZindex = () => {
        const tO = requestTimeout(() => {
          // console.log('Overlay: re-setting z-index to -1');
          setOverlayZindex(-1);
          clearRequestTimeout(tO);
        }, 100);
      };
      // console.log('Overlay: unlocking scroll');
      unlockScroll({ onComplete: resetOverlayZindex });
    }
  }, [overlay]);

  return (
    <Box
      data-comp="Overlay"
      variant="box.fill"
      onClick={() => {
        setModal(null);
        setSideDrawer(null);
        setCartDrawer(false);
        setMenuDrawer(false);
        setMenuDrawerIndex(null);
        setOverlay(false);
        setActiveRewardsModal(null);
        setActiveMobileMenuDrawer(null);
      }}
      onMouseOver={() => {
        if (menuDrawerIndex != null) {
          setMenuItem(null);
          setOverlay(false);
          setMenuDrawer(false);
          setMenuDrawerIndex(null);
        }
      }}
      {...props}
      sx={{
        // zIndex: overlay ? 40 : -1,
        zIndex: overlayZindex,
        position: 'fixed',
        backgroundColor: 'black',
        opacity: overlay ? 0.6 : 0,
        willChange: 'opacity',
        transition: 'opacity .2s cubic-bezier(0.2, 0.5, 0.2, 1)',
        '&:hover': {
          cursor:
            cartDrawer || sideDrawer || menuDrawer || modal
              ? `url('/svg/close-cursor.svg') 0 0, auto`
              : 'pointer',
        },
        ...props.sx,
      }}
    />
  );
};
