import { useCallback, useEffect, useRef } from "react";
import { Box } from "theme-ui";
import {
  useCartAddItem,
  useCartRemoveItem,
  useCart,
  useSettings,
  useProductItemByHandle,
} from "@backpackjs/storefront";

import store, { useRecoilValue } from "@store";

import { CartLineItem } from "./CartLineItem";

const useGiftWithPurchase = (cart) => {
  const { lines = [], updatedAt } = { ...cart };
  const busy = useRef(false);
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const { cartRemoveItem, ...removeItemStatus } = useCartRemoveItem();
  const settings = useSettings();
  const giftWithPurchaseLine = useRecoilValue(store.giftWithPurchaseLine$); // gwp line item if any
  const giftWithPurchaseEligibleInCart = useRecoilValue(
    store.giftWithPurchaseEligibleInCart$
  );
  const giftWithPurchaseMinimumQualifies = useRecoilValue(
    store.giftWithPurchaseMinimumQualifies$
  );

  const gwpHandle = settings?.cart?.gwp?.giftProduct?.[0]?.product?.handle;
  const { productItem: gwpProduct, ...gwpProductStatus } =
    useProductItemByHandle({
      handle: gwpHandle,
    });

  const gwpId = gwpProduct?.variants?.[0]?.id;

  const checkGWP = useCallback(async () => {
    // if eligible is not in cart, or was just removed..

    if (!giftWithPurchaseEligibleInCart || !giftWithPurchaseMinimumQualifies) {
      if (!giftWithPurchaseLine) {
        busy.current = false;
        return; // eligible product not in cart exit
      }

      // remove existing gift with purchase
      console.log(
        "Auto-removing gift with purchase from cart no eligibles currently on the cart"
      );
      await cartRemoveItem({ lineId: giftWithPurchaseLine.id });

      busy.current = false;
    } else {
      console.log("Auto-adding gift with purchase to the cart");
      if (giftWithPurchaseLine) {
        // gift already in the cart
        busy.current = false;
        return;
      }

      await cartAddItem({
        merchandiseId: gwpId,
        quantity: 1,
        attributes: [{ key: "_giftWithPurchase", value: "true" }],
      });

      busy.current = false;
    }
  }, [
    giftWithPurchaseEligibleInCart,
    giftWithPurchaseLine?.id,
    giftWithPurchaseMinimumQualifies,
    gwpId,
  ]);

  useEffect(() => {
    if (!lines?.length || !gwpId) return; // no items or no gwpId no need to add;
    if (busy.current) return;

    busy.current = true;

    checkGWP();
  }, [updatedAt, gwpId]);
};

export function CartLineItems({ ...props }) {
  const cart = useCart();

  // maybe auto-add gift with purchase if enabled and applicable
  useGiftWithPurchase(cart);

  return (
    <Box
      data-comp="CartLineItems"
      sx={{
        flex: 1,
        overflow: "hidden",
      }}
    >
      {cart?.lines.map((lineItem, index) => (
        <CartLineItem key={lineItem.id} {...lineItem} />
      ))}
    </Box>
  );
}
