import { Flex, Paragraph, Spinner, Box } from 'theme-ui';
import Head from 'next/head';

export const StockistWidget = () => {
  return (
    <>
      <Head>
        <script
          key="on-swell-loaded"
          id="on-swell-loaded"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              (function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;
              k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);
              })(document,'script','//stockist.co/embed/v1/widget.min.js');
            `,
          }}
        />
      </Head>

      <Flex
        data-comp="StockistWidget"
        variant="flex.section"
        sx={{
          py: [11, null, null, 14],
          px: [6, 9, null, 11],
        }}
      >
        <Flex
          variant="flex.column.center.center"
          sx={{
            maxWidth: '1100px',
            width: '100%',
          }}
        >
          <Box data-stockist-widget-tag="u3020">
            <Paragraph
              variant="xs"
              sx={{
                mt: '50%',
                mb: 11,
                textAlign: 'center',
                mx: 'auto',
              }}
            >
              Loading store locator from{' '}
              <a href="https://stockist.co" target="_blank">
                Stockist
              </a>
              ...
            </Paragraph>
            <Flex variant="flex.row.center.center">
              <Spinner sx={{ width: '30px' }} />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

StockistWidget.Schema = {
  label: 'Stockist Widget',
  key: 'stockist-widget',
  fields: [],
};
