import { useMemo } from 'react';
import { Button, Paragraph, Flex, Text } from 'theme-ui';
import store, { useRecoilValue, useSetRecoilState } from '@store';

import { Svg } from '@snippets';

export const CartCashback = () => {
  const cashbackInCart = useRecoilValue(store.cashbackInCart);
  const setCashbackModalOpen = useSetRecoilState(store.cashbackModalOpen);
  const totalsRegular = useRecoilValue(store.totalsRegular$);

  const cashbackTotal = useMemo(() => {
    if (totalsRegular?.total) {
      return (totalsRegular.total * 0.2)?.toFixed(2);
    }
    return null;
  }, [totalsRegular]);

  if (!cashbackInCart || !cashbackTotal) return null;

  return (
    <Flex
      variant="flex.row.start.center"
      sx={{
        mb: 9,
        p: 2,
        position: 'relative',
      }}
    >
      <Svg
        src="/svgs/cashback.svg#cashback"
        sx={{ height: 40, width: 40 }}
        viewBox="0 0 56 49"
      />

      <Button
        sx={{
          position: 'absolute',
          top: 2,
          right: 2,
        }}
        onClick={setCashbackModalOpen}
      >
        <Svg
          alt=""
          src="/svgs/info.svg#info"
          sx={{ height: 14, width: 14, color: 'gold' }}
          viewBox="0 0 14 14"
        />
      </Button>

      <Flex
        variant="flex.column.start.center"
        sx={{
          ml: 2,
          mr: '20px',
        }}
      >
        <Paragraph variant="xxs" sx={{ color: 'white' }}>
          You get{' '}
          <Text variant="xxs" sx={{ color: '#50cca2' }}>
            ${cashbackTotal}
          </Text>{' '}
          after your purchase to use anywhere.
        </Paragraph>

        <Paragraph variant="xxs" sx={{ color: 'white' }}>
          No points. No Coupons. Just cash.
        </Paragraph>
      </Flex>
    </Flex>
  );
};

CartCashback.displayName = 'CartCashback';
