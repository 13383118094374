import { Flex } from 'theme-ui';

import { useMounted, useMountAfter } from '@hooks';
import { entryAnimation } from '@utils';
import { InlineHeading } from '@snippets';

import { MenuItem } from './MenuItem';

export const ExploreLinks = ({ exploreLinks }) => {
  const [mounted] = useMounted();
  const [mountItems] = useMountAfter({ delay: 200 });

  return (
    <Flex
      sx={{
        maxWidth: 1390,
        paddingX: '15px',
        margin: 'auto',
        gap: '10%',
        alignItems: 'flex-start',
        display: ['none', null, null, null, 'flex'],
      }}
    >
      <Flex
        variant="flex.column"
        data-comp="ExploreLinks"
        sx={{
          mt: 0,
          mb: 8,
          width: '100%',
          ...entryAnimation({
            entered: mounted,
            fadeIn: true,
            index: 1,
            delay: 0,
          }),
        }}
      >
        <Flex
          variant="flex.row"
          data-comp="ExploreLinks"
          sx={{
            flex: 1,
            flexWrap: 'wrap',
            gap: '20px',
          }}
        >
          {exploreLinks?.map((item, exploreIndex) => {
            return (
              <MenuItem
                item={item}
                index={exploreIndex}
                key={item.id}
                cardSize="large"
                hasOptionsGrid={false}
                sx={{
                  opacity: 0,
                  ...entryAnimation({
                    entered: mountItems,
                    fadeIn: true,
                    slideIn: false,
                    duration: 50,
                    index: exploreIndex,
                    delay: 50,
                  }),
                }}
              />
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
