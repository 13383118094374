import { Box } from 'theme-ui';

import { Svg } from '@snippets';
import store, { useSetRecoilState } from '@store';

export const ProductSellingPlanRadio = ({
  isSelected,
  plan,
  selectedVariant,
}) => {
  const setSelectedPlan = useSetRecoilState(
    store.selectedPlan(selectedVariant.product.handle)
  ); // onetime or subscribe
  const setSelectedFreq = useSetRecoilState(
    store.selectedFreq(selectedVariant.product.handle)
  );

  return (
    <Box
      data-comp={`ProductSellingPlanRadio—${plan.type}`}
      onClick={() => {
        const isSubscribe = plan.type === 'subscribe';

        if (isSubscribe) {
          // select second subscription option as active
          setSelectedFreq(plan.options[1]);
        }

        setSelectedPlan(plan);
      }}
      sx={{
        cursor: 'pointer',
        mt: 0,
        flexShrink: 0,
      }}
    >
      {isSelected ? (
        <Svg
          viewBox="0 0 20 20"
          src="/svgs/radio-active.svg#radio-active"
          sx={{
            color: '#CAB683',
            width: 20,
          }}
        />
      ) : (
        <Svg
          viewBox="0 0 20 20"
          src="/svgs/radio-inactive.svg#radio-inactive"
          sx={{
            color: `#333333`,
            width: 20,
          }}
        />
      )}
    </Box>
  );
};

ProductSellingPlanRadio.displayName = 'ProductSellingPlanRadio';
