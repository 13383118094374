import { Box, Flex, Spinner } from 'theme-ui';

export const SectionRevealer = ({
  section,
  children,
  fadeIn = true,
  ...props
}) => {
  const isRevealed = section.isVisible || section.isFirst || !fadeIn;
  return (
    <Box
      data-comp="SectionRevealer"
      {...props}
      sx={{
        opacity: isRevealed ? 1 : 0,
        transition: 'opacity .3s ease-in-out',
        willChange: 'opacity',
        minHeight: 'inherit',
        ...props.sx,
      }}
    >
      {section.isVisible || section.isFirst ? (
        children
      ) : (
        <Flex
          variant="flex.row.center"
          sx={{ bg: 'gray.0', minHeight: 'inherit' }}
        >
          <Spinner />
        </Flex>
      )}
    </Box>
  );
};

export const withSectionRevealer = (Section, options) => (props) => {
  return (
    <SectionRevealer {...options} {...props}>
      <Section {...props} />
    </SectionRevealer>
  );
};
