import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Image } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css';

import { ProductItem } from '@snippets';

SwiperCore.use([Navigation, Pagination]);

export function ProductSwiper({
  products,
  hidePrices,
  hideReviews,
  disableHover,
  ...props
}) {
  const [swiper, setSwiper] = useState();

  const len = products?.length;

  const params = useMemo(
    () => ({
      centered: len === 1,
      centeredBounds: len !== 1,
      offset: len === 1 ? 0 : 20,
      smallOffset: len === 1 ? 0 : 12,
      navigation: len > 1,
    }),
    [products]
  );

  return (
    <Box
      data-comp="ProductSwiper"
      sx={{
        minHeight: ['400px', '460px'],
        width: '100%',
        position: 'relative',
        px: [0, null, null, null, 9, null, 0],
      }}
      {...props}
    >
      {len > 0 && (
        <Swiper
          onSwiper={setSwiper}
          spaceBetween={12}
          slidesOffsetBefore={params.smallOffset}
          slidesOffsetAfter={params.smallOffset}
          slidesPerView={1.1}
          // slidesPerColumnFill='row'
          centeredSlides={params.centered}
          centeredSlidesBounds={params.centeredBounds}
          watchOverflow
          allowTouchMove
          breakpoints={{
            480: {
              slidesPerView: 1.1,
              spaceBetween: 12,
              centeredSlides: params.centered,
              centeredSlidesBounds: params.centeredBounds,
              slidesOffsetBefore: params.smallOffset,
              slidesOffsetAfter: params.smallOffset,
              allowTouchMove: len > 1,
            },
            600: {
              slidesPerView: 1.1,
              spaceBetween: 12,
              centeredSlides: params.centered,
              centeredSlidesBounds: params.centeredBounds,
              slidesOffsetBefore: params.smallOffset,
              slidesOffsetAfter: params.smallOffset,
              allowTouchMove: len > 1,
            },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 20,
              centeredSlides: params.centered,
              centeredSlidesBounds: params.centeredBounds,
              slidesOffsetBefore: len === 2 ? 40 : params.offset,
              slidesOffsetAfter: params.offset,
              allowTouchMove: len > 2,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesOffsetBefore: len === 2 ? 180 : 0,
              slidesOffsetAfter: 0,
              allowTouchMove: len > 3,
            },
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }}
          sx={{
            width: '100%',
            maxWidth: '1130px',
            paddingBottom: '80px',
          }}
        >
          {products.map((product, index, arr) => {
            if (!product?.product?.handle) return null;
            return (
              <SwiperSlide key={product?.product?.handle + index}>
                <ProductItem
                  handle={product?.product?.handle}
                  withPrice={!hidePrices}
                  withReviews={!hideReviews}
                  disableHover={disableHover}
                  imageSizes="(max-width: 640px) 640px, (max-width: 780px) 828px, (min-width: 781px) 320px"
                  index={index}
                  isSwiper
                  itemsCount={arr.length}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {products?.length > 3 && (
        <>
          <div
            className="swiper-pagination"
            sx={{
              backgroundColor: 'none',
              color: 'text',
              display: 'flex',
              height: 10,
              justifyContent: 'center',
              mt: [-16, 4],
              mb: 4,
              span: {
                backgroundColor: 'text',
                height: 10,
                width: 10,
                mx: 4,
                borderRadius: '100%',
                opacity: '0.25',
              },
              'span.swiper-pagination-bullet-active': {
                opacity: '1',
              },
            }}
          />
          <Button
            className="swiper-button-prev"
            variant="buttons.plain"
            aria-label="Move to previous review slide"
            sx={{
              display: ['none', null, null, null, 'block'],
              position: 'absolute',
              top: 'calc(50% - 80px)',
              left: ['2.5%', null, null, null, null, '0'],
              height: 24,
              width: 28,
              zIndex: 2,
              transform: 'translateY(-50%)',
              '&:after': {
                content: '""',
              },
              '&:hover': {
                opacity: 1,
              },
            }}
            onClick={() => swiper.slidePrev()}
          >
            <Image
              src="/svg/carousel-arrow-left.svg"
              alt="Arrow Previous"
              sx={{
                height: '100%',
                position: 'absolute',
                objectFit: 'contain',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Button>

          <Button
            className="swiper-button-next"
            variant="buttons.plain"
            aria-label="Move to next review slide"
            sx={{
              display: ['none', null, null, null, 'block'],
              position: 'absolute',
              top: 'calc(50% - 80px)',
              right: ['2.5%', null, null, null, null, '0'],
              height: 24,
              width: 28,
              zIndex: 2,
              transform: 'translateY(-50%)',
              '&:after': {
                content: '""',
              },
              '&:hover': {
                opacity: 1,
              },
            }}
            onClick={() => swiper.slideNext()}
          >
            <Image
              src="/svg/carousel-arrow-right.svg"
              alt="Arrow Next"
              sx={{
                height: '100%',
                position: 'absolute',
                objectFit: 'contain',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Button>
        </>
      )}
    </Box>
  );
}

ProductSwiper.propTypes = {
  products: PropTypes.array,
  heading: PropTypes.string,
  hidePrices: PropTypes.bool,
  hideReviews: PropTypes.bool,
  hideColors: PropTypes.bool,
};
