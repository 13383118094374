import { useCallback } from 'react';
import { Flex, Paragraph, Spinner, Box } from 'theme-ui';

import store, { useSetRecoilState, useRecoilValue } from '@store';
import { getYotpoLoyaltyData } from '@utils';
import { StyledButton } from '@snippets';

import { RewardsBirthday } from './RewardsBirthday';

export const RewardsModalCampaign = ({ campaign, email }) => {
  const setModal = useSetRecoilState(store.modal);
  const setOverlay = useSetRecoilState(store.overlay);
  const setYotpoLoyaltyData = useSetRecoilState(store.yotpoLoyaltyData);
  const isSwellSetup = useRecoilValue(store.isSwellSetup);

  const handleSubmit = useCallback(() => {
    if (!isSwellSetup) return;
    setModal(null);
    setOverlay(null);
    getYotpoLoyaltyData({
      email,
      action: (data) => setYotpoLoyaltyData(data),
    });
  }, [email, isSwellSetup]);

  return (
    <Flex
      data-comp="RewardsModalCampaign"
      variant="flex.column.center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Paragraph
        variant="20"
        sx={{
          mb: 8,
          textAlign: 'center',
        }}
      >
        {campaign?.title}
      </Paragraph>
      <Paragraph
        variant="base"
        sx={{
          textAlign: 'center',
        }}
      >
        {campaign?.details}
      </Paragraph>

      {campaign?.type === 'BirthdayCampaign' && (
        <RewardsBirthday campaign={campaign} email={email} />
      )}

      <Flex variant="flex.column.center">
        {campaign?.max_completions_per_user > 0 &&
        campaign?.status?.customer_times_completed >=
          campaign?.max_completions_per_user ? (
          <Paragraph
            variant="base"
            sx={{
              textAlign: 'center',
              mt: 8,
            }}
          >
            Looks like you've already completed this offer!
          </Paragraph>
        ) : (
          <Box
            sx={{
              mt: 11,
              display: campaign?.type === 'BirthdayCampaign' ? 'none' : 'block',
            }}
          >
            <StyledButton
              text={campaign?.cta_text}
              sx={{
                width: 'auto',
                display: isSwellSetup ? 'flex !important' : 'none !important',
              }}
              variant="outline"
              onClick={handleSubmit}
              swellCampaignProps={{
                className: 'swell-campaign-link',
                'data-display-mode': 'modal',
                'data-campaign-id': campaign.id,
              }}
            />
            <Spinner
              sx={{
                width: '30px',
                display: isSwellSetup ? 'none' : 'block',
              }}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
