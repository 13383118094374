import { requestYotpoLoyalty } from '@utils';

export const getYotpoCustomer = async ({ email }) => {
  try {
    return requestYotpoLoyalty({
      action: 'getCustomer',
      data: { email },
    });
  } catch (error) {
    throw error;
  }
};

export const getCampaigns = async ({ email }) => {
  try {
    return await requestYotpoLoyalty({
      action: email ? 'getCampaignsWithStatus' : 'getCampaigns',
      data: email ? { email } : {},
    });
  } catch (error) {
    throw error;
  }
};

export const getVipTiers = async () => {
  try {
    return await requestYotpoLoyalty({
      action: 'getVipTiers',
    });
  } catch (error) {
    throw error;
  }
};

export const getYotpoLoyaltyData = async (props) => {
  const action =
    typeof props?.action === 'function' ? props?.action : () => null;

  const email = props?.email;

  try {
    const [yotpoCustomer, activeCampaigns, vipTiers] = await Promise.all([
      getYotpoCustomer({ email: email }),
      getCampaigns({ email: email }),
      getVipTiers(),
    ]);

    if (yotpoCustomer?.data?.error) {
      throw yotpoCustomer.data.error;
    }

    if (activeCampaigns?.data?.error) {
      throw activeCampaigns.data.error;
    }

    if (vipTiers?.data?.error) {
      throw vipTiers.data.error;
    }

    const data = {
      yotpoCustomer: yotpoCustomer.data,
      activeCampaigns: activeCampaigns.data,
      vipTiers: vipTiers.data,
    };

    console.log('Yotpo loyalty data', data);

    action(data);
  } catch (error) {
    throw error;
  }
};
