import { useMemo } from 'react';
import { Box, Paragraph } from 'theme-ui';
import { useRouter, useSettings } from '@backpackjs/storefront';

import { Markdown } from '@snippets';
import store, { useRecoilValue } from '@store';

import { CartUpsellItem } from './CartUpsellItem';

export function CartDiscountProgress() {
  const isCartPage = useRouter()?.asPath === '/cart';
  const settings = useSettings();

  const cartTotal = useRecoilValue(store.cartTotal$);
  const totalsTiered = useRecoilValue(store.totalsTiered$);
  const steps = settings?.cart?.discount?.steps;

  const upsellProductHandle = settings?.cart?.upsell?.product?.handle || null;
  const upsellProductImageOverride = settings?.cart?.upsell?.image || null;
  const upsellInCart = useRecoilValue(store.upsellInCart(upsellProductHandle));
  const showUpsell =
    settings?.cart?.upsell?.enabled && upsellProductHandle && !upsellInCart;

  const subTotalPrice = useMemo(() => {
    if (totalsTiered?.subtotal > 0)
      return parseFloat(totalsTiered.subtotal) || 0;
    return parseFloat(cartTotal) || 0;
  }, [cartTotal]);

  const remaining = useMemo(() => {
    let diff = 0;
    let progress = null;
    let step = 0;
    const min1 = steps?.[0]?.minimum;
    const min2 = steps?.[1]?.minimum;
    const min3 = steps?.[2]?.minimum;
    const min4 = steps?.[3]?.minimum;
    if (subTotalPrice < min1) {
      diff = min2;
      progress = steps?.[0]?.message;
      step = 0;
    } else if (subTotalPrice < min2) {
      diff = min2 - subTotalPrice;
      progress = steps?.[0]?.message;
      step = 0;
    } else if (subTotalPrice < min3) {
      diff = min3 - subTotalPrice;
      progress = steps?.[1]?.message;
      step = 1;
    } else if (subTotalPrice < min4) {
      diff = min4 - subTotalPrice;
      progress = steps?.[2]?.message;
      step = 2;
    } else {
      diff = 0;
      progress = steps?.[3]?.message;
      step = 3;
    }
    return { diff, progress, step };
  }, [cartTotal, steps]);

  const remainingAmount = remaining?.diff?.toFixed(2);
  const remainingText = remaining?.progress?.replace(
    '$',
    `$${remainingAmount}`
  );

  return isCartPage ? null : (
    <Box
      data-comp={CartDiscountProgress.displayName}
      sx={{
        pl: '20px',
        pr: 9,
        pt: '16px',
        pb: '24px',
        background: '#333333',
      }}
    >
      <Markdown
        textAlign="center"
        sx={{
          fontSize: 0,
          color: 'white',
          maxWidth: '272px',
          textAlign: 'center',
          mx: 'auto',
          p: {
            fontSize: '14px',
          },
        }}
      >
        {remainingText}
      </Markdown>

      {/* The dotted progress bar */}
      <Box
        data-comp="ProgressBar"
        as="ol"
        sx={{
          mt: '22px',
          listStyle: 'none',
          padding: 0,
          display: 'table',
          tableLayout: 'fixed',
          width: '100%',
        }}
      >
        {settings?.cart?.discount?.steps?.length
          ? settings.cart.discount.steps.map((step, index) => {
              const hasCompleted = index + 1 <= remaining.step;
              return (
                <Box as="li" key={index} sx={{ ...stepSx(hasCompleted) }}>
                  <Paragraph
                    sx={{
                      fontSize: '10px',
                      textAlign: 'center',
                      width: '100%',
                      color: 'white',
                      marginTop: 'calc(12px + 0.5rem)',
                      lineHeight: '1.4',
                    }}
                  >
                    {step.discount}
                  </Paragraph>
                </Box>
              );
            })
          : null}
      </Box>

      {/* Show upsell if enabled and not already added */}
      {showUpsell ? (
        <CartUpsellItem
          upsellProductHandle={upsellProductHandle}
          upsellProductImageOverride={upsellProductImageOverride}
        />
      ) : null}
    </Box>
  );
}

const stepSx = (isActive) => ({
  position: 'relative',
  display: 'table-cell',
  textAlign: 'center',
  fontSize: '0.875rem',
  color: '#6D6875',
  '&:before': {
    content: `""`,
    display: 'block',
    margin: '0 auto',
    background: 'linear-gradient(132.82deg, #CAB683 25.95%, #88723A 92.55%)',
    width: '12px',
    height: '12px',
    textAlign: 'center',
    lineHeight: '12px',
    borderRadius: '100%',
    position: 'absolute',
    zIndex: 1,
    fontWeight: 700,
    fontSize: '1rem',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  '&:after': {
    content: "''",
    position: 'absolute',
    display: 'block',
    background: isActive ? '#CAB683' : '#1A1A1A',
    width: '100%',
    height: '4px',
    top: '0.25rem',
    left: '50%',
  },
  '&:last-child:after': {
    display: 'none',
  },
});

CartDiscountProgress.displayName = 'CartDiscountProgress';
