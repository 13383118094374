import { Box } from "theme-ui";
import PropTypes from "prop-types";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
import MarkdownToJsx from "markdown-to-jsx";

export function Markdown({
  text,
  children,
  style,
  textAlign = "left",
  ...props
}) {
  const brChildren =
    typeof children === "string" ? children.split("<br>") : children;

  return (
    <Box
      data-comp={Markdown.displayName}
      sx={{
        ...markdownSx(textAlign),
        ...style,
        overflowX: "hidden",
      }}
      {...props}
    >
      {/* {brChildren && brChildren?.length ? (
        brChildren.map((child, index) => (
          <ReactMarkdown
            key={index}
            children={child}
            remarkPlugins={[remarkGfm]}
          />
        ))
      ) : brChildren ? (
        <ReactMarkdown children={brChildren} remarkPlugins={[remarkGfm]} />
      ) : (
        <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} />
      )} */}
      {brChildren && brChildren?.length ? (
        brChildren.map((child, index) => (
          <MarkdownToJsx key={index} children={child} />
        ))
      ) : brChildren ? (
        <MarkdownToJsx children={brChildren} />
      ) : (
        <MarkdownToJsx children={text} />
      )}
    </Box>
  );
}

const markdownSx = (textAlign) => ({
  table: {
    width: "100%",
    overflowX: "auto",
    position: "relative",
    height: "100%",
    display: "block",
  },
  thead: {
    backgroundColor: "lightgrey",
  },
  "th, td": {
    py: "5px",
    px: 4,
    textAlign: "left",
    verticalAlign: "top",
  },
  "tr:nth-of-type(even)": {
    backgroundColor: "#f5f5f5",
  },
  p: {
    mb: 8,
    variant: "text.base",
    textAlign,
  },
  "ul, ol": {
    ml: 13,
    mb: 8,
  },
  ul: {
    li: {
      listStyleType: "disc",
    },
  },
  ol: {
    li: {
      listStyleType: "decimal",
    },
  },
  li: {
    mb: 4,
    variant: "text.base",
  },
  a: {
    textDecoration: "underline",
    variant: "text.base",
  },
  h1: {
    textAlign: "center",
    variant: "text.24.r",
    mb: 11,
  },
  h2: {
    mb: 10,
    mt: 11,
    variant: "text.20",
    textAlign,
  },
  h3: {
    mt: 11,
    mb: 6,
    variant: "text.18",
    textAlign,
  },
  "h4, h5, h6": {
    mb: 4,
    variant: "text.18",
    textAlign,
  },
  strong: {
    fontWeight: 600,
  },
});

Markdown.displayName = "Markdown";

Markdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  style: PropTypes.object,
};
