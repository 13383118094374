import { useState, useEffect } from 'react';
import { Flex } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { RecoverPasswordForm } from './RecoverPasswordForm';
import { CreateAccountForm } from './CreateAccountForm';
import { LoginForm } from './LoginForm';

export const LoginMain = ({ router }) => {
  const [formState, setFormState] = useState('login');
  const customer = useCustomer();

  useEffect(() => {
    if (!router.isReady) return;
    if (!customer) return;

    console.log('customer', customer);

    if (
      customer.accessToken &&
      customer.customer &&
      router.query.route === 'login'
    ) {
      if (router.query.from) {
        return router.push(`/pages/rewards`);
      } else {
        return router.push(`/account/orders`, undefined, { shallow: true });
      }
    }
  }, [customer, router.isReady]);

  return formState === 'login' ? (
    <Flex
      data-comp="LoginMain"
      sx={{
        flexDirection: ['column', null, null, 'row'],
        justifyContent: ['flex-start', null, null, 'center'],
        alignItems: ['center', null, null, 'flex-start'],
        width: '100%',
        maxWidth: '800px',
      }}
    >
      <LoginForm
        action={setFormState}
        sx={{
          mb: [12, null, null, 0],
          mr: [0, null, null, 16, 19],
          flex: 1,
          width: ['100%', null, null, 'auto'],
          maxWidth: '350px',
        }}
      />
      <CreateAccountForm
        action={setFormState}
        sx={{
          flex: 1,
          width: ['100%', null, null, 'auto'],
          maxWidth: '350px',
        }}
      />
    </Flex>
  ) : formState === 'recover_pass' ? (
    <RecoverPasswordForm action={setFormState} />
  ) : null;
};
