import { useEffect, useState } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import { getYotpoLoyaltyData, getYotpoCustomer } from '@utils';
import store, { useRecoilState } from '@store';

export const useYotpoLoyalty = () => {
  const [yotpoLoyaltyData, setYotpoLoyaltyData] = useRecoilState(
    store.yotpoLoyaltyData
  );
  const [yotpoCustomer, setYotpoCustomer] = useState(null);

  const customer = useCustomer();

  useEffect(() => {
    if (!customer?.email) return;

    async function fetchLoyaltyData() {
      await getYotpoLoyaltyData({
        action: (data) => setYotpoLoyaltyData(data),
        email: customer.email,
      });

      const { data: customerData } = await getYotpoCustomer({
        email: customer.email,
      });
      if (customerData) setYotpoCustomer(customerData);
    }

    fetchLoyaltyData();
  }, [customer?.id, customer?.email]);

  return { yotpoLoyaltyData, yotpoCustomer };
};
