import { Paragraph, Flex } from 'theme-ui';
import {
  useCart,
  useCartCheckout,
  useCartUpdateAttributes,
} from '@backpackjs/storefront';

import store, { useRecoilValue, useSetRecoilState } from '@store';
import { StyledButton } from '@snippets';

import { CartTotalAmountRegular } from './CartTotalAmountRegular';
import { CartTotalAmountTiered } from './CartTotalAmountTiered';
import { CartCashback } from './CartCashback';

export const CartTotals = ({ ...props }) => {
  const cart = useCart();
  const { cartCheckout } = useCartCheckout();
  const { cartUpdateAttributes, ...attrStatus } = useCartUpdateAttributes();

  const giftWrapNote = useRecoilValue(store.giftWrapNote);
  const activeTierAppliesDiscount = useRecoilValue(
    store.activeTierAppliesDiscount$
  );

  const setCartDrawer = useSetRecoilState(store.cartDrawer);

  const cartEmpty = !cart?.lines?.length;

  return cart && !cartEmpty ? (
    <Flex
      data-comp="CartTotals"
      variant="flex.column"
      sx={{
        pt: 9,
        pb: 9,
        px: 9,
        backgroundColor: 'black',
      }}
      {...props}
    >
      {/* Total amounts */}
      <Flex data-comp="CartTotalAmounts" variant="flex.column" {...props}>
        {
          activeTierAppliesDiscount ? (
            <CartTotalAmountTiered /> // when tiered discount is applied
          ) : (
            <CartTotalAmountRegular />
          ) // no tiered discounts applied
        }
      </Flex>

      <Paragraph variant="xxs" sx={{ color: 'gray.3', mb: 9 }}>
        Shipping & taxes calculated at checkout
      </Paragraph>

      <CartCashback />

      <StyledButton
        text="Checkout"
        variant="animated"
        onClick={async (event) => {
          event.preventDefault();

          if (giftWrapNote) {
            const attributes = [
              {
                key: 'Gift note',
                value: giftWrapNote,
              },
            ];

            await cartUpdateAttributes({ attributes });
          }

          await cartCheckout();

          setCartDrawer(false);
        }}
        sx={{ width: '100%' }}
      />
    </Flex>
  ) : null;
};
