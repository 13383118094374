import { Heading, Flex, Paragraph, Box } from 'theme-ui';
import { StyledButton } from '@snippets';

export function HalfBlockText({
  block,
  index,
  disabledPy,
  columnDirection,
  color,
  bgColor,
  ...props
}) {
  return (
    <Flex
      data-comp="HalfBlockText"
      variant="flex.column"
      sx={{
        display: block?.hideMobile ? ['none', null, null, 'flex'] : 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        px: [0, null, null, 13],
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignSelf: block?.content ? block?.content : 'center',
        }}
      >
        {block?.eyebrow && (
          <Box
            sx={{
              color: 'gold',
              fontSize: '15px',
              lineHeight: '1.2',
              fontWeight: '700',
              fontFamily: 'heading',
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
              mb: ['16px', null, null, '24px'],
            }}
          >
            {block.eyebrow}
          </Box>
        )}
        <Heading
          variant="24.r"
          sx={{
            mb: block?.description ? 0 : [8, null, null, 11],
            width: '100%',
            maxWidth: ['100%', null, null, '440px'],
            color: block?.headingColor || color || 'black',
          }}
        >
          {block?.text || block?.heading}
        </Heading>
        <Paragraph
          variant="lg"
          sx={{
            display: block?.description ? 'block' : 'none',
            width: '100%',
            maxWidth: ['100%', null, null, '440px'],
            color: block?.descriptionColor || color || 'black',
            mt: block?.text || block?.heading ? [4, null, null, 6] : 0,
            mb:
              block?.cta?.text && !block?.hideButton ? [8, null, null, 11] : 0,
          }}
        >
          {block?.description}
        </Paragraph>
        <Flex
          sx={{
            width: '100%',
            maxWidth: ['100%', null, null, '440px'],
          }}
        >
          {block?.cta?.text && !block?.hideButton && (
            <StyledButton
              href={block?.scrollToTop ? '' : block?.cta?.url}
              text={block?.cta?.text}
              newTab={block?.scrollToTop ? false : block?.cta?.newTab}
              variant="solid"
              sx={{
                width: 'auto',
                minWidth: '260px',
              }}
              onClick={() => {
                if (block?.scrollToTop) {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
