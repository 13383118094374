// eslint-disable-next-line import/no-unresolved
import store, { useRecoilState, useSetRecoilState } from '@store';

async function rechargeFetch(data = {}) {
  const response = await fetch('/api/recharge', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  return response.json();
}

export const useRecharge = () => {
  const [isLoaded, setIsLoaded] = useRecoilState(store.rechargeLoaded);
  const [rechargeCustomer, setRechargeCustomer] = useRecoilState(
    store.rechargeCustomer
  );
  const [subscriptions, setSubscriptions] = useRecoilState(
    store.rechargeSubscriptions
  );
  const [addresses, setAddresses] = useRecoilState(store.rechargeAddresses);
  const [onetimes, setOnetimes] = useRecoilState(store.rechargeOnetimes);
  const setCharges = useSetRecoilState(store.rechargeCharges);
  const setPayments = useSetRecoilState(store.rechargePayments);
  const setRetentionStrategies = useSetRecoilState(
    store.rechargeRetentionStrategies
  );
  const [editSubscription, setEditSubscription] = useRecoilState(
    store.editSubscription
  );

  const getCustomer = async (customer) => {
    const { data } = await rechargeFetch({
      action: 'get-customer-by-email',
      email: customer.email,
    });
    return data;
  };

  const getSubscriptions = async () => {
    const { data } = await rechargeFetch({
      action: 'get-subscriptions-by-customer-id',
      customerId: rechargeCustomer.id,
    });
    return data;
  };

  const getOnetimes = async () => {
    const { data } = await rechargeFetch({
      action: 'get-onetimes-by-customer-id',
      customerId: rechargeCustomer.id,
    });
    return data;
  };

  const getAddresses = async () => {
    const { data } = await rechargeFetch({
      action: 'get-addresses-by-customer-id',
      customerId: rechargeCustomer.id,
    });
    return data;
  };

  const getCharges = async () => {
    const { data } = await rechargeFetch({
      action: 'get-charges-by-customer-id',
      customerId: rechargeCustomer.id,
    });
    return data;
  };

  const getPayments = async () => {
    const { data } = await rechargeFetch({
      action: 'get-payments-by-customer-id',
      customerId: rechargeCustomer.id,
    });
    return data;
  };

  const getRetentionStrategies = async () => {
    const { data } = await rechargeFetch({
      action: 'get-retention-strategies',
    });
    return data;
  };

  // Initialize Recharge Data
  const initializeRechargeCustomer = async (customer) => {
    if (!customer || isLoaded) return;
    const customerData = await getCustomer(customer);
    setRechargeCustomer(customerData);
  };

  // Get Recharge Data
  const initializeRechargeData = async () => {
    if (!rechargeCustomer?.id || isLoaded) return;

    // Get Subscriptions
    const subscriptionsData = await getSubscriptions();
    setSubscriptions(subscriptionsData);

    // Get Onetimes
    const onetimesData = await getOnetimes();
    setOnetimes(onetimesData);

    // Get Addresses
    const addressesData = await getAddresses();
    setAddresses(addressesData);

    // Get Payment Methods
    const paymentsData = await getPayments();
    setPayments(paymentsData);

    // Get Charges
    const chargesData = await getCharges();
    setCharges(chargesData);

    // Get Retention Strategies
    const retentionsData = await getRetentionStrategies();
    setRetentionStrategies(retentionsData);

    setIsLoaded(true);
  };

  // Update Methods
  const activateSubscription = async (subscription) => {
    // Make Activate Request to Recharge
    const { data } = await rechargeFetch({
      action: 'activate-subscription',
      id: subscription.id,
    });
    const chargesData = await getCharges();
    setSubscriptions(
      subscriptions.map((_subscription) => {
        return _subscription.id === subscription.id ? data : _subscription;
      })
    );
    setCharges(chargesData);
    return data;
  };

  const cancelSubscription = async (subscription, reason) => {
    // Make Cancel Request to Recharge
    const { data } = await rechargeFetch({
      action: 'cancel-subscription',
      id: subscription.id,
      reason,
    });
    const chargesData = await getCharges();
    if (editSubscription) setEditSubscription(null);
    setSubscriptions(
      subscriptions.map((_subscription) => {
        return _subscription.id === subscription.id ? data : _subscription;
      })
    );
    setCharges(chargesData);
    return data;
  };

  const changeFrequency = async (subscription, interval, unit) => {
    // Make Change Frequency Request to Recharge
    const { data } = await rechargeFetch({
      action: 'change-frequency',
      id: subscription.id,
      interval,
      unit,
    });
    const chargesData = await getCharges();
    setSubscriptions(
      subscriptions.map((_subscription) => {
        return _subscription.id === subscription.id ? data : _subscription;
      })
    );
    setCharges(chargesData);
    if (editSubscription) setEditSubscription(data);
    return data;
  };

  const changeChargeDate = async (subscription, date) => {
    // Make Change Charge Date Request to Recharge
    const { data } = await rechargeFetch({
      action: 'change-charge-date',
      id: subscription.id,
      date,
    });
    const chargesData = await getCharges();
    setSubscriptions(
      subscriptions.map((_subscription) => {
        return _subscription.id === subscription.id ? data : _subscription;
      })
    );
    setCharges(chargesData);
    if (editSubscription) setEditSubscription(data);
    return data;
  };

  const changeQuantity = async (subscription, quantity) => {
    // Make Change Charge Quantity Request to Recharge
    const { data } = await rechargeFetch({
      action: 'change-quantity',
      id: subscription.id,
      quantity,
    });
    const chargesData = await getCharges();
    setSubscriptions(
      subscriptions.map((_subscription) => {
        return _subscription.id === subscription.id ? data : _subscription;
      })
    );
    setCharges(chargesData);
    if (editSubscription) setEditSubscription(data);
    return data;
  };

  const changeProduct = async (subscription, variantId) => {
    // Make Change Product Request to Recharge
    const { data } = await rechargeFetch({
      action: 'change-product',
      id: subscription.id,
      variantId,
    });
    const chargesData = await getCharges();
    setSubscriptions(
      subscriptions.map((_subscription) => {
        return _subscription.id === subscription.id ? data : _subscription;
      })
    );
    setCharges(chargesData);
    if (editSubscription) setEditSubscription(data);
    return data;
  };

  const skipCharge = async (charge) => {
    // Make Skip Charge Request to Recharge
    const { data } = await rechargeFetch({
      action: 'skip-charge',
      id: charge.id,
      itemIds: charge.line_items.map((item) => item.purchase_item_id),
    });
    const chargesData = await getCharges();
    const subscriptionsData = await getSubscriptions();
    setCharges(chargesData);
    setSubscriptions(subscriptionsData);
    if (editSubscription)
      setEditSubscription(
        subscriptionsData.find(
          (_subscription) => _subscription.id === editSubscription.id
        )
      );
    return data;
  };

  const createOnetime = async ({ variantId, quantity, addressId, date }) => {
    const { data } = await rechargeFetch({
      action: 'create-onetime',
      variantId,
      quantity,
      addressId,
      date,
    });
    setOnetimes([...onetimes, data]);
    const chargesData = await getCharges();
    setCharges(chargesData);
  };

  const deleteOnetime = async (onetime) => {
    const { data } = await rechargeFetch({
      action: 'delete-onetime',
      id: onetime.id,
    });
    setOnetimes(onetimes.filter((_onetime) => _onetime.id !== onetime.id));
    const chargesData = await getCharges();
    setCharges(chargesData);
    return data;
  };

  const updateAddress = async (address, fields) => {
    // Make Change Product Request to Recharge
    const { data } = await rechargeFetch({
      action: 'update-address',
      id: address.id,
      fields,
    });
    setAddresses(
      addresses.map((_address) => {
        return _address.id === address.id ? data : _address;
      })
    );
    return data;
  };

  const requestPaymentEmail = async (id) => {
    const response = await rechargeFetch({
      action: 'request-payment-email',
      id,
    });
    return response;
  };

  return {
    initializeRechargeCustomer,
    initializeRechargeData,
    activateSubscription,
    cancelSubscription,
    changeFrequency,
    changeChargeDate,
    changeQuantity,
    changeProduct,
    skipCharge,
    updateAddress,
    requestPaymentEmail,
    createOnetime,
    deleteOnetime,
  };
};
