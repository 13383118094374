import { useState } from 'react';
import { Box, Image, Flex } from 'theme-ui';
import { UnderlineTab } from '@snippets';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { entryAnimation } from '@utils';

export function SubNav({ cms }) {
  const router = useRouter();
  const { ref, inView, entry } = useInView({ triggerOnce: true });
  const [subNavDrawer, setSubNavDrawer] = useState(false);
  function toggleSubNavDrawer() {
    setSubNavDrawer(!subNavDrawer);
  }

  return (
    <Flex
      data-comp="SubNav"
      ref={ref}
      sx={{
        minHeight: [null, null, null, 48],
        maxHeight: [null, null, null, 48],
        padding: 0,
        backgroundColor: 'black',
        overflowX: ['scroll', null, null, 'auto'],
      }}
    >
      <Box
        sx={{
          boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
          color: 'white',
          display: [null, null, null, 'none'],
          fontFamily: 'heading',
          fontSize: '12px',
          fontWeight: '400',
          letterSpacing: '0.01em',
          textAlign: 'center',
          textTransform: 'uppercase',
          width: '100%',
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            p: ['9px 12px', null, null, 0],
          }}
          onClick={() => {
            toggleSubNavDrawer();
          }}
        >
          {(cms?.navItems || []).map((item, index, arr) => {
            if (!item?.navItem) return null;
            const linkIsCurrentUrl = item?.navItem?.url === router.asPath;
            if (!linkIsCurrentUrl) return null;
            return (
              <Flex
                key={index}
                sx={{
                  alignItems: 'center',
                  color: 'gold',
                  gap: '10px',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    mt: '2px',
                  }}
                >
                  {item?.navItem?.text || ''}
                </Box>
                <Image
                  src={`/svg/chevron-down-gold.svg`}
                  sx={{
                    width: '12px',
                    transform: subNavDrawer ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
              </Flex>
            );
          })}
        </Box>
        <Flex
          sx={{
            background: '#333',
            flexDirection: 'column',
            height: subNavDrawer ? 'auto' : 0,
            overflow: 'hidden',
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          {(cms?.navItems || []).map((item, index, arr) => {
            if (!item?.navItem) return null;
            const linkIsCurrentUrl = item?.navItem?.url === router.asPath;
            return (
              <Box
                key={index}
                sx={{
                  color: linkIsCurrentUrl ? 'gold' : null,
                  cursor: linkIsCurrentUrl ? null : 'pointer',
                  p: ['9px 12px', null, null, 0],
                }}
                onClick={() => {
                  if (linkIsCurrentUrl) return null;
                  router.push(item?.navItem?.url || '#');
                }}
              >
                <Box
                  sx={{
                    mt: '2px',
                  }}
                >
                  {item?.navItem?.text || ''}
                </Box>
                <Box
                  sx={{
                    background: '#434f50',
                    height: '1px',
                    mb: '-12px',
                    mt: '12px',
                    width: '100%',
                  }}
                />
              </Box>
            );
          })}
        </Flex>
      </Box>
      <Flex
        variant="flex.row.center"
        sx={{
          alignItems: 'stretch',
          justifyContent: ['flex-start', null, null, 'center'],
          minWidth: 'auto',
          width: ['auto', null, null, '100%'],
          display: ['none', null, null, 'flex'],
          whiteSpace: ['nowrap', null, null, 'normal'],
          flexWrap: ['nowrap'],
          mx: ['auto', null, null, 'initial'],
          ...entryAnimation({
            entered: inView,
            fadeIn: true,
            slideIn: false,
            duration: 350,
            index: 0,
            delay: 0,
          }),
        }}
      >
        {(cms?.navItems || []).map((item, index, arr) => {
          if (!item?.navItem) return null;
          const linkIsCurrentUrl = item?.navItem?.url === router.asPath;
          return (
            <UnderlineTab
              text={item?.navItem?.text || ''}
              link={item?.navItem?.url || '#'}
              key={item?.navItem?.text || item.id}
              index={index}
              isLast={index === arr.length - 1}
              isActive={linkIsCurrentUrl}
              theme="dark"
              size="small"
              sx={{
                mt: [0, null, null, 8],
                height: 'auto',
              }}
              collectionIndex={-1}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

SubNav.Schema = {
  label: 'Sub Nav',
  key: 'collection-sub-nav',
  fields: [
    {
      label: 'Nav Items',
      name: 'navItems',
      component: 'group-list',
      itemProps: {
        label: '{{item.navItem.text}}',
      },
      defaultItem: (item) => ({
        name: 'New nav item',
        id: Math.random().toString(36).substr(2, 9),
        navItem: {
          text: '',
          url: '#',
        },
      }),
      fields: [
        {
          name: 'navItem',
          component: 'link',
          label: 'Navigation Item',
        },
      ],
    },
  ],
};
