import { Flex, Box, Heading, Text } from 'theme-ui';

import { useProductsFromHandles } from '@hooks';
import { ShopifyImage, InlineHeading } from '@snippets';

export function ProductWhatsIncluded({ whatsIncluded, ...props }) {
  const handles = whatsIncluded.map((product) => product.product.handle);
  const { fetched, success, products } = useProductsFromHandles({ handles });

  if (!fetched || !success) return null;

  const getIncludes = (product) => {
    const includesTags = product?.tags?.find((tag) => {
      return tag.startsWith('includes::');
    });
    const includesTagNames = includesTags?.split('::')[1].trim();
    return includesTagNames || '1 Bottle';
  };

  return (
    <Box
      data-comp="ProductWhatsIncluded"
      {...props}
      sx={{
        mb: 7,
        display: 'block',
        ...props.sx,
      }}
    >
      <InlineHeading theme="dark" text="What's Included" />

      <Flex sx={{ flexDirection: 'column', mt: 9 }}>
        {Object.values(products).map((product) => {
          return (
            <Flex mb={5} key={product.handle}>
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  border: (t) => `1px solid ${t.colors.gray[6]}`,
                  borderRadius: '50%',
                  overflow: 'hidden',
                  mr: '10px',
                }}
              >
                <ShopifyImage
                  layout="fixed"
                  width={40}
                  height={40}
                  alt={product.title}
                  src={
                    product.variants?.length
                      ? product.variants?.[0]?.image?.originalSrc
                      : product.images?.[0]?.originalSrc
                  }
                />
              </Box>

              <Box ml="4px">
                <Heading variant="14" color="white">
                  {product.title}
                </Heading>
                <Text variant="14" color="white" sx={{ fontSize: 0 }}>
                  {getIncludes(product)}
                </Text>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}
