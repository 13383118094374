import { store } from '@backpackjs/storefront';
import effects from './effects';

const menuDrawer = store.recoil.atom({
  key: 'menu/menuDrawer',
  default: false,
  effects_UNSTABLE: [
    // effects.logStateChange('menuDrawer')
  ],
});

const menuDrawerIndex = store.recoil.atom({
  key: 'menu/menuDrawerIndex',
  default: null,
  effects_UNSTABLE: [],
});

const activeMobileMenuDrawer = store.recoil.atom({
  key: 'menu/activeMobileMenuDrawer',
  default: null,
  effects_UNSTABLE: [],
});

const activeMobileShopNav = store.recoil.atom({
  key: 'menu/activeMobileShopNav',
  default: true,
  effects_UNSTABLE: [],
});

const menuItem = store.recoil.atom({
  key: 'menu/menuItem',
  default: null,
  effects_UNSTABLE: [
    // showHideYotpoReviews,
    // effects.logStateChange('menuItem'),
  ],
});

const menuItemChild = store.recoil.atom({
  key: 'menu/menuItemChild',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('menuItem')
  ],
});

// eslint-disable-next-line import/no-default-export
export default {
  // atoms
  menuDrawer,
  menuDrawerIndex,
  activeMobileMenuDrawer,
  activeMobileShopNav,
  menuItem,
  menuItemChild,
};
