import { imageCmsSettings, activeMediaTypeSettings } from '@utils';

import { MediaTextBlock } from '@snippets';

export const PageMediaTextBlock = ({ cms, section }) => {
  return <MediaTextBlock cms={cms} />;
};

PageMediaTextBlock.Schema = {
  label: 'Media Text Block',
  key: 'media-text-block',
  fields: [
    {
      name: 'mediaSide',
      label: 'Media Side',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          value: 'left',
          label: 'Left',
        },
        {
          value: 'right',
          label: 'Right',
        },
      ],
      defaultValue: 'left',
    },
    ...activeMediaTypeSettings,
    imageCmsSettings({ overlay: false }),
    {
      label: 'Video Settings',
      name: 'videoSettings',
      description: 'YouTube Video ID, Player Parameters.',
      component: 'group',
      fields: [
        {
          name: 'videoId',
          component: 'text',
          label: 'YouTube Video ID',
          description: `The sequence of numbers and letters at the end of the URL, immediately after the string "?v=".`,
        },
        {
          name: 'disableControls',
          label: 'Disable Controls',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'disableFullscreen',
          label: 'Disable Fullscreen',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
    },
    {
      label: 'Text Settings',
      name: 'textSettings',
      description: 'Heading, Description',
      component: 'group',
      fields: [
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
        },
        {
          name: 'superheading',
          component: 'text',
          label: 'Superheading',
        },
        {
          name: 'description',
          component: 'text',
          label: 'Description',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Description Inline Hyperlink',
        },
      ],
    },
    {
      label: 'Primary Button Settings',
      name: 'primaryButtonSettings',
      description: 'Button Text, Button Path.',
      component: 'group',
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'CTA',
        },
        {
          name: 'scrollToTop',
          label: 'Enable Scroll To Top',
          description:
            'Override button link with a scroll to the top of the page.',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'hideButton',
          label: 'Hide Button',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
    },
    {
      label: 'Secondary Button Settings',
      name: 'secondaryButtonSettings',
      description: 'Button Text, Button Path.',
      component: 'group',
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'CTA',
        },
        {
          name: 'hideButton',
          label: 'Hide Button',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
    },
    {
      name: 'topWave',
      label: 'Show Top Wave',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'Hide',
      },
      defaultValue: false,
    },
    {
      name: 'bottomWave',
      label: 'Show Bottom Wave',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'Hide',
      },
      defaultValue: true,
    },
  ],
};
