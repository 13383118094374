import { Flex, Paragraph } from 'theme-ui';

export const RewardsHistoryRow = ({
  item,
  index,
  arr,
  isHeadings,
  sx,
  ...props
}) => {
  return (
    <Flex
      data-comp="RewardsHistoryRow"
      sx={{
        justifyContent: 'space-between',
        py: 4,
        borderBottom: index !== arr.length - 1 ? '1px solid' : null,
        borderColor: 'gray.3',
        p: {
          variant: isHeadings ? 'text.label.2' : 'text.sm',
        },
        ...sx,
      }}
      {...props}
    >
      <Paragraph
        sx={{
          width: '75px',
          mr: [4, null, 13],
        }}
      >
        {item.date}
      </Paragraph>
      <Paragraph
        sx={{
          flex: 1,
          whiteSpace: 'pre-wrap',
          mr: [4, null, 13],
        }}
      >
        {item.action}
      </Paragraph>
      <Paragraph
        sx={{
          width: '50px',
          mr: [4, null, 13],
        }}
      >
        {item.points}
      </Paragraph>
      <Paragraph
        sx={{
          width: '70px',
        }}
      >
        {item.status}
      </Paragraph>
    </Flex>
  );
};
