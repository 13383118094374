import React from 'react';
import { Container } from 'theme-ui';

import store, { useRecoilState } from '@store';
import { SubCollection } from './SubCollection';
import { CollectionScrollNav } from './CollectionScrollNav';

export function CollectionGrid({ cms }) {
  const [activeCollectionIndex, setActiveCollectionIndex] = useRecoilState(
    store.activeCollectionIndex
  );

  React.useEffect(() => {
    document
      .querySelectorAll('[data-comp="collection-grid"]')
      .forEach((grid) => {
        grid.style.backgroundColor = '#f5f5f5';
      });
  }, [cms]);

  React.useEffect(() => {
    try {
      if (!cms?.useScrollNav) return;
      setTimeout(() => {
        const onScroll = () => {
          const header = document.querySelector('[data-comp="Header"]');
          const promoBar = document.querySelector('[data-comp="PromoBar"]');
          const collectionScrollNav = document.querySelector(
            '[data-comp="CollectionScrollNav"]'
          );
          const calcOffset =
            (header ? header.offsetHeight : 0) +
            (promoBar ? promoBar.offsetHeight : 0) +
            (collectionScrollNav ? collectionScrollNav.offsetHeight : 0) +
            32;
          const subCollections = document.querySelectorAll(
            '[data-comp="collection-grid"] [data-comp="SubCollection"]'
          );
          let activeIndex = 0; // default to first collection
          subCollections.forEach((subCollection, index) => {
            const bounding = subCollection.getBoundingClientRect();
            const topOffset = bounding.top;
            const botOffset = bounding.bottom;
            if (topOffset <= calcOffset) {
              activeIndex = index;
            }
          });
          setActiveCollectionIndex(activeIndex);
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
      }, 500);
    } catch (error) {
      throw new Error(error);
    }
  }, [cms?.useScrollNav]);

  return (
    <>
      {cms?.useScrollNav ? <CollectionScrollNav cms={cms} /> : null}
      <Container
        variant="layout.collectionGrid"
        data-comp="CollectionsGrid"
        sx={{
          zIndex: 10,
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: ['1fr'],
          columnGap: [0, 0, 0, 4, 8, 10],
          py: [12, null, null, null, 14],
          px: [6, null, null, null, 10],
          minHeight: 'calc(var(--viewport-height) - 600px)',
        }}
      >
        {cms?.subCollections?.length &&
          cms.subCollections.map((collection, index, arr) => {
            return (
              <SubCollection
                key={collection?.heading + index}
                index={index}
                count={arr.length}
                collection={collection}
              />
            );
          })}
      </Container>
    </>
  );
}

CollectionGrid.Schema = {
  label: 'Collection Grid',
  key: 'collection-grid',
  fields: [
    {
      label: 'Scroll-to Collection Nav',
      name: 'useScrollNav',
      component: 'toggle',
      toggleLabels: {
        true: 'Show',
        false: 'Hide',
      },
      defaultValue: false,
    },
    {
      label: 'Collection Grid',
      name: 'subCollections',
      component: 'group-list',
      itemProps: {
        label: '{{item.heading}}',
      },
      defaultItem: () => ({
        name: 'New collection grid',
        id: Math.random().toString(36).substr(2, 9),
        item: null,
        products: [],
      }),
      fields: [
        {
          name: 'heading',
          component: 'text',
          label: 'Collection Heading',
        },
        {
          name: 'cover',
          component: 'group',
          label: 'Collection Cover',
          fields: [
            {
              name: 'video',
              component: 'text',
              label: 'Vimeo Video File Link*',
              description:
                '*Required. Max quality: Standard def (mp4, 540x540).',
            },
            {
              name: 'poster',
              label: 'Video Thumbnail*',
              component: 'image',
              description:
                '*Required. Usually the first or best frame of the video.',
            },
            {
              label: 'Video Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
        {
          label: 'Hide Quick Add',
          description:
            'Hide quick add to cart on this collection',
          name: 'hideQuickAdd',
          component: 'toggle',
          toggleLabels: {
            true: 'Hide',
            false: 'Show',
          },
          defaultValue: false,
        },
        {
          label: 'Filter & Sort',
          description:
            'Enable/disable filter and sort controls on this collection',
          name: 'enableFilter',
          component: 'toggle',
          toggleLabels: {
            true: 'Show',
            false: 'Hide',
          },
          defaultValue: false,
        },
        {
          label: 'Filter Options',
          name: 'filterOptions',
          component: 'group-list',
          itemProps: {
            label: '{{item.group}} - {{item.option}}',
          },
          defaultItem: () => ({
            group: 'group',
            option: 'option',
          }),
          fields: [
            {
              name: 'group',
              component: 'text',
              label: 'Filter Group',
            },
            {
              name: 'option',
              component: 'text',
              label: 'Filter Value',
            },
          ],
        },
        {
          name: 'products',
          label: "What's Included",
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          defaultItem: () => ({
            product: {
              handle: 'truff',
            },
          }),
          fields: [
            {
              name: 'filterlist',
              component: 'group-list',
              label: 'Filter Values',
              itemProps: {
                label: '{{item.group}} - {{item.option}}',
              },
              defaultItem: () => ({
                group: 'group',
                option: 'option',
              }),
              fields: [
                {
                  name: 'group',
                  component: 'text',
                  label: 'Filter Group',
                },
                {
                  name: 'option',
                  component: 'text',
                  label: 'Filter Value',
                },
              ],
            },
            {
              name: 'sortlist',
              component: 'group-list',
              label: 'Sort Values',
              itemProps: {
                label: '{{item.option}}',
              },
              defaultItem: () => ({
                option: 'option',
                value: 1,
              }),
              fields: [
                {
                  name: 'option',
                  component: 'text',
                  label: 'Sort Option',
                },
                {
                  name: 'value',
                  component: 'number',
                  label: 'Sort Ranking',
                },
              ],
            },
            {
              name: 'featuredtag',
              component: 'text',
              label: 'Featured Tag',
            },
            {
              name: 'altimage',
              component: 'image',
              label: 'Alt Image',
            },
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
          defaultValue: [
            {
              product: {
                handle: 'truff',
              },
            },
          ],
        },
      ],
    },
  ],
};
