export const lineHeights = {
  11: '11px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  26: '26px',
  28: '28px',
  30: '30px',
  34: '34px',
  36: '36px',
  38: '38px',
  40: '40px',
  46: '46px',
  54: '54px',
  56: '56px',
  64: '64px',
  68: '68px',
  72: '72px',
  84: '84px',
  94: '94px',
  104: '104px',
};
