import { useEffect } from 'react';
import { isBrowser } from '@utils';
import { useRouter } from 'next/router';

const requestGeolocation = async () => {
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : 'https://www.truff.com';

  try {
    const url = `${baseUrl}/api/geolocation`;
    const response = await fetch(url);
    if (response.ok) {
      return await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const useGeolocation = () => {
  const router = useRouter();

  useEffect(() => {
    if (!isBrowser) return;
    const getGeo = async () => {
      try {
        const data = await requestGeolocation();
        const countryCode = data?.['x-country'];

        console.groupCollapsed('🌎 Geo location');
        console.log('Country code:\t', countryCode);
        console.groupEnd('🌎 Geo location');

        if (countryCode) {
          if (countryCode === 'AU' || countryCode === 'NZ') {
            router.replace('http://truff.com.au/');
          }
        }
      } catch (error) {
        console.log('useGeolocation:error', error.message);
      }
    };
    getGeo();
  }, []);
};
