import { useEffect } from 'react';
import '@reach/skip-nav/styles.css';
import { Container, Box, ThemeProvider } from 'theme-ui';

import { MDXProvider, useMDXComponents } from '@mdx-js/react';
import { useThemedStylesWithMdx } from '@theme-ui/mdx';

import { PageTransition } from 'next-page-transitions';

import {
  useStorefrontInit,
  useCartAddDiscountUrl,
  useRouter,
  useSettings,
  useCountry,
} from '@backpackjs/storefront';

import { StorefrontHead } from '@head';

import {
  useGeolocation,
  useTrackSettings,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useFondueCashback,
} from '@hooks';

import {
  Header,
  Footer,
  Overlay,
  CartDrawer,
  SideDrawer,
  Modal,
  RewardsModal,
  AccessiBeApp,
  SchemaMarkup,
  DataLayerWithElevar,
  FondueCashbackModal,
} from '@snippets';

import store, { useSetRecoilState } from '@store';

import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import StorefrontTheme from '../theme';

const GlobalThemeProvider = ({ children, theme, components }) => {
  const componentsWithStyles = useThemedStylesWithMdx(
    useMDXComponents(components)
  );

  return (
    <ThemeProvider theme={theme}>
      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>
    </ThemeProvider>
  );
};

const StorefrontLayout = ({ ContentForLayout, ...props }) => {
  const settings = useSettings();
  const noIndexUrlPathList = settings?.noindex?.urlPathList;
  const { pathname: path, asPath } = useRouter();
  const router = useRouter();

  const enableNoIndexPage = noIndexUrlPathList?.some(
    (item) => item?.urlPath === asPath
  );

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  useGeolocation();

  // adds --viewport-height css var to the :root
  useSetViewportHeightCssVar();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl({
    queryParams: ['discount'],
    removeParams: false,
  });

  // track siteSetting changes in recoil
  useTrackSettings();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  useFondueCashback();

  const setCountry = useSetRecoilState(store.country);

  const { country } = useCountry();

  useEffect(() => {
    setCountry(country?.isoCode || null);
  }, [country?.isoCode]);

  return (
    <GlobalThemeProvider theme={StorefrontTheme}>
      <StorefrontHead />
      {enableNoIndexPage && <meta name="robots" content="noindex" />}

      <DataLayerWithElevar {...props} />

      <SchemaMarkup {...props} />

      <style jsx global>
        {`
          .page-transition-enter {
            opacity: 0;
            will-change: opacity;
          }
          .page-transition-enter-active {
            opacity: 1;
            transition: opacity 250ms;
          }
          .page-transition-exit {
            opacity: 1;
          }
          .page-transition-exit-active {
            opacity: 0;
            transition: opacity 125ms;
          }
        `}
      </style>
      {/* Skip nav links - for accessibility */}
      <SkipNavLink style={{ zIndex: 100 }} contentId="main-content">
        Skip To Content
      </SkipNavLink>
      <SkipNavLink style={{ zIndex: 100 }} contentId="footer">
        Skip To Footer
      </SkipNavLink>
      {settings?.header ? <Header /> : null}
      {/* Skip nav links to main content - for accessibility */}
      <SkipNavContent id="main-content" />
      {/* main content */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight:
            'calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))',
          alignItems: 'stretch',
          marginTop: '-2px',
        }}
      >
        <Container
          as="main"
          sx={{
            flexGrow: 1,
            flexShrink: 0,
            overflowX:
              path === '/'
                ? 'hidden' // fixes the overflow of productItem drawer inside swiper
                : 'unset',
          }}
        >
          <PageTransition timeout={250} classNames="page-transition">
            <ContentForLayout {...props} key={asPath} />
          </PageTransition>
        </Container>

        {/* Skip nav links to footer - for accessibility */}
        <SkipNavContent id="footer" />

        <Box sx={{ flexShrink: 0 }}>{settings?.footer ? <Footer /> : null}</Box>
      </Box>
      <Overlay />
      <SideDrawer />
      <CartDrawer />
      <Modal />
      <RewardsModal />
      <FondueCashbackModal />
      <AccessiBeApp />
    </GlobalThemeProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
