import React, { useState } from 'react';
import { Flex } from 'theme-ui';

import { AccordionItem } from './AccordionItem';

export const Accordion = ({ content = [], onePanelOpen = false }) => {
  const [indexOpen, setIndexOpen] = useState(null);

  return (
    <Flex
      data-comp="Accordion"
      variant="flex.column"
      sx={{
        width: '100%',
      }}
    >
      {content?.map((item, index) => (
        <AccordionItem
          key={index}
          item={item}
          index={index}
          indexOpen={indexOpen}
          setIndexOpen={setIndexOpen}
          onePanelOpen={onePanelOpen}
        />
      ))}
    </Flex>
  );
};
