import React from 'react';
import { Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';

export const YouTubeEmbed = ({ video = {}, display = 'block' }) => {
  const controls = video?.disableControls ? 0 : 1;
  const fs = video?.disableFullscreen ? 0 : 1;
  const id = video?.videoId || '';

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <Box
      data-comp="YouTubeEmbed"
      sx={{
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        display,
        position: 'absolute',
        bottom: '-3px',
        left: '-3px',
      }}
      ref={ref}
    >
      {inView && (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${id.trim()}?&origin=https://truff.com&rel=0&modestbranding=1&controls=${controls}&fs=${fs}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </Box>
  );
};
