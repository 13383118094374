import React from 'react';
import { Flex, Paragraph } from 'theme-ui';
import { LinkWrapper, UnderlineLink, BackpackImage } from '@snippets';

export function LifestyleGridMainImage({ mainImage, imageSx, boxShadow }) {

  return (
    <LinkWrapper
      newTab={mainImage?.cta?.newTab}
      href={mainImage?.cta?.url}
      sx={{ gridArea: 'primary' }}
      as={mainImage?.cta?.text ? 'span' : 'a'}
    >
      <Flex
        data-comp="LifestyleGridMainImage"
        variant="flex.row.center.center"
        sx={{
          height: '100%',
          minHeight: ['calc(123vw - 24px)', null, null, 'auto'],
          maxHeight: ['calc(123vw - 24px)', null, null, 'auto'],
          pb: [0, null, null, '65%'],
          borderRadius: '6px',
          position: 'relative',
          cursor: mainImage?.cta?.url ? 'pointer' : 'default',
          bg: 'ghostwhite',
          overflow: 'hidden',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            boxShadow: mainImage?.cta?.url ? boxShadow : null,
            '.lifestyle-image': {
              transform: mainImage?.cta?.url ? 'scale(1.05)' : null,
            },
          },
        }}
      >
        <BackpackImage
          className="lifestyle-image"
          sx={imageSx}
          src={mainImage?.image?.src || mainImage?.image}
          alt={mainImage?.cta?.text}
        />

        <Flex
          variant="flex.column.start.end"
          sx={{
            height: '37%',
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: 0,
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
            pb: [13, null, null, 14],
            px: [9, null, null, 13],
          }}
        >
          <Paragraph
            variant="30"
            sx={{
              color: mainImage?.textColor || 'white',
            }}
          >
            {mainImage?.heading}
          </Paragraph>

          {mainImage?.cta?.text && (
            <UnderlineLink
              text={mainImage?.cta?.text}
              href={mainImage?.cta?.url}
              variant={mainImage?.buttonStyle || 'primary'}
              mobileVariant={mainImage?.buttonStyle || 'primary'}
              newTab={mainImage?.cta?.newTab}
              sx={{ mt: [6, null, null, 4] }}
            />
          )}
        </Flex>
      </Flex>
    </LinkWrapper>
  );
}
