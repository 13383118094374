import { Paragraph, Image, Button } from "theme-ui";

import store, { useRecoilState, useSetRecoilState } from "@store";

export const MenuToggleDesktop = (props) => {
  const [menuDrawer, setMenuDrawer] = useRecoilState(store.menuDrawer);
  const setMenuItem = useSetRecoilState(store.menuItem);
  const setOverlay = useSetRecoilState(store.overlay);
  const setModal = useSetRecoilState(store.modal);
  const [menuDrawerIndex, setMenuDrawerIndex] = useRecoilState(
    store.menuDrawerIndex
  );

  const toggleMenuDrawer = () => {
    if (menuDrawer) {
      // reset and close menu
      setMenuItem(null);
      setOverlay(false);
      setMenuDrawer(false);
      setMenuDrawerIndex(null);
    } else {
      activateMenuDrawer();
    }
  };
  const activateMenuDrawer = () => {
    setOverlay(true);
    setMenuDrawer(true);
    setModal(null);
    setMenuDrawerIndex(props.menuIndex);
  };

  return (
    <Button
      onClick={toggleMenuDrawer}
      onMouseOver={activateMenuDrawer}
      variant="buttons.link"
      aria-label={
        menuDrawerIndex === props.menuIndex
          ? `Close ${props.menuTitle} menu drawer`
          : `Open ${props.menuTitle} menu drawer`
      }
      {...props}
      sx={{
        display: ["none", null, null, null, "flex"],
        alignItems: "center",
        height: 16,
        gap: 5,
        ...props.sx,
        "svg line#bottom": {
          transition: "transform 250ms ease-in",
          transformOrigin: "bottom right",
        },
        "&:hover": {
          "svg line#bottom": {
            transform: "scaleX(2.5);",
          },
        },
        ...(menuDrawerIndex === props.menuIndex
          ? {
              "&:before": {
                animation: "in 0.33s cubic-bezier(.52,.02,.31,.99) normal",
                transition: "all 0.33s",
                opacity: 1,
              },
            }
          : {}),
      }}
    >
      <Paragraph
        variant="text.14"
        sx={{
          color: (t) => t.colors.white,
          height: "16px",
          lineHeight: "unset",
        }}
      >
        {props.menuTitle}
      </Paragraph>
      <Image
        src="/svg/chevron-down.svg"
        alt="Chevron down"
        sx={{
          width: "12px",
          transform:
            menuDrawerIndex === props.menuIndex
              ? "rotate(180deg)"
              : "rotate(0deg)",
          transition: "transform 0.3s ease-in-out",
          userSelect: "none",
          filter: "brightness(0) invert(1)",
        }}
        draggable={false}
      />
    </Button>
  );
};
