import { Flex } from 'theme-ui';

import { HeroImage, VideoPlayer } from '@snippets';

export const MediaTextBlockMedia = ({ mediaSide, image, video, mediaType }) => {
  return (
    <Flex
      data-comp="MediaTextBlockMedia"
      variant="flex.column.center.center"
      sx={{
        flexBasis: [null, null, null, 'calc(50% - 10px)'],
        height: [
          mediaType.mobile === 'image'
            ? 'calc(125vw - 24px)'
            : 'calc(56.25vw - 14px)',
          mediaType.mobile === 'image'
            ? 'calc(70vw - 24px)'
            : 'calc(56.25vw - 36px)',
          null,
          mediaType.main === 'image'
            ? 'calc(35vw - 10px)'
            : 'calc(28.6vw - 10px)',
        ],
        width: ['100%', null, null, 'auto'],
        maxWidth: ['100%', null, null, '710px'],
        maxHeight: [null, null, null, '399.38px'],
        mr: mediaSide === 'left' ? [0, null, null, 9] : 0,
        ml: mediaSide === 'left' ? 0 : [0, null, null, 9],
        borderRadius: [
          '6px',
          null,
          null,
          mediaSide === 'left' ? '0 6px 6px 0' : '6px 0 0 6px',
          null,
          null,
          null,
          '6px',
        ],
        position: 'relative',
        overflow: 'hidden',
        transform: 'translateZ(0)',
      }}
    >
      <HeroImage image={image} mediaType={mediaType} />
      <VideoPlayer video={video} mediaType={mediaType} />
    </Flex>
  );
};
