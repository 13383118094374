import React from 'react';

import { useMountAfter } from '@hooks';

export const MountAfter = (
  { delay, loader, children, ...props } = { delay: 300, loader: null }
) => {
  const [isReady] = useMountAfter({ delay });

  return isReady ? children : React.isValidElement(loader) ? loader : null;
};
