import {
  Container,
  Box,
  Heading,
  Button,
  Paragraph,
  Flex,
  Text,
  Divider,
} from 'theme-ui';
import { LinkWrapper, Svg } from '@snippets';

import store, { useRecoilState } from '@store';

import { themed } from './FondueCashbackModal.theme';

export const FondueCashbackModal = themed(({ theme }) => {
  const [cashbackModalOpen, setCashbackModalOpen] = useRecoilState(
    store.cashbackModalOpen
  );

  if (!cashbackModalOpen) return null;

  return (
    <>
      <Box
        onClick={() => {
          setCashbackModalOpen(false);
        }}
        sx={theme.overlay}
      ></Box>

      <Box data-comp={FondueCashbackModal.displayName} sx={theme.content}>
        <Button
          type="button"
          onClick={() => {
            setCashbackModalOpen(false);
          }}
          sx={theme.closeModalBtn}
        >
          <Svg
            alt="Close Modal"
            src="/svg/modal-close.svg#modal-close"
            viewBox="0 0 16 16"
            sx={{ height: 16, width: 16 }}
          />
        </Button>

        <Flex variant="flex.row.start.center" sx={theme.header}>
          <Heading sx={theme.heading}>TRUFF</Heading>

          <Text
            sx={{
              fontSize: '32px',
            }}
          >
            +
          </Text>

          <Svg
            alt="Cashback"
            src="/svgs/cashback.svg#cashback"
            sx={{ height: 50, width: 50 }}
            viewBox="0 0 56 49"
          />
        </Flex>

        <Heading sx={theme.subheading}>
          No points. No Coupons. Just cash.
        </Heading>

        <Paragraph sx={theme.smallHeadline}>How it works: easily.</Paragraph>

        <Box as="ul" sx={theme.steps}>
          <Box
            as="li"
            style={{
              listStyle: 'disc',
            }}
          >
            <Paragraph>{`After purchase, you’ll get an email with a link to redeem your cash back`}</Paragraph>
          </Box>

          <Box
            as="li"
            style={{
              listStyle: 'disc',
            }}
          >
            <Paragraph>
              Choose how to get your cash back: Visa Debit Card, Biom Gift Card,
              or donate to charity. It will be useable once the 30 day return
              period has passed.
            </Paragraph>
          </Box>

          <Box
            as="li"
            style={{
              listStyle: 'disc',
            }}
          >
            <Paragraph>Done! Enjoy the cash</Paragraph>
          </Box>
        </Box>

        <Divider sx={{ my: 10, color: '#D1D5DB' }} />

        <Paragraph sx={theme.smallHeadline}>
          Why people love Cash Back
        </Paragraph>

        <Paragraph>
          Any rebate submission regarding products marked as eligible for cash
          back that does not strictly comply with all rebate terms and
          conditions may be rejected.
        </Paragraph>

        <Paragraph>
          After a qualifying purchase, you have 6 months to claim your reward.
          See example of a redemption form{' '}
          <LinkWrapper
            variant="text.link"
            aria-label={'How to redeem cashback rewards'}
            href="https://shopper.getfondue.com/cashback/redeem"
            newTab={true}
            sx={{
              textTransform: 'none',
              color: '#1D4ED8',
            }}
          >
            here.
          </LinkWrapper>
        </Paragraph>

        <Flex variant="flex.row.between.center" sx={{ mt: 10 }}>
          <Svg
            alt="Powered by Fondue"
            src="/svgs/powered-by-fondue.svg#powered-by-fondue"
            sx={{ height: 16, width: 123 }}
            viewBox="0 0 123 16"
          />
          <LinkWrapper
            variant="text.link"
            aria-label={'Terms of Service'}
            href="/pages/terms-of-service"
            sx={{
              fontSize: '10px',
              textTransform: 'none',
              color: '#1D4ED8',
            }}
          >
            Cash Back Redemption Terms
          </LinkWrapper>
        </Flex>
      </Box>
    </>
  );
});

FondueCashbackModal.displayName = 'FondueCashbackModal';
