import { useState, useEffect, useMemo } from 'react';
import { Label, Paragraph, Flex, Image } from 'theme-ui';

import { LinkWrapper } from '@snippets';

export const StyledSelect = ({
  label = '',
  placeholder = '',
  list = [],
  defaultValue = null,
  action = () => null,
  isMenu = false,
  id = '',
  smallPadding = false,
  listAsArrayOfStrings = false,
  isCountryProvinceSelector = false,
  isRechargeCustomer,
  zIndex = 1,
  sx,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const showLabel =
    (isCountryProvinceSelector && defaultValue) ||
    (!isMenu && typeof activeIndex === 'number');
  const selectId = `styled-select-${id}`;

  const defaultLabel = useMemo(() => {
    if (isCountryProvinceSelector) return defaultValue;
    const defaultItem = list.find((item, index) => {
      const value = listAsArrayOfStrings ? item : item.value;
      if (value !== defaultValue) return false;
      setActiveIndex(index);
      return true;
    });
    return listAsArrayOfStrings ? defaultItem : defaultItem?.label;
  }, [defaultValue, list]);

  const activeLabel = useMemo(() => {
    if (typeof activeIndex !== 'number') return placeholder;
    const _activeLabel = listAsArrayOfStrings
      ? list[activeIndex]
      : list[activeIndex]?.label;
    return _activeLabel;
  }, [activeIndex]);

  useEffect(() => {
    if (!isOpen) return;
    const element = document.getElementById(selectId);
    const isVisible = (el) => {
      return (
        !!el &&
        !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
      );
    };
    const outsideClickHandler = (e) => {
      if (!element.contains(e.target) && isVisible(element)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', outsideClickHandler);
    return () => document.removeEventListener('click', outsideClickHandler);
  }, [isOpen]);

  return (
    <Flex
      data-comp="StyledSelect"
      id={selectId}
      variant="flex.column"
      sx={{
        ...containerSx(zIndex),
        ...sx,
      }}
      {...props}
    >
      <Flex
        variant="flex.row.between.center"
        sx={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isMenu && (
          <Label
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              top: '9px',
              left: 6,
              variant: 'text.10',
              color: 'gold',
              visibility: showLabel ? 'block' : 'hidden',
            }}
          >
            {label || placeholder}
          </Label>
        )}
        <Paragraph
          variant="base"
          sx={{
            mr: 4,
            px: '11px',
            height: '100%',
            pt: showLabel ? '17px' : '11px',
            pb: showLabel ? '5px' : '11px',
          }}
        >
          {isMenu
            ? defaultLabel
            : isCountryProvinceSelector
            ? defaultValue
            : activeLabel}
        </Paragraph>
        <Image
          src={`/svg/chevron-down.svg`}
          sx={{
            width: '12px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease-in-out',
            mr: '11px',
            userSelect: 'none',
          }}
          draggable={false}
        />
      </Flex>

      {isOpen && (
        <Flex
          variant="flex.column"
          sx={{
            position: 'absolute',
            left: '-1px',
            top: 'calc(100% + 4px)',
            width: 'calc(100% + 2px)',
            bg: 'white',
            border: '1px solid',
            borderColor: 'gray.3',
            maxHeight: '400px',
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {list?.map((item, index) => {
            if (index === activeIndex) return null;
            if (item.rechargeLink && !isRechargeCustomer) return null;
            const value = listAsArrayOfStrings ? item : item.value;
            const label = listAsArrayOfStrings ? item : item.label;
            return (
              <Paragraph
                variant="base"
                sx={itemSx(smallPadding)}
                key={index}
                onClick={() => {
                  setActiveIndex(index);
                  setIsOpen(false);
                  action({ label, value, index });
                }}
              >
                {isMenu && (
                  <LinkWrapper
                    href={
                      value === 'rewards'
                        ? `/pages/${value}`
                        : `/account/${value}`
                    }
                    hiddenForSeo={true}
                  />
                )}
                {label}
              </Paragraph>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

const itemSx = (smallPadding) => ({
  cursor: 'pointer',
  px: 6,
  py: smallPadding ? 3 : 6,
  bg: 'white',
  transition: 'all 0.1s ease-in-out',
  color: 'gray.6',
  ':hover': {
    bg: 'gray.2',
    color: 'black',
  },
});

const containerSx = (zIndex) => ({
  width: 'auto',
  minWidth: '200px',
  height: '44px',
  position: 'relative',
  bg: 'white',
  variant: 'text.base',
  border: '1px solid',
  borderColor: 'gray.3',
  zIndex,
  ':focus': {
    outline: 'none !important',
    borderColor: 'gold',
  },
  ':valid': {
    borderColor: 'gold',
  },
  '&::placeholder': {
    fontSize: 4,
    color: 'gray.5',
  },
});
