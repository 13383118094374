import { store } from '@backpackjs/storefront';

const rechargeLoaded = store.recoil.atom({
  key: 'recharge/rechargeLoaded',
  default: false,
});

const rechargeCustomer = store.recoil.atom({
  key: 'recharge/rechargeCustomer',
  default: null,
});

const rechargeSubscriptions = store.recoil.atom({
  key: 'recharge/rechargeSubscriptions',
  default: [],
});

const rechargeOnetimes = store.recoil.atom({
  key: 'recharge/rechargeOnetimes',
  default: [],
});

const rechargeCharges = store.recoil.atom({
  key: 'recharge/rechargeCharges',
  default: [],
});

const rechargeAddresses = store.recoil.atom({
  key: 'recharge/rechargeAddresses',
  default: [],
});

const rechargePayments = store.recoil.atom({
  key: 'recharge/rechargePayments',
  default: [],
});

const rechargeRetentionStrategies = store.recoil.atom({
  key: 'recharge/rechargeRetentionStrategies',
  default: [],
});

const rechargeProducts = store.recoil.atom({
  key: 'recharge/rechargeProducts',
  default: [],
});

const rechargeModal = store.recoil.atom({
  key: 'recharge/rechargeModal',
  default: null,
});

const editSubscription = store.recoil.atom({
  key: 'recharge/editSubscription',
  default: null,
});

const activeSubscriptions = store.recoil.selector({
  key: 'recharge/activeSubscriptions',
  get: ({ get }) => {
    const subs = get(rechargeSubscriptions);
    return subs.filter(
      (subscription) => subscription?.status?.toLowerCase() !== 'cancelled'
    );
  },
});

const cancelledSubscriptions = store.recoil.selector({
  key: 'recharge/cancelledSubscriptions',
  get: ({ get }) => {
    const subs = get(rechargeSubscriptions);
    return subs.filter(
      (subscription) => subscription?.status?.toLowerCase() === 'cancelled'
    );
  },
});

const addressById = store.recoil.selectorFamily({
  key: 'recharge/addressById',
  get:
    (id) =>
    ({ get }) => {
      const addresses = get(rechargeAddresses);
      return addresses.find((item) => {
        return item.id === id;
      });
    },
});

const chargeBySubscriptionId = store.recoil.selectorFamily({
  key: 'recharge/chargeBySubscriptionId',
  get:
    (id) =>
    ({ get }) => {
      const charges = get(rechargeCharges);
      return charges.find((item) => {
        return (
          item.status === 'queued' &&
          item.line_items.some((lineItem) => {
            return lineItem.purchase_item_id === id;
          })
        );
      });
    },
});

const paymentById = store.recoil.selectorFamily({
  key: 'recharge/paymentByAddressId',
  get:
    (id) =>
    ({ get }) => {
      const payments = get(rechargePayments);
      return payments.find((item) => {
        return item.id === id;
      });
    },
});

const onetimesByCharge = store.recoil.selectorFamily({
  key: 'recharge/paymentByAddressId',
  get:
    (charge) =>
    ({ get }) => {
      const onetimes = get(rechargeOnetimes);
      return onetimes.filter((onetime) => {
        return charge?.line_items?.some(
          (_item) => onetime.id === _item.purchase_item_id
        );
      });
    },
});

const productById = store.recoil.selectorFamily({
  key: 'recharge/productById',
  get:
    (id) =>
    ({ get }) => {
      const products = get(rechargeProducts);
      return Object.values(products).find((_product) => {
        return _product.legacyResourceId === id;
      });
    },
});

export default {
  // States
  rechargeLoaded,
  rechargeCustomer,
  rechargeSubscriptions,
  rechargeOnetimes,
  rechargeCharges,
  rechargeAddresses,
  rechargePayments,
  rechargeRetentionStrategies,
  rechargeProducts,
  rechargeModal,
  editSubscription,

  // Selectors
  activeSubscriptions,
  cancelledSubscriptions,
  addressById,
  chargeBySubscriptionId,
  paymentById,
  onetimesByCharge,
  productById,
};
