import { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { useCountries } from '@hooks';
import { StyledButton } from '@snippets';

import { AddressFormFields } from './AddressFormFields';

export const AddressForm = ({
  title,
  address,
  isDefault,
  action,
  onSuccess,
  isCreateAddressForm = false,
  ...props
}) => {
  const { countryNames, countryNamesData } = useCountries({
    firstCountries: [
      'United States',
      'Canada',
      'United Kingdom',
      'United Arab Emirates',
    ],
  });
  const { errors, loading } = useCustomer();
  const [mounted, setMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDefaultAddress, setIsDefaultAddress] = useState(isDefault || false);
  const [selectedCountryIndex, setSelectedCountryIndex] = useState(null);
  const [provinceNames, setProvinceNames] = useState([]);
  const [addressForm, setAddressForm] = useState({
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    city: address?.city || '',
    province: address?.province || '',
    country: address?.country || 'United States',
    zip: address?.zip || '',
    phone: address?.phone || '',
  });

  const clearForm = () => {
    setAddressForm({
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      province: '',
      country: '',
      zip: '',
      phone: '',
    });
    setIsDefaultAddress(false);
  };

  useEffect(() => {
    const hasErrors = errors?.addressCreate?.length;
    const hasFinished = !loading?.addressCreate;

    if (isLoading && hasFinished) {
      setTimeout(() => {
        setIsLoading(false);

        if (!hasErrors) {
          clearForm();
          onSuccess();
        }
      }, 1500);
    }
  }, [loading, errors]);

  useEffect(() => {
    if (!countryNames.length) return;
    let countryIndex = 0;
    countryNames.find((item, index) => {
      if (
        item === address?.country ||
        (isCreateAddressForm && item === 'United States')
      ) {
        countryIndex = index;
        return true;
      }
      return false;
    });
    setSelectedCountryIndex(countryIndex);
  }, [address, countryNames]);

  useEffect(() => {
    if (selectedCountryIndex === null) return;
    const country = countryNamesData[selectedCountryIndex];
    let provinces = [];
    country.countryName === 'United Kingdom'
      ? (provinces = ukProvinces)
      : (provinces = country?.regions?.map((item) => item.name));
    if (mounted || !addressForm.province) {
      setAddressForm({
        ...addressForm,
        province: !provinces || provinces.length < 1 ? null : provinces[0],
      });
    }
    setProvinceNames(provinces || []);
    setMounted(true);
  }, [selectedCountryIndex]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!addressForm.country || !addressForm.province) return;

      setIsLoading(true);

      action({
        id: isCreateAddressForm ? null : address?.id,
        isDefault: isDefaultAddress,
        address: addressForm,
      });
    },
    [addressForm, isDefaultAddress, address]
  );

  return (
    <Box
      data-comp="AddressForm"
      as="form"
      onSubmit={handleSubmit}
      {...props}
      sx={{
        width: '100%',
      }}
    >
      <AddressFormFields
        selectedCountryIndex={selectedCountryIndex}
        setSelectedCountryIndex={setSelectedCountryIndex}
        addressForm={addressForm}
        setAddressForm={setAddressForm}
        provinceNames={provinceNames}
        countryNames={countryNames}
      />

      <Flex variant="flex.row.start.center" sx={{ mt: 6 }}>
        <input
          type="checkbox"
          name="default"
          label="Make Default"
          onClick={() => setIsDefaultAddress(!isDefaultAddress)}
          defaultChecked={isDefault}
          sx={{
            fontSize: '16px',
          }}
        />

        <Paragraph variant="base" sx={{ ml: 2 }}>
          Set as default address
        </Paragraph>
      </Flex>

      <Flex variant="flex.column.start" sx={{ mt: 9 }}>
        <StyledButton text="Save" variant="solid" isLoading={isLoading} />
      </Flex>
    </Box>
  );
};

const ukProvinces = [
  'British Forces',
  'England',
  'Northern Ireland',
  'Scotland',
  'Wales',
];
