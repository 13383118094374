import account from './account';
import analytics from './analytics';
import cart from './cart';
import footer from './footer';
import header from './header';
import noindex from './noindex';
import products from './products';
import social from './social';

export default [
  account,
  analytics,
  cart,
  footer,
  header,
  noindex,
  products,
  social,
];
