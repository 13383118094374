import { useEffect } from 'react';
import { Flex, Paragraph } from 'theme-ui';

import { Dropdown } from '@snippets';
import store, {
  useSetRecoilState,
  useRecoilValue,
  useRecoilState,
} from '@store';

import { ProductSellingPlanRadio } from './ProductSellingPlanRadio';

export function ProductSellingPlanSubscribe({
  isSelected,
  isSubscribe,
  plan,
  selectedVariant,
  isSingleBottlePdp,
}) {
  const price = plan?.pricing?.price?.amount;

  const [activeQuantityPriceSubscription, setActiveQuantityPriceSubscription] =
    useRecoilState(
      store.activeQuantityPriceSubscription(selectedVariant?.product?.handle)
    );

  const activeQuantity = useRecoilValue(
    store.activeQuantity(selectedVariant?.product?.handle)
  );

  useEffect(() => {
    const _price = price * activeQuantity;
    setActiveQuantityPriceSubscription(_price.toFixed(2));
  }, [activeQuantity, price]);

  const setSelectedFreq = useSetRecoilState(
    store.selectedFreq(selectedVariant.product.handle)
  );

  const updateSubscriptionFreq = (optionId) => {
    const selectedFreq = plan.options.find((option) => option.id === optionId);
    setSelectedFreq(selectedFreq);
  };

  if (!selectedVariant?.product?.handle) return null;

  return (
    <Flex
      variant="flex.row.start"
      data-comp={ProductSellingPlanSubscribe.displayName}
      key={plan.type}
      sx={{
        mb: 5,
      }}
    >
      <ProductSellingPlanRadio
        selectedVariant={selectedVariant}
        isSelected={isSelected}
        plan={plan}
        tabIndex={0}
      />

      <Flex variant="flex.column" sx={{ ml: 6 }}>
        {/* Heading */}
        <Flex variant="flex.row.start.center" sx={{ color: 'white' }}>
          <Paragraph variant="label.3" sx={{ mt: 1 }}>
            {plan.message}
          </Paragraph>

          {isSingleBottlePdp && activeQuantityPriceSubscription && (
            <Paragraph sx={{ ml: 4 }}>
              ${activeQuantityPriceSubscription}
            </Paragraph>
          )}

          {!isSingleBottlePdp && price && (
            <Paragraph sx={{ ml: 4 }}>${price}</Paragraph>
          )}

          {isSelected ? (
            <Paragraph
              sx={{
                fontSize: 1,
                color: 'gray.4',
                ml: 4,
              }}
            >
              {plan.save}
            </Paragraph>
          ) : null}
        </Flex>

        {/* subscription options: 14 day(s), 30 day(s) ... */}
        {isSelected && plan.options.length && (
          <Dropdown
            onClick={updateSubscriptionFreq}
            title="DELIVERY EVERY"
            items={plan.options}
            initialIndex={1}
            tabIndex={0}
            sx={{
              height: '100%',
              mt: 4,
              mb: [5, null, null, null],
              zIndex: 15,
            }}
          />
        )}

        {/* footnote */}
        {isSelected && isSubscribe ? (
          <Paragraph sx={{ fontSize: 1, color: 'gray.4' }}>
            ✔ No commitment, pause or cancel anytime
          </Paragraph>
        ) : null}
      </Flex>
    </Flex>
  );
}

ProductSellingPlanSubscribe.displayName = 'ProductSellingPlanSubscribe';
