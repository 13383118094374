import React from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';
import { LinkWrapper } from '@snippets';

export const ImageButton = ({
  text = '',
  href = '#',
  variant = 'primary',
  mobileVariant = 'primary',
  newTab = false,
  sx,
  ...props
}) => {
  return (
    <LinkWrapper aria-label={`Go to ${text} page.`} href={href} newTab={newTab}>
      <ButtonComp
        text={text}
        variant={variant}
        mobileVariant={mobileVariant}
        sx={sx}
        {...props}
      />
    </LinkWrapper>
  );
};

const ButtonComp = React.forwardRef(
  ({ text = '', variant, mobileVariant, sx, ...props }, ref) => {
    const width = 188;

    return (
      <Flex
        data-comp="ImageButton"
        variant="flex.row.center.center"
        ref={ref}
        sx={{
          width: [width + 'px'],
          height: '44px',
          cursor: 'pointer',
          position: 'relative',
          px: 9,
          py: '13px',
          ...sx,
        }}
        {...props}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            'svg, svg path': {
              transition: 'all .16s ease-in-out',
            },
            '&:hover .svg-gradient path': {
              stroke: 'url(#linear)',
              opacity: 1,
            },
            '&:hover': {
              'svg path': {
                d: 'path("M 1 43 V 13 L 17 1 H 187 V 32 L 171 43 H 1 Z")',
              },
              '.svg-gradient': {
                opacity: 1,
              },
            },
            ...sx,
          }}
        >
          <Flex
            variant="flex.row.center.center"
            className="svg-solid"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            <svg
              width="100%"
              height="100%"
              fill="none"
              viewBox={`0 0 ${width} 44`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d={pathD} stroke="#cab683" strokeWidth="2" />
            </svg>
          </Flex>
          <Flex
            variant="flex.row.center.center"
            className="svg-gradient"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              opacity: 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <svg
              width="100%"
              height="100%"
              fill="none"
              viewBox={`0 0 ${width} 44`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="linear"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="-12%"
                  gradientTransform="rotate(20)"
                >
                  <stop offset="0%" stopColor="#CDAF62" />
                  <stop offset="50%" stopColor="#f7f1e0" />
                  <stop offset="90%" stopColor="#B58D28" />
                </linearGradient>
              </defs>
              <path d={pathD} stroke="url(#linear)" strokeWidth="2" />
            </svg>
          </Flex>
        </Box>
        <Paragraph sx={textSx(variant)}>{text}</Paragraph>
      </Flex>
    );
  }
);

const pathD = 'M 1 43 V 13 L 1 1 H 187 V 32 L 187 43 H 1 Z';

const transition = 'all 0.25s ease-in-out';

const textSx = (variant) => ({
  fontFamily: 'heading',
  cursor: 'pointer',
  textAlign: 'center',
  textTransform: 'uppercase',
  borderRadius: 0,
  p: 0,
  bg: 'transparent',
  width: '100%',
  height: '100%',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 600,
  letterSpacing: 0.08,
  color: variant === 'primary' ? 'gold' : 'gray.8',
  transition,
  pt: 1,
});
