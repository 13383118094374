import { useCallback, useMemo } from 'react';

import { Dropdown } from '@snippets';
import store, { useSetRecoilState } from '@store';

export function ProductDropdownGiftCards({ product }) {
  const options = useMemo(
    () =>
      product.variants.map((variant) => {
        return {
          label: variant.title.replace('.00', ''),
          value: variant.id,
        };
      }),
    [product?.handle]
  );

  const setSelectedVariant = useSetRecoilState(
    store.selectedVariant(product.handle)
  );

  const setGiftCardVariantAsSelected = useCallback(
    (variantId) => {
      console.log('Setting Gift Card Variant as Selected...');
      const variant = product.variants.find(
        (variant) => variant.id === variantId
      );
      if (!variant?.id) return;
      setSelectedVariant(variant);
    },
    [product?.variants, product?.handle]
  );

  return (
    <Dropdown
      data-comp="ProductDropdownGiftCards"
      id="product-giftcard-dropdown"
      onClick={setGiftCardVariantAsSelected}
      title="Pick your amount"
      items={options}
      initialIndex={0}
      sx={{
        zIndex: 19,
        mb: [8, null, null, 11],
      }}
    />
  );
}
