import { Box, Flex, Image } from 'theme-ui';
import { LinkWrapper, BackpackImage } from '@snippets';

export function InstagramBlockItem({
  image,
  index,
  sx,
  isSecond = false,
  ...props
}) {
  return (
    <Box
      variant="flex.row.center.center"
      sx={{
        width: [
          'calc(50% - 6px)',
          null,
          null,
          'calc(50% - 10px)',
          'calc(50% - 15px)',
        ],
        pb: [
          'calc(50% - 6px)',
          null,
          null,
          'calc(50% - 10px)',
          'calc(50% - 15px)',
        ],
        bg: 'ghostwhite',
        borderRadius: '6px',
        overflow: 'hidden',
        position: 'relative',
        transition: 'all 0.25s ease-in-out',
        transitionDelay: `${index * 0.1}s`,
        ...sx,
        '&:hover .instagram-overlay': {
          visibility: 'visible',
          opacity: 1,
        },
        '&:hover .instagram-image': {
          transform: 'scale(1.03)',
        },
        '@media only screen and (min-width: 1024px)': {
          transitionDelay: isSecond
            ? `${(index + 2) * 0.1}s`
            : `${index * 0.1}s`,
        },
      }}
      {...props}
    >
      <BackpackImage
        className="instagram-image"
        src={image?.image?.src || image?.image}
        alt={image?.cta?.text}
        sx={{
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          transform: 'scale(1.01)',
          transition: 'transform 0.25s ease-in-out',
          position: 'absolute',
        }}
      />
      <Flex
        variant="flex.column.center.center"
        className="instagram-overlay"
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          bg: 'rgba(0,0,0,0.7)',
          transition: 'all 0.25s ease-in-out',
          opacity: 0,
          p: 6,
        }}
      >
        {!image?.hideInsta && (
          <LinkWrapper href={image?.instagramUrl?.trim()} newTab>
            <Image src="/svg/instagram.svg" />
          </LinkWrapper>
        )}
        {image?.cta?.text && (
          <Box mt={5}>
            <LinkWrapper
              href={image?.cta?.url}
              newTab={image?.cta?.newTab}
              variant="buttons.underlineLink"
              sx={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              {image?.cta?.text}
            </LinkWrapper>
          </Box>
        )}
      </Flex>
    </Box>
  );
}
