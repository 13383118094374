import { store } from '@backpackjs/storefront';
import effects from './effects';
import variantStore from './SelectedVariant';

const sellingPlans = store.recoil.selectorFamily({
  key: 'sellingPlan/sellingPlans',
  get:
    ({ product, handle }) =>
    ({ get }) => {
      const _product = product || get(store.state.product);

      const _selectedVariant =
        get(variantStore.selectedVariant(handle)) ||
        _product?.variants.find((variant) => variant.product.handle === handle);

      // get the selling plan for the selected product (grouped or not)
      // note: groupSellingPlanGroups is generated via the custom build transform 'grouping-selling-plan-groups'
      const _sellingPlans = _product?.sellingPlanGroups?.[0]?.sellingPlans;

      const mapPlans = (plan) => ({
        id: plan.id,
        value: plan.id,
        label: plan.options[0].value,
        option: plan.name,
        discount:
          plan.priceAdjustments?.[0]?.adjustmentValue?.adjustmentPercentage,
        discountType: '%',
      });

      const lessToHigherFreq = (planA, planB) => {
        if (planA.label < planB.label) {
          return -1;
        }

        if (planA.label > planB.label) {
          return 1;
        }

        return 0;
      };

      const sellingPlans = _sellingPlans?.length
        ? [..._sellingPlans].map(mapPlans).sort(lessToHigherFreq)
        : null;

      const subscriptionPlan = {
        type: 'subscribe',
        message: 'SUBSCRIBE & SAVE',
        save: sellingPlans?.length
          ? `Save ${sellingPlans[0].discount}${sellingPlans[0].discountType}`
          : '',
        pricing: _selectedVariant
          ? _selectedVariant?.sellingPlanAllocations?.[0]?.priceAdjustments?.[0]
          : null,
        options: sellingPlans,
      };

      const oneTimePlan = {
        type: 'onetime',
        message: 'ONE-TIME PURCHASE',
        save: null,
        pricing: _selectedVariant
          ? {
              price: _selectedVariant?.priceV2,
              compareAtPrice: _selectedVariant?.compareAtPriceV2,
            }
          : null,
        options: [],
      };

      if (!sellingPlans) {
        return [];
      }

      // subscription only
      if (_product.requiresSellingPlans) {
        return [subscriptionPlan];
      }

      return [subscriptionPlan, oneTimePlan];
    },
});

// onetime plan or subscribe plan
const selectedPlan = store.recoil.atomFamily({
  key: 'sellingPlan/selectedPlan',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('selectedPlan'),
    effects.onChange({
      key: 'selectedPlan',
      topic: 'SELLING_PLAN_UPDATE',
      actions: ['publishGtmEvent', 'publishBusEvent'],
    }),
  ],
});

const selectedFreq = store.recoil.atomFamily({
  key: 'sellingPlan/selectedFreq',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('selectedFreq'),
    effects.onChange({
      key: 'selectedFreq',
      topic: 'SUBSCRIPTION_PLAN_UPDATE',
      actions: ['publishGtmEvent', 'publishBusEvent'],
    }),
  ],
});

export default {
  sellingPlans,
  selectedPlan,
  selectedFreq,
};
