import { useState, useEffect, useCallback } from "react";
import { Paragraph, Flex, Button, Image } from "theme-ui";
import PropTypes from "prop-types";
import { useCartRemoveItem, useCartUpdateItem } from "@backpackjs/storefront";

import store, { useRecoilValue } from "@store";

export function QuantitySelect({
  id,
  quantity,
  setIsDeleting,
  isGiftWrap,
  isBundleProduct = false,
  ...props
}) {
  const isGiftWithPurchaseLine = useRecoilValue(
    store.isGiftWithPurchaseLine$(id)
  );
  const { cartUpdateItem, ...updateStatus } = useCartUpdateItem({
    autoReset: 100,
  });
  const { cartRemoveItem, ...removeStatus } = useCartRemoveItem({
    autoReset: 100,
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const disabled = !id || isUpdating;

  const handleDecrement = useCallback(async () => {
    try {
      if (disabled) return;
      const prevQuantity = quantity - 1;
      if (prevQuantity) {
        setIsUpdating(true);
        await cartUpdateItem({ lineId: id, quantity: prevQuantity });
        setIsUpdating(false);
      } else {
        setIsDeleting(true);
        await cartRemoveItem({ lineId: id });
      }
    } catch (error) {
      setIsUpdating(false);
      setIsDeleting(false);
      console.error(error.message);
    }
  }, [id, quantity, disabled]);

  const handleIncrement = useCallback(async () => {
    try {
      if (disabled) return;
      setIsUpdating(true);
      await cartUpdateItem({ lineId: id, quantity: quantity + 1 });
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.error(error.message);
    }
  }, [id, quantity, disabled]);

  return (
    <Flex
      data-comp="QuantitySelect"
      variant="flex.row.center.center"
      sx={{
        width: "90px",
        height: "28px",
        border: "1px solid",
        borderColor: "gold",
      }}
      {...props}
    >
      {!isBundleProduct && (
        <Button
          sx={{
            flex: 1,
            pl: 4,
            pb: 3,
            userSelect: "none",
            display: isGiftWrap || isGiftWithPurchaseLine ? "none" : "block",
          }}
          disabled={disabled}
          onClick={handleDecrement}
        >
          <Image draggable="false" src="/svg/minus-gold.svg" />
        </Button>
      )}

      <Paragraph
        variant="label.2"
        sx={{
          flex: 2,
          textAlign: "center",
          color: "gold",
          pt: 1,
        }}
      >
        {quantity}
      </Paragraph>
      {!isBundleProduct && (
        <Button
          sx={{
            flex: 1,
            pr: 4,
            pt: 1,
            userSelect: "none",
            display: isGiftWrap || isGiftWithPurchaseLine ? "none" : "block",
          }}
          disabled={disabled}
          onClick={handleIncrement}
        >
          <Image src="/svg/plus-gold.svg" draggable="false" />
        </Button>
      )}
    </Flex>
  );
}

QuantitySelect.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
