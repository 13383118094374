import React from 'react';
import { Box, Flex, Heading } from 'theme-ui';
import { ImageButton, UnderlineLink } from '@snippets';

export const PrimaryHeroContent = ({
  textSettings,
  secondaryButtonSettings,
  primaryButtonSettings,
  isMobile = false,
}) => {
  const showPrimaryButton =
    primaryButtonSettings?.cta?.text && !primaryButtonSettings.hideButton;
  const showSecondaryButton =
    secondaryButtonSettings?.cta?.text && !secondaryButtonSettings.hideButton;

  return (
    <Flex
      variant="flex.column.center.center"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      }}
    >
      <Flex
        data-comp="PrimaryHeroContent"
        variant="flex.column.center.center"
        sx={{
          p: [10, 12, null, null, 13, 15],
          maxWidth: ['100%', null, null, '880px'],
        }}
      >
        <Heading
          as="h1"
          variant={textSettings?.textSize || '48.r'}
          sx={{
            color: isMobile
              ? textSettings?.mobileTextColor
              : textSettings?.textColor || 'white',
            textAlign: 'center',
            mb:
              showPrimaryButton || showSecondaryButton
                ? [8, null, null, 11]
                : 0,
          }}
        >
          {textSettings?.heading}
        </Heading>

        <Flex variant="flex.row.center.center" sx={{ flexWrap: 'wrap' }}>
          {showPrimaryButton && (
            <Box sx={{ mx: 6, my: 5 }}>
              <ImageButton
                text={primaryButtonSettings?.cta?.text}
                href={primaryButtonSettings?.cta?.url}
                newTab={primaryButtonSettings?.cta?.newTab}
              />
            </Box>
          )}
          {showSecondaryButton && (
            <UnderlineLink
              text={secondaryButtonSettings?.cta?.text}
              href={secondaryButtonSettings?.cta?.url}
              variant={secondaryButtonSettings?.buttonStyle}
              mobileVariant={secondaryButtonSettings?.mobileButtonStyle}
              newTab={secondaryButtonSettings?.cta?.newTab}
              sx={{
                mx: 6,
                my: 5,
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
