import { useCallback, useState } from 'react';
import React from 'react';
import { Flex, Box } from 'theme-ui';
import { AddToCart, StyledButton } from '@snippets';
import store, { useRecoilValue } from '@store';
import { useBodyScrollLock } from '@hooks';

export function ProductItemGiftCards({
  selectedVariant,
  setSelectedVariant,
  productItem = null,
  quantity = 1,
  isCollectionPage = null,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { lockScroll, unlockScroll } = useBodyScrollLock();

  const selectedPlan = useRecoilValue(
    store.selectedPlan(selectedVariant?.product?.handle)
  );
  const isSubscriptionPlan = selectedPlan?.type === 'subscribe';
  const isSoldOut = !selectedVariant?.availableForSale;
  const addToCartText = isSoldOut
    ? 'Sold Out'
    : isSubscriptionPlan
    ? 'Subscribe'
    : 'Add to Bag';

  const options = productItem?.variants?.map((variant) => {
    return {
      label: variant.title.replace('.00', ''),
      value: variant.id,
    };
  });

  const setGiftCardVariantAsSelected = useCallback(
    (variantId) => {
      console.log('Setting Gift Card Variant as Selected...');
      const variant = productItem.variants.find(
        (variant) => variant.id === variantId
      );
      if (!variant?.id) return;
      setSelectedVariant(variant);
    },
    [productItem?.variants, productItem?.handle]
  );

  const stopProp = (e) => {
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (isOpen && window.innerWidth < 1024) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isOpen]);

  return (
    <>
      <StyledButton
        data-comp="OpenModal"
        variant="solid"
        disable={isSoldOut}
        text={addToCartText}
        disableClipping={false}
        onClick={() => setIsOpen(true)}
        sx={{
          display: [null, null, null, null, 'none'],
          height: ['30px', null, null, null, 'auto'],
          minWidth: '0',
          lineHeight: 'normal',
        }}
      />
      <Box
        sx={{
          display: isOpen ? ['flex', null, null, null, 'none'] : 'none',
          height: isOpen ? null : '0',
          overflow: isOpen ? null : 'hidden',
          opacity: isOpen ? null : '0',
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(26, 26, 26, 0.6)',
            zIndex: '21',
          }}
          onClick={() => setIsOpen(false)}
        >
          <Flex
            onClick={stopProp}
            sx={{
              flexDirection: 'column',
              gap: '16px',
              padding: '20px 12px 30px',
              margin: '0',
              position: 'absolute',
              zIndex: '1',
              bottom: '0',
              left: '0',
              width: '100%',
              backgroundColor: '#000',
              color: '#fff',
            }}
          >
            <Box
              sx={{
                width: '12px',
                height: '12px',
                position: 'relative',
                marginLeft: 'auto',
                cursor: 'pointer',
                '&:before, &:after': {
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#cab683',
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  transformOrigin: 'center',
                },
                '&:before': {
                  transform: 'rotate(45deg)',
                },
                '&:after': {
                  transform: 'rotate(-45deg)',
                },
              }}
              onClick={() => setIsOpen(false)}
            />
            <Flex
              sx={{
                display: ['flex', null, null, null, 'none'],
                gap: '14px',
                alignItems: 'flex-end',
              }}
            >
              <Box
                sx={{
                  fontFamily: 'heading',
                  fontSize: '18px',
                  lineHeight: '1.22',
                  letterSpacing: '0.02em',
                  textTransform: 'uppercase',
                }}
              >
                Select Your Amount
              </Box>
              <Box
                sx={{
                  height: '1px',
                  flexGrow: '1',
                  flexShrink: '1',
                  backgroundColor: 'currentColor',
                }}
              />
            </Flex>
            <Flex
              sx={{
                gap: '16px',
                rowGap: '20px',
                flexWrap: 'wrap',
              }}
            >
              {options?.map((item, index, arr) => {
                return (
                  <Box
                    key={item.value}
                    sx={{
                      Family: 'heading',
                      fontSize: '15px',
                      lineHeight: '1.47',
                      letterSpacing: '0.02em',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      padding: '8px 12px',
                      cursor: 'pointer',
                      width: 'calc(50% - 8px)',
                      backgroundColor:
                        selectedVariant?.id === item.value ? '#1a1a1a' : '#333',
                      color:
                        selectedVariant?.id === item.value
                          ? '#cab683'
                          : '#6f6f70',
                      border:
                        selectedVariant?.id === item.value
                          ? '1px solid currentColor'
                          : '1px solid transparent',
                    }}
                    onClick={() => {
                      setGiftCardVariantAsSelected(item.value);
                    }}
                  >
                    {item.label}
                  </Box>
                );
              })}
            </Flex>
            <AddToCart
              selectedVariant={selectedVariant}
              quantity={quantity}
              isPdp
              isCollectionPage={isCollectionPage}
              isCollectionModal={true}
              setIsOpen={setIsOpen}
            />
          </Flex>
        </Box>
      </Box>
      <Flex
        sx={{
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: '10px',
          paddingBottom: options.length ? '10px' : '',
          display: ['none', null, null, null, 'flex'],
        }}
      >
        {options?.map((item, index, arr) => {
          return (
            <Box
              key={item.value}
              sx={{
                fontFamily: 'heading',
                fontSize: '15px',
                lineHeight: '1.47',
                letterSpacing: '0.02em',
                textTransform: 'uppercase',
                textAlign: 'center',
                padding: '8px 8px',
                cursor: 'pointer',
                minWidth: '50px',
                backgroundColor:
                  selectedVariant?.id === item.value ? '#1a1a1a' : '#f2f3f4',
                color:
                  selectedVariant?.id === item.value ? '#cab683' : '#d7d9db',
                border:
                  selectedVariant?.id === item.value
                    ? '1px solid currentColor'
                    : '1px solid transparent',
                flexGrow: '1',
              }}
              onClick={() => {
                setGiftCardVariantAsSelected(item.value);
              }}
            >
              {item.label}
            </Box>
          );
        })}
      </Flex>
    </>
  );
}
