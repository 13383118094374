import { useEffect, useState } from "react";
import { Box, Flex } from "theme-ui";

import { useInitSelectedVariant } from "@hooks";
import { fetchProductsFromHandles } from "@utils";
import { useInView } from "react-intersection-observer";
import store, { useRecoilState, useSetRecoilState } from "@store";
import { ProductDetails } from "./ProductDetails";
import { ProductImage } from "./ProductImage";
import { ProductImageCarousel } from "./ProductImageCarousel";

/**
 * Todo:
 *  - Remove add to cart if GWP handle matches product.handle so that they can't add a free product
 *  - Wait for chiv to clone GWP and make it $0
 *  - Selling plans SSR (tyler)
 */
export function ProductMain({
  product,
  inQuickAdd = false,
  whatsIncluded,
  passedSelectedVariant,
  isSubscriptionOnly = false,
  ...props
}) {
  const selectedVariantFromInit = useInitSelectedVariant();
  const selectedVariant = passedSelectedVariant || selectedVariantFromInit;
  const productMedia = product?.media?.filter((media) => {
    return media.product.handle === product.handle;
  });
  if (productMedia?.length > 1) {
    productMedia.shift();
  }
  const featuredMediaSrc =
    selectedVariant?.image?.originalSrc || product?.images?.[0]?.originalSrc;
  const featuredMediaIndex = productMedia?.findIndex((media) => {
    return (
      media.image?.originalSrc?.split("?")[0] ===
      featuredMediaSrc?.split("?")[0]
    );
  });
  const { ref, inView, entry } = useInView({ threshold: 0 });
  const [buyboxInView, setBuyboxInView] = useRecoilState(store.buyboxInView);

  const [groupingProducts, setGroupingProducts] = useState([]);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      let products = await fetchProductsFromHandles(
        product?.grouping?.products
      );

      products = products?.map((product) => {
        const variant = product.variants[0];
        return {
          ...product,
          label: variant.product.title,
          value: variant.product.handle,
          image:
            product.media.find(
              (media) => media.product.handle === variant.product.handle
            )?.image || null,
          variant,
        };
      });

      const _selectedProductIndex =
        products?.findIndex(
          (gProduct) => gProduct?.handle === selectedVariant?.product?.handle
        ) || 0;
      setGroupingProducts(products);
      if (_selectedProductIndex >= 0) {
        setSelectedProductIndex(_selectedProductIndex);
      }
    };
    fetchProducts();
  }, [product?.id, selectedVariant]);

  useEffect(() => {
    if (entry) {
      setBuyboxInView(entry.isIntersecting);
    } else {
      setBuyboxInView(null);
    }
  }, [inView]);

  return (
    <Flex
      data-comp="ProductMain"
      variant="flex.section"
      sx={{ overflow: "visible", bg: "black" }}
      ref={ref}
      {...props}
    >
      {/* Image */}
      <Flex
        sx={{
          bg: "black",
          width: "100%",
          // maxWidth: "1440px",
          flexDirection: ["column", null, null, "row"],
          // alignItems: 'stretch',
        }}
      >
        <Flex
          sx={{
            width: ["100%", null, null, "50%"],
            flex: "1",
            flexDirection: "row",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 18,
              width: "100%",
            }}
          >
            {productMedia?.length > 1 ? (
              <ProductImageCarousel
                productMedia={productMedia}
                featuredMediaIndex={featuredMediaIndex}
              />
            ) : (
              <ProductImage
                src={
                  selectedVariant?.image?.originalSrc ||
                  product?.images?.[0]?.originalSrc
                }
                alt={selectedVariant?.image?.altText}
                sx={{ height: "100%" }}
              />
            )}
          </Box>
        </Flex>

        {/* Details */}
        {selectedVariant ? (
          <ProductDetails
            groupingProducts={groupingProducts}
            product={product}
            whatsIncluded={whatsIncluded}
            selectedProductIndex={selectedProductIndex}
            selectedVariant={selectedVariant}
            inQuickAdd={inQuickAdd}
            isSubscriptionOnly={isSubscriptionOnly}
            sx={{ bg: "black", flex: "1" }}
          />
        ) : null}
      </Flex>
    </Flex>
  );
}
