import { useMemo } from 'react';

import { Button, Spinner } from 'theme-ui';
import { LinkWrapper } from '@snippets';

export function StyledButton({
  isStateful = false,
  statefulText = {
    started: 'LOADING',
    success: 'DONE',
    error: 'FAILED',
    default: 'SUBMIT',
  },
  started,
  success,
  finished,
  loading,
  errors,
  reset,
  resetTimer,
  showErrors = true,
  autoReset = true,
  resetAfter = 2000,

  text = '',
  href,
  children,
  newTab = false,
  variant = 'outline',
  width = '100%',
  innerColor = 'white',
  disable: disableProp = false,
  isLoading = false,
  swellCampaignProps = {},
  disableClipping,
  sx,
  ...props
}) {
  const resetTimerRef = resetTimer;

  const statefulButtonText = useMemo(() => {
    if (finished && autoReset) {
      resetTimerRef.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimerRef.current);
      }, resetAfter);
    }

    return finished
      ? success
        ? statefulText.success
        : statefulText.error
      : // not finished yet
      started
      ? statefulText.started
      : statefulText.default;
  }, [started, success, finished, Object.values(text).join(',')]);

  const disable = disableProp || isLoading;
  const buttonText = isStateful ? statefulButtonText : text;

  return (
    <LinkWrapper
      data-comp='StyledButton'
      href={href}
      newTab={newTab}
      tabIndex={-1}
      {...props}
      sx={{
        display: 'flex',
        ...buttonSx({
          isAnimated: variant === 'animated',
          width,
          disable,
          disableClipping,
        }),
        ...props.sx,
      }}>
      <Button
        sx={textSx({ variant, innerColor, disable })}
        data-comp='InnerStyledButton'
        tabIndex={0}
        {...swellCampaignProps}>
        {isLoading || started ? (
          <Spinner
            sx={{
              height: '25px',
              color: 'black',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          children || buttonText
        )}
      </Button>

      {isStateful && showErrors && errors?.length
        ? errors.map((err, index) => (
            <Alert key={err} variant='alerts.error' mt={6}>
              {err}
            </Alert>
          ))
        : null}
    </LinkWrapper>
  );
}

const transition = 'all 0.2s ease-in-out';

const buttonSx = ({ isAnimated, width, disable, disableClipping }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  width,
  minWidth: '188px',
  height: '44px',
  border: `2px solid '#cab683'`,
  backgroundColor: 'rgba(202,182,131,1)',
  backgroundImage: 'linear-gradient(#cab683, #cab683)',
  cursor: disable ? 'default' : 'pointer',
  borderRadius: 0,
  position: 'relative',
  overflow: 'hidden',
  zIndex: 10,
  '@keyframes shine': {
    '0%': { transform: 'translateX(0)' },
    '25%': { transform: 'translateX(25%)' },
    '100%': { transform: 'translateX(100%)' },
  },
  '@keyframes glow': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  clipPath: `polygon(
    0% 0px,
    0px 0%,
    100% 0%,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    0% 100%
  )`,
  transition,
  '&:before': {
    backgroundColor: isAnimated ? 'transparent' : '#B58D28',
    backgroundImage: isAnimated
      ? `linear-gradient(
        100deg,
        rgba(202,182,131,0) 33.33%,
        rgba(255,255,255,0.25) 50%,
        rgba(202,182,131,0) 66.66%
      )`
      : `linear-gradient(
          135deg,
          #CDAF62 0.33%,
          #EEDBAB 18%,
          #B58D28 50%
        )`,
    width: isAnimated ? '300%' : '200%',
    height: '100%',
    content: '""',
    position: 'absolute',
    top: 0,
    left: isAnimated ? '-200%' : 0,
    zIndex: -100,
    opacity: isAnimated ? 1 : 0,
    transform: isAnimated ? null : 'translateX(-50%)',
    transition: 'all 0.23s ease-in-out',
    animation: isAnimated
      ? 'shine 1.3s cubic-bezier(.52,.02,.31,.99) infinite'
      : null,
  },
  '&:after': {
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(100deg, rgba(205,175,98,1) 10%, rgba(255,255,255,0.4) 40%, rgba(181,141,40,1) 90%)',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -110,
    display: isAnimated ? 'block' : 'none',
    animation: isAnimated
      ? 'glow 1.3s cubic-bezier(.52,.02,.31,.99) infinite'
      : null,
  },
  '&:hover': {
    '&:before': {
      opacity: disable ? 0 : 1,
      transform: disable || isAnimated ? null : 'translateX(0)',
    },
    clipPath:
      disable || disableClipping
        ? null
        : `polygon(
          0% 15px,
          15px 0%,
          100% 0%,
          100% calc(100% - 15px),
          calc(100% - 15px) 100%,
          0% 100%
        )`,
    '[data-comp="InnerStyledButton"]': {
      clipPath:
        disable || disableClipping
          ? null
          : `polygon(
            0% 14px,
            14px 0%,
            100% 0%,
            100% calc(100% - 14px),
            calc(100% - 14px) 100%,
            0% 100%
          )`,
    },
  },
});

const textSx = ({ variant, innerColor, disable }) => ({
  p: 0,
  borderRadius: 0,
  width: '100%',
  height: '100%',
  lineHeight: 'normal',
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'heading',
  letterSpacing: 0.08,
  textAlign: 'center',
  textTransform: 'uppercase',
  color: variant === 'outline' ? 'gold' : 'gray.8',
  bg: variant === 'outline' ? innerColor : 'transparent',
  clipPath: `polygon(
    0% 0px,
    0px 0%,
    100% 0%,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    0% 100%
  )`,
  transition,
  cursor: disable ? 'default' : 'pointer',
  pt: '0.15em',
});
