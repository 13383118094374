import { useState, useEffect, useCallback } from "react";
import { Flex, Box, Paragraph, Button } from "theme-ui";
import { useCartAddItems } from "@backpackjs/storefront";
import { generateId } from "@utils/generateId";
import store, { useSetRecoilState, useRecoilState } from "@store";
import { countValuesByKey } from "@utils/arrayUtils";

import { Svg } from "@snippets/Svg/Svg.jsx";

function BundleBox({
  bundleProducts,
  setBundleProducts,
  handleShowCloseButton,
  handleHideCloseButton,
  handleRemoveFromBundle,
}) {
  const { cartAddItems, ...addItemStatus } = useCartAddItems();
  const [bundleBoxOpen, setBundleBoxOpen] = useState(true);
  const [cartDrawer, setCartDrawer] = useRecoilState(store.cartDrawer);

  // const setCartDrawer = useSetRecoilState(store.cartDrawer);
  const setOverlay = useSetRecoilState(store.overlay);

  const toggleCart = useCallback(() => {
    setCartDrawer(true);
    const t0 = requestTimeout(() => {
      setOverlay(true);
      clearRequestTimeout(t0);
    }, 175);
  }, []);

  let bundleProductsTotalBeforeDiscount = bundleProducts?.reduce(
    (acc, curr) => {
      const priceMin = parseFloat(curr?.priceRange?.min).toFixed(2);
      // console.log('priceMin', priceMin)
      return acc + parseFloat(priceMin);
    },
    0
  );

  bundleProductsTotalBeforeDiscount = parseFloat(
    bundleProductsTotalBeforeDiscount
  ).toFixed(2);

  let bundleProductsTotalAfterDiscount;
  let savingsAmount = 0;
  let bundleBoxTitleText = "Your Truff Bundle";

  if (bundleProducts.length === 1) {
    bundleProductsTotalAfterDiscount = bundleProductsTotalBeforeDiscount;
  } else if (bundleProducts.length === 2) {
    bundleBoxTitleText = "10% Off Unlocked";
    bundleProductsTotalAfterDiscount = bundleProductsTotalBeforeDiscount * 0.9;
  } else if (bundleProducts.length === 3) {
    bundleBoxTitleText = "15% Off Unlocked";
    bundleProductsTotalAfterDiscount = bundleProductsTotalBeforeDiscount * 0.85;
  } else if (bundleProducts.length === 4) {
    bundleBoxTitleText = "20% Off Unlocked";
    bundleProductsTotalAfterDiscount = bundleProductsTotalBeforeDiscount * 0.8;
  }

  bundleProductsTotalAfterDiscount = parseFloat(
    bundleProductsTotalAfterDiscount
  ).toFixed(2);

  savingsAmount = parseFloat(
    bundleProductsTotalBeforeDiscount - bundleProductsTotalAfterDiscount
  ).toFixed(2);

  const handleAddToCart = async (products) => {
    const bundleId = generateId();

    let bundleDiscount = "0";

    if (bundleProducts.length === 2) {
      bundleDiscount = "10";
    } else if (bundleProducts.length === 3) {
      bundleDiscount = "15";
    } else if (bundleProducts.length === 4) {
      bundleDiscount = "20";
    }

    const items = products.map((product) => {
      return {
        merchandiseId: product.variants[0].id,
        quantity: 1,
        attributes: [
          {
            key: "_bundleId",
            value: bundleId,
          },
        ],
      };
    });

    let groupedItems = countValuesByKey(items, "merchandiseId");

    groupedItems = Object.keys(groupedItems).map((key) => {
      if (items.find((item) => item.merchandiseId === key)) {
        return {
          merchandiseId: key,
          quantity: groupedItems[key],
          attributes: [
            {
              key: "_bundleId",
              value: bundleId,
            },
          ],
        };
      }
      return null;
    });

    const cart = await cartAddItems(groupedItems);

    if (!cart) {
      console.log("Error adding to cart", addItemStatus?.errors);
    } else {
      setBundleProducts([]);
      localStorage.removeItem("bundle_products");
      toggleCart();
    }
  };
  const handleZindex =
    cartDrawer && bundleBoxOpen
      ? "60"
      : !cartDrawer && bundleBoxOpen
      ? "2147483650"
      : "99";

  useEffect(() => {
    if (cartDrawer) {
      const cookie = window.document.getElementById("ot-sdk-btn-floating");
      if (cookie) {
        cookie.style.zIndex = cartDrawer ? "-1" : "9999";
      }
    }
    return () => {
      if (cartDrawer) {
        const cookie = window.document.getElementById("ot-sdk-btn-floating");
        if (cookie) {
          cookie.style.zIndex = "2147483646";
        }
      }
    };
  }, [cartDrawer]);

  const notReady = bundleProducts.length >= 1 && bundleProducts.length < 2;
  const titleText = notReady
    ? "Add at least one more item"
    : bundleBoxTitleText;

  return (
    <Box
      sx={{
        width: ["100%", "100%", "20%"],
        px: ["0", "0", "20px"],
        pt: ["0", "0", "20px"],
        pb: "16px",
        backgroundColor: "black",
        height: "fit-content",
        position: ["fixed", null, null, "sticky"],
        top: [null, null, "100px"],
        bottom: ["0", "0", null],
        right: [null, null, "0"],
        zIndex: [`${handleZindex}`, "0"],
      }}
    >
      <Box
        sx={{
          color: ["#1A1A1A", "#FFFFFF"],
          backgroundColor: ["#CAB683", "transparent"],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: "500",
          p: ["10px 16px 10px 16px"],
          fontSize: ["14px", "16px", "18px"],
          mb: `${bundleBoxOpen ? "16px" : "0"}`,
        }}
        onClick={() => setBundleBoxOpen(!bundleBoxOpen)}
      >
        <span>{titleText}</span>
        <Box
          sx={{
            width: "20px",
            height: "10px",
            position: "relative",
            left: "10px",
            cursor: "pointer",
            display: ["block", "none", "none"],
          }}
          className="caret-icon"
        >
          {bundleBoxOpen ? (
            <Svg
              src="/svgs/caret-down.svg#caret-down"
              alt="Caret Down"
              viewBox="0 0 20 10"
            />
          ) : (
            <Svg
              src="/svgs/caret-up.svg#caret-up"
              alt="Caret Up"
              viewBox="0 0 20 10"
            />
          )}
        </Box>
      </Box>
      <Flex
        sx={{
          display: `${bundleBoxOpen ? "grid" : "none"}`,
          gridTemplateColumns: ["repeat(4, 1fr)", "repeat(4, 1fr)", "1fr"],
          flexDirection: ["row", "row", "column"],
          gap: ["10px", "10px", "16px"],
          justifyContent: "center",
          alignItems: "center",
          px: ["16px", "16px", "0"],
          width: "100%",
        }}
        className="bundle-box-products"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: [null, null, `${bundleProducts.length >= 1 ? "24px" : "0"}`],
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <Box
              onMouseEnter={(e) => handleShowCloseButton(e, 0)}
              onMouseLeave={(e) => handleHideCloseButton(e, 0)}
              sx={{
                width: ["64px", "80px", "100px"],
                height: ["64px", "80px", "100px"],
                minWidth: ["64px", "80px", "100px"],
                backgroundColor: "black",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: `${
                  bundleProducts.length >= 1 ? "3px solid #CAB683" : "none"
                }`,
                cursor: "pointer",
                position: "relative",
                lineHeight: bundleProducts.length >= 1 ? 0 : "1.23",
              }}
              className={bundleProducts.length >= 1 ? "" : "bundle-box"}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: ["64px", "80px", "100px"],
                  "&:after": {
                    content: '" "',
                    backgroundColor: "#CAB683",
                    position: "absolute",
                    width: ["2rem", "4rem", "0rem", "3px", "3px"],
                    height: ["2px", null, "1.5rem", "1.5rem"],
                    left: ["75%", "100%", "50%"],
                    transform: [
                      "translateX(45%)",
                      "translateX(0%)",
                      "translateX(-50%)",
                    ],
                    marginTop: ["0rem", null, "7.5rem", "7.5rem"],
                  },
                }}
              >
                <Box
                  as="span"
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    right: "-12px",
                    backgroundColor: "#CAB683",
                    color: "black",
                    width: "24px",
                    height: "24px",
                    display: `${bundleProducts.length > 0 ? "flex" : "none"}`,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "50%",
                    zIndex: "10",
                  }}
                  onClick={(e) => {
                    handleRemoveFromBundle(e, bundleProducts[0], 0);
                  }}
                  className="close-button"
                >
                  &times;
                </Box>
                {bundleProducts.length > 0 ? (
                  <Box className="bundle-product-image">
                    <img
                      src={bundleProducts[0]?.featuredImage?.src}
                      alt={bundleProducts[0]?.title}
                    />
                  </Box>
                ) : (
                  <Box sx={{ p: "16px" }}>
                    <Svg
                      src="/svgs/geologo.svg#geologo"
                      alt="Bundle"
                      viewBox="0 0 48 39"
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              className="bundle-product-title"
              sx={{
                fontSize: "14px",
                width: ["100%", "100%", "50%"],
                color: "white",
                textTransform: "uppercase",
                display: [
                  "none",
                  "none",
                  `${bundleProducts.length >= 1 ? "block" : "none"}`,
                ],
              }}
            >
              {bundleProducts.length > 0 ? (
                bundleProducts[0]?.title
              ) : (
                <Box as="span" sx={{ color: "transparent" }}>
                  Bundle Bundle
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Box
            onMouseEnter={(e) => handleShowCloseButton(e, 1)}
            onMouseLeave={(e) => handleHideCloseButton(e, 1)}
            className={bundleProducts.length >= 2 ? "" : "bundle-box"}
            sx={{
              width: ["64px", "80px", "100px"],
              height: ["64px", "80px", "100px"],
              minWidth: ["64px", "80px", "100px"],
              backgroundColor: "black",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              border: `${
                bundleProducts.length >= 2 ? "3px solid #CAB683" : "none"
              }`,
              position: "relative",
              fontSize: "18px",
              lineHeight: bundleProducts.length >= 2 ? 0 : "1.23",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: ["64px", "80px", "100px"],
                "&:after": {
                  content: '" "',
                  backgroundColor: "#CAB683",
                  position: "absolute",
                  width: ["2rem", "4rem", "0rem", "3px", "3px"],
                  height: ["2px", null, "1.5rem", "1.5rem"],
                  left: ["75%", "100%", "50%"],
                  transform: [
                    "translateX(45%)",
                    "translateX(0%)",
                    "translateX(-50%)",
                  ],
                  marginTop: ["0rem", null, "7.5rem", "7.5rem"],
                },
              }}
            >
              <Box
                as="span"
                sx={{
                  position: "absolute",
                  top: "-12px",
                  right: "-12px",
                  backgroundColor: "#CAB683",
                  color: "black",
                  width: "24px",
                  height: "24px",
                  display: `${bundleProducts.length > 1 ? "flex" : "none"}`,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "50%",
                  zIndex: "10",
                }}
                onClick={(e) => {
                  handleRemoveFromBundle(e, bundleProducts[1], 1);
                }}
                className="close-button"
              >
                &times;
              </Box>
              {bundleProducts.length > 1 ? (
                <div className="bundle-product-image">
                  <img
                    src={bundleProducts[1]?.featuredImage?.src}
                    alt={bundleProducts[1]?.title}
                  />
                </div>
              ) : (
                <span>
                  10%
                  <br />
                  OFF
                </span>
              )}
            </Box>
          </Box>
          <Box
            className="bundle-product-title"
            sx={{
              fontSize: "14px",
              width: ["100%", "100%", "50%"],
              color: "white",
              textTransform: "uppercase",
              display: [
                "none",
                "none",
                `${bundleProducts.length >= 1 ? "block" : "none"}`,
              ],
            }}
          >
            {bundleProducts.length > 1 ? (
              bundleProducts[1]?.title
            ) : (
              <Box as="span" sx={{ color: "transparent" }}>
                Bundle Bundle
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Box
            onMouseEnter={(e) => handleShowCloseButton(e, 2)}
            onMouseLeave={(e) => handleHideCloseButton(e, 2)}
            className={bundleProducts.length >= 3 ? "" : "bundle-box"}
            sx={{
              width: ["64px", "80px", "100px"],
              height: ["64px", "80px", "100px"],
              minWidth: ["64px", "80px", "100px"],
              backgroundColor: "black",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              border: `${
                bundleProducts.length >= 3 ? "3px solid #CAB683" : "none"
              }`,
              position: "relative",
              fontSize: "18px",
              lineHeight: bundleProducts.length >= 3 ? 0 : "1.23",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: ["64px", "80px", "100px"],
                "&:after": {
                  content: '" "',
                  backgroundColor: "#CAB683",
                  position: "absolute",
                  width: ["2rem", "4rem", "0rem", "3px", "3px"],
                  height: ["2px", null, "1.5rem", "1.5rem"],
                  left: ["75%", "100%", "50%"],
                  transform: [
                    "translateX(45%)",
                    "translateX(0%)",
                    "translateX(-50%)",
                  ],
                  marginTop: ["0rem", null, "7.5rem", "7.5rem"],
                },
              }}
            >
              <Box
                as="span"
                sx={{
                  position: "absolute",
                  top: "-12px",
                  right: "-12px",
                  backgroundColor: "#CAB683",
                  color: "black",
                  width: "24px",
                  height: "24px",
                  display: `${bundleProducts.length > 2 ? "flex" : "none"}`,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "50%",
                  zIndex: "10",
                }}
                onClick={(e) => {
                  handleRemoveFromBundle(e, bundleProducts[2], 2);
                }}
                className="close-button"
              >
                &times;
              </Box>
              {bundleProducts.length > 2 ? (
                <div className="bundle-product-image">
                  <img
                    src={bundleProducts[2]?.featuredImage?.src}
                    alt={bundleProducts[2]?.title}
                  />
                </div>
              ) : (
                <span>
                  15%
                  <br />
                  OFF
                </span>
              )}
            </Box>
          </Box>

          <Box
            className="bundle-product-title"
            sx={{
              fontSize: "14px",
              width: ["100%", "100%", "50%"],
              color: "white",
              textTransform: "uppercase",
              display: [
                "none",
                "none",
                `${bundleProducts.length >= 1 ? "block" : "none"}`,
              ],
            }}
          >
            {bundleProducts.length > 2 ? (
              bundleProducts[2]?.title
            ) : (
              <Box as="span" sx={{ color: "transparent" }}>
                Bundle Bundle
              </Box>
            )}
          </Box>
        </Box>

        <Box
          className="bundle-product-last"
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Box
            onMouseEnter={(e) => handleShowCloseButton(e, 3)}
            onMouseLeave={(e) => handleHideCloseButton(e, 3)}
            className={bundleProducts.length >= 4 ? "" : "bundle-box"}
            sx={{
              width: ["64px", "80px", "100px"],
              height: ["64px", "80px", "100px"],
              minWidth: ["64px", "80px", "100px"],
              backgroundColor: "black",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              border: `${
                bundleProducts.length >= 4 ? "3px solid #CAB683" : "none"
              }`,
              position: "relative",
              fontSize: "18px",
              lineHeight: bundleProducts.length >= 4 ? 0 : "1.23",
              aspectRatio: "1/1",
            }}
          >
            <Box
              as="span"
              sx={{
                position: "absolute",
                top: "-12px",
                right: "-12px",
                backgroundColor: "#CAB683",
                color: "black",
                width: "24px",
                height: "24px",
                display: `${bundleProducts.length > 3 ? "flex" : "none"}`,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "50%",
                zIndex: "10",
              }}
              onClick={(e) => {
                handleRemoveFromBundle(e, bundleProducts[3], 3);
              }}
              className="close-button"
            >
              &times;
            </Box>
            {bundleProducts.length > 3 ? (
              <div className="bundle-product-image">
                <img
                  src={bundleProducts[3]?.featuredImage?.src}
                  alt={bundleProducts[3]?.title}
                />
              </div>
            ) : (
              <span>
                20%
                <br />
                OFF
              </span>
            )}
          </Box>
          <Box
            className="bundle-product-title"
            sx={{
              fontSize: "14px",
              width: ["100%", "100%", "50%"],
              color: "white",
              textTransform: "uppercase",
              display: [
                "none",
                "none",
                `${bundleProducts.length >= 1 ? "block" : "none"}`,
              ],
            }}
          >
            {bundleProducts.length > 3 ? (
              bundleProducts[3]?.title
            ) : (
              <Box as="span" sx={{ color: "transparent" }}>
                Bundle Bundle
              </Box>
            )}
          </Box>
        </Box>
      </Flex>

      <Button
        disabled={notReady}
        sx={{
          backgroundColor: "#CAB683",
          fontWeight: "bold",
          fontSize: ["12px", "18px"],
          textAlign: "center",
          width: ["90%", "90%", "100%"],
          mx: "auto",
          textTransform: "uppercase",
          marginTop: "24px",
          height: ["42px", "42px", "60px", "42px"],
          display: `${bundleBoxOpen ? "flex" : "none"}`,
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #CAB683",

          cursor: `${
            bundleProducts.length === 0 || notReady ? "not-allowed" : "pointer"
          }`,
          opacity: `${bundleProducts.length === 0 || notReady ? "0.5" : "1"}`,
          "&:hover": `${
            bundleProducts.length === 0
              ? { backgroundColor: "#1A1A1A", color: "#CAB683" }
              : null
          }`,
        }}
      >
        <Box
          as="span"
          sx={{
            fontSize: ["14px", "14px", "12px", "12px"],
            color: "black",
          }}
          onClick={() => handleAddToCart(bundleProducts)}
        >
          {bundleProducts.length === 0
            ? "Bundle More And Save"
            : `Add Bundle To Cart - $${bundleProductsTotalAfterDiscount}`}
        </Box>
      </Button>
      {bundleProducts.length > 1 && savingsAmount && (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mt: "16px",
          }}
        >
          <Paragraph
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontSize: "12px",
              marginBottom: 0,
              fontFamily: "heading",
            }}
          >
            You&apos;re saving ${savingsAmount}
          </Paragraph>
        </Flex>
      )}
    </Box>
  );
}
export default BundleBox;
