export const imageCmsSettings = (props) => {
  const overlay = props?.overlay || false;
  const showHeightSettings = props?.showHeightSettings || false;

  const fields = [
    {
      name: 'image',
      label: 'Image',
      component: 'image',
      clearable: true,
      parse: (media) => {
        if (!media) return '';
        // by default: component:image returns just the src as a string
        // by defining: parse: image => image (the user can opt-in to the full image metadata object)
        return media;
      },
      previewSrc: (media) => {
        if (!media) return '';

        if (typeof media === 'string') {
          return media; // default when component: 'image'
        }

        return media?.thumbnail || media?.src || media?.filename || '';
      },
    },
    {
      name: 'alt',
      label: 'Alt',
      description: 'Brief description of image',
      component: 'text',
    },
    {
      name: 'imagePosition',
      component: 'select',
      label: 'Image Position',
      options: [
        {
          value: 'left top',
          label: 'Left Top',
        },
        {
          value: 'left center',
          label: 'Left Center',
        },
        {
          value: 'left bottom',
          label: 'Left Bottom',
        },
        {
          value: 'right top',
          label: 'Right Top',
        },
        {
          value: 'right center',
          label: 'Right Center',
        },
        {
          value: 'right bottom',
          label: 'Right Bottom',
        },
        {
          value: 'center top',
          label: 'Center Top',
        },
        {
          value: 'center center',
          label: 'Center Center',
        },
        {
          value: 'center bottom',
          label: 'Center Bottom',
        },
      ],
      defaultValue: 'center center',
    },
    {
      name: 'imageHeight',
      component: 'select',
      label: 'Image Height',
      options: [
        {
          value: 0.1,
          label: '10%',
        },
        {
          value: 0.2,
          label: '20%',
        },
        {
          value: 0.3,
          label: '30%',
        },
        {
          value: 0.4,
          label: '40%',
        },
        {
          value: 0.5,
          label: '50%',
        },
        {
          value: 0.6,
          label: '60%',
        },
        {
          value: 0.7,
          label: '70%',
        },
        {
          value: 0.8,
          label: '80%',
        },
        {
          value: 0.9,
          label: '90%',
        },
        {
          value: 1,
          label: '100%',
        },
      ],
      defaultValue: 0.9,
    },
    {
      name: 'mobileImage',
      label: 'Image on Mobile',
      component: 'image',
      description: 'If empty, mobile will use main image.',
      clearable: true,
      parse: (media) => {
        if (!media) return '';
        // by default: component:image returns just the src as a string
        // by defining: parse: image => image (the user can opt-in to the full image metadata object)
        return media;
      },
      previewSrc: (media) => {
        if (!media) return '';

        if (typeof media === 'string') {
          return media; // default when component: 'image'
        }

        return media?.thumbnail || media?.src || media?.filename || '';
      },
    },
    {
      name: 'mobileImagePosition',
      component: 'select',
      label: 'Image Position on Mobile',
      options: [
        {
          value: 'left top',
          label: 'Left Top',
        },
        {
          value: 'left center',
          label: 'Left Center',
        },
        {
          value: 'left bottom',
          label: 'Left Bottom',
        },
        {
          value: 'right top',
          label: 'Right Top',
        },
        {
          value: 'right center',
          label: 'Right Center',
        },
        {
          value: 'right bottom',
          label: 'Right Bottom',
        },
        {
          value: 'center top',
          label: 'Center Top',
        },
        {
          value: 'center center',
          label: 'Center Center',
        },
        {
          value: 'center bottom',
          label: 'Center Bottom',
        },
      ],
      defaultValue: 'center center',
    },
    {
      name: 'mobileImageHeight',
      component: 'select',
      label: 'Image Height on Mobile',
      options: [
        {
          value: 0.1,
          label: '10%',
        },
        {
          value: 0.2,
          label: '20%',
        },
        {
          value: 0.25,
          label: '25%',
        },
        {
          value: 0.3,
          label: '30%',
        },
        {
          value: 0.4,
          label: '40%',
        },
        {
          value: 0.5,
          label: '50%',
        },
        {
          value: 0.6,
          label: '60%',
        },
        {
          value: 0.7,
          label: '70%',
        },
        {
          value: 0.75,
          label: '75%',
        },
        {
          value: 0.8,
          label: '80%',
        },
        {
          value: 0.9,
          label: '90%',
        },
        {
          value: 1,
          label: '100%',
        },
      ],
      defaultValue: 0.7,
    },
    {
      name: 'overlay',
      label: 'Add Dark Overlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ];

  return {
    label: 'Image Settings',
    name: 'imageSettings',
    description: 'Image, Image Position.',
    component: 'group',
    fields:
      overlay && showHeightSettings
        ? fields
        : !overlay && showHeightSettings
        ? fields.filter((field) => field.name !== 'overlay')
        : !showHeightSettings & overlay
        ? fields.filter(
            (field) =>
              field.name !== 'imageHeight' && field.name !== 'mobileImageHeight'
          )
        : fields.filter((field) => {
            return (
              field.name !== 'imageHeight' &&
              field.name !== 'mobileImageHeight' &&
              field.name !== 'overlay'
            );
          }),
  };
};

export const videoCmsSettings = (props) => {
  const overlay = props?.overlay || false;
  const showHeightSettings = props?.showHeightSettings || false;
  const videoDescription =
    props?.videoDescription ||
    '*Required. Max quality: High def (mp4, 1280x720).';
  const mobileVideoDescription =
    props?.mobileVideoDescription ||
    '*Required. Max quality: Standard def (mp4, 960x540).';

  const fields = [
    {
      name: 'video',
      component: 'text',
      label: 'Vimeo Video File Link*',
      description: videoDescription,
    },
    {
      name: 'poster',
      label: 'Video Thumbnail*',
      component: 'image',
      description: '*Required. Usually the first or best frame of the video.',
      clearable: true,
      parse: (media) => {
        if (!media) return '';
        // by default: component:image returns just the src as a string
        // by defining: parse: image => image (the user can opt-in to the full image metadata object)
        return media;
      },
      previewSrc: (media) => {
        if (!media) return '';

        if (typeof media === 'string') {
          return media; // default when component: 'image'
        }

        return media?.thumbnail || media?.src;
      },
    },
    {
      name: 'videoHeight',
      component: 'select',
      label: 'Video Height',
      options: [
        {
          value: 0.1,
          label: '10%',
        },
        {
          value: 0.2,
          label: '20%',
        },
        {
          value: 0.3,
          label: '30%',
        },
        {
          value: 0.4,
          label: '40%',
        },
        {
          value: 0.5,
          label: '50%',
        },
        {
          value: 0.6,
          label: '60%',
        },
        {
          value: 0.7,
          label: '70%',
        },
        {
          value: 0.8,
          label: '80%',
        },
        {
          value: 0.9,
          label: '90%',
        },
        {
          value: 1,
          label: '100%',
        },
      ],
      defaultValue: 0.9,
    },
    {
      name: 'mobileVideo',
      component: 'text',
      label: 'Vimeo Video File Link for Mobile*',
      description: mobileVideoDescription,
    },
    {
      name: 'mobilePoster',
      label: 'Video Thumbnail on Mobile*',
      component: 'image',
      description: '*Required. Usually the first or best frame of the video.',
      clearable: true,
      parse: (media) => {
        if (!media) return '';
        // by default: component:image returns just the src as a string
        // by defining: parse: image => image (the user can opt-in to the full image metadata object)
        return media;
      },
      previewSrc: (media) => {
        if (!media) return '';

        if (typeof media === 'string') {
          return media; // default when component: 'image'
        }

        return media?.thumbnail || media?.src;
      },
    },
    {
      name: 'mobileVideoHeight',
      component: 'select',
      label: 'Video Height on Mobile',
      options: [
        {
          value: 0.1,
          label: '10%',
        },
        {
          value: 0.2,
          label: '20%',
        },
        {
          value: 0.25,
          label: '25%',
        },
        {
          value: 0.3,
          label: '30%',
        },
        {
          value: 0.4,
          label: '40%',
        },
        {
          value: 0.5,
          label: '50%',
        },
        {
          value: 0.6,
          label: '60%',
        },
        {
          value: 0.7,
          label: '70%',
        },
        {
          value: 0.75,
          label: '75%',
        },
        {
          value: 0.8,
          label: '80%',
        },
        {
          value: 0.9,
          label: '90%',
        },
        {
          value: 1,
          label: '100%',
        },
      ],
      defaultValue: 0.7,
    },
    {
      name: 'overlay',
      label: 'Add Dark Overlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ];

  return {
    label: 'Video Settings',
    name: 'videoSettings',
    description: 'Video URL, Video Thumbnail.',
    component: 'group',
    fields:
      overlay && showHeightSettings
        ? fields
        : !overlay && showHeightSettings
        ? fields.filter((field) => field.name !== 'overlay')
        : !showHeightSettings & overlay
        ? fields.filter(
            (field) =>
              field.name !== 'videoHeight' && field.name !== 'mobileVideoHeight'
          )
        : fields.filter((field) => {
            return (
              field.name !== 'videoHeight' &&
              field.name !== 'mobileVideoHeight' &&
              field.name !== 'overlay'
            );
          }),
  };
};

export const activeMediaTypeSettings = [
  {
    name: 'activeMediaType',
    component: 'radio-group',
    direction: 'horizontal',
    variant: 'radio',
    label: 'Active Media Type',
    options: [
      {
        value: 'image',
        label: 'Image',
      },
      {
        value: 'video',
        label: 'Video',
      },
    ],
    defaultValue: 'image',
  },
  {
    name: 'activeMobileMediaType',
    component: 'radio-group',
    direction: 'horizontal',
    variant: 'radio',
    label: 'Active Media Type on Mobile',
    options: [
      {
        value: 'image',
        label: 'Image',
      },
      {
        value: 'video',
        label: 'Video',
      },
    ],
    defaultValue: 'image',
  },
];

export const mediaCmsSettings = (props) => [
  ...activeMediaTypeSettings,
  imageCmsSettings(props),
  videoCmsSettings(props),
];

export const primaryButtonCmsSettings = {
  label: 'Primary Button Settings',
  name: 'primaryButtonSettings',
  description: 'Button Text, Button Path.',
  component: 'group',
  fields: [
    {
      name: 'cta',
      component: 'link',
      label: 'CTA',
    },
    {
      name: 'hideButton',
      label: 'Hide Button',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

export const secondaryButtonCmsSettings = ({ defaultValue = 'primary' }) => ({
  label: 'Secondary Button Settings',
  name: 'secondaryButtonSettings',
  description: 'Button Text, Button Path.',
  component: 'group',
  fields: [
    {
      name: 'cta',
      component: 'link',
      label: 'CTA',
    },
    {
      name: 'buttonStyle',
      label: 'Button Style',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          value: 'primary',
          label: 'On Dark',
        },
        {
          value: 'secondary',
          label: 'On Light',
        },
      ],
      defaultValue,
    },
    {
      name: 'mobileButtonStyle',
      label: 'Button Style on Mobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        {
          value: 'primary',
          label: 'On Dark',
        },
        {
          value: 'secondary',
          label: 'On Light',
        },
      ],
      defaultValue,
    },
    {
      name: 'hideButton',
      label: 'Hide Button',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
});
