/* eslint-disable no-nested-ternary */
import { useCallback, useState } from "react";
import {
  Flex,
  Button,
  Input,
  Paragraph,
  Image,
  Spinner,
  Label,
} from "theme-ui";
import { subscribe } from "klaviyo-subscribe";
import { useSettings } from "@backpackjs/storefront";
import { isBrowser } from "@utils";
import { useDataLayerActions } from "@hooks";

export const EmailSignup = ({ ...props }) => {
  const siteSettings = useSettings();
  const {
    footer: { emailSignup },
  } = siteSettings;

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [placeholderText, setPlaceholderText] = useState("Enter your email...");
  const { sendSubscribeEvent } = useDataLayerActions();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!email || submitted || isLoading) return;

    if (isBrowser) {
      setIsLoading(true);
      subscribe("Px84XR", email).then(({ data }) => {
        if (typeof data.is_subscribed === "boolean") {
          setIsLoading(false);
          setSubmitted(true);
          setPlaceholderText(
            !data.is_subscribed ? "Email submitted!" : "Already subscribed"
          );
          setEmail("");
          setTimeout(() => {
            setSubmitted(false);
            setPlaceholderText("Enter your email...");
            setOpacity(0);
          }, 4000);
        } else {
          setIsLoading(false);
          setPlaceholderText("Please try again later");
          setTimeout(() => {
            setPlaceholderText("Enter your email...");
          }, 4000);
        }
      });
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "klaviyoFormSubmission",
        formId: "Px84XR",
      });
      sendSubscribeEvent(email);
    }
  });

  return (
    <Flex data-comp="EmailSignup" variant="flex.column" {...props}>
      <Paragraph variant="label.3" sx={{ color: "white", mb: [4, 7] }}>
        {emailSignup?.heading}
      </Paragraph>
      <Paragraph
        variant="base"
        sx={{ color: "white", mb: [5, 6], whiteSpace: "pre-wrap" }}
      >
        {emailSignup?.subtext}
      </Paragraph>

      <Flex
        as="form"
        // variant="flex.row.between.center"
        onSubmit={handleSubmit}
        sx={{
          flexDirection: "column",
        }}
      >
        <Label htmlFor="email-signup" sx={{ my: 4, color: "white" }}>
          Enter your email
        </Label>
        <Input
          id="email-signup"
          aria-label="Enter email address to sign up"
          aria-required="true"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder={placeholderText}
          required
          sx={{
            variant: "text.base",
            pl: 8,
            py: 6,
            m: 0,
            pr: 0,
            fontSize: 5,
            height: "100%",
            flex: 1,
            bg: "white",
            border: "1px solid",
            borderColor: "gray.3",
            fontFamily: "body",
            "&:focus": {
              outline: "none",
            },
            "&::placeholder": {
              fontFamily: "body",
              variant: "text.base",
            },
          }}
        />
        <Flex
          variant="flex.row.center.center"
          sx={{
            minWidth: "80px",
            minHeight: "44px",
          }}
        >
          {isLoading ? (
            <Spinner
              sx={{
                width: "20px",
                color: "black",
                position: "absolute",
              }}
            />
          ) : submitted ? (
            <Image
              alt="check mark"
              src="/svg/check-mark.svg"
              onLoad={() => setOpacity(1)}
              sx={{
                width: "18px",
                transition: "opacity 0.3s ease-in-out",
                opacity,
              }}
            />
          ) : (
            <Button
              sx={{
                color: "black",
                fontSize: 1,
                lineHeight: 18,
                letterSpacing: 0.08,
                textAlign: "right",
                px: 8,
                py: 6,
              }}
            >
              Submit
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
