export default {
  label: 'Help Settings',
  name: 'help',
  component: 'group',
  fields: [
    {
      component: 'group-list',
      name: 'topics',
      label: 'Topics',
      itemProps: {
        label: '{{item.heading}}',
      },
      fields: [
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
        },
        {
          component: 'markdown',
          name: 'body',
          label: 'Content',
        },
      ],
    },
  ],
};
