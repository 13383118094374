import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.overlay = {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 62,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      cursor: 'pointer',
    };

    this.content = {
      position: 'fixed',
      zIndex: 63,
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      backgroundColor: 'white',
      p: [10, 14],
      width: '100%',
      maxWidth: '490px',
      height: '100%',
      maxHeight: '630px',
      overflowY: 'scroll',
      '* p, > p': {
        fontSize: '14px',
        mb: 6,
      },
    };

    this.closeModalBtn = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 4,
      right: 4,
      color: 'black',
      width: '20px',
      height: '20px',
    };

    this.header = {
      columnGap: '20px',
      mb: 10,
    };

    this.heading = {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: 1,
      marginBottom: '-10px',
    };

    this.subheading = {
      fontSize: '16px',
      mb: 10,
    };

    this.smallHeadline = {
      fontWeight: 700,
      mb: 4,
    };

    this.steps = {
      pl: 10,
    };
  })()
);
