import { Flex } from "theme-ui";

import { useMounted, useMountAfter } from "@hooks";
import { entryAnimation } from "@utils";
import { InlineHeading } from "@snippets";

import { MenuItem } from "./MenuItem";

export const CollectionProductLinks = ({ title, links }) => {
  const [mount] = useMounted();
  const [mountItems] = useMountAfter({ delay: 100 });

  return (
    <Flex
      variant="flex.column"
      data-comp="CollectionProductLinks"
      sx={{
        mt: 0,
        mb: 8,
        width: "100%",
        ...entryAnimation({
          entered: mount,
          fadeIn: true,
          index: 1,
          delay: 0,
        }),
      }}
    >
      <Flex
        variant="flex.row"
        data-comp="CollectionLinks"
        sx={{
          flex: 1,
          flexWrap: "wrap",
          gap: "20px",
          p: 0,
        }}
        as="ul"
        aria-label={`Product list containing ${links.length} items`}
      >
        {links?.map((subItem, collectionIndex) => {
          return (
            <MenuItem
              item={subItem}
              index={collectionIndex}
              key={subItem.id}
              cardSize={links.length > 4 ? "small" : "large"}
              hasOptionsGrid
              sx={{
                ...entryAnimation({
                  entered: mountItems,
                  fadeIn: true,
                  slideIn: false,
                  duration: 50,
                  index: collectionIndex,
                  delay: 50,
                }),
              }}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
