import React from 'react';
import { useEditForm } from './useEditForm';

export const useEditFormChange = (
  subscription = { pristine: true, values: {} }
) => {
  const editForm = useEditForm();
  const [formData, setFormData] = React.useState(subscription);
  const [seo, setSeo] = React.useState(null);
  const [sections, setSections] = React.useState(null);
  const [globalSections, setGlobalSections] = React.useState(null);

  React.useEffect(() => {
    if (!editForm) return;
    const unsubscribe = editForm.subscribe(setFormData, subscription);
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [editForm]);

  React.useEffect(() => {
    if (formData?.values?.sections) {
      setSeo(formData.values.seo);
      if (formData.values.sections) {
        setSections(formData.values.sections);
        setGlobalSections(
          formData.values.sections.filter(
            (section) => section.sectionType === 'global'
          )
        );
      }
    }
  }, [editForm, formData?.values?.sections?.length]);

  return { seo, sections, globalSections, formData };
};
