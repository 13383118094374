import { Image, Flex } from "theme-ui";
import { LinkWrapper } from "@snippets";
import { Schema } from "./BannerImage.schema";

export function BannerImage({ cms }) {
  const { image, imageDt, link, alt } = cms;

  return (
    <LinkWrapper
      newTab={link?.newTab}
      sx={{
        backgroundColor: "#1a1a1a",
      }}
      href={link?.url}
      ariaLabel={`go to ${link?.url} page`}
    >
      <Flex
        data-comp={BannerImage.displayName}
        sx={{
          width: "100%",
          height: "auto",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Image
          alt={alt}
          src={imageDt?.src}
          sx={{
            display: image?.src ? ["none", null, null, "block"] : "block",
            width: "100%",
            height: "100%",
          }}
        />

        {image?.src && (
          <Image
            alt={alt}
            src={image?.src}
            sx={{
              display: ["block", null, null, "none"],
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </Flex>
    </LinkWrapper>
  );
}

BannerImage.displayName = "BannerImage";
BannerImage.Schema = Schema;
