import { useCallback, useMemo } from 'react';

import { Dropdown } from '@snippets';

import { useRouter } from 'next/router';

export function DropdownSubNav({ cms }) {
  const { navItems, subNavHeading } = cms;
  const router = useRouter();

  const options = useMemo(
    () =>
      navItems?.map((item) => {
        return {
          label: item.navItem.text,
          value: item.navItem.url,
        };
      }),
    [navItems]
  );

  const activeIndex = useMemo(() => {
    return options?.findIndex((opt) => opt?.value === router?.asPath);
  }, [router.asPath]);

  const handleClick = useCallback((option) => {
    router.push(option);
  }, []);

  return (
    <Dropdown
      id="sub-nav-dropdown"
      onClick={handleClick}
      title={subNavHeading}
      items={options}
      initialIndex={activeIndex}
      overrideStyles
      sx={{
        zIndex: 20,
        width: '200px',
        mr: '24px',
      }}
    />
  );
}
