import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      bg: '#000000',
      '*': {
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif !important',
      },
    };

    this.successMessageBox = {
      width: '100%',
      maxWidth: '600px',
      mx: 'auto',
      px: [11],
      py: [9],
    };

    this.successMessage = {
      '*, p': {
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: '1.25',
        textAlign: 'center',
        color: 'white',
      },
    };

    this.form = {
      width: '100%',
      maxWidth: '600px',
      mx: 'auto',
      px: [11],
      py: [9],
    };

    this.inputWrapper = {
      display: 'flex',
      flexDirection: 'column',
      my: 10,
    };

    this.label = {
      color: 'gold',
      fontWeight: 700,
      mb: 2,
    };

    this.input = {
      fontSize: 5,
      fontWeight: 600,
      border: '1px solid #b4bbc3',
      borderRadius: '2px',
      color: 'gold',
      height: '38px',
      px: 8,
      py: 0,
      mb: 0,
      lineHeight: 1,
      '&:focus': {
        boxShadow: 'rgb(202, 182, 131) 0 0 5px',
      },
      '&::placeholder': {
        color: '#D0C3AA',
        fontSize: 5,
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif !important',
      },
    };

    this.consentText = {
      '*, a, p': {
        fontSize: 1,
        color: 'rgba(255, 255, 255, 0.6)',
        fontStyle: 'italic',
        mt: 0,
      },
      a: {
        color: '#0066CC',
      },
    };

    this.submitWrapper = {
      flexDirection: 'column',
      gap: 8,
      gridColumn: '1 / span 2',
    };

    this.recaptcha = {
      my: 6,
      width: '100%',
    };

    this.submit = {
      fontSize: 6,
      fontWeight: 600,
      height: '50px',
      width: 'auto',
      mt: 10,
      bg: 'gold',
      color: '#000000',
      letterSpacing: '1px',
      borderRadius: '2px',
    };

    this.errors = {
      flexDirection: 'column',
      gap: 2,
    };

    this.error = {
      color: 'red',
    };
  })()
);
