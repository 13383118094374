import { useState, useEffect, useRef } from "react";
import { Flex, Box, Text, Button, Image, Heading } from "theme-ui";
import { YotpoStars } from "@snippets";
import Modal from "react-modal";

Modal.setAppElement("*"); // suppresses modal-related warnings.

function BundleProductModal({
  showModal,
  setShowModal,
  product,
  handleAddToBundle,
  isAdded,
}) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(0);
  const modal = useRef(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = (e) => {
    setKey(key + 1);
    setIsOpen(false);
    setShowModal({
      show: false,
      productId: null,
    });
  };

  useEffect(() => {
    if (showModal.show) {
      openModal();
    }
  }, [showModal]);

  return (
    <Modal
      ref={modal}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Bundle Product Modal"
      className="bundle-product-modal"
      shouldCloseOnOverlayClick
      overlayClassName="bundle-product-modal-overlay"
      shouldCloseOnEsc
      sx={{
        backgroundColor: ["#1A1A1A", "transparent"],
      }}
    >
      <Box
        key={key}
        sx={{
          maxWidth: "1000px",
          margin: "0 auto",
          maxHeight: ["90vh", "900px"],
          position: [null, "relative"],
          zIndex: "4000",
          overflow: ["scroll", "unset"],
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            gap: "16px",
            padding: "24px",
            backgroundColor: "#1A1A1A",
            color: "white",
            position: [null, "relative"],
            top: ["100px", "100px", "150px"],
            zIndex: "4000",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              zIndex: "110",
            }}
          >
            <Box
              as="span"
              onClick={closeModal}
              sx={{
                cursor: "pointer",
                width: "24px",
                height: "24px",
                color: "#CAB683",
                fontSize: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "110",
              }}
            >
              &times;
            </Box>
          </Box>
          <Box
            sx={{
              width: ["100%", "50%"],
            }}
          >
            <Image src={product.images[1].src} alt={product.title} />
          </Box>
          <Flex
            sx={{
              width: ["100%", "50%"],
              flexDirection: "column",
              p: "0 16px 16px 16px",
              justifyContent: "start",
            }}
          >
            <YotpoStars
              legacyResourceId={product.legacyResourceId}
              withCta={false}
            />
            <Text
              sx={{
                fontSize: ["18px", "21px", "24px"],
                textTransform: "uppercase",
                fontFamily: "Brown, sans-serif",
                lineHeight: 1.5,
              }}
            >
              {product.title}
            </Text>
            <Text
              sx={{
                fontSize: "16px",
                fontFamily: "Brown, sans-serif",
                mt: "8px",
              }}
            >
              ${product.priceRange?.min}
            </Text>
            {/* <ProductDetails  cms={product} /> */}
            <Box
              sx={{
                mt: "1rem",
                fontSize: "16px",
                fontFamily: "Brown, sans-serif",
                lineHeight: "1.5",
                color: "white",
              }}
            >
              <Heading
                as="h3"
                sx={{
                  fontSize: "12px",
                  textTransform: "uppercase",
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  backgroundColor: "#1A1A1A",
                  zIndex: "1",
                }}
              >
                Product Description{" "}
                <Box
                  as="hr"
                  sx={{
                    boxSizing: "border-box",
                    border: "0",
                    height: "1px",
                    backgroundColor: "#FFFFFF",
                    flexGrow: 1,
                    marginBottom: "0",
                    marginTop: "0",
                  }}
                />
              </Heading>
              {product.description}
            </Box>
            <Button
              onClick={(e) => {
                handleAddToBundle(e, product);
                closeModal();
              }}
              sx={{
                backgroundColor: "#CAB683",
                color: "black",
                textTransform: "uppercase",
                height: "48px",
                mt: "24px",
                maxWidth: "250px",
                border: "1px solid #CAB683",
                "&:hover": {
                  backgroundColor: "black",
                  color: "#CAB683",
                },
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isAdded ? "Added" : "Add to Bundle"}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
}
export default BundleProductModal;
