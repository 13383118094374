import { Paragraph, Box, Image, Flex, Button } from 'theme-ui';

import store, { useRecoilState, useSetRecoilState } from '@store';

export const MenuToggle = (props) => {
  const [menuDrawer, setMenuDrawer] = useRecoilState(store.menuDrawer);
  const setMenuItem = useSetRecoilState(store.menuItem);
  const setOverlay = useSetRecoilState(store.overlay);
  const setModal = useSetRecoilState(store.modal);
  const [menuDrawerIndex, setMenuDrawerIndex] = useRecoilState(
    store.menuDrawerIndex
  );
  const setActiveMobileMenuDrawer = useSetRecoilState(
    store.activeMobileMenuDrawer
  );

  const toggleMenuDrawer = () => {
    if (menuDrawer) {
      // reset and close menu
      setMenuItem(null);
      setOverlay(false);
      setMenuDrawer(false);
      setMenuDrawerIndex(null);
    } else {
      setOverlay(true);
      setMenuDrawer(true);
      setModal(null);
      setMenuDrawerIndex(0);
      setActiveMobileMenuDrawer(null);
    }
  };

  return (
    <Button
      onClick={toggleMenuDrawer}
      variant="buttons.plain"
      aria-label={menuDrawer ? 'Close menu drawer' : 'Open menu drawer'}
      {...props}
      sx={{
        display: ['flex', null, null, null, 'none'],
        alignItems: 'center',
        height: 16,
        ...props.sx,
        'svg line#bottom': {
          transition: 'transform 250ms ease-in',
          transformOrigin: 'bottom right',
        },
        '&:hover': {
          'svg line#bottom': {
            transform: 'scaleX(2.5);',
          },
        },
      }}
    >
      {/*
        Why static svg you may ask?,
          - it allows us to reduce the js bundle size as asset/svgs get converted to react elements
          - Static svgs will be cached in the users browser from the first visit onwards where as asset/svgs won't
          @see: public/svg
      */}

      {/* when opened */}
      <Box sx={{ minHeight: '16px', minWidth: '16px' }}>
        <Image
          data-comp="ToggleMenuClose"
          src="/svg/menu-open.svg"
          alt="Close menu"
          sx={{
            height: 16,
            display: menuDrawer ? 'block' : 'none',
          }}
        />

        {/* when closed */}
        <Box
          data-comp="ToggleMenuOpen"
          alt="Open menu"
          sx={{ display: menuDrawer ? 'none' : 'block' }}
        >
          <svg
            width="16px"
            height="14px"
            viewBox="0 0 16 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="menu-closed" stroke="#B6A879" strokeWidth="1.5">
                <line x1="0" y1="1" x2="16" y2="1" id="top"></line>
                <line x1="0" y1="7" x2="16" y2="7" id="middle"></line>
                <line x1="9" y1="13" x2="16" y2="13" id="bottom"></line>
              </g>
            </g>
          </svg>
        </Box>
      </Box>

      <Paragraph
        variant="text.14"
        sx={{
          color: (t) => (menuDrawer ? t.colors.gray[6] : t.colors.white),
          ml: 6,
          mb: 1,
          height: '16px',
          lineHeight: 'unset',
          display: ['none', null, null, null, 'block'],
        }}
      >
        {menuDrawer ? 'CLOSE' : 'EXPLORE'}
      </Paragraph>
    </Button>
  );
};
