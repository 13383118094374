import { Container, jsx, Image, Flex } from 'theme-ui';

import store, { useSetRecoilState } from '@store';
import { LinkWrapper } from '@snippets';

import { MenuCtas } from './MenuCtas';
import { MenuToggle } from './MenuToggle';
import { MenuToggleDesktop } from './MenuToggleDesktop';

export const Menu = (props) => {
  const setModal = useSetRecoilState(store.modal);
  const setRewardsModal = useSetRecoilState(store.activeRewardsModal);
  const setCartDrawer = useSetRecoilState(store.cartDrawer);
  const setMenuDrawer = useSetRecoilState(store.menuDrawer);
  const setSideDrawer = useSetRecoilState(store.sideDrawer);
  const setOverlay = useSetRecoilState(store.overlay);

  return (
    <Container
      data-comp="Menu"
      variant="layout.menu"
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: [48, null, null, 64],
        ...props.sx,
      }}
    >
      {/* 🍔 / 🚫 */}
      <Flex sx={{ flex: 1, gap: 13 }}>
        <MenuToggle />
        <MenuToggleDesktop menuIndex={0} menuTitle="Shop" />
        <MenuToggleDesktop menuIndex={1} menuTitle='Explore' />
      </Flex>

      {/* 💎 Logo */}
      <Flex variant="flex.row.center">
        <LinkWrapper aria-label="Go to home page." href="/">
          <Flex
            variant="flex.row.center"
            sx={{
              width: [105, null, null, null, 130],
              cursor: 'pointer',
            }}
            onClick={() => {
              setModal(null);
              setRewardsModal(null);
              setOverlay(false);
              setCartDrawer(false);
              setMenuDrawer(null);
              setSideDrawer(null);
            }}
          >
            <Image src="/svg/logo.svg" alt="Truff Logo" />
          </Flex>
        </LinkWrapper>
      </Flex>

      {/* 🛒 */}
      <MenuCtas sx={{ flex: 1 }} />
    </Container>
  );
};
