import { Heading, Flex, Paragraph, Image } from 'theme-ui';
import { StyledButton } from '@snippets';
import { useResponsiveValue } from '@theme-ui/match-media';

export function HalfBlockTextWithImage({
  block,
  index,
  disabledPy,
  columnDirection,
  bgColor,
  ...props
}) {
  const responsiveImageSrc = useResponsiveValue(
    block?.image?.mobileImage?.src
      ? [
          block?.image?.mobileImage?.src,
          block?.image?.mobileImage?.src,
          block?.image?.mobileImage?.src,
          block?.image?.image?.src || '',
        ]
      : [block?.image?.image?.src || '']
  );

  return (
    <Flex
      data-comp="HalfBlockTextWithImage"
      sx={{
        display: block?.hideMobile ? ['none', null, null, 'flex'] : 'flex',
        width: '100%',
        height: 'auto',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: block?.content ? block?.content : 'center',
        maxWidth: ['100%', null, null, '800px'],
        pt: disabledPy ? [index === 0 ? 13 : 9, null, null, 17] : 0,
        pb: disabledPy ? [index === 0 ? 9 : 13, null, null, 17] : 0,
        px: [9, null, null, 13],
      }}
      {...props}
    >
      {block?.image?.image?.src && (
        <Flex
          data-comp="HalfBlockImage"
          sx={{
            width: '100%',
            alignSelf: 'stretch',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            pr: 9,
          }}
          {...props}
        >
          <Image
            src={responsiveImageSrc?.src || responsiveImageSrc}
            sx={{
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Flex>
      )}

      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Heading
          variant="24.r"
          sx={{
            mb: block?.description ? [4, null, null, 6] : [8, null, null, 11],
            width: '100%',
            maxWidth: ['100%', null, null, '440px'],
            color:
              block?.headingColor || bgColor === '#000000' ? 'white' : 'black',
          }}
        >
          {block?.heading}
        </Heading>
        {block?.description && (
          <Paragraph
            variant="lg"
            sx={{
              display: block?.description ? 'block' : 'none',
              width: '100%',
              maxWidth: ['100%', null, null, '440px'],
              color:
                block?.descriptionColor || bgColor === '#000000'
                  ? 'white'
                  : 'black',
              mb: !block?.hideButton ? [8, null, null, 11] : 0,
            }}
          >
            {block?.description}
          </Paragraph>
        )}
        <Flex
          sx={{
            width: '100%',
            maxWidth: ['100%', null, null, '440px'],
          }}
        >
          {block?.cta?.text && !block?.hideButton && (
            <StyledButton
              href={block?.scrollToTop ? '' : block?.cta?.url}
              text={block?.cta?.text}
              newTab={block?.scrollToTop ? false : block?.cta?.newTab}
              variant="solid"
              sx={{
                width: '100%',
                minWidth: '150px',
                maxWidth: '280px',
              }}
              onClick={() => {
                if (block?.scrollToTop) {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }
              }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
