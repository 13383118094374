import { useMemo } from 'react';

import { Dropdown } from '@snippets';

export function DropdownFilters({ cms, onClick }) {
  const { filterItems, filtersHeading } = cms;

  const options = useMemo(
    () =>
      filterItems?.map((item) => {
        return {
          label: item,
          value: item,
        };
      }),
    [filterItems]
  );

  return (
    <Dropdown
      id="filters-dropdown"
      onClick={onClick}
      title={filtersHeading}
      items={options}
      initialIndex={0}
      overrideStyles
      sx={{
        zIndex: 20,
        width: '200px',
      }}
    />
  );
}
