import { useRef } from "react";
import { Flex } from "theme-ui";

import { OptionsGridItem } from "./OptionsGridItem";

export const OptionsGrid = ({
  index,
  isGrouping,
  isOptionsMap = false,
  isProduct = true,
  optionName,
  options,
  onClick,
  onMouseOver,
  product,
  productVariants,
  selectedOption,
  selectedVariant,
  selectedIndex,
  sx,
  theme = "dark",
  ...props
}) => {
  const startAtPrice = useRef(null);

  return (
    <Flex
      variant="flex.column"
      data-comp={`OptionsGrid-${optionName}`}
      {...props}
      sx={{
        flexWrap: "wrap",
        width: "100%",
        minHeight: isProduct ? "38px" : "53px",
        gap: "20px",
        minWidth: "fit-content",
        p: 0,
        ...sx,
      }}
      as="ul"
      aria-label={`Menu list containing ${options.length} items`}
    >
      {options?.map((option, index) => (
        <OptionsGridItem
          key={index}
          index={index}
          isGrouping={isGrouping}
          isOptionsMap={isOptionsMap}
          isProduct={isProduct}
          option={option}
          optionName={optionName}
          onClick={onClick}
          onMouseOver={onMouseOver}
          product={product}
          productVariants={productVariants}
          selectedIndex={selectedIndex}
          selectedOption={selectedOption}
          selectedVariant={selectedVariant}
          startAtPrice={startAtPrice}
          theme={theme}
        />
      ))}
    </Flex>
  );
};
