import { Paragraph, Flex, Heading } from 'theme-ui';

export function HalfBlockOrderedList({
  block,
  index,
  disabledPy,
  columnDirection,
  bgColor,
  color,
  ...props
}) {
  const bulletColor =
    block?.bulletColor || (bgColor === 'black' ? '#FFD700' : '#000000');

  return (
    <Flex
      data-comp="HalfBlockOrderedList"
      variant="flex.column.start.center"
      sx={{
        display: block?.hideMobile ? ['none', null, null, 'flex'] : 'flex',
        width: '100%',
        height: '100%',
        position: 'relative',
        pl:
          index === 0
            ? [0, null, null, 13]
            : [0, null, null, 9, 13, '80px', '110px'],
        pr:
          index === 0
            ? [0, null, null, 9, 13, '80px', '110px']
            : [0, null, null, 13],
        pt: disabledPy ? [index === 0 ? 13 : 0, null, null, 17] : 0,
        pb: disabledPy ? [index === 0 ? 0 : 13, null, null, 17] : 0,
      }}
      {...props}
    >
      <Heading
        variant="24.r"
        sx={{
          mb: [8, null, null, 11],
          color: color ? color : bgColor === 'black' ? 'white' : 'black',
        }}
      >
        {block?.heading}
      </Heading>
      <Flex variant="flex.column">
        {block?.listItems?.map((item, itemIndex, arr) => (
          <Flex
            key={itemIndex}
            variant="flex.row.start.center"
            sx={{
              mb: itemIndex !== arr.length - 1 ? [5, null, null, 7] : 0,
            }}
          >
            <Flex
              sx={{
                position: 'relative',
                width: ['36px', null, null, '40px'],
                minWidth: ['36px', null, null, '40px'],
                mr: [4, null, null, 6],
              }}
            >
              <svg
                width="41"
                height="36"
                viewBox="0 0 41 36"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: '100%' }}
              >
                <path
                  d="M30.2895 0.982896L40.3066 18.2935L30.3237 35.6239L10.3237 35.6436L0.306652 18.333L10.2895 1.00264L30.2895 0.982896Z"
                  fill={
                    bulletColor
                      ? bulletColor
                      : bgColor === 'black'
                      ? 'white'
                      : 'gold'
                  }
                />
              </svg>
              <Paragraph
                variant="label.1"
                sx={{
                  color: block?.itemColor
                    ? block?.itemColor
                    : bgColor === 'black'
                    ? 'black'
                    : 'white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  mt: 1,
                  textAlign: 'center',
                }}
              >
                {itemIndex + 1}
              </Paragraph>
            </Flex>
            <Paragraph
              variant="base.r2"
              color={
                color
                  ? color
                  : bgColor === 'black' || bulletColor === '#000000'
                  ? 'white'
                  : 'black'
              }
            >
              {item}
            </Paragraph>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
