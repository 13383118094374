import { Box, Paragraph, Heading, Flex, Image, Button } from 'theme-ui';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { StyledButton } from '@snippets';
import 'swiper/css';

SwiperCore.use([Navigation, Autoplay]);

export function TextCarousel({ cms: { slides } }) {
  const [swiper, setSwiper] = useState();

  return (
    <Flex
      data-comp="TextCarousel"
      variant="flex.section"
      sx={{
        bg: 'white',
        py: [13, null, null, 18],
        px: [6, null, null, 0],
      }}
    >
      <Swiper
        onSwiper={setSwiper}
        spaceBetween={0}
        slidesPerView={1}
        // slidesPerColumnFill='row'
        watchOverflow
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        autoplay={{
          enabled: true,
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop
        sx={{
          width: '100%',
          maxWidth: '1160px',
        }}
      >
        {slides?.map((slide, index) => (
          <SwiperSlide key={index}>
            <Flex
              variant="flex.column.center"
              sx={{
                maxWidth: ['80vw', null, null, null, '800px'],
                mx: 'auto',
                px: [6, null, null, 9],
              }}
            >
              <Heading
                variant="32.r"
                sx={{
                  textAlign: 'center',
                  mb: [4, null, null, 6],
                }}
              >
                {slide.heading}
              </Heading>
              <Paragraph
                variant="base.r"
                sx={{
                  textAlign: 'center',
                }}
              >
                {slide.description}
              </Paragraph>
              {slide.cta?.text && (
                <Box sx={{ mt: [10, null, null, 11] }}>
                  <StyledButton
                    text={slide.cta?.text}
                    href={slide.cta?.url}
                    newTab={slide.cta?.newTab}
                  />
                </Box>
              )}
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        className="swiper-button-prev"
        variant="buttons.plain"
        aria-label="Move to previous review slide"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '5%',
          height: 24,
          width: 28,
          zIndex: 2,
          transform: 'translateY(-50%)',
          marginLeft: '12px',
          '@media only screen and (max-width: 767px)': {
            marginLeft: '-12px',
          },
          '&:after': {
            content: '""',
          },
          '&:hover': {
            opacity: 1,
          },
        }}
        onClick={() => swiper.slidePrev()}
      >
        <Image
          src="/svg/carousel-arrow-left.svg"
          alt="Arrow Previous"
          sx={{
            height: '100%',
            position: 'absolute',
            objectFit: 'contain',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Button>

      <Button
        className="swiper-button-next"
        variant="buttons.plain"
        aria-label="Move to next review slide"
        sx={{
          position: 'absolute',
          top: '50%',
          right: '5%',
          height: 24,
          width: 28,
          zIndex: 2,
          transform: 'translateY(-50%)',
          marginLeft: '12px',
          '@media only screen and (max-width: 767px)': {
            marginLeft: '-12px',
          },
          '&:after': {
            content: '""',
          },
          '&:hover': {
            opacity: 1,
          },
        }}
        onClick={() => swiper.slideNext()}
      >
        <Image
          src="/svg/carousel-arrow-right.svg"
          alt="Arrow Next"
          sx={{
            height: '100%',
            position: 'absolute',
            objectFit: 'contain',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Button>
    </Flex>
  );
}

TextCarousel.Schema = {
  label: 'Text Carousel',
  key: 'text-carousel',
  fields: [
    {
      name: 'slides',
      label: 'Slides',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      fields: [
        {
          name: 'name',
          label: 'Item Name',
          component: 'text',
        },
        {
          name: 'heading',
          label: 'Heading',
          component: 'text',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'textarea',
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
      ],
    },
  ],
};
