import Script from 'next/script';
import { Box } from 'theme-ui';

import { useYotpoLoyalty } from '@hooks';
import store, { useRecoilValue } from '@store';

export const RedeemProducts = () => {
  useYotpoLoyalty();
  const { yotpoCustomer } = useRecoilValue(store.yotpoLoyaltyData);
  return (
    <>
      <Script
        async
        id="swell-rewards"
        src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/yIanDe8wBrDQPP_zPFi5Cg`}
        strategy="afterInteractive"
        type="text/javascript"
      />
      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={yotpoCustomer?.email}
        data-id={yotpoCustomer?.third_party_id}
        data-tags='"[]"'
        style={{ display: 'none' }}
      />
      <Box className="yotpo-widget-instance" data-yotpo-instance-id="236480" />
    </>
  );
};

RedeemProducts.displayName = 'RedeemProducts';
RedeemProducts.Schema = {
  label: 'Redeem Products',
  key: 'redeen-products',
  fields: [],
};
