import { useState, useEffect } from 'react';
import { Box, Button, Flex, Paragraph } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { StyledButton, StyledInput } from '@snippets';
import { useDataLayerActions } from '@hooks';

export const LoginForm = ({ action, ...props }) => {
  const customer = useCustomer();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { sendLogInEvent } = useDataLayerActions();

  const loginCustomer = (e) => {
    e.preventDefault();
    setIsLoading(true);
    customer.loginCustomer({
      email,
      password,
    });
    sendLogInEvent();
  };

  useEffect(() => {
    const errorArray = customer?.errors?.['customer.login'];
    if (!errorArray) return;
    let errors = [];
    if (errorArray?.length > 0) {
      setIsLoading(false);
      customer?.errors?.['customer.login'].forEach((err) => {
        let error = err.message;
        if (!error) return;
        if (error === 'Unidentified customer') {
          error = 'Your email and/or password do not match';
        }
        return errors.push(error + `.<br/>`);
      });
      errors.length > 0 ? setMessage(errors.join('')) : null;
      return;
    } else {
      setMessage('');
    }
  }, [customer]);

  return (
    <Box data-comp="LoginForm" {...props}>
      <Paragraph
        variant="text.24.r"
        sx={{
          mb: 8,
        }}
      >
        Welcome Back
      </Paragraph>

      <Flex
        variant="flex.column"
        as="form"
        sx={{ mb: 9 }}
        onSubmit={loginCustomer}
      >
        <StyledInput
          name="email"
          type="email"
          placeholder="Email Address"
          label="Email Address"
          id="signin-email"
          required
          value={email}
          action={setEmail}
        />

        <StyledInput
          name="password"
          type="password"
          placeholder="Password"
          label="Password"
          id="signin-password"
          required
          value={password}
          action={setPassword}
        />

        <Paragraph
          variant="sm"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          sx={{
            color: 'red',
            lineHeight: 26,
          }}
        />

        <StyledButton
          text="Sign In"
          isLoading={isLoading}
          variant="solid"
          sx={{
            width: '100%',
            mt: 9,
            mb: 9,
          }}
        />

        <Button
          variant="underlineLink"
          sx={{
            color: 'black',
            alignSelf: 'flex-start',
            fontWeight: 400,
            '&:before, &:after': {
              height: '1px',
            },
          }}
          onClick={() => action('recover_pass')}
        >
          Forgot password
        </Button>
      </Flex>
    </Box>
  );
};
