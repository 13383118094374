import { Flex, Paragraph, Image } from 'theme-ui';

import { LinkWrapper } from '@snippets';

export const OrderDetailsItem = ({ item, ...props }) => {
  return (
    <Flex
      data-comp="OrderDetailsItem"
      sx={{
        flexDirection: ['column', null, null, null, 'row'],
        justifyContent: ['flex-start', null, null, null, 'space-between'],
        borderBottom: '1px solid',
        borderColor: 'gray.3',
        py: 9,
      }}
      {...props}
    >
      <Flex
        variant="flex.row.start.center"
        sx={{
          flexBasis: ['auto', null, null, null, 'calc(50% - 10px)'],
        }}
      >
        <Image
          src={item.variant.image.src}
          alt={
            item.variant.image.altText ||
            `${item.title} - ${item.variant.title}`
          }
          sx={{
            width: '108px',
            height: '108px',
            objectFit: 'contain',
          }}
        />
        <Flex
          variant="flex.column.start"
          sx={{
            ml: 9,
            flex: 1,
          }}
        >
          <LinkWrapper href={`/products/${item.variant.product.handle}`}>
            <Paragraph
              variant="label.1"
              sx={{
                fontSize: [5, 6],
                lineHeight: [20, 24],
                color: 'gold',
                cursor: 'pointer',
              }}
            >
              {item.title}
            </Paragraph>
          </LinkWrapper>
          <Paragraph variant="label.2" sx={{ fontWeight: 400, mt: [2, 4] }}>
            {item.variant.title}
          </Paragraph>
          <Flex
            sx={{
              display: ['flex', null, null, null, 'none'],
              justifyContent: 'space-between',
              width: '100%',
              mt: 9,
              p: { variant: 'text.label.2', fontWeight: 400 },
            }}
          >
            <Paragraph>{item.quantity}</Paragraph>
            <Paragraph>
              {'$' + parseFloat(item.fallbackPrice.amount).toFixed(2)}
            </Paragraph>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        variant="flex.row.between.center"
        sx={{
          display: ['none !important', null, null, null, 'flex !important'],
          flexBasis: 'calc(50% - 10px)',
          p: { variant: 'text.label.2', fontWeight: 400 },
        }}
      >
        <Paragraph>{item.quantity}</Paragraph>
        <Paragraph>
          {'$' + parseFloat(item.fallbackPrice.amount).toFixed(2)}
        </Paragraph>
      </Flex>
    </Flex>
  );
};
