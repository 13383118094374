import React, { useState } from 'react';
import { Flex, Heading } from 'theme-ui';

import { HalfBlockAccordionItem } from './HalfBlockAccordionItem';

export function HalfBlockAccordion({
  block,
  index,
  disabledPy,
  bgColor,
  isCenterSingle,
  color,
  ...props
}) {
  const [indexOpen, setIndexOpen] = useState(null);

  return (
    <Flex
      data-comp="HalfBlockAccordion"
      variant="flex.column"
      sx={{
        display: block?.hideMobile ? ['none', null, null, 'flex'] : 'flex',
        width: '100%',
        height: '100%',
        alignItems: isCenterSingle
          ? 'center'
          : index === 0
          ? 'flex-end'
          : 'flex-start',
        pl: isCenterSingle
          ? 0
          : index === 0
          ? [0, null, null, 13]
          : [0, null, null, 9, 13, '80px', '110px'],
        pr: isCenterSingle
          ? 0
          : index === 0
          ? [0, null, null, 9, 13, '80px', '110px']
          : [0, null, null, 13],
        pt: disabledPy ? [0, null, null, 17] : 0,
        pb: disabledPy ? [13, null, null, 17] : 0,
        color,
      }}
      {...props}
    >
      <Heading
        variant="24.r"
        sx={{
          mb: [8, 9],
          width: '100%',
          maxWidth: [null, null, null, isCenterSingle ? '670px' : '440px'],
          color: color ? color : bgColor === 'black' ? 'white' : 'black',
          textAlign: isCenterSingle ? 'center' : null,
        }}
      >
        {block?.heading}
      </Heading>
      <Flex
        variant="flex.column"
        sx={{
          width: '100%',
          maxWidth: [null, null, null, isCenterSingle ? '670px' : '440px'],
        }}
      >
        {block?.faqs?.map((faq, index) => (
          <HalfBlockAccordionItem
            key={index}
            faq={faq}
            index={index}
            indexOpen={indexOpen}
            setIndexOpen={setIndexOpen}
            color={color ? color : bgColor === 'black' ? 'white' : 'black'}
          />
        ))}
      </Flex>
    </Flex>
  );
}
