const menuItemLink = [
  {
    label: 'Link',
    name: 'link',
    component: 'link',
  },
];

const imagesWithAlt = {
  label: 'Images',
  name: 'images',
  component: 'group-list',
  itemProps: {
    label: '{{item.alt}}',
  },
  fields: [
    {
      label: 'Alt',
      name: 'alt',
      component: 'text',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue: '',
    },
  ],
};

const menuItemsWithImages = [
  // level 1
  ...menuItemLink,
  imagesWithAlt,
  {
    label: 'Sub Items',
    name: 'subItems',
    component: 'group-list',
    itemProps: {
      label: '{{item.link.text}}',
    },
    defaultItem: () => ({
      name: 'New menu item',
      id: Math.random().toString(36).substr(2, 9),
      link: {
        text: '',
        url: '#',
      },
    }),
    fields: [
      // level 2
      ...menuItemLink,
      imagesWithAlt,
    ],
  },
];

export { imagesWithAlt, menuItemLink, menuItemsWithImages };
