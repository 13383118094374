import { useEffect, useState } from "react";
import { Box } from "theme-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/thumbs";

import { ProductImage } from "./ProductImage";

export function ProductImageCarousel({ productMedia, featuredMediaIndex }) {
  const [initialFeaturedMediaIndex, setInitialFeaturedMediaIndex] =
    useState(featuredMediaIndex);
  const [imageCarousel, setImageCarousel] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  useEffect(() => {
    if (featuredMediaIndex !== initialFeaturedMediaIndex) {
      imageCarousel.slideTo(featuredMediaIndex);
      setInitialFeaturedMediaIndex(featuredMediaIndex);
    }
  });
  if (!productMedia) return null;
  return (
    <Box
      data-comp="ProductImageCarousel"
      sx={{
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }}
    >
      <Swiper
        slidesPerView={1}
        watchOverflow
        allowTouchMove
        initialSlide={featuredMediaIndex}
        onSwiper={setImageCarousel}
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {productMedia.map((media, index, arr) => {
          if (!media?.image?.originalSrc) return null;
          return (
            <SwiperSlide key={media?.image?.id + index} virtualIndex={index}>
              <ProductImage
                src={media?.image?.originalSrc}
                alt={media?.image?.altText}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        slidesPerView="auto"
        watchSlidesProgress
        modules={[Thumbs]}
        onSwiper={setThumbsSwiper}
        sx={{
          position: "absolute",
          bottom: ["30px", null, null, "70px"],
          left: "0",
          right: "0",
          ".swiper-wrapper": {
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          },
          ".swiper-slide": {
            width: ["10px !important", null, null, "40px !important"],
            height: ["10px !important", null, null, "40px !important"],
            border: [null, null, null, "1px solid #4e4f50"],
            borderRadius: "50%",
            overflow: "hidden",
            boxShadow: [
              null,
              null,
              null,
              "0 5px 20px rgba(255, 255, 255, 0.1)",
            ],
            background: "rgba(255, 255, 255, 0.2)",
          },
          ".swiper-slide-thumb-active": {
            borderColor: "#cab683",
            backgroundColor: "white",
          },
        }}
      >
        {productMedia.map((media, index, arr) => {
          if (!media?.image?.originalSrc) return null;
          return (
            <SwiperSlide
              key={`${media?.image?.id}thumbs${index}`}
              virtualIndex={index}
            >
              <img
                src={media.image?.originalSrc}
                alt=""
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  display: ["none", null, null, "block"],
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
