import { useMemo } from 'react';
import { Markdown } from '@snippets';
import { containerTinaSettings, parseContainerSettings } from '@utils';

export function MarkdownBody({
  cms: { body, containerSettings, textSettings },
}) {
  const containerSx = useMemo(
    () => parseContainerSettings(containerSettings),
    [containerSettings]
  );

  return (
    <Markdown
      text={body}
      textAlign={textSettings?.centerText ? 'center' : 'left'}
      style={containerSx}
    />
  );
}

MarkdownBody.displayName = 'MarkdownBody';

MarkdownBody.Schema = {
  label: 'Markdown Body',
  key: 'markdownBody',
  fields: [
    {
      name: 'body',
      component: 'markdown',
      label: 'Body',
      defaultValue:
        'I am a text field with *italic* __bold__ and other powers...',
    },
    {
      label: 'Text Settings',
      name: 'textSettings',
      component: 'group',
      fields: [
        {
          label: 'Center Align All Text',
          name: 'centerText',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
      ],
    },
    containerTinaSettings,
  ],
};
