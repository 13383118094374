import { useMemo } from 'react';
import { Paragraph, Flex, Box, Image } from 'theme-ui';

export const HalfBlockAccordionItem = ({
  faq,
  index,
  indexOpen,
  setIndexOpen,
  color = 'black',
  ...props
}) => {
  const activeIndex = index === indexOpen;

  const splitAnswer = useMemo(() => faq?.answer?.split('\n'), [faq]);

  return (
    <Flex
      variant="flex.column"
      data-comp="HalfBlockAccordionItem"
      key={index}
      sx={{
        height: 'auto',
        maxHeight: activeIndex ? '300px' : '43px',
        overflow: 'hidden',
        transition: 'max-height 0.4s ease-in-out',
        borderBottom: '1px solid',
        borderTop: index === 0 ? '1px solid' : 0,
        borderColor: 'gray.3',
      }}
      {...props}
    >
      <Flex
        variant="flex.row.between.center"
        sx={{
          cursor: 'pointer',
          minHeight: '43px',
        }}
        onClick={() => setIndexOpen(activeIndex ? null : index)}
      >
        <Paragraph variant="label.3" color={color}>
          {faq.question}
        </Paragraph>
        <Image
          src={
            activeIndex
              ? `/svg/minus-${color === 'black' ? 'black' : 'gold'}.svg`
              : `/svg/plus-${color === 'black' ? 'black' : 'gold'}.svg`
          }
          draggable="false"
          sx={{ userSelect: 'none' }}
        />
      </Flex>
      <Box sx={{ mb: 8 }}>
        {splitAnswer?.map((p, index, arr) => {
          if (!p) return null;
          return (
            <Paragraph
              key={index}
              variant="sm"
              sx={{
                mb: index !== arr.length - 1 ? 4 : 0,
                color,
              }}
            >
              {p}
            </Paragraph>
          );
        })}
      </Box>
    </Flex>
  );
};
