import { Box, Image } from 'theme-ui';

export const WaveSvg = ({
  isTop = false
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: ['18px', null, null, '24px'],
        position: 'absolute',
        bottom: isTop ? null : ['-13px', null, null, '-19px'],
        top: !isTop ? null : ['-13px', null, null, '-19px'],
        left: 0,
        zIndex: 10,
        overflowX: 'hidden',
        userSelect: 'none',
        transform: isTop ? 'rotate(180deg)' : null,
      }}
    >
      <Image
        alt=""
        src="/svg/wave.svg"
        draggable="false"
        sx={{
          width: ['2016px', null, null, '2880px'],
          minWidth: ['2016px', null, null, '2880px'],
          height: '100%',
          minHeight: '100%',
          position: 'absolute',
          top: '-3px',
          left: '50%',
          transform: 'translateX(-50%)',
          userSelect: 'none',
        }}
      />
    </Box>
  );
};
