import { useEffect, useState, useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Flex, Button, Paragraph, Grid } from 'theme-ui';

import store, { useRecoilState, useRecoilValue } from '@store';
import { InlineHeading, Svg } from '@snippets';

import { OneQuantity } from './Svgs/OneQuantity';
import { SixQuantity } from './Svgs/SixQuantity';

export function ProductQuantitySelector({ selectedVariant }) {
  const settings = useSettings();
  const { enabled: enableDiscountMessaging, steps } = {
    ...settings?.cart.discount,
  };
  const [activeQuantity, setActiveQuantity] = useRecoilState(
    store.activeQuantity(selectedVariant?.product?.handle)
  );

  const activeQuantityPrice = useRecoilValue(
    store.activeQuantityPrice(selectedVariant?.product?.handle)
  );
  const activeQuantityPriceSubscription = useRecoilValue(
    store.activeQuantityPriceSubscription(selectedVariant?.product?.handle)
  );

  const handleSelectedQuantity = (value) => {
    setActiveQuantity(parseInt(value, 10));
  };

  const activeDiscount = useMemo(() => {
    if (!enableDiscountMessaging) return null;

    const freeShippingStep = steps.find((step) => step.isFreeShippingStep);

    const active = [...steps]
      ?.reverse()
      ?.find(
        (step) =>
          activeQuantityPrice > step.minimum ||
          activeQuantityPriceSubscription > step.minimum
      );

    return {
      ...active,
      enablesFreeShipping: active?.minimum >= freeShippingStep?.minimum,
    };
  }, [
    steps,
    enableDiscountMessaging,
    activeQuantityPrice,
    activeQuantityPriceSubscription,
  ]);

  const quantities = [
    { value: 1, label: 'One', src: ['/svgs/quantity/one.svg#one'] },
    {
      value: 2,
      label: 'Two',
      note: 'Most Popular',
      src: ['/svgs/quantity/one.svg#one', '/svgs/quantity/one.svg#one'],
    },
    {
      value: 3,
      label: 'Three',
      src: [
        '/svgs/quantity/one.svg#one',
        '/svgs/quantity/one.svg#one',
        '/svgs/quantity/one.svg#one',
      ],
    },
    {
      value: 6,
      label: 'Six',
      note: 'Best Value',
      src: '/svgs/quantity/six.svg#six',
    },
  ];

  return (
    <Flex sx={{ flexDirection: 'column', mb: 10 }}>
      <InlineHeading theme="dark" text="Quantity" sx={{ mb: 8 }} />

      <Grid
        sx={{
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridGap: 8,
        }}
      >
        {quantities?.map((quantity, index) => {
          const isActive = quantity.value === activeQuantity;
          return (
            <Flex
              key={index}
              sx={{
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              {quantity.note && (
                <Flex
                  sx={{
                    bg: '#CAB683',
                    width: '100%',
                    minHeight: '14px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'flex-start',
                    px: 1,
                    py: 1,
                  }}
                >
                  <Paragraph
                    variant="label.3"
                    sx={{
                      fontSize: ['9px', null, null, null, '11px'],
                      lineHeight: ['9px', null, null, null, '11px'],
                      fontWeight: 400,
                      letterSpacing: '0.48px',
                      color: 'black',
                      textAlign: 'center',
                      pt: 1,
                    }}
                  >
                    {quantity.note}
                  </Paragraph>
                </Flex>
              )}

              <Button
                variant="buttons.plain"
                onClick={() => {
                  handleSelectedQuantity(quantity.value);
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '85px',
                  border: isActive ? '2px solid' : '1px solid',
                  borderColor: isActive ? '#CAB683' : '#D9D9D9',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: isActive ? '#CAB683' : '#D9D9D9',
                  '&:hover': {
                    color: '#CAB683',
                    borderColor: '#CAB683',
                    bg: '#333333',
                  },
                }}
              >
                <Flex sx={{ mt: '8px' }}>
                  {quantity?.value !== 6 &&
                    quantity?.src?.map((src) => {
                      return (
                        <OneQuantity
                          fill={isActive ? '#CAB683' : undefined}
                          border={isActive ? '#88723A' : undefined}
                        />
                      );
                    })}
                  {quantity?.value === 6 && (
                    <SixQuantity
                      fill={isActive ? '#CAB683' : undefined}
                      border={isActive ? '#88723A' : undefined}
                    />
                  )}
                </Flex>
                <Paragraph
                  variant="label.3"
                  sx={{
                    flexShrink: 0,
                    color: 'white',
                    my: '10px',
                  }}
                >
                  {quantity.label}
                </Paragraph>
              </Button>
              {isActive ? (
                <Paragraph
                  variant="text.xs"
                  sx={{
                    color: '#CAB683',
                    my: '10px',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontFamily: 'heading',
                  }}
                >
                  Selected
                </Paragraph>
              ) : (
                <Paragraph
                  variant="text.xs"
                  sx={{
                    color: 'transparent',
                    my: '10px',
                    textAlign: 'center',
                    fontFamily: 'heading',
                  }}
                >
                  Selected
                </Paragraph>
              )}
            </Flex>
          );
        })}
      </Grid>

      <Flex sx={{ mt: 9 }}>
        {activeDiscount && (
          <Flex sx={{ justifyContent: 'center', alignItems: 'center', mr: 11 }}>
            {activeDiscount?.enablesFreeShipping ? (
              <Svg
                src="/svgs/quantity/free-shipping.svg#free-shipping"
                viewBox="0 0 16 16"
                sx={{
                  width: '20px',
                }}
              />
            ) : (
              <Svg
                src="/svgs/quantity/no-free-shipping.svg#no-free-shipping"
                viewBox="0 0 16 16"
                draggable={false}
                sx={{
                  width: '20px',
                }}
              />
            )}
            <Paragraph
              variant="label.3"
              sx={{
                fontSize: '11px',
                lineHeight: 'normal',
                fontWeight: 400,
                letterSpacing: '0.6px',
                color: 'white',
                pt: 1,
                ml: 4,
              }}
            >
              Free Shipping
            </Paragraph>
          </Flex>
        )}

        {activeDiscount && activeDiscount?.pdpMessage && (
          <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Svg
              src="/svgs/quantity/truffle.svg#truffle"
              viewBox="0 0 20 16"
              sx={{
                width: '24px',
              }}
            />
            <Paragraph
              variant="label.3"
              sx={{
                fontSize: '11px',
                lineHeight: 'normal',
                fontWeight: 400,
                letterSpacing: '0.6px',
                color: 'white',
                pt: 1,
                ml: 4,
              }}
            >
              {activeDiscount.pdpMessage}
            </Paragraph>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
