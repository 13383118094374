import { videoCmsSettings, imageCmsSettings } from "@utils";

const VideoBlock = {
  label: "Video Block",
  key: "video-block",
  fields: [
    ...videoCmsSettings({
      overlay: false,
      videoDescription: "*Required. Max quality: High def (mp4, 720x720).",
      mobileVideoDescription:
        "*Required. Max quality: Standard def (mp4, 540x540).",
    }).fields,
    {
      label: "Aspect Ratio (Mobile)",
      component: "radio-group",
      direction: "horizontal",
      variant: "radio",
      name: "ratio",
      description: "Choose video aspect ratio for mobile.",
      options: [
        {
          value: "100%",
          label: "Square (1:1)",
        },
        {
          value: "140%",
          label: "Portrait (5:7)",
        },
        {
          value: "56.25%",
          label: "Wide (16:9)",
        },
      ],
      defaultValue: "100%",
    },
    {
      label: "Aspect Ratio (Tablet & Desktop)",
      component: "radio-group",
      direction: "horizontal",
      variant: "radio",
      name: "ratioDt",
      description: "Choose video aspect ratio for desktop.",
      options: [
        {
          value: "100%",
          label: "Square (1:1)",
        },
        {
          value: "140%",
          label: "Portrait (5:7)",
        },
        {
          value: "56.25%",
          label: "Wide (16:9)",
        },
      ],
      defaultValue: "100%",
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

const ImageBlock = {
  label: "Image Block",
  key: "image-block",
  fields: [
    ...imageCmsSettings({ overlay: false }).fields,
    {
      label: "Aspect Ratio (Mobile)",
      component: "radio-group",
      direction: "horizontal",
      variant: "radio",
      name: "ratio",
      description: "Choose image aspect ratio for mobile.",
      options: [
        {
          value: "100%",
          label: "Square (1:1)",
        },
        {
          value: "140%",
          label: "Portrait (5:7)",
        },
        {
          value: "56.25%",
          label: "Wide (16:9)",
        },
      ],
      defaultValue: "100%",
    },
    {
      label: "Aspect Ratio (Tablet & Desktop)",
      component: "radio-group",
      direction: "horizontal",
      variant: "radio",
      name: "ratioDt",
      description: "Choose image aspect ratio for desktop.",
      options: [
        {
          value: "100%",
          label: "Square (1:1)",
        },
        {
          value: "140%",
          label: "Portrait (5:7)",
        },
        {
          value: "56.25%",
          label: "Wide (16:9)",
        },
      ],
      defaultValue: "100%",
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

const OrderedListBlock = {
  label: "Ordered List Block",
  key: "ordered-list-block",
  fields: [
    {
      name: "heading",
      label: "Heading",
      component: "text",
    },
    {
      name: "bulletColor",
      label: "Bullet Color",
      component: "color",
    },
    {
      name: "itemColor",
      label: "Item Color",
      component: "color",
    },
    {
      name: "listItems",
      label: "Ordered List Items",
      component: "list",
      field: {
        component: "text",
      },
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

const TextBlock = {
  label: "Text Block",
  key: "text-block",
  fields: [
    {
      name: "eyebrow",
      label: "Eyebrow",
      component: "text",
    },
    {
      name: "heading",
      label: "Heading",
      component: "text",
      defaultValue: "Heading...",
    },
    {
      name: "headingColor",
      label: "Heading Color",
      component: "color",
    },
    {
      name: "description",
      label: "Description",
      component: "textarea",
      defaultValue: "Text description here...",
    },
    {
      name: "descriptionColor",
      label: "Description Color",
      component: "color",
    },
    {
      name: "cta",
      component: "link",
      label: "Button",
      defaultValue: {
        text: "Buy Now",
        url: "#",
      },
    },
    {
      label: "Content Alignment",
      component: "radio-group",
      direction: "horizontal",
      variant: "radio",
      name: "content",
      options: [
        {
          value: "flex-start",
          label: "Left",
        },
        {
          value: "center",
          label: "Center",
        },
        {
          value: "flex-end",
          label: "Right",
        },
      ],
      defaultValue: "center",
    },
    {
      name: "scrollToTop",
      label: "Enable Scroll To Top",
      description: "Override button link with a scroll to the top of the page.",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
    {
      name: "hideButton",
      label: "Hide Button",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

const TextWithImageBlock = {
  label: "Text With Image Block",
  key: "text-image-block",
  fields: [
    {
      name: "heading",
      label: "Heading",
      component: "text",
      defaultValue: "Heading...",
    },
    {
      name: "headingColor",
      label: "Heading Color",
      component: "color",
    },
    {
      name: "description",
      label: "Description",
      component: "textarea",
      defaultValue: "Text description here...",
    },
    {
      name: "descriptionColor",
      label: "Description Color",
      component: "color",
    },
    {
      name: "cta",
      component: "link",
      label: "Button",
      defaultValue: {
        text: "Buy Now",
        url: "#",
      },
    },
    {
      label: "Image Settings",
      name: "image",
      component: "group",
      fields: [...imageCmsSettings({ overlay: false }).fields],
    },
    {
      label: "Content Alignment",
      component: "radio-group",
      direction: "horizontal",
      variant: "radio",
      name: "content",
      options: [
        {
          value: "flex-start",
          label: "Left",
        },
        {
          value: "center",
          label: "Center",
        },
        {
          value: "flex-end",
          label: "Right",
        },
      ],
      defaultValue: "center",
    },
    {
      name: "scrollToTop",
      label: "Enable Scroll To Top",
      description: "Override button link with a scroll to the top of the page.",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
    {
      name: "hideButton",
      label: "Hide Button",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

const AccordionBlock = {
  label: "Accordion Block",
  key: "accordion-block",
  fields: [
    {
      name: "heading",
      label: "Heading",
      component: "text",
    },
    {
      label: "Questions and Answers",
      name: "faqs",
      component: "group-list",
      itemProps: {
        label: "{{item.question}}",
      },
      fields: [
        {
          label: "Question",
          name: "question",
          component: "text",
        },
        {
          label: "Answer",
          name: "answer",
          component: "textarea",
        },
      ],
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

const KlayvioForm = {
  label: "Klayvio Form",
  key: "klayvio-form",
  fields: [
    {
      name: "formId",
      label: "Form ID",
      component: "text",
    },
  ],
};

const NutritionBlock = {
  label: "Nutrition Block",
  key: "nutrition-block",
  fields: [
    {
      name: "productName",
      label: "Product Name (optional)",
      description:
        "Useful if there are more than one nutrional facts on a page.",
      component: "text",
    },
    {
      name: "servingSize",
      label: "Serving Size",
      component: "text",
    },
    {
      name: "servingPerContainer",
      label: "Serving Per Container",
      component: "text",
    },
    {
      name: "footnote",
      label: "Footnote",
      component: "textarea",
    },
    {
      name: "ingredients",
      label: "Ingredients",
      component: "textarea",
    },
    {
      label: "Facts",
      name: "facts",
      component: "group-list",
      itemProps: {
        label: "{{item.name}}",
      },
      fields: [
        {
          label: "Fact Name",
          name: "name",
          component: "text",
        },
        {
          label: "Fact Value",
          name: "value",
          component: "text",
        },
      ],
    },
    {
      name: "hideMobile",
      label: "Hide Block on Mobile",
      component: "toggle",
      toggleLabels: {
        true: "On",
        false: "Off",
      },
      defaultValue: false,
    },
  ],
};

export function Schema() {
  return {
    label: "Half Blocks",
    key: "half-blocks",
    fields: [
      {
        name: "backgroundColor",
        label: "Background Color",
        component: "color",
        defaultValue: "#ffffff",
      },
      {
        name: "color",
        label: "Text Color",
        component: "color",
        defaultValue: "#000000",
      },
      {
        name: "py",
        label: "Top and Bottom Padding",
        component: "radio-group",
        direction: "horizontal",
        variant: "radio",
        options: [
          {
            value: "on",
            label: "Show",
          },
          {
            value: "off",
            label: "Hide",
          },
        ],
        defaultValue: "on",
      },
      {
        name: "columnDirection",
        label: "Vertical Stack Order",
        component: "radio-group",
        direction: "horizontal",
        variant: "radio",
        options: [
          {
            value: "column",
            label: "First block on top",
          },
          {
            value: "column-reverse",
            label: "Second block on top",
          },
        ],
        defaultValue: "column",
      },

      {
        label: "Section Settings",
        name: "section",
        component: "group",
        fields: [
          {
            label: "Mobile Settings",
            name: "mobile",
            component: "group",
            fields: [
              {
                label: "Container width (mobile)",
                component: "select",
                name: "maxWidth",
                options: [
                  { label: "Full Bleed", value: "100%" },
                  { label: "Contained (Wide 90%)", value: "90%" },
                  { label: "Contained (Medium 75%)", value: "75%" },
                  { label: "Contained (Narrow 60%)", value: "60%" },
                ],
              },
            ],
          },
          {
            label: "Desktop Settings",
            name: "desktop",
            component: "group",
            fields: [
              {
                label: "Container width (desktop)",
                component: "select",
                name: "maxWidth",
                options: [
                  { label: "Full Bleed", value: "100%" },
                  { label: "Contained (Wide 1440px)", value: "90em" },
                  { label: "Contained (Medium 1280px)", value: "80em" },
                  { label: "Contained (Narrow 976px)", value: "61em" },
                ],
              },
              {
                name: "centerSingle",
                component: "toggle",
                label: "Center Single Block",
                toggleLabels: {
                  true: "On",
                  false: "Off",
                },
                defaultValue: false,
              },
            ],
          },
        ],
      },

      {
        label: "Half Blocks",
        name: "blocks",
        component: "blocks",
        description:
          "There must be two blocks at all times. Drag items to rearrage horizontal order.",
        validate: {
          maxItems: 2,
        },
        templates: {
          "accordion-block": AccordionBlock,
          "image-block": ImageBlock,
          "ordered-list-block": OrderedListBlock,
          "nutrition-block": NutritionBlock,
          "text-block": TextBlock,
          "text-image-block": TextWithImageBlock,
          "video-block": VideoBlock,
          "klayvio-form": KlayvioForm,
        },
      },
    ],
  };
}
