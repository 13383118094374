import { useState, useEffect } from 'react';

export const useProductsFromHandles = ({ handles } = { handles: null }) => {
  if (!handles) throw new Error('[handles] prop not provided');
  if (!handles?.length) throw new Error('[handles] prop has no handles');

  const [products, setProducts] = useState({});
  const [fetched, setFetched] = useState(false);

  const fetchBundleProductsFromHandles = () => {
    let unMounting;
    const fetchProductFromHandle = (handle) => {
      // if we have already fetched return it, else fetch it
      return (
        products?.[handle] ??
        fetch(`/json/products/item/${handle}.json`)
          .then((response) => response.json())
          .catch((error) => {
            console.error('Problem fetching', handle, error.message);
            return null;
          })
      );
    };

    const bundleProducts = handles.map(fetchProductFromHandle);

    const setBundleProducts = (_products) => {
      if (_products?.length) {
        if (unMounting) return;
        setFetched(true);
        const mergedProducts = (prevProducts) => {
          const newProducts = _products.reduce((byHandle, product) => {
            product ? (byHandle[product.handle] = product) : null;
            return byHandle;
          }, {});

          return {
            ...prevProducts,
            ...newProducts,
          };
        };
        if (unMounting) return;
        setProducts(mergedProducts);
      } else {
        // didn't fetch
        if (unMounting) return;
        setFetched(true);
      }
    };

    Promise.all(bundleProducts).then(setBundleProducts);

    return () => {
      unMounting = true;
    };
  };

  useEffect(fetchBundleProductsFromHandles, [handles.join('|')]);

  return {
    products,
    fetched,
    success: Object.keys(products).length === handles.length,
  };
};
