import { useEffect } from 'react';
import { Flex, Box } from 'theme-ui';
import { useRouter } from 'next/router';

import { isBrowser } from '@utils';
import { useHideCustomizerBar } from '@hooks';
import { MenuDrawer } from '@snippets';

import { PromoBar } from './PromoBar';
import { Menu } from './Menu';

const HIDE_CUSTOMIZER_TOOLBAR = false;

export function Header(props) {
  const router = useRouter();
  useHideCustomizerBar(HIDE_CUSTOMIZER_TOOLBAR);

  const setHeaderHeightEffect = () => {
    const headerHeight = () => {
      const mediaQueryList = window.matchMedia('(max-width: 48em)');
      if (mediaQueryList.matches) {
        document.documentElement.style.setProperty(
          '--header-height',
          `${48}px`
        );
      } else {
        document.documentElement.style.setProperty(
          '--header-height',
          `${64}px`
        );
      }
    };
    window.addEventListener('resize', headerHeight);
    headerHeight();

    return () => {
      window.removeEventListener('resize', headerHeight);
    };
  };

  useEffect(setHeaderHeightEffect, []);

  useEffect(() => {
    if (!isBrowser || !router.isReady) return;
    if (router.query.discount) {
      window.sessionStorage.setItem('discount', router.query.discount);
    }
  }, [isBrowser, router.isReady]);

  return (
    <>
      <Flex
        data-comp="Header"
        as="header"
        sx={{
          position: 'sticky',
          flexDirection: 'column',
          width: '100%',
          top: 0,
          zIndex: 60,
          height: 'auto',
          boxShadow: 'bottom',
          alignItems: 'center',
          bg: 'black',
        }}
      >
        <Box
          data-comp="MenuWrapper"
          sx={{
            bg: 'black',
            width: '100%',
            zIndex: 2,
          }}
        >
          {/* top bar menu */}
          <Menu />
        </Box>

        <MenuDrawer />
      </Flex>

      <PromoBar />
    </>
  );
}
