export const space = [
  /*
    DON'T ADD NEW SPACES AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  0, // 0
  2, // 1
  4, // 2
  6, // 3
  8, // 4
  10, // 5
  12, // 6
  14, // 7
  16, // 8
  20, // 9
  24, // 10
  32, // 11
  36, // 12
  40, // 13
  48, // 14
  52, // 15
  64, // 16
  72, // 17
  80, // 18
  100, // 19
  128, // 20
  150, // 21
  200, // 22
  256, // 23
  400, // 24
  512, // 25
  /* DON'T ADD NEW SPACES AS IT WILL MESS UP THE SCALE ORDER */
];
