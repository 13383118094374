import { useRef, useEffect } from 'react';
import { Paragraph, Flex, Image, Box } from 'theme-ui';
import { useSwipeable } from 'react-swipeable';
import { useRouter } from '@backpackjs/storefront';

import store, {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from '@store';
import { CartCounterToggle, ProductInSideDrawer } from '@snippets';

export const SideDrawer = (props) => {
  const setOverlay = useSetRecoilState(store.overlay);
  const [sideDrawer, setSideDrawer] = useRecoilState(store.sideDrawer);
  const sideDrawerIsProduct$ = useRecoilValue(store.sideDrawerIsProduct$);
  const sideDrawerRef = useRef();
  const router = useRouter();

  const closeSideDrawer = () => {
    setSideDrawer(null);
    setOverlay(false);
  };

  useEffect(() => {
    // close side quick add product drawer if not in collection
    const isCollectionPage = router?.asPath?.includes('/collection');
    if (!isCollectionPage) {
      closeSideDrawer();
    }
  }, [router.asPath]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      closeSideDrawer();
    },
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  });

  const refPassthrough = (el) => {
    swipeHandlers.ref(el);
    sideDrawerRef.current = el;
  };

  return (
    <Box
      as="aside"
      data-comp="SideDrawer"
      {...props}
      {...swipeHandlers}
      ref={refPassthrough}
      sx={{
        zIndex: 61,
        position: 'fixed',
        top: 0,
        right: 0,
        minHeight: `var(--viewport-height)`,

        width: ['100%', null, null, null, '375px'],
        backgroundColor: 'black',
        boxShadow: '10px 0px 30px rgb(0 0 0 / 8%)',
        transform: sideDrawer ? 'translateX(0)' : 'translateX(100%)',
        willChange: 'transform',
        transition: 'transform .3s ease-in-out',
        ...props.sx,
      }}
    >
      {/* content area */}
      <Box
        data-comp="SideDrawerScroll"
        sx={{
          height: `var(--viewport-height)`,
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
          webkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {/* Head */}
        <Flex
          variant="flex.column"
          sx={{
            position: 'sticky',
            backgroundColor: 'black',
            zIndex: 60,
            top: 0,
            minHeight: '48px',
            borderBottom: '1px solid',
            borderColor: 'gray.6',
          }}
        >
          <Flex
            variant="flex.row.between.center"
            sx={{
              minHeight: '48px',
              pr: 9,
              pl: 8,
            }}
          >
            <Image
              sx={{ mr: 5, cursor: 'pointer', height: 'auto' }}
              onClick={closeSideDrawer}
              alt="Close Quick Add"
              src="/svg/arrow-left.svg"
              width="20px"
            />

            <Paragraph
              variant="label.2"
              sx={{
                color: 'white',
                mr: 'auto',
                fontSize: 3,
                pt: '3px',
              }}
            >
              {sideDrawerIsProduct$ ? sideDrawer.data.product.title : null}
            </Paragraph>

            <CartCounterToggle />
          </Flex>
        </Flex>

        {sideDrawerIsProduct$ ? (
          <ProductInSideDrawer
            handle={sideDrawer.data.product.handle}
            inQuickAdd={true}
          />
        ) : null}
      </Box>
    </Box>
  );
};
