import React from 'react';
import { InlineHeading } from '@snippets';
import { jsx, Box, Flex, Paragraph } from 'theme-ui';
import { ShopRecipeProductItem } from './ShopRecipeProductItem';

export function ArticleSidebar({ sidebar, ...props }) {
  const haveCookTime = sidebar?.cookTime;
  const enableCookTime = sidebar?.cookTimeDisable;
  const havePrepTime = sidebar?.prepTime;
  const enablePrepTime = sidebar?.prepTimeDisable;
  const haveIngredients = sidebar?.ingredients?.length;
  const enableIngredients = sidebar?.ingredientsDisable;
  const haveServings = sidebar?.servings;
  const enableServings = sidebar?.servingsDisable;
  const haveShopRecipeProducts =
    sidebar?.shopRecipe?.length &&
    (sidebar?.shopRecipe[0]?.product?.handle || sidebar?.shopRecipe[0]?.handle);

  return (
    <Box
      data-comp="ArticleSidebar"
      {...props}
      sx={{
        pt: [10, 12, 14, 15],
        ...props.sx,
      }}
    >
      {/* Cook Time */}
      {haveCookTime && enableCookTime ? (
        <Flex variant="flex.column" sx={{ mb: 10 }}>
          <Paragraph variant="text.label.2">Cook Time</Paragraph>
          <Paragraph variant="text.base">{sidebar.cookTime}</Paragraph>
        </Flex>
      ) : null}

      {/* Prep Time */}
      {havePrepTime && enablePrepTime ? (
        <Flex variant="flex.column" sx={{ mb: 10 }}>
          <Paragraph variant="text.label.2">Prep Time</Paragraph>
          <Paragraph variant="text.base">{sidebar.prepTime}</Paragraph>
        </Flex>
      ) : null}

      {/* Servings */}
      {haveServings && enableServings ? (
        <Flex variant="flex.column" sx={{ mb: 10 }}>
          <Paragraph variant="text.label.2">Servings</Paragraph>
          <Paragraph variant="text.base">{sidebar.servings}</Paragraph>
        </Flex>
      ) : null}

      {/* Ingredient */}
      {haveIngredients && enableIngredients ? (
        <Flex variant="flex.column" sx={{ mb: 11 }}>
          <InlineHeading
            text="Ingredients"
            sx={{ width: '100%', mb: [6] }}
            size="medium"
          />

          {sidebar.ingredients.map((ingredient, index) => (
            <Paragraph
              variant="text.base"
              key={ingredient + index}
              sx={{
                mb: '10px',
              }}
            >
              {ingredient}
            </Paragraph>
          ))}
        </Flex>
      ) : null}

      {/* Shop The recipe */}
      {haveShopRecipeProducts ? (
        <Flex variant="flex.column" sx={{ mb: 8 }}>
          <InlineHeading
            text="Shop Recipe"
            sx={{ width: '100%', mb: [7, null, null, 8] }}
            size="medium"
          />
          {sidebar.shopRecipe.map((productField, index) => {
            const product = productField?.product || productField;

            if (!product?.handle) return null;
            return (
              <ShopRecipeProductItem
                key={product.handle + index}
                handle={product.handle}
              />
            );
          })}
        </Flex>
      ) : null}
    </Box>
  );
}
