export default {
  label: 'Subscriptions Settings',
  name: 'subscriptions',
  component: 'group',
  fields: [
    {
      component: 'list',
      name: 'intervals',
      label: 'Frequency Intervals',
      description: 'This number value must match the values in Recharge.',
      field: {
        component: 'number',
      },
    },
    {
      component: 'text',
      name: 'unit',
      label: 'Frequency Unit',
      description:
        'This value must match Recharge. Example: days, weeks, months',
      defaultValue: 'days',
    },
    {
      component: 'group-list',
      name: 'productGroups',
      label: 'Product Groups',
      description: 'Products used for swaps and add-ons.',
      itemProps: {
        label: '{{item.filter}}',
      },
      fields: [
        {
          component: 'text',
          name: 'filter',
          label: 'Group Label',
          description:
            'Label for product grouping. Example: Hot Sauce, Pasta Sauce, Oil, etc.',
        },
        {
          component: 'list',
          name: 'products',
          label: 'Products',
          description: 'Products associated to this group.',
          field: {
            component: 'productSearch',
          },
        },
      ],
    },
    {
      component: 'image',
      name: 'discountImage',
      label: 'Discount Background Image',
      description: 'Shows on retention step in the Cancel modal.',
    },
    {
      label: 'No Subscriptions Content',
      name: 'nosubscriptions',
      component: 'group',
      fields: [
        {
          component: 'text',
          name: 'subheading',
          label: 'Subheading',
        },
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
        },
        {
          component: 'group-list',
          name: 'block',
          label: 'Content Block',
          itemProps: {
            label: '{{item.heading}}',
          },
          fields: [
            {
              component: 'image',
              name: 'image',
              label: 'Image',
            },
            {
              component: 'text',
              name: 'heading',
              label: 'Heading',
            },
            {
              component: 'textarea',
              name: 'content',
              label: 'Content',
            },
          ],
        },
        {
          component: 'text',
          name: 'ctaheading',
          label: 'CTA Heading',
        },
        {
          component: 'textarea',
          name: 'ctacontent',
          label: 'CTA Content',
        },
        {
          component: 'link',
          name: 'primary',
          label: 'Primary Link',
        },
        {
          component: 'link',
          name: 'secondary',
          label: 'Secondary Link',
        },
      ],
    },
  ],
};
