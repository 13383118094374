import { Flex, Button, Image, Spinner } from 'theme-ui';

export const ReferSubmitButton = ({
  submitted,
  handleSendReferralEmail,
  setOpacity,
  opacity,
  isLoading,
  sx,
}) => (
  <Flex
    data-comp="ReferSubmitButton"
    variant="flex.row.center.center"
    sx={{
      minWidth: '80px',
      height: '44px',
      ...sx,
    }}
  >
    {isLoading ? (
      <Spinner
        sx={{
          height: '25px',
          color: 'black',
        }}
      />
    ) : submitted ? (
      <Image
        src="/svg/check-mark.svg"
        onLoad={() => setOpacity(1)}
        sx={{
          width: '18px',
          transition: 'opacity 0.3s ease-in-out',
          opacity,
        }}
      />
    ) : (
      <Button
        sx={{
          color: 'black',
          fontSize: 1,
          lineHeight: 18,
          letterSpacing: 0.08,
          textAlign: 'center',
          px: 8,
          py: 6,
          width: '100%',
        }}
        onClick={handleSendReferralEmail}
      >
        Submit
      </Button>
    )}
  </Flex>
);
