export const containerTinaSettings = {
  label: 'Container Settings',
  name: 'containerSettings',
  component: 'group',
  fields: [
    {
      name: 'maxWidth',
      label: 'Max Width',
      component: 'select',
      options: [
        { value: 'narrow', label: 'Narrow' },
        { value: 'medium', label: 'Medium' },
        { value: 'wide', label: 'Wide' },
        { value: 'default', label: 'Full' },
      ],
      defaultValue: 'default',
    },
    {
      name: 'mx',
      label: 'Alignment',
      component: 'select',
      options: [
        { value: 'left', label: 'Left' },
        { value: 'default', label: 'Center' },
        { value: 'right', label: 'Right' },
      ],
      defaultValue: 'default',
    },
    {
      name: 'px',
      label: 'Horizontal Spacing',
      component: 'select',
      options: [
        { value: 'default', label: 'None' },
        { value: 'small', label: 'Small' }, //8px-16px-20px
        { value: 'medium', label: 'Medium' }, //20px-32px-36px
        { value: 'large', label: 'Large' }, //32px-48px-52px
      ],
      defaultValue: 'default',
    },
    {
      name: 'py',
      label: 'Vertical Spacing',
      component: 'select',
      options: [
        { value: 'default', label: 'None' },
        { value: 'small', label: 'Small' }, //20px-36px-48px
        { value: 'medium', label: 'Medium' }, //32px-52px-64px
        { value: 'large', label: 'Large' }, //48px-72px-100px
      ],
      defaultValue: 'default',
    },
  ],
};
