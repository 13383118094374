import { SpiceMeter } from '@snippets';

export const SpiceMeters = ({ cms }) => {
  return <SpiceMeter cms={cms} />;
};

SpiceMeters.Schema = {
  label: 'Spice Meter',
  key: 'spice-meter',
  fields: [
    {
        label: 'Heading',
        name: 'heading',
        component: 'text'
    },
    {
        label: 'Spice Lebel',
        name: 'spiceLevel',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: '0',
            label: 'NO HEAT',
          },
          {
            value: '50',
            label: 'MILD',
          },
          {
            value: '100',
            label: 'SPICY',
          },
        ],
        defaultValue: '50',
    },
  ],
};
