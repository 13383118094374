import Script from 'next/script';

export function RecipeSchemaMarkup({ article = {}, pageUrl }) {
  return (
    <Script
      id="schema-markup-article"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Recipe',
          '@id': pageUrl,
          name: article?.sections?.[0]?.content?.title || '',
          image: article?.sections?.[0]?.hero?.image?.desktop || '',
          author: [
            {
              '@type': 'Person',
              name: article.article?.author || '',
            },
          ],
          description: article.seo?.description || '',
          datePublished: article.article?.publishedAt || '',
          recipeCategory: article.article?.category || '',
          ingredients: article?.sections?.[0]?.sidebar?.ingredients || '',
          recipeIngredient: article?.sections?.[0]?.sidebar?.ingredients || '',
          cookTime: article?.sections?.[0]?.sidebar?.cookTime || '',
          prepTime: article?.sections?.[0]?.sidebar?.prepTime || '',
          totalTime: article?.sections?.[0]?.sidebar?.cookTime || '',
          recipeYield:
            (article?.sections?.[0]?.sidebar?.servings &&
              `${article?.sections?.[0]?.sidebar.servings} servings`) ||
            '',
          publisher: {
            '@type': 'Organization',
            '@id': process.env.SITE_URL,
            name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            logo: {
              '@type': 'ImageObject',
              '@id': process.env.NEXT_PUBLIC_SITE_LOGO || '',
              url: process.env.NEXT_PUBLIC_SITE_LOGO || '',
            },
          },
          url: pageUrl,
          isPartOf: {
            '@type': 'Blog',
            '@id': `${process.env.SITE_URL}/blogs/${article.blogs?.[0]?.handle}`,
            name: article.blogs?.[0]?.title,
            publisher: {
              '@type': 'Organization',
              '@id': process.env.SITE_URL,
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            },
          },
        }),
      }}
    />
  );
}

RecipeSchemaMarkup.displayName = 'RecipeSchemaMarkup';
