import { useState } from 'react';
import { Box, Image, Flex } from 'theme-ui';
import { UnderlineTab } from '@snippets';
import { useInView } from 'react-intersection-observer';
import store, { useRecoilValue } from '@store';
import { entryAnimation } from '@utils';

export function CollectionScrollNav({ cms }) {
    const { ref, inView, entry } = useInView({ triggerOnce: true });
    const activeCollectionIndex = useRecoilValue(store.activeCollectionIndex);
    const [subNavDrawer, setSubNavDrawer] = useState(false);
    function toggleSubNavDrawer() {
      setSubNavDrawer(!subNavDrawer);
    }
    return (
        <Flex
          data-comp="CollectionScrollNav"
          ref={ref}
          sx={{
            minHeight: [null, null, null, 48],
            maxHeight: [null, null, null, 48],
            padding: 0,
            backgroundColor: 'black',
            position: 'sticky',
            zIndex: '11',
            top: 'calc(var(--header-height) + var(--promoBar-height) - 1px)',
            overflowX: ['scroll', null, null, 'auto'],
          }}
        >
          <Box
            sx={{
              color: 'white',
              display: [null, null, null, 'none'],
              fontFamily: 'heading',
              fontSize: '12px',
              fontWeight: '400',
              letterSpacing: '0.01em',
              textAlign: 'center',
              textTransform: 'uppercase',
              width: '100%',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                p: ['9px 12px', null, null, 0],
              }}
              onClick={()=>{
                toggleSubNavDrawer();
              }}
            >
              {(cms?.subCollections || []).map((collection, index, arr) => {
                if (!collection?.products) return null;
                const linkIsCurrentUrl = index === activeCollectionIndex;
                if (!linkIsCurrentUrl) return null;
                return (
                  <Flex
                    sx={{
                      alignItems: 'center',
                      color: 'gold',
                      gap: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        mt: '2px',
                      }}
                    >
                      {collection?.heading || ''}
                    </Box>
                    <Image
                      src={`/svg/chevron-down-gold.svg`}
                      sx={{
                        width: '12px',
                        transform: subNavDrawer ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </Flex>
                );
              })}
            </Box>
            <Flex
              sx={{
                background: '#333',
                flexDirection: 'column',
                height: subNavDrawer ? 'auto' : 0,
                overflow: 'hidden',
                transition: 'transform 0.3s ease-in-out',
              }}
            >
              {(cms?.subCollections || []).map((collection, index, arr) => {
                if (!collection?.products) return null;
                const linkIsCurrentUrl = index === activeCollectionIndex;
                return (
                  <Box
                    sx={{
                      color: linkIsCurrentUrl ? 'gold' : null,
                      cursor: linkIsCurrentUrl ? null : 'pointer',
                      p: ['9px 12px', null, null, 0],
                    }}
                    onClick={(e)=>{
                      if (linkIsCurrentUrl) return null;
                      let scrollTarget = e.target.closest('[data-comp="collection-grid"]').querySelector('[data-comp="SubCollection"][data-collection-index="'+index+'"]');
                      let navBar = e.target.closest('[data-comp="CollectionScrollNav"]');
                      if(scrollTarget) {
                        window.scrollTo({
                          top: scrollTarget.getBoundingClientRect().top + window.scrollY - getComputedStyle(scrollTarget).getPropertyValue('--header-height').replace('px','') - getComputedStyle(scrollTarget).getPropertyValue('--promoBar-height').replace('px','') - navBar.offsetHeight - 16,
                          left: 0,
                          behavior: 'smooth',
                        });
                        setSubNavDrawer(false);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        mt: '2px',
                      }}
                    >
                      {collection?.heading || ''}
                    </Box>
                    <Box
                      sx={{
                        background: '#434f50',
                        height: '1px',
                        mb: '-12px',
                        mt: '12px',
                        width: '100%',
                      }}
                    />
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Flex
            variant="flex.row.center"
            sx={{
              alignItems: 'stretch',
              justifyContent: ['flex-start', null, null, 'center'],
              minWidth: 'auto',
              width: ['auto', null, null, '100%'],
              display: ['none', null, null, 'flex'],
              whiteSpace: ['nowrap', null, null, 'normal'],
              flexWrap: ['nowrap'],
              mx: ['auto', null, null, 'initial'],
              ...entryAnimation({
                entered: inView,
                fadeIn: true,
                slideIn: false,
                duration: 350,
                index: 0,
                delay: 0,
              }),
            }}
          >
            {(cms?.subCollections || []).map((collection, index, arr) => {
              if (!collection?.products) return null;
              const linkIsCurrentUrl = index === activeCollectionIndex;
              return (
                <UnderlineTab
                  text={collection?.heading || ''}
                  collectionIndex={index}
                  key={collection.id}
                  index={index}
                  isLast={index === arr.length - 1}
                  isActive={linkIsCurrentUrl}
                  theme="dark"
                  size="small"
                  sx={{
                    mt: [0, null, null, 8],
                    height: 'auto',
                  }}
                />
              );
            })}
          </Flex>
        </Flex>
    );
}