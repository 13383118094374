import { Flex } from 'theme-ui';
import { useCart, useSettings } from '@backpackjs/storefront';

import { CartEmptyState } from './CartEmptyState';
import { CartLineItems } from './CartLineItems';
import { CartFooter } from './CartFooter';
import { CartDiscountProgress } from './CartDiscountProgress';

export function Cart({ isDrawer, ...props }) {
  const cart = useCart();
  const settings = useSettings();

  return (
    <Flex
      data-comp="CartComponent"
      variant="flex.column"
      sx={{
        height: cart?.lines?.length ? 'auto' : '100%',
        pb: 'var(--cart-footer-height)',
      }}
      {...props}
    >
      {cart?.lines?.length > 0 ? (
        <>
          {isDrawer && settings?.cart?.discount?.enabled ? (
            <CartDiscountProgress />
          ) : null}
          <CartLineItems key="cart-line-items" />
        </>
      ) : (
        <CartEmptyState key="cart-empty-state" />
      )}
      <CartFooter />
    </Flex>
  );
}
