import { AspectRatio, Image } from 'theme-ui';
import shopifyImages from 'shopify-images';

const shopifyRetinaLoader = ({ src, width, quality }) => {
  const options = {
    size: `x${width}`, // could be width x height too
    crop: 'center',
    scale: '2',
    format: 'pjg', // TODO test png
  };
  const sizedSrc = shopifyImages(src, options);
  return sizedSrc;
};

const shopifyLoader = ({ src, width, quality }) => {
  const options = {
    size: `x${width}`, // could be width x height too
    crop: 'center',
    format: 'pjg', //'pjg'
  };
  const sizedSrc = shopifyImages(src, options);
  return sizedSrc;
};

export const ShopifyImage = ({ ratio, ...props }) => {
  return typeof ratio !== 'undefined' ? (
    <AspectRatio
      data-comp="ShopifyImageRatio"
      ratio={ratio}
      // sx={{
      //   width: props?.sx?.width || '100%',
      //   height: props?.sx?.height || '100%'
      // }}
    >
      <Image loader={shopifyRetinaLoader} {...props} />
    </AspectRatio>
  ) : (
    <Image data-comp="ShopifyImage" {...props} />
  );
};

/*
  Can be used to size a src directly or inline.
  for example: sizing a src for a background image:
    ->  url(sizeShopifySrc(src, {width: 800 }))
*/
// export const sizeShopifySrc = _sizeShopifySrc

/**
<ShopifyImage
  src={image ? image : null}
  ratio={1200/640}
  objectFit='cover'
  fluid
  sizes='(max-width: 640px) 300px, (max-width: 1023px) 1023px, (min-width: 1024px) 1000px'
  layout='fill'
  sx={{
    width: '100%'
  }}
/>
 */
