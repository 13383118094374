import React from 'react';
import { Flex, Input } from 'theme-ui';

import { ReferSubmitButton } from './ReferSubmitButton';

export const ReferInputFields = ({
  isYotpoCustomer,
  setCustomEmail,
  customEmail,
  setEmails,
  emails,
  submitted,
  handleSendReferralEmail,
  setOpacity,
  opacity,
  isLoading,
}) => {
  return (
    <Flex
      data-comp="ReferInputFields"
      variant="flex.column.center"
      sx={{
        width: '100%',
      }}
    >
      {!isYotpoCustomer && (
        <Flex
          variant="flex.row.between.center"
          sx={{
            bg: 'white',
            border: '1px solid',
            borderColor: 'gray.3',
            mb: 5,
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <Input
            type="email"
            onChange={(e) => setCustomEmail(e.target.value)}
            value={customEmail}
            placeholder={`Your email`}
            sx={inputSx}
          />
        </Flex>
      )}
      <Flex
        variant="flex.row.between.center"
        sx={{
          bg: 'white',
          border: '1px solid',
          borderColor: 'gray.3',
          mb: 8,
          width: '100%',
          maxWidth: isYotpoCustomer ? '420px' : '400px',
        }}
      >
        <Input
          type="email"
          onChange={(e) => setEmails(e.target.value)}
          value={emails}
          placeholder={`Your friends' emails (separated by commas)`}
          sx={inputSx}
        />
        {isYotpoCustomer && (
          <ReferSubmitButton
            submitted={submitted}
            handleSendReferralEmail={handleSendReferralEmail}
            setOpacity={setOpacity}
            opacity={opacity}
            isLoading={isLoading}
          />
        )}
      </Flex>
      {!isYotpoCustomer && (
        <ReferSubmitButton
          submitted={submitted}
          handleSendReferralEmail={handleSendReferralEmail}
          setOpacity={setOpacity}
          opacity={opacity}
          isLoading={isLoading}
          sx={{
            mb: 8,
            minWidth: '120px',
            border: '1px solid',
            borderColor: 'black',
          }}
        />
      )}
    </Flex>
  );
};

const inputSx = {
  pl: 8,
  py: 6,
  m: 0,
  pr: 0,
  border: 0,
  height: '100%',
  flex: 1,
  fontFamily: 'body',
  variant: 'text.base',
  fontSize: '16px',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    fontFamily: 'body',
    variant: 'text.base',
  },
};
