import { store } from '@backpackjs/storefront';
import effects from './effects';

const yotpoLoyaltyData = store.recoil.atom({
  key: 'yotpo/data',
  default: {
    yotpoCustomer: null,
    activeCampaigns: null,
    vipTiers: null,
  },
});

const activeRewardsModal = store.recoil.atom({
  key: 'yotpo/modal',
  default: null,
});

const isSwellSetup = store.recoil.atom({
  key: 'yotpo/setup',
  default: false,
});

export default {
  yotpoLoyaltyData,
  activeRewardsModal,
  isSwellSetup,
};
