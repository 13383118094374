import store, { useRecoilState } from '@store';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, Spinner, AspectRatio, Flex } from 'theme-ui';
import { useProductItemByHandle } from '@backpackjs/storefront';

import { ProductItem, StyledButton } from '@snippets';
import { entryAnimation } from '@utils';
import { useBodyScrollLock } from '@hooks';

import { CollectionGridCover } from './CollectionGridCover';
import { SubCollectionFilter } from './SubCollectionFilter';
import { SubCollectionSort } from './SubCollectionSort';

export function SubCollection({ collection, index, count }) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedSort, setSelectedSort] = useState(null);
  const [filterDrawerIndex, setFilterDrawerIndex] = useRecoilState(
    store.filterDrawerIndex
  );
  const { lockScroll, unlockScroll } = useBodyScrollLock();
  const isFirstItem = index === 0;
  const [isVisible, setIsVisible] = useState(isFirstItem);
  const { ref, inView, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: 0.15,
    triggerOnce: true,
  });
  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      acc[key] ??= [];
      acc[key].push(obj);
      return acc;
    }, {});
  }
  const collectionProducts = collection?.products?.reduce((prev, product) => {
    const handle = product.product?.handle || product.handle;
    const { productItem: _productItem } = useProductItemByHandle({ handle });
    prev.push({
      ...product,
      productItem: _productItem,
    });
    return prev;
  }, []);
  const collectionSortOptions = collection?.products?.reduce((pVal, cVal) => {
    cVal.sortlist?.forEach((sortOption) => {
      pVal.push(sortOption.option);
    });
    return pVal;
  }, []);
  collectionSortOptions.push('Price: Low to High');
  collectionSortOptions.push('Price: High to Low');
  const collectionSortOptionsUnique = Array.from(
    new Set(collectionSortOptions.map(JSON.stringify)),
    JSON.parse
  );
  const collectionFilters = collection?.products?.reduce((pVal, cVal) => {
    cVal.filterlist?.forEach((filter) => {
      const newFilter = Object.keys(filter)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: filter[key],
          }),
          {}
        );
      pVal.push(newFilter);
    });
    return pVal;
  }, []);
  const collectionFiltersUnique = Array.from(
    new Set(collectionFilters.map(JSON.stringify)),
    JSON.parse
  );
  const filterOptionsUnique = Array.from(
    new Set(collection?.filterOptions?.map(JSON.stringify)),
    JSON.parse
  );
  const filterOptionsSet = new Set(filterOptionsUnique);
  const intersectFilterOptions = Array.from(
    new Set(
      [...filterOptionsSet].filter((i) => {
        let hasMatch = false;
        collectionFiltersUnique.forEach((filter) => {
          if (JSON.stringify(filter) === JSON.stringify(i)) {
            hasMatch = true;
          }
        });
        return hasMatch;
      })
    )
  );
  const intersectFilterOptionsGrouped = groupBy(
    intersectFilterOptions,
    'group'
  );
  const toggleSelectedSort = (sortOption) => {
    if (selectedSort == null || selectedSort !== sortOption) {
      setSelectedSort(sortOption);
    } else {
      setSelectedSort(null);
    }
  };
  const toggleSelectedFilter = (filter) => {
    let tempSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (
      selectedFilters.some((e) => JSON.stringify(e) === JSON.stringify(filter))
    ) {
      tempSelectedFilters = tempSelectedFilters.filter((el) => {
        return !(JSON.stringify(el) === JSON.stringify(filter));
      });
    } else {
      tempSelectedFilters.push(filter);
    }
    setSelectedFilters(tempSelectedFilters);
  };
  const selectedFiltersGrouped = groupBy(selectedFilters, 'group');
  const isSelectedSort = (sortOption) => {
    return selectedSort === sortOption;
  };
  const isSelected = (filter) => {
    let retVal = false;
    if (selectedFilters.length > 0) {
      selectedFilters.forEach((selectedFilter) => {
        if (JSON.stringify(selectedFilter) === JSON.stringify(filter)) {
          retVal = true;
        }
      });
    }
    return retVal;
  };
  const filteredProducts =
    selectedFilters.length === 0
      ? collectionProducts
      : collectionProducts?.reduce((prev, current) => {
          let allGroupsMatched = true;
          Object.keys(selectedFiltersGrouped).forEach((key) => {
            let groupHasMatch = false;
            current.filterlist?.forEach((currentFilter) => {
              selectedFiltersGrouped[key].forEach((groupFilter) => {
                if (
                  groupFilter.group === currentFilter.group &&
                  groupFilter.option === currentFilter.option
                ) {
                  groupHasMatch = true;
                }
              });
            });
            if (groupHasMatch === false) {
              allGroupsMatched = false;
            }
          });
          if (allGroupsMatched) {
            prev.push(current);
          }
          return prev;
        }, []);
  const sortProducts = () => {
    if (!selectedSort) {
      return false;
    }
    if (
      selectedSort === 'Price: Low to High' ||
      selectedSort === 'Price: High to Low'
    ) {
      // sort by price of products
      if (selectedSort === 'Price: Low to High') {
        filteredProducts.sort((a, b) => {
          const aProduct = a.productItem.productItem;
          const bProduct = b.productItem.productItem;
          if (aProduct?.variants?.[0]?.price < bProduct?.variants?.[0]?.price) {
            return -1;
          }
          if (aProduct?.variants?.[0]?.price > bProduct?.variants?.[0]?.price) {
            return 1;
          }
          return 0;
        });
      } else {
        filteredProducts.sort((a, b) => {
          const aProduct = a.productItem.productItem;
          const bProduct = b.productItem.productItem;
          if (aProduct?.variants?.[0]?.price > bProduct?.variants?.[0]?.price) {
            return -1;
          }
          if (aProduct?.variants?.[0]?.price < bProduct?.variants?.[0]?.price) {
            return 1;
          }
          return 0;
        });
      }
    } else {
      filteredProducts.sort((a, b) => {
        let aSortVal = 0;
        let bSortVal = 0;
        a.sortlist?.forEach((sortOption) => {
          if (sortOption.option === selectedSort) {
            aSortVal = sortOption.value || 1;
          }
        });
        b.sortlist?.forEach((sortOption) => {
          if (sortOption.option === selectedSort) {
            bSortVal = sortOption.value || 1;
          }
        });
        if (aSortVal === 0 && bSortVal === 0) {
          return 0;
        }
        if (aSortVal !== 0 && bSortVal !== 0) {
          return aSortVal - bSortVal;
        }
        if (aSortVal !== 0) {
          return -1;
        }
        return 1;
      });
    }
  };
  sortProducts();
  const toggleFilterDrawer = (index, key) => {
    if (filterDrawerIndex === null || filterDrawerIndex !== `${index}-${key}`) {
      setFilterDrawerIndex(`${index}-${key}`);
    } else {
      setFilterDrawerIndex(null);
    }
  };
  const checkmarkSVG =
    'data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.10526L8.84615 0L3.46154 4.78947L1.15385 2.57895L0 3.68421L3.46154 7L10 1.10526Z" fill="%231A1A1A"/></svg>';
  const stopProp = (e) => {
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (filterDrawerIndex != null && window.innerWidth < 1024) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [filterDrawerIndex]);

  React.useEffect(() => {
    if (inView) {
      if (!isVisible) {
        setIsVisible(true);
      }
    }
  }, [inView]);

  const hideQuickAdd = collection?.hideQuickAdd;

  if (!filteredProducts.length) return <Spinner />;
  return (
    <Flex
      variant="flex.column"
      data-comp="SubCollection"
      data-collection-index={index}
      ref={ref}
      sx={{
        zIndex: [null, null, null, count - index],
        position: 'relative',
        pb: [11, null, null, 18],
      }}
    >
      {/* Collection Filter & Sort */}
      {(Object.keys(intersectFilterOptionsGrouped).length &&
        collection?.enableFilter) ||
      (collectionSortOptionsUnique.length && collection?.enableFilter) ? (
        <Box
          data-comp="SubCollectionFilterSort"
          sx={{
            display: 'flex',
            gap: ['16px', null, null, null, '20px'],
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            marginBottom: '30px',
          }}
        >
          {/* Filter Options */}
          {Object.keys(intersectFilterOptionsGrouped).length ? (
            <SubCollectionFilter
              index={index}
              toggleFilterDrawer={toggleFilterDrawer}
              filterDrawerIndex={filterDrawerIndex}
              setFilterDrawerIndex={setFilterDrawerIndex}
              stopProp={stopProp}
              intersectFilterOptionsGrouped={intersectFilterOptionsGrouped}
              toggleSelectedFilter={toggleSelectedFilter}
              isSelected={isSelected}
              checkmarkSVG={checkmarkSVG}
            />
          ) : null}

          {/* Sort Options */}
          <SubCollectionSort
            toggleFilterDrawer={toggleFilterDrawer}
            filterDrawerIndex={filterDrawerIndex}
            setFilterDrawerIndex={setFilterDrawerIndex}
            stopProp={stopProp}
            collectionSortOptionsUnique={collectionSortOptionsUnique}
            toggleSelectedSort={toggleSelectedSort}
            isSelectedSort={isSelectedSort}
            index={index}
            checkmarkSVG={checkmarkSVG}
          />
        </Box>
      ) : null}

      {/* Heading */}
      <Box
        data-comp="CollectionGridHeading"
        sx={{
          width: '100%',
          mb: '22px',
          opacity: 0,
          ...entryAnimation({
            entered: isVisible,
            fadeIn: true,
            slideIn: false,
            duration: 300,
            index: index + 1,
            delay: 0,
          }),
        }}
      >
        <Flex
          sx={{
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              fontFamily: 'heading',
              fontSize: '24px',
              lineHeight: '.92',
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
            }}
          >
            {collection.heading}
          </Box>
          <Box
            as="hr"
            sx={{
              border: '0',
              height: '1px',
              backgroundColor: '#d7d9db',
              flexGrow: 1,
              mt: '1em',
              mb: '0',
            }}
          />
          <Box
            sx={{
              color: '#d7d9db',
              fontFamily: 'heading',
              fontSize: '18px',
              lineHeight: '1.22',
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
            }}
          >
            {filteredProducts.length ? `${filteredProducts.length} items` : ''}
          </Box>
        </Flex>
      </Box>

      {/* Products */}
      <Box
        data-comp="CollectionGridProducts"
        sx={{
          display: 'grid',
          position: 'relative',
          gridTemplateColumns: filteredProducts.length
            ? ['repeat(2, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(3, 1fr)']
            : ['1fr'],
          columnGap: [6, null, null, 10],
          rowGap: [6, null, null, 10],
          opacity: 0,
          ...entryAnimation({
            entered: isVisible,
            fadeIn: true,
            slideIn: false,
            duration: 300,
            index: 1,
            delay: 0,
          }),
        }}
      >
        {isVisible ? (
          <>
            {collection?.cover?.video ? (
              <CollectionGridCover collection={collection} />
            ) : null}
            {filteredProducts.length ? (
              filteredProducts.map((productField, index, arr) => {
                const product = productField?.product || productField;

                if (!product.handle) return null;
                return (
                  <ProductItem
                    key={product.handle + index}
                    handle={product.handle}
                    index={index}
                    imageSizes="(max-width: 640px) 320px, (max-width: 1199px) 750px, (min-width: 1200px) 550px"
                    itemsCount={arr.length}
                    featuredTag={productField.featuredtag}
                    altImage={productField.altimage}
                    hideQuickAdd={hideQuickAdd}
                    isCollection
                  />
                );
              })
            ) : selectedFilters.length > 0 ? (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    color: '#e6e7e9',
                    fontFamily: 'heading',
                    fontSize: '48px',
                    textTransform: 'uppercase',
                    letterSpacing: '0.02em',
                    my: '100px',
                  }}
                >
                  No matching products
                </Box>
                <StyledButton
                  text="Reset"
                  variant="solid"
                  onClick={() => setSelectedFilters([])}
                  sx={{
                    width: '210px',
                    mx: 'auto',
                  }}
                />
              </Box>
            ) : (
              `No products on ${collection.heading}`
            )}
          </>
        ) : (
          // loader + spacer
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '0',
                pb: [
                  `calc(${
                    Math.ceil((collection?.products?.length || 1) / 2) * 159.1
                  }% + ${
                    (Math.ceil((collection?.products?.length || 1) / 2) - 1) *
                    12
                  }px)`,
                  null,
                  null,
                  null,
                  `calc(${
                    Math.ceil((collection?.products?.length || 1) / 3) * 117.5
                  }% + ${
                    (Math.ceil((collection?.products?.length || 1) / 3) - 1) *
                    24
                  }px)`,
                ],
              }}
            />
            <Spinner
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
              }}
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
}
