import { useCallback, useMemo, useEffect, useState } from 'react';
import { useRouter } from '@backpackjs/storefront';

import { Dropdown } from '@snippets';
import store, { useSetRecoilState } from '@store';

export function ProductDropdownSizes({ product }) {
  const router = useRouter();
  const [initialVariantIndex, setInitialVariantIndex] = useState(0);

  useEffect(() => {
    const queryKey = 'variant';
    const queriedVariantId =
      router.query[queryKey] ||
      router.asPath.match(new RegExp(`[&?]${queryKey}=(.*)(&|$)`));

    const queriedVariantIndex = product.variants.findIndex(
      (variant) => variant?.legacyResourceId === queriedVariantId
    );
    return setInitialVariantIndex(
      queriedVariantIndex >= 0 ? queriedVariantIndex : 0
    );
  }, []);

  const options = useMemo(
    () =>
      product.variants.map((variant) => {
        return {
          label: variant.title,
          value: variant.id,
        };
      }),
    [product?.handle]
  );

  const setSelectedVariant = useSetRecoilState(
    store.selectedVariant(product.handle)
  );

  const setSizeVariantAsSelected = useCallback(
    (variantId) => {
      console.log('Setting Size Variant as Selected...');
      const variant = product.variants.find(
        (variant) => variant.id === variantId
      );
      if (!variant?.id) return;
      setSelectedVariant(variant);
    },
    [product?.variants, product?.handle]
  );

  return (
    <Dropdown
      data-comp="ProductDropdownSizes"
      id="product-sizes-dropdown"
      onClick={setSizeVariantAsSelected}
      title="Size"
      items={options}
      initialIndex={initialVariantIndex}
      sx={{
        zIndex: 18,
        mb: [8, null, null, 11],
      }}
    />
  );
}
