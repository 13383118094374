/* eslint-disable no-nested-ternary */
import { forwardRef } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Link as LinkUI, Box } from "theme-ui";

export const LinkWrapper = forwardRef(
  (
    {
      href = "",
      newTab = false,
      hiddenForSeo = false,
      prefetch = false,
      children,
      query, // next/link query object
      sx,
      linkIsCode = false,
      ariaLabel,
      ...props
    },
    ref
  ) => {
    return hiddenForSeo ? (
      <LinkUI
        data-comp="Link-Hidden"
        href={href}
        ref={ref}
        sx={{
          display: "none",
          pointerEvents: "none",
        }}
        aria-label="hidden link for SEO purposes"
      />
    ) : href ? (
      linkIsCode ? (
        <Box
          data-comp="Link-Clipboard"
          ref={ref}
          sx={{ cursor: "pointer", ...sx }}
          onClick={() => {
            navigator.clipboard.writeText(href).then(
              function () {
                const alertBox = document.createElement("div");
                alertBox.className = "link-wrapper-alert-modal";
                alertBox.innerHTML = "Successfully copied to clipboard";
                alertBox.style.cssText =
                  'position: fixed; top: 50%; left: 50%; color: white; background: var(--theme-ui-colors-black); padding: 0.75rem min(2.25rem, 5vw); transform: translate(-50%, -50%); box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 16px -4px; border: 1px solid var(--theme-ui-colors-gold); max-width: 80vw; margin: auto; transition: all 0.5s ease-in 0s; opacity: 0; z-index: 21; font-family: "Brown",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif; text-align: center;';
                document.body.appendChild(alertBox);
                alertBox.style.opacity = 1;
                setTimeout(function () {
                  alertBox.style.opacity = 0;
                  setTimeout(function () {
                    document.body.removeChild(alertBox);
                  }, 500);
                }, 1000);
              },
              function (err) {
                console.error("copy to clipboard failed.", err);
              }
            );
          }}
          {...props}
        >
          {children}
        </Box>
      ) : newTab ? (
        <LinkUI
          data-comp="Link-External"
          href={href}
          target="_blank"
          ref={ref}
          sx={{
            display: "block",
            width: "auto",
            height: "auto",
            ...sx,
          }}
          aria-label={ariaLabel}
          {...props}
        >
          {children}
        </LinkUI>
      ) : (
        <Link
          href={typeof query === "object" ? { pathname: href, query } : href}
          prefetch={prefetch}
          passHref
          aria-label={ariaLabel}
        >
          <LinkUI
            data-comp="Link-Internal"
            ref={ref}
            sx={{
              display: "block",
              width: "auto",
              height: "auto",
              ...sx,
            }}
            aria-label={ariaLabel}
            {...props}
          >
            {children}
          </LinkUI>
        </Link>
      )
    ) : (
      <Box
        data-comp="Link-NoHref"
        ref={ref}
        sx={{ cursor: "default", ...sx }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

LinkWrapper.propTypes = {
  href: PropTypes.string,
  newTab: PropTypes.bool,
  hiddenForSeo: PropTypes.bool,
  prefetch: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  query: PropTypes.object,
  sx: PropTypes.object,
};
