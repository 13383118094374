import { useState } from "react";
import BundleBuilderMenu from "./BundleBuilderMenu";
import BundleCollection from "./BundleCollection";

import { Schema } from "./BundleBuilder.schema";

export function BundleBuilder({ cms }) {
  const { bundleHeading, bundleCollections } = cms;

  const bundleCollectionTitles = bundleCollections.map(
    (collection) => collection.bundleCollectionTitle
  );
  const [selectedMenu, setSelectedMenu] = useState(bundleCollectionTitles[0]);

  return (
    <>
      <BundleBuilderMenu
        menuItems={bundleCollectionTitles}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />

      {bundleCollections?.map((collection, index) => {
        if (selectedMenu === collection.bundleCollectionTitle) {
          return (
            <BundleCollection
              key={index}
              bundleCollection={collection.bundleCollection}
              bundleHeading={bundleHeading}
            />
          );
        }
        return null;
      })}
    </>
  );
}

BundleBuilder.displayName = "BundleBuilder";
BundleBuilder.Schema = Schema;
