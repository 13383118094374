import { Box, Flex, Paragraph, Image, Link } from 'theme-ui';

export const ReferShareLinks = ({ referralData }) => {
  return (
    <Flex data-comp="ReferShareLinks" variant="flex.column" sx={{ mb: 11 }}>
      <Paragraph variant="xs" sx={{ mb: 4, textAlign: 'center' }}>
        You can also share with the buttons below
      </Paragraph>
      <Flex variant="flex.row.center">
        {links(referralData).map((link, index, arr) => (
          <Box key={index} sx={{ mr: index !== arr.length - 1 ? 8 : 0 }}>
            {link.key === 'link' ? (
              <Flex
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(link.url);
                }}
              >
                <Image
                  src={link.icon?.src || link.icon}
                  sx={{ width: '14px', mr: 2 }}
                />
                <Paragraph variant="xxs">{link.text}</Paragraph>
              </Flex>
            ) : (
              <Link target="_blank" href={link.url}>
                <Flex
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Image
                    src={link.icon?.src || link.icon}
                    sx={{ width: '14px', mr: 2 }}
                  />
                  <Paragraph variant="xxs">{link.text}</Paragraph>
                </Flex>
              </Link>
            )}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

const links = (referralData) => [
  {
    key: 'facebook',
    icon: '/svg/facebook-black.svg',
    text: 'Facebook',
    className: 'swell-share-referral-facebook',
    url: `https://www.facebook.com/dialog/feed?app_id=1647129615540489&link=${encodeURIComponent(
      referralData?.referral_link
    )}&redirect_uri=${encodeURIComponent(
      `https://loyalty.yotpo.com/close.html`
    )}&display=popup&name${encodeURIComponent(
      `Earn A Discount When You Shop Today!`
    )}&description=&caption=Truff`,
  },
  {
    key: 'twitter',
    icon: '/svg/twitter.svg',
    text: 'Tweet',
    className: 'swell-share-referral-twitter',
    url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `These guys are great! Get a discount using my code ${referralData?.referral_link}`
    )}`,
  },
  {
    key: 'messenger',
    icon: '/svg/messenger.svg',
    text: 'Messenger',
    className: 'swell-share-referral-messenger',
    url: `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
      referralData?.referral_link
    )}&app_id=1647129615540489&redirect_uri=undefined`,
  },
  {
    key: 'link',
    icon: '/svg/link.svg',
    text: 'Copy Link',
    className: '',
    url: referralData?.referral_link,
  },
];
