export const useKlaviyo = async () => {
  const klaviyoAPI = async ({ action, params }) => {
    try {
      if (!action) throw new Error("useKlaviyo error: No action provided!");

      const url = `/api/klaviyo`;
      const options = {
        method: "POST",
        body: JSON.stringify({
          action,
          params,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);
      const data = await response.json();

      if (!data) throw new Error(`Klaviyo data error: ${data.message}`);

      return data;
    } catch (error) {
      console.error(`Klaviyo error: ${error.message}`);
      throw error;
    }
  };
  return { klaviyoAPI };
};
