/* eslint-disable react/jsx-props-no-spreading */
import { Flex } from 'theme-ui';

import { HalfBlockVideo } from './HalfBlockVideo';
import { HalfBlockImage } from './HalfBlockImage';
import { HalfBlockText } from './HalfBlockText';
import { HalfBlockTextWithImage } from './HalfBlockTextWithImage';
import { HalfBlockAccordion } from './HalfBlockAccordion';
import { HalfBlockOrderedList } from './HalfBlockOrderedList';
import { HalfBlockNutrition } from './HalfBlockNutrition';
import { HalfBlockKlayvioForm } from './HalfBlockKlayvioForm';

export function HalfBlock({
  block,
  blocks,
  index,
  isInvisible,
  disabledPy,
  columnDirection,
  bgColor,
  color,
  isCenterSingle,
  sx,
  ...props
}) {
  const blockProps = {
    block,
    blocks,
    index,
    disabledPy,
    columnDirection,
    isInvisible,
    bgColor,
    color,
    isCenterSingle,
  };

  const template = block?._template;

  return (
    <Flex
      data-comp="HalfBlock"
      variant="flex.column.start.center"
      sx={{
        gridArea: `grid-area-${index + 1}`,
        display: block?.hideMobile ? ['none', null, null, 'flex'] : 'flex',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        ':before': {
          content: '""',
          float: 'left',
          pb: [block?.ratio || 'unset', null, null, block?.ratioDt || 'unset'],
        },

        ...sx,
      }}
      {...props}
    >
      {template === 'video-block' && <HalfBlockVideo {...blockProps} />}
      {template === 'image-block' && <HalfBlockImage {...blockProps} />}
      {template === 'text-block' && <HalfBlockText {...blockProps} />}
      {template === 'text-image-block' && (
        <HalfBlockTextWithImage {...blockProps} />
      )}
      {template === 'ordered-list-block' && (
        <HalfBlockOrderedList {...blockProps} />
      )}
      {template === 'accordion-block' && <HalfBlockAccordion {...blockProps} />}
      {template === 'nutrition-block' && <HalfBlockNutrition {...blockProps} />}
      {template === 'klayvio-form' && <HalfBlockKlayvioForm {...blockProps} />}
    </Flex>
  );
}
