import { useEffect } from 'react';

// On cms sidebar open/close update swiper instance to adjust for the width change
export const useUpdateSwiperOnSidebarToggle = (swiper) => {
  useEffect(() => {
    if (!window?.__bpCMS || !window.__bpCMS?.enabled || !swiper) return;
    const updateSwiper = (event) => {
      const t0 = requestTimeout(() => {
        try {
          swiper.update();
          swiper.updateSize();
        } catch (error) {
          // ignore hot-reload error
        } finally {
          clearRequestTimeout(t0);
        }
      }, 300);
    };

    window.__bpCMS.events.subscribe('sidebar:opened', updateSwiper);
    window.__bpCMS.events.subscribe('sidebar:closed', updateSwiper);

    return () => {
      // window.__bpCMS.events.unsubscribe('sidebar:opened', updateSwiper)
      // window.__bpCMS.events.unsubscribe('sidebar:closed', updateSwiper)
    };
  }, [swiper]);
};
