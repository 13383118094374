import { Picture } from '@snippets';

export function HalfBlockImage({ block, blocks, index, ...props }) {
  const mediaType = { main: 'image', mobile: 'image' };

  const image = {
    src: block?.image?.src || block?.image?.filename || '',
    mobileSrc: block?.mobileImage?.src || block?.mobileImage?.filename || block?.image?.src || '',
    position: block?.imagePosition || 'center center',
    mobilePosition: block?.mobileImagePosition || 'center center',
    alt: block?.alt || 'alt text'
  };

  return (
    <Picture
      data-comp="HalfBlockImage"
      alt={image?.alt}
      images={[
        {
          src: image?.mobileSrc,
          width: 768,
          ratio: 1,
        },
        {
          src: image?.mobileSrc,
          width: 512,
          ratio: 1,
        },
        {
          src: image?.mobileSrc,
          width: 640,
          ratio: 1,
        },
        {
          src: image?.src,
          width: 720,
          ratio: 1,
        },
      ]}
      imageSx={{
        objectPosition: [image?.mobilePosition, null, null, image?.position],
        borderRadius: [
          '6px',
          null,
          null,
          '6px',
        ],
      }}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      }}
      {...props}
    />
  );
}
