import { InlineHeading } from '@snippets';
import { shopifySrcFilter } from '@utils';
import { Box, Flex } from 'theme-ui';
import { useRouter } from 'next/router';

export function ProductTabFlavors({
  groupingProducts,
  selectedProductIndex,
  selectedVariant,
  ...props
}) {
  const router = useRouter();

  return (
    <Box
      sx={{
        mb: [8, null, null, 11],
      }}
    >
      <InlineHeading
        theme="dark"
        text="Pick Your Flavor"
        sx={{ mt: [8, null, null, 11] }}
      />
      <Flex
        sx={{
          gap: ['13px', null, null, '10px'],
          rowGap: [null, null, null, '15px'],
          flexWrap: 'wrap',
        }}
      >
        {groupingProducts?.byIndex.map((product, index, arr) => (
          <Box
            key={product.id}
            sx={{
              width: ['60px', null, null, '80px'],
              height: ['60px', null, null, '80px'],
              borderRadius: '50%',
              overflow: 'hidden',
              border:
                index == selectedProductIndex
                  ? '2px solid #cab683'
                  : '1px solid #333',
              cursor: index == selectedProductIndex ? null : 'pointer',
            }}
            onClick={() => {
              if (index != selectedProductIndex) {
                router.push(`/products/${product.handle}`);
              }
            }}
          >
            <img
              sx={{ borderRadius: '50%', overflow: 'hidden' }}
              src={shopifySrcFilter(
                product?.variant?.image?.originalSrc ||
                  product?.image?.originalSrc ||
                  product?.image?.previewImage?.originalSrc,
                { width: 80, ratio: 1 }
              )}
              alt={`Link to ${product?.title}`}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
