import { AccordionBlock } from './AccordionBlock';
import { Article } from './Article';
import { BannerImage } from './BannerImage';
import { BlogGrid } from './BlogGrid';
import { CollectionGrid } from './CollectionGrid';
import { DestiniWidget } from './DestiniWidget';
import { KlaviyoForm } from './KlaviyoForm';
import { HalfBlocks } from './HalfBlocks';
import { IconRow } from './IconRow';
import { InstagramBlock } from './InstagramBlock';
import { LifestyleGrid } from './LifestyleGrid';
import { MarkdownBody } from './MarkdownBody';
import { MediaHero } from './MediaHero';
import { NutritionFacts } from './NutritionFacts';
import { OprahLovesTruffTextBlock } from './OprahLovesTruffTextBlock';
import { PageMediaTextBlock } from './PageMediaTextBlock';
import { PressBanner } from './PressBanner';
import { PrimaryHero } from './PrimaryHero';
import { ProductCarousel } from './ProductCarousel';
import { ProductDetails } from './ProductDetails';
import { ProductMain } from './ProductMain';
import { ProductRecommendations } from './ProductRecommendations';
import { RedeemProducts } from './RedeemProducts';
import { Rewards } from './Rewards';
import { RewardsRefer } from './RewardsRefer';
import { SpiceMeters } from './SpiceMeters';
import { StockistWidget } from './StockistWidget';
import { SubNav } from './SubNav';
import { TextCarousel } from './TextCarousel';
import { YotpoWidget } from './YotpoWidget';
import { BundleBuilder } from './BundleBuilder';

export default [
  AccordionBlock,
  Article,
  BannerImage,
  BlogGrid,
  CollectionGrid,
  DestiniWidget,
  KlaviyoForm,
  HalfBlocks,
  IconRow,
  InstagramBlock,
  LifestyleGrid,
  MarkdownBody,
  MediaHero,
  NutritionFacts,
  OprahLovesTruffTextBlock,
  PageMediaTextBlock,
  PressBanner,
  PrimaryHero,
  ProductCarousel,
  ProductDetails,
  ProductMain,
  ProductRecommendations,
  RedeemProducts,
  Rewards,
  RewardsRefer,
  SpiceMeters,
  StockistWidget,
  SubNav,
  TextCarousel,
  YotpoWidget,
  BundleBuilder,
];
