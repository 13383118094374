export const buttons = {
  primary: {
    fontFamily: 'heading',
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: 0,
    p: 0,
    bg: 'transparent',
  },
  link: {
    fontFamily: 'heading',
    p: 0,
    bg: 'transparent',
    fontSize: 1, // 12px
    lineHeight: '1.5em',
    letterSpacing: 0.02,
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 400,
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'left',
    borderRadius: 0,
    '@keyframes in': {
      '0%': { transform: 'translateX(-101%)' },
      '100%': { transform: 'translateX(0)' },
    },
    '@keyframes out': {
      '0%': { transform: 'translateX(0)' },
      '100%': { transform: 'translateX(101%)' },
    },
    '&:before': {
      content: '""',
      width: '100%',
      height: '1px',
      backgroundColor: 'white',
      position: 'absolute',
      left: 0,
      bottom: 0,
      animation: 'out 0.33s cubic-bezier(.52,.02,.31,.99) both',
      opacity: 0,
      transition: 'all 1.2s',
    },
    '&:hover:before': {
      animation: 'in 0.33s cubic-bezier(.52,.02,.31,.99) normal',
      transition: 'all 0.33s',
      opacity: 1,
    },
  },
  underlineLink: {
    fontFamily: 'heading',
    p: 0,
    color: 'white',
    bg: 'transparent',
    fontSize: 2, // 13px
    lineHeight: '1.4em',
    letterSpacing: 0.02,
    textTransform: 'uppercase',
    fontWeight: 600,
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 0,
    '&:before': {
      content: '""',
      width: '100%',
      height: '2px',
      backgroundColor: 'currentColor',
      position: 'absolute',
      left: 0,
      bottom: 0,
      transform: 'translateX(0)',
      transition: 'transform 0.4s ease-in-out',
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '2px',
      backgroundColor: 'currentColor',
      position: 'absolute',
      left: '-200%',
      bottom: 0,
      transform: 'translateX(0)',
      transition: 'transform 0.4s ease-in-out',
    },
    '&:hover:before, &:hover:after': {
      transform: 'translateX(200%)',
    },
    sm: {
      fontFamily: 'heading',
      p: 0,
      color: 'white',
      bg: 'transparent',
      fontSize: 0, // 11px
      lineHeight: 12,
      letterSpacing: 0.02,
      textTransform: 'uppercase',
      fontWeight: 600,
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: 0,
      '&:before': {
        content: '""',
        width: '100%',
        height: '1px',
        backgroundColor: 'currentColor',
        position: 'absolute',
        left: 0,
        bottom: 0,
        transform: 'translateX(0)',
        transition: 'transform 0.4s ease-in-out',
      },
      '&:after': {
        content: '""',
        width: '100%',
        height: '1px',
        backgroundColor: 'currentColor',
        position: 'absolute',
        left: '-200%',
        bottom: 0,
        transform: 'translateX(0)',
        transition: 'transform 0.4s ease-in-out',
      },
      '&:hover:before, &:hover:after': {
        transform: 'translateX(200%)',
      },
    },
  },
  radio: {
    display: 'grid',
    placeContent: 'center',
    fontFamily: 'heading',
    cursor: 'pointer',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderRadius: '50%',
    border: '1px solid #CAB683',
    p: 0,
    bg: 'transparent',
    width: '20px',
    height: '20px',
    '&:before': {
      content: '""',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      boxShadow: 'inset 1em 1em #CAB683',
    },
    '&:hover:before': {
      transform: 'scale(1)',
    },
  },
  plain: {
    position: 'relative',
    minWidth: 'unset',
    p: 0,
    m: 0,
    bg: 'transparent',
    border: 0,
    borderRadius: 0,
    color: 'text',
    fontFamily: 'body',
    textDecoration: 'none',
    cursor: 'pointer',
    ':hover': {
      color: 'currentColor',
    },
  },
};
