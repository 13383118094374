import { Box } from 'theme-ui';

import { LinkWrapper, ShopifyImage } from '@snippets';
import store, { useSetRecoilState } from '@store';

export const ProductItemImage = ({
  handle,
  title,
  src,
  onLoad,
  withViewProduct,
  imageSizes,
  sx,
  isSwiper,
  isCollectionPage = null,
  ...props
}) => {
  const setSideDrawer = useSetRecoilState(store.sideDrawer);

  return (
    <Box
      className="product-item-image"
      sx={{
        width: '100%',
        height: isSwiper ? ['311px', '371px'] : 'auto',
        position: 'relative',
        pb: 0,
        overflow: 'hidden',
        cursor: 'pointer',
        borderRadius: '6px 6px 0 0',
        ...sx,
      }}
      {...props}
    >
      <LinkWrapper
        aria-label="Go to product details page."
        href={`/products/${handle}`}
      >
        <>
          {src ? (
            <ShopifyImage
              alt="Go to product details page."
              className="product-item-shopify-image"
              src={src}
              onLoad={onLoad}
              objectFit="contain"
              fluid="true"
              sizes={imageSizes}
              layout="fill"
              sx={{
                height: 'auto',
                transition: 'all 0.25s ease-in-out',
                transform: 'scale(0.8)',
                top: '0%',
                left: '0%',
                objectFit: 'contain',
              }}
            />
          ) : null}
        </>
      </LinkWrapper>
    </Box>
  );
};
