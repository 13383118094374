import { useState, useEffect } from 'react';
import { Flex, Box, Heading } from 'theme-ui';
import { useRouter } from 'next/router';
import { ProductItem } from '@snippets';
import { useProduct } from '@backpackjs/storefront';
import { withInView } from '@utils';

export const ProductRecommendations = withInView(
  ({ cms, inView }) => {
    const { productGroups } = cms;

    const [productGroup, setProductGroup] = useState(null);
    const router = useRouter();
    const handle = router.query.handle;
    const product = useProduct({ handle, forceRefetch: inView });

    useEffect(() => {
      let cancelling = false;
      if (!productGroups || !product) return;

      const _productGroup = productGroups.find((item) => {
        return item.tags?.some((tag) => product.tags?.includes(tag.trim()));
      });

      if (!cancelling && typeof setProductGroup === 'function') {
        setProductGroup(_productGroup || null);
      }
      return () => {
        cancelling = true;
      };
    }, [handle, inView]);

    if (!productGroup) return null;

    return productGroup ? (
      <Flex
        data-comp="ProductRecommendations"
        variant="flex.section"
        sx={{
          pb: [13, null, null, 17],
          px: [6, 11],
        }}
      >
        <Heading
          variant="24.r"
          sx={{
            mb: [10, null, null, 12],
            maxWidth: '700px',
            textAlign: ['left', 'center'],
            alignSelf: ['flex-start', 'center'],
          }}
        >
          {productGroup.heading}
        </Heading>

        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            alignItems: ['center', null, null, 'flex-start'],
            justifyContent: ['flex-start', null, null, 'center'],
            maxWidth: '1130px',
            width: '100%',
            overflow: 'hidden',
            display: 'grid',
            gridGap: ['12px', '20px'],
            gridTemplateColumns: ['1fr', null, 'repeat(3, 1fr)'],
          }}
        >
          {productGroup?.products
            ?.slice(0, 3)
            .map((productField, index, arr) => {
              const product = productField?.product || productField;

              if (!product.handle) return null;
              return (
                <Box
                  key={index}
                  sx={{
                    // flexBasis: ['100%', null, null, `calc(33.33% - ${40/3}px)`],
                    // width: ['100%', null, null, `calc(33.33% - ${40/3}px)`],
                    width: '100%',
                    maxWidth: ['auto', null, '518px', 'auto'],
                    zIndex: arr.length - index,
                  }}
                >
                  <ProductItem
                    handle={product.handle}
                    itemsCount={arr.length}
                    index={index}
                    imageSizes="(max-width: 2400px) 375px"
                    disableHover={true}
                    isSwiper={true}
                  />
                </Box>
              );
            })}
        </Flex>
      </Flex>
    ) : null;
  },
  { triggerOnce: true }
);

ProductRecommendations.Schema = {
  label: 'Product Recommendations',
  key: 'product-recommendations',
  fields: [
    {
      name: 'productGroups',
      label: 'Product Groups',
      description:
        'A Product Group should narrowly target only the products that will share the same Product Details section via a shared Shopify Tag.',
      component: 'group-list',
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          name: 'title',
          label: 'Product Group Name',
          component: 'text',
        },
        {
          name: 'tags',
          label: 'Product Group Shopify Tag(s)',
          component: 'tags',
          description:
            'Hit enter or spacebar to add tag. A tag must match exact spelling, casing, and spacing of the Shopify tag. If tag has any spaces, it must be copy and pasted in. Products that match ANY of these tags will be targeted.',
        },
        {
          name: 'heading',
          label: 'Heading',
          component: 'text',
        },
        {
          name: 'products',
          label: 'Products',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          validate(list) {
            if (list?.length > 3) {
              alert(`Max of 3 Products`);
              return list.slice(0, 3);
            }
          },
          fields: [
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
        },
      ],
    },
  ],
};
