import { Box } from 'theme-ui';

import { HeroVideo, LinkWrapper } from '@snippets';

export const CollectionGridCover = ({ collection }) => {
  return (
    <Box
      data-comp="CollectionGridCover"
      sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        transform: 'translateZ(0)',
        bg: 'white',
        cursor: collection?.cover?.link?.url ? 'pointer' : 'default',
        width: '100%',
        height: '100%',
        display: ['none', null, null, null, 'block'],
      }}
    >
      <LinkWrapper
        href={collection?.cover?.link?.url}
        newTab={collection?.cover?.link?.newTab}
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <HeroVideo
          video={{
            src: collection?.cover?.video,
            poster: collection?.cover?.poster?.src,
          }}
          mediaType={{
            main: 'video',
            mobile: 'video',
          }}
        />
      </LinkWrapper>
    </Box>
  );
};
