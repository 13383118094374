import store, { useRecoilValue } from '@store';

/*
  results: returns the API search results when term changes.
  because it's an async call any component that uses this useSearch hook
  must be wrapped on <Suspense />
*/
export const useSearchResults = () => {
  const results = useRecoilValue(store.results$);
  const foundProducts = useRecoilValue(store.foundProducts$);
  const foundCollections = useRecoilValue(store.foundCollections$);

  return [{ results, foundProducts, foundCollections }, {}];
};
