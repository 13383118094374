/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { Box, Flex, Paragraph, Text } from 'theme-ui';
import { LinkWrapper, YotpoStars, AddToCart } from '@snippets';
import store, { useRecoilValue } from '@store';
import { ProductItemQuantitySelect } from './ProductItemQuantitySelect';

export function ProductItemDetails({
  productItem,
  selectedVariant,
  quantity,
  setQuantity,
  handle,
  isCollectionPage = null,
  setIsHover,
  setIsFocused,
  isFocused,
  ...props
}) {
  const isHidden =
    !isCollectionPage &&
    selectedVariant?.product?.tags.includes('hide-quick-add');
  const country = useRecoilValue(store.country);
  const minPrice =
    productItem?.variants?.length > 1 &&
    (productItem?.productType === 'Gift Cards' ||
      productItem?.productType === 'Fashion')
      ? productItem.variants.reduce((a, b) => {
          if (!a) {
            return b.price;
          }
          return parseFloat(a) < parseFloat(b.price) ? a : b.price;
        }, productItem?.variants?.[0]?.price)
      : 0;
  const maxPrice =
    productItem?.variants?.length > 1 &&
    (productItem?.productType === 'Gift Cards' ||
      productItem?.productType === 'Fashion')
      ? productItem.variants.reduce((a, b) => {
          if (!a) {
            return b.price;
          }
          return parseFloat(a) > parseFloat(b.price) ? a : b.price;
        }, productItem?.variants?.[0]?.price)
      : 0;

  return (
    <Box data-comp='ProductItemDetails' {...props}>
      <Flex
        sx={{
          mb: 3,
          alignItems: isCollectionPage
            ? ['center', null, null, null, '']
            : null,
          justifyContent: isCollectionPage
            ? ['center', null, null, null, 'space-between']
            : null,
          gap: '8px',
        }}>
        {productItem?.legacyResourceId && (
          <LinkWrapper href={`/products/${handle}`}>
            <YotpoStars
              legacyResourceId={productItem.legacyResourceId}
              isProductItem
              isCollectionPage={isCollectionPage}
              textColor='black'
            />
          </LinkWrapper>
        )}
        <Flex
          variant='flex.row.end.center'
          className='product-item-starting-price'
          sx={{
            flexWrap: 'wrap',
            display: isCollectionPage
              ? ['none', null, null, null, 'flex']
              : null,
          }}>
          {minPrice === maxPrice ? null : (
            <Text
              variant='xs'
              ml={3}
              sx={{
                fontSize: isCollectionPage
                  ? [null, null, null, null, '14px']
                  : null,
              }}>
              Starting at
            </Text>
          )}
          <Text variant='18' ml={3}>
            {minPrice !== maxPrice ? (
              <>${minPrice}</>
            ) : (
              <>${productItem?.variants?.[0]?.price}</>
            )}
          </Text>
        </Flex>
      </Flex>

      {/* Product Title */}
      <Flex
        sx={{
          position: 'relative',
          width: '100%',
          justifyContent: isCollectionPage
            ? ['center', null, null, null, 'flex-start']
            : null,
          textAlign: isCollectionPage
            ? ['center', null, null, null, 'left']
            : null,
        }}>
        <LinkWrapper
          href={`/products/${handle}`}
          onFocus={() => {
            setIsFocused(true);
            setIsHover(false);
          }}
          // onBlur={() => setIsHover(false)}
        >
          <Paragraph
            onFocus={() => {
              setIsFocused(true);
              setIsHover(false);
            }}
            // onBlur={() => setIsHover(false)}
            variant='18'
            sx={{
              letterSpacing: '.03em !important',
              whiteSpace: 'wrap',
              cursor: 'pointer',
              fontSize: isCollectionPage
                ? ['13px', null, null, null, '18px']
                : null,
              lineHeight: ['1.0769', null, null, null, '1.3333'],
              fontWeight: isCollectionPage
                ? ['700', null, null, null, '400']
                : null,
            }}>
            {productItem?.title}
          </Paragraph>
        </LinkWrapper>
      </Flex>

      {/* Collection Page Mobile Price */}
      {isCollectionPage && (
        <Text
          variant='18'
          sx={{
            fontSize: '13px',
            lineHeight: '1.0769',
            textAlign: 'center',
            display: ['block', null, null, null, 'none'],
            mt: '15px',
          }}>
          {minPrice !== maxPrice ? (
            <>Starting at ${minPrice}</>
          ) : (
            <>${productItem?.variants?.[0]?.price}</>
          )}
        </Text>
      )}

      {/* Quantity + A2c mobile */}
      {!isHidden && selectedVariant && country && (
        <Box
          onFocus={() => {
            setIsFocused(true);
            setIsHover(false);
          }}
          tabIndex={0}
          sx={{
            display: isCollectionPage
              ? 'none'
              : ['flex', 'flex', 'flex', null, isFocused ? 'flex' : 'none'],
            mt: 8,
            mb: 3,
            ' [data-comp="AddToCart"]': {
              minWidth: ['120px', '188px'],
            },
          }}>
          <ProductItemQuantitySelect
            quantity={quantity}
            setQuantity={setQuantity}
            selectedVariant={selectedVariant}
            isCollectionPage={isCollectionPage}
          />

          <AddToCart
            selectedVariant={selectedVariant}
            quantity={quantity}
            isPdp
            isCollectionPage={isCollectionPage}
          />
        </Box>
      )}
    </Box>
  );
}
