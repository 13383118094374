import { Paragraph, Box } from 'theme-ui';
import { entryAnimation, withInView } from '@utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);

export const ProductDetails = withInView(
  ({ cms: { description, features }, inView }) => {
    if (!features) return null;
    return (
      <Box
        data-comp="ProductDetails"
        sx={{
          zIndex: 1,
          position: 'relative',
          ...entryAnimation({
            entered: inView,
            fadeIn: true,
            slideIn: false,
            duration: 500,
            index: 1,
            delay: 0,
          }),
        }}
      >
        <Box
          sx={{
            backgroundImage:
              "url(\"data:image/svg+xml;charset=utf-8,<svg width='1440' height='20' viewBox='0 0 1440 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1440 20.0002C1412.23 20.0002 1412.23 0.000241713 1384.63 0.0002393C1356.86 0.000236872 1356.86 20.0002 1329.26 20.0002C1301.49 20.0002 1301.49 0.000232031 1273.89 0.000229618C1246.11 0.000227191 1246.11 20.0002 1218.51 20.0002C1190.74 20.0002 1190.74 0.00022235 1163.14 0.000219937C1135.37 0.000217509 1135.37 20.0002 1107.77 20.0002C1080 20.0002 1080 0.000212668 1052.4 0.000210256C1024.63 0.000207828 1024.63 20.0002 997.029 20.0002C969.257 20.0002 969.257 0.000202987 941.657 0.000200574C913.886 0.000198146 913.886 20.0002 886.286 20.0002C858.514 20.0002 858.514 0.000193306 830.914 0.000190893C803.143 0.000188465 803.143 20.0002 775.543 20.0002C747.771 20.0002 747.771 0.000183624 720.171 0.000181211C692.4 0.000178783 692.4 20.0002 664.8 20.0002C637.029 20.0002 637.029 0.000173943 609.429 0.00017153C581.657 0.000169102 581.657 20.0002 554.057 20.0002C526.286 20.0002 526.286 0.000164261 498.514 0.000161833C470.743 0.000159405 470.743 20.0002 443.143 20.0002C415.371 20.0002 415.371 0.000154565 387.771 0.000152152C360 0.000149724 360 20.0001 332.4 20.0001C304.629 20.0001 304.629 0.000144883 277.029 0.00014247C249.257 0.000140043 249.257 20.0001 221.657 20.0001C193.886 20.0001 193.886 0.000135202 166.286 0.000132789C138.514 0.000130361 138.514 20.0001 110.914 20.0001C83.1428 20.0001 83.1429 0.00012552 55.5428 0.000123108C27.7715 0.00012068 27.7714 20.0001 0.171387 20.0001' fill='%23F2F3F4'/></svg>\")",
            backgroundPosition: 'center top',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '1440px 20px',
            height: '20px',
            width: '100%',
            mt: '-20px',
          }}
        />
        <Box
          data-comp="ProductDetailsInner"
          sx={{
            bg: 'gray.1',
            py: '38px',
          }}
        >
          <Swiper
            init={true}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              waitForTransition: true,
            }}
            centeredSlides={true}
            cssMode={false}
            freeMode={true}
            loop={true}
            loopedSlides={features?.length * 2}
            slidesPerView="auto"
            spaceBetween={0}
            speed={6000}
            watchOverflow={true}
            sx={{
              width: '100%',
              '.swiper-wrapper': {
                alignItems: 'center',
                transitionTimingFunction: 'linear !important',
              },
              '.swiper-slide': {
                width: 'auto',
              },
            }}
          >
            {features?.map((feature, index, arr) => (
              <SwiperSlide key={index}>
                <Paragraph
                  variant="18"
                  sx={{
                    px: ['16px', null, null, '48px'],
                  }}
                >
                  {feature}
                </Paragraph>
              </SwiperSlide>
            ))}
            {features?.map((feature, index, arr) => (
              <SwiperSlide key={index}>
                <Paragraph
                  variant="18"
                  sx={{
                    px: ['16px', null, null, '48px'],
                  }}
                >
                  {feature}
                </Paragraph>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box
          sx={{
            backgroundImage:
              "url(\"data:image/svg+xml;charset=utf-8,<svg width='1440' height='20' viewBox='0 0 1440 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1440 20.0002C1412.23 20.0002 1412.23 0.000241713 1384.63 0.0002393C1356.86 0.000236872 1356.86 20.0002 1329.26 20.0002C1301.49 20.0002 1301.49 0.000232031 1273.89 0.000229618C1246.11 0.000227191 1246.11 20.0002 1218.51 20.0002C1190.74 20.0002 1190.74 0.00022235 1163.14 0.000219937C1135.37 0.000217509 1135.37 20.0002 1107.77 20.0002C1080 20.0002 1080 0.000212668 1052.4 0.000210256C1024.63 0.000207828 1024.63 20.0002 997.029 20.0002C969.257 20.0002 969.257 0.000202987 941.657 0.000200574C913.886 0.000198146 913.886 20.0002 886.286 20.0002C858.514 20.0002 858.514 0.000193306 830.914 0.000190893C803.143 0.000188465 803.143 20.0002 775.543 20.0002C747.771 20.0002 747.771 0.000183624 720.171 0.000181211C692.4 0.000178783 692.4 20.0002 664.8 20.0002C637.029 20.0002 637.029 0.000173943 609.429 0.00017153C581.657 0.000169102 581.657 20.0002 554.057 20.0002C526.286 20.0002 526.286 0.000164261 498.514 0.000161833C470.743 0.000159405 470.743 20.0002 443.143 20.0002C415.371 20.0002 415.371 0.000154565 387.771 0.000152152C360 0.000149724 360 20.0001 332.4 20.0001C304.629 20.0001 304.629 0.000144883 277.029 0.00014247C249.257 0.000140043 249.257 20.0001 221.657 20.0001C193.886 20.0001 193.886 0.000135202 166.286 0.000132789C138.514 0.000130361 138.514 20.0001 110.914 20.0001C83.1428 20.0001 83.1429 0.00012552 55.5428 0.000123108C27.7715 0.00012068 27.7714 20.0001 0.171387 20.0001' fill='%23F2F3F4'/></svg>\")",
            backgroundPosition: 'center top',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '1440px 20px',
            height: '20px',
            width: '100%',
            mb: '-20px',
            transform: 'rotate(180deg)',
          }}
        />
      </Box>
    );
  },
  { triggerOnce: true }
);

ProductDetails.Schema = {
  label: 'Product Details',
  key: 'product-details',
  fields: [
    {
      name: 'description',
      component: 'textarea',
      label: 'Description',
    },
    {
      name: 'features',
      label: 'Features',
      component: 'list',
      field: {
        component: 'text',
      },
    },
  ],
};
