import React from 'react';

export const useMounted = () => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    let cancelled = false;
    if (!cancelled) {
      setMounted(true);
    }

    return () => {
      cancelled = true;
    };
  }, []);

  return [mounted];
};
