import { useCallback, useEffect, useState } from "react";
import { Flex, Button, Input, Paragraph, Image, Spinner } from "theme-ui";

import { useMounted, useBreakpointValue, useKlaviyo } from "@hooks";
import { useSettings } from "@backpackjs/storefront";

import { isBrowser } from "@utils";

export function SmsSignup({ ...props }) {
  const siteSettings = useSettings();
  const {
    footer: { smsSignup },
  } = siteSettings;

  const [tel, setTel] = useState("");
  const [opacity, setOpacity] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [placeholderText, setPlaceholderText] = useState(
    smsSignup?.placeholder || ""
  );
  const { klaviyoAPI } = useKlaviyo();
  const mounted = useMounted();
  const listId = useBreakpointValue([
    smsSignup?.listIdMobile || smsSignup?.listId,
    smsSignup?.listIdMobile || smsSignup?.listId,
    smsSignup?.listIdMobile || smsSignup?.listId,
    smsSignup?.listId,
  ]);

  useEffect(() => {
    const filteredValue = tel.replace("+1 ", "").match(/\d*/g).join("");
    setTel(
      filteredValue.replace(/(\d{3})\-?(\d{3})\-?(\d{4}).*/, "+1 ($1)-$2-$3")
    );
  }, [tel]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!tel || !listId || submitted || isLoading) return;

      const cleanInput = `${tel}`.replace(/\D/g, "");
      const match = cleanInput.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      const intlCode = match[1] ? "+1" : "+1";
      const formattedTel = [intlCode, match[2], match[3], match[4]].join("");

      const params = { tel: formattedTel, listId };

      if (isBrowser) {
        setIsLoading(true);
        const response = await klaviyoAPI({
          action: "postSubscribeSms",
          params,
        });

        if (response.ok) {
          setIsLoading(false);
          setSubmitted(true);
          setPlaceholderText("Phone number submitted!");
          setTel("");
          setTimeout(() => {
            setSubmitted(false);
            setPlaceholderText(smsSignup?.placeholder || "");
            setOpacity(0);
          }, 5000);
        } else {
          setIsLoading(false);
          setErrorMessage(
            tel.length < 12
              ? "Error: Phone number length does not appear to be valid. Please try again."
              : "Error: Something went wrong, please try again."
          );
          setTimeout(() => {
            setPlaceholderText(smsSignup?.placeholder || "");
            setErrorMessage("");
          }, 9000);
        }
      }
    },
    [tel, listId, submitted, isLoading, smsSignup?.placeholder]
  );

  if (!mounted) return null;

  return (
    <Flex
      data-comp="SmsSignup"
      variant="flex.column"
      {...props}
      sx={{ mt: [4, 10] }}
    >
      {smsSignup?.heading && (
        <Paragraph variant="label.3" sx={{ color: "white", mb: [4, 7] }}>
          {smsSignup?.heading}
        </Paragraph>
      )}
      <Flex
        as="form"
        variant="flex.row.between.center"
        onSubmit={handleSubmit}
        sx={{ bg: "white", border: "1px solid", borderColor: "gray.3" }}
      >
        <Input
          id="phone"
          aria-label="Enter US domestic cellphone number to sign up"
          aria-required="true"
          type="tel"
          required
          value={tel}
          onChange={(e) => setTel(e.target.value)}
          placeholder={placeholderText}
          sx={{
            variant: "text.base",
            pl: 8,
            py: 6,
            m: 0,
            pr: 0,
            fontSize: 5,
            border: 0,
            height: "100%",
            flex: 1,
            fontFamily: "body",
            "&:focus": {
              outline: "none",
            },
            "&::placeholder": {
              fontFamily: "body",
              variant: "text.base",
            },
            "::before": {
              content: "'+1'",
            },
          }}
        />
        <Flex
          variant="flex.row.center.center"
          sx={{
            minWidth: "80px",
            minHeight: "44px",
          }}
        >
          {isLoading ? (
            <Spinner
              sx={{
                width: "20px",
                color: "black",
                position: "absolute",
              }}
            />
          ) : submitted ? (
            <Image
              alt="check mark"
              src="/svg/check-mark.svg"
              onLoad={() => setOpacity(1)}
              sx={{
                width: "18px",
                transition: "opacity 0.3s ease-in-out",
                opacity,
              }}
            />
          ) : (
            <Button
              sx={{
                color: "black",
                fontSize: 1,
                lineHeight: 18,
                letterSpacing: 0.08,
                textAlign: "right",
                px: 8,
                py: 6,
              }}
            >
              Submit
            </Button>
          )}
        </Flex>
      </Flex>
      {smsSignup?.subtext && (
        <Paragraph
          variant="base"
          sx={{
            color: "white",
            mt: [5, 6],
            whiteSpace: "pre-wrap",
          }}
        >
          {smsSignup?.subtext}
        </Paragraph>
      )}
      {errorMessage && (
        <Paragraph variant="base" sx={{ color: "white", mt: [5, 6] }}>
          {errorMessage}
        </Paragraph>
      )}
    </Flex>
  );
}
