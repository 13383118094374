import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Box, Image, Paragraph, Flex, Spinner } from "theme-ui";
import {
  useRouter,
  useCartRemoveItem,
  useSettings,
} from "@backpackjs/storefront";

import store, { useSetRecoilState, useRecoilValue } from "@store";
import { Picture, LinkWrapper, Svg } from "@snippets";

import { generateId } from "@utils";

import atob from "atob";
import { QuantitySelect } from "./QuantitySelect";

export function CartLineItem({
  id,
  quantity,
  variant,
  sellingPlanAllocation,
  discountAllocations,
  estimatedCost,
  ...line
}) {
  const settings = useSettings();
  const giftWrapHandle = settings?.cart?.gifting?.product?.handle;
  const isGiftWrap = variant?.product?.handle === giftWrapHandle;
  const isGiftCard = variant?.product?.productType === "Gift Cards";
  const isCashBack = variant?.product?.handle === "20-cashback";
  const isCartPage = useRouter()?.asPath === "/cart";
  const [isDeleting, setIsDeleting] = useState(false);
  const { cartRemoveItem, ...cartRemoveStatus } = useCartRemoveItem({
    autoReset: 100,
  });
  const setCashbackModalOpen = useSetRecoilState(store.cashbackModalOpen);
  const setCartDrawer = useSetRecoilState(store.cartDrawer);
  const discountDescription =
    discountAllocations?.[0]?.discountApplication?.description;

  const discountAppliedNumber = useMemo(() => {
    const subtotalAmount = estimatedCost?.subtotalAmount?.amount;
    const totalAmount = estimatedCost?.totalAmount?.amount;

    return totalAmount && subtotalAmount
      ? Math.round(100 - (totalAmount / subtotalAmount) * 100)
      : 0;
  }, [estimatedCost]);

  // Gift with purchase line item
  const isGiftWithPurchaseLine = useRecoilValue(
    store.isGiftWithPurchaseLine$(id)
  );

  // e.g 3rd and 4th tiers give 5% and 10% discount
  const activeTierAppliesDiscount = useRecoilValue(
    store.activeTierAppliesDiscount$
  );

  // line item price including any discounts applied due to the tiering 5% or 10%
  const tieredLinePrice = parseFloat(estimatedCost.totalAmount.amount);

  const gwpMessage = useMemo(() => {
    return discountDescription?.toLowerCase().includes("free")
      ? discountDescription
      : null;
  }, [discountDescription]);

  const discountedPrice = 0;

  const compareAtPrice = useMemo(() => {
    const _compareAtPrice = parseFloat(variant?.compareAtPrice);
    const pricePerQty = parseFloat(variant.price);
    if (_compareAtPrice > pricePerQty) {
      return _compareAtPrice;
    }
    if (discountedPrice < pricePerQty) {
      return pricePerQty;
    }
    return null;
  }, [variant, discountedPrice]);

  const isGear = variant?.product?.productType.toLowerCase() === "fashion";
  const gearSize = isGear ? variant?.title : null;
  const isBundleProduct = line?.attributes?.find(
    (attr) => attr.key === "_bundleId"
  );

  return (
    <Flex
      data-comp="CartLineItem"
      variant="flex.row.between.start"
      sx={{
        borderBottom: "1px solid",
        borderColor: "gray.6",
        py: 8,
        px: 9,
        overflow: "hidden",
        position: "relative",
        ":hover .close-icon": {
          opacity: 1,
          visibility: "visible",
        },
      }}
    >
      <Picture
        alt={variant?.title}
        images={[
          {
            src: variant?.image?.src || "/svg/default-image.svg",
            ratio: 1,
            width: 96,
          },
        ]}
        sx={{
          width: "96px",
          borderRadius: "3px",
          mr: 8,
        }}
      />

      <Flex
        variant="flex.column.start.between"
        sx={{ flex: 1, minHeight: "90px" }}
      >
        {/* title + remove icon */}
        <Flex
          variant="flex.row.between.start"
          sx={{ width: "100%", minHeight: "44px" }}
        >
          <Flex
            variant="flex.column.start"
            sx={{
              mr: 4,
              flex: 1,
              minHeight: "44px",
            }}
          >
            <LinkWrapper
              href={
                isGiftWrap || isGiftWithPurchaseLine
                  ? null
                  : `/products/${variant?.product?.handle}`
              }
            >
              <Paragraph
                variant="18"
                sx={{
                  cursor:
                    isGiftWrap || isGiftWithPurchaseLine
                      ? "default"
                      : "pointer",
                  color: isCartPage ? "black" : "white",
                }}
                onClick={() => {
                  if (isGiftWrap || isGiftWithPurchaseLine) return;
                  setCartDrawer(false);
                }}
              >
                {variant.product.title}
              </Paragraph>
            </LinkWrapper>

            {sellingPlanAllocation?.sellingPlan && (
              <Paragraph
                variant="text.xs"
                sx={{ color: "white", mt: 2, mb: gwpMessage ? 4 : 6 }}
              >
                {sellingPlanAllocation.sellingPlan.name}
              </Paragraph>
            )}
            {isGear && (
              <Paragraph
                variant="text.xs"
                sx={{ color: "white", mt: 2, mb: gwpMessage ? 4 : 6 }}
              >
                {gearSize}
              </Paragraph>
            )}

            {discountAppliedNumber > 0 && (
              <Paragraph variant="text.xxs" sx={{ color: "white", mb: 8 }}>
                {discountAppliedNumber}% OFF APPLIED
              </Paragraph>
            )}

            {gwpMessage && (
              <Paragraph variant="text.xs" sx={{ color: "white", mb: 8 }}>
                {gwpMessage}
              </Paragraph>
            )}

            {isCashBack && (
              <Button
                sx={{ display: "flex", alignItems: "center", mt: 2 }}
                onClick={setCashbackModalOpen}
              >
                <Box variant="text.xs" as="span" sx={{ color: "gold", mr: 2 }}>
                  MORE INFO
                </Box>
                <Svg
                  alt=""
                  src="/svgs/info.svg#info"
                  sx={{
                    height: 14,
                    width: 14,
                    color: "gold",
                    position: "relative",
                    top: "-1px",
                  }}
                  viewBox="0 0 14 14"
                />
              </Button>
            )}
          </Flex>

          {isGiftWithPurchaseLine ? null : (
            <Image
              src="/svg/close-cart-item.svg"
              className="close-icon"
              sx={{
                cursor: "pointer",
                opacity: [1, null, null, null, 0],
                visibility: ["visible", null, null, null, "hidden"],
                transition: "all 0.1s ease-in-out",
              }}
              onClick={async () => {
                if (!id) return;
                await cartRemoveItem({ lineId: id });

                if (!variant) return;

                // const removeFromCartData = {
                //   name: variant?.product?.title,
                //   id: variant?.sku || '',
                //   product_id: atob(variant?.product?.id).split('/').pop() || '',
                //   variant_id: atob(variant?.id).split('/').pop(),
                //   image: variant?.image?.src || '',
                //   price: variant?.priceV2?.amount,
                //   brand: 'Truff',
                //   variant: variant?.title || '',
                //   inventory: variant?.quantityAvailable,
                // }

                // const removeFromCartEvent = {
                //   event: 'dl_remove_from_cart',
                //   event_id: generateId(),
                //   ecommerce: {
                //     currencyCode: 'USD',
                //     remove: {
                //       actionField: { list: location.pathname },
                //       products: [removeFromCartData],
                //     },
                //   },
                // };

                // console.log('Elevar:dl_remove_from_cart', removeFromCartEvent);
                // window.dataLayer.push(removeFromCartEvent);
              }}
            />
          )}
        </Flex>

        {/* Quantity + Price */}
        {!isCashBack && (
          <Flex variant="flex.row.between.end" sx={{ width: "100%" }}>
            <QuantitySelect
              id={id}
              quantity={quantity}
              setIsDeleting={setIsDeleting}
              isGiftWrap={isGiftWrap}
              isBundleProduct={isBundleProduct}
            />

            {/* Price  */}
            {isGiftWithPurchaseLine ? (
              <Paragraph
                variant="label.2"
                sx={{
                  textAlign: "right",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                Free 🎁
              </Paragraph>
            ) : (
              <Flex variant="flex.column" sx={{ mb: 2 }}>
                {discountAppliedNumber > 0 &&
                !activeTierAppliesDiscount &&
                estimatedCost?.subtotalAmount?.amount ? (
                  <Paragraph
                    variant="label.2"
                    sx={{
                      textDecoration: "line-through",
                      textAlign: "right",
                      color: "white",
                    }}
                  >
                    $
                    {parseFloat(estimatedCost.subtotalAmount.amount).toFixed(2)}
                  </Paragraph>
                ) : compareAtPrice ? (
                  <Paragraph
                    variant="label.2"
                    sx={{
                      textDecoration: "line-through",
                      textAlign: "right",
                      color: "white",
                    }}
                  >
                    ${compareAtPrice.toFixed(2)}
                  </Paragraph>
                ) : null}

                {activeTierAppliesDiscount ? (
                  <>
                    {!isGiftCard && (
                      <Paragraph
                        variant="label.2"
                        sx={{
                          textDecoration: "line-through",
                          textAlign: "right",
                          color: "white",
                        }}
                      >
                        $
                        {parseFloat(
                          estimatedCost.subtotalAmount.amount
                        ).toFixed(2)}
                      </Paragraph>
                    )}
                    <Paragraph
                      variant="label.2"
                      sx={{
                        textAlign: "right",
                        color: "white",
                        textTransform: "uppercase",
                      }}
                    >
                      ${tieredLinePrice.toFixed(2)}
                    </Paragraph>
                  </>
                ) : (
                  <Paragraph
                    variant="label.2"
                    sx={{
                      textAlign: "right",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    ${tieredLinePrice.toFixed(2)}
                  </Paragraph>
                )}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>

      {cartRemoveStatus.started && !cartRemoveStatus.finished ? (
        <Flex
          variant="flex.column.center.center"
          sx={{
            width: "100%",
            height: "100%",
            bg: "rgba(0,0,0,0.4)",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Spinner sx={{ width: "30px", color: "gold" }} />
        </Flex>
      ) : null}
    </Flex>
  );
}

CartLineItem.propTypes = {
  id: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.object,
  index: PropTypes.number,
};
