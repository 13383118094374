import { useCallback, useEffect, useState, useRef } from 'react';
import { Box, Spinner, Flex, Heading } from 'theme-ui';

import { useCustomer } from '@backpackjs/storefront';
import store, { useRecoilValue } from '@store';
import { useYotpoLoyalty } from '@hooks';

export const Rewards = () => {
  const ref = useRef();
  const scriptRef = useRef(null);
  const { yotpoCustomer: customer } = useYotpoLoyalty();
  const { yotpoCustomer: ytpCustomer } = useRecoilValue(store.yotpoLoyaltyData);
  const storeCustomer = useCustomer();
  const yotpoCustomer = ytpCustomer || customer || storeCustomer;

  const [injected, setInjected] = useState(false);
  const injectYotpoScriptOnMount = useCallback(async () => {
    try {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/yIanDe8wBrDQPP_zPFi5Cg`;
      script.setAttribute('id', `swell-rewards`);
      script.setAttribute('strategy', 'afterInteractive');
      script.setAttribute('async', true);
      scriptRef.current = script;

      await ref.current.appendChild(script);

      setInjected(true);
    } catch (error) {
      console.error(error);
    }
  }, [yotpoCustomer?.email]);

  const reloadWidgetOnCustomerUpdate = useCallback(async () => {
    try {
      if (!injected || !yotpoCustomer?.email) return;
      console.log('💬 Attempting Yotpo Loyalty widget reload...');
      // const guidWidgetContainer = window.yotpoWidgetsContainer?.guids?.['yIanDe8wBrDQPP_zPFi5Cg'];
      // const initWidgets = window.yotpoWidgetsContainer?.initWidgets();
      // window.yotpoWidgetsContainer?.yotpo_widget_initializer();
      // guidWidgetContainer?.['yotpo_widget_scripts_loaded'] = false;
      // guidWidgetContainer?.onInitializerLoad();
      // guidWidgetContainer?.initWidgets();
    } catch (error) {
      console.error(error);
    }
  }, [yotpoCustomer?.email, injected]);

  useEffect(() => {
    setTimeout(injectYotpoScriptOnMount, 2500);
  }, [yotpoCustomer?.email]);

  useEffect(() => {
    const div = document.querySelector(
      '.yotpo-widget-instance-container[yotpo-instance-id="99311"]'
    );

    if (!div) {
      setInjected(false);
      window.yotpoWidgetsContainer?.initWidgets();
      setInjected(true);
    }
  }, [yotpoCustomer?.email, injected]);

  return (
    <Box
      ref={ref}
      sx={{
        minHeight: '800px',
      }}>
      {!injected && (
        <Flex
          variant='flex.column.center.center'
          sx={{
            height: '500px',
          }}>
          <Heading
            variant='text.24.r'
            sx={{
              mb: 10,
              px: 10,
              textAlign: 'center',
            }}>
            Truff Rewards Loading...
          </Heading>
          <Spinner />
        </Flex>
      )}

      <div
        id='swell-customer-identification'
        data-authenticated='true'
        data-email={yotpoCustomer?.email}
        data-id={yotpoCustomer?.third_party_id}
        data-tags='"[]"'
        style={{ display: 'none' }}
      />

      <Box className='yotpo-widget-instance' data-yotpo-instance-id='99310' />
    </Box>
  );
};
