import React, { useState, useEffect } from 'react';
import { Flex, Paragraph, Spinner } from 'theme-ui';

import store, { useRecoilValue } from '@store';

import { RewardsHistoryRow } from './RewardsHistoryRow';

export const RewardsModalHistory = ({ yotpoCustomer }) => {
  const isSwellSetup = useRecoilValue(store.isSwellSetup);
  const [isLoading, setIsLoading] = useState(true);
  const email = yotpoCustomer?.email;
  const isYotpoCustomer = !!email && email !== 'undefined';

  useEffect(() => {
    setIsLoading(!isSwellSetup || !isYotpoCustomer);
  }, [isSwellSetup, isYotpoCustomer]);

  const history = Array.isArray(yotpoCustomer?.history_items)
    ? [...yotpoCustomer?.history_items]
    : [];

  return (
    <Flex
      data-comp="RewardsModalHistory"
      variant="flex.column.center"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Paragraph variant="24" sx={{ mb: 8 }}>
        My Rewards
      </Paragraph>
      <RewardsHistoryRow
        item={headings}
        index={0}
        arr={2}
        isHeadings={true}
        sx={{ width: '100%' }}
      />
      {!isLoading ? (
        <Flex
          variant="flex.column"
          sx={{
            width: '100%',
            overflowY: 'scroll',
            maxHeight: '300px',
            overflowY: 'scroll',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {history.reverse().map((item, index, arr) => (
            <RewardsHistoryRow
              key={index}
              item={item}
              index={index}
              arr={arr}
            />
          ))}
        </Flex>
      ) : (
        <Flex
          variant="flex.row.center.center"
          sx={{
            width: '100%',
            minHeight: '200px',
          }}
        >
          <Spinner sx={{ color: 'black', width: '30px' }} />
        </Flex>
      )}
    </Flex>
  );
};

const headings = {
  date: 'Date',
  action: 'Action',
  points: 'Points',
  status: 'Status',
};
