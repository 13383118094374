import { Flex } from 'theme-ui';

import { ShopifyImage } from '@snippets';

export function ProductImage({ src, alt, ...props }) {
  return (
    <Flex
      data-comp="ProductImage"
      variant="flex.row.center.center"
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {src && (
        <ShopifyImage
          src={src}
          objectFit="contain"
          fluid="true"
          sizes="(max-width: 640px) 640px, (max-width: 1022px) 828px, (max-width: 3000px) 720px"
          layout="fill"
          sx={{
            height: '100%',
            top: '0%',
            left: '0%',
            objectFit: ['contain','cover'],
          }}
        />
      )}
    </Flex>
  );
}
