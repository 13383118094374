import { useRef } from 'react';
import { Box, Container } from 'theme-ui';
import { useSwipeable } from 'react-swipeable';
import { useSettings } from '@backpackjs/storefront';

import store, { useRecoilState, useSetRecoilState } from '@store';
import { MountAfter } from '@snippets';

import { CollectionsMenu } from './CollectionsMenu';
import { ExploreLinks } from './ExploreLinks';
import { MobileMenu } from './MobileMenu';

export const MenuDrawer = (props) => {
  const siteSettings = useSettings();
  const { header } = siteSettings;
  const menuDrawerRef = useRef();
  const [menuDrawer, setMenuDrawer] = useRecoilState(store.menuDrawer);
  const [menuDrawerIndex, setMenuDrawerIndex] = useRecoilState(
    store.menuDrawerIndex
  );
  const setMenuItem = useSetRecoilState(store.menuItem);
  const setOverlay = useSetRecoilState(store.overlay);

  const closeMenuDrawer = () => {
    if (menuDrawer) {
      // reset and close menu
      setMenuItem(null);
      setOverlay(false);
      setMenuDrawer(false);
      setMenuDrawerIndex(null);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      closeMenuDrawer();
    },
    onSwipedRight: () => {
      closeMenuDrawer();
    },
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  });

  const refPassthrough = (el) => {
    swipeHandlers.ref(el);
    menuDrawerRef.current = el;
  };

  const headerTopPx = 'var(--header-height)';
  const handleCloseDrawerWithESC = (e) => {
    if (e && e.key === 'Escape') {
      closeMenuDrawer();
    }
  };
  return (
    <Box
      as="aside"
      data-comp="MenuDrawer"
      ref={refPassthrough}
      {...props}
      onKeyDown={handleCloseDrawerWithESC}
      sx={{
        zIndex: 1,
        position: 'fixed',
        top: headerTopPx,
        left: 0,
        width: '100%',
        bg: (t) => t.colors.black,
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
        transform: [
          menuDrawer ? 'translateX(0)' : 'translateX(-100%)',
          null,
          null,
          null,
          menuDrawer ? 'translateY(0)' : 'translateY(-100vh)',
        ],
        willChange: 'transform',
        transition: 'transform .3s ease-in-out',
        ...props.sx,
      }}
    >
      <Container
        data-comp="MenuDrawerScroll"
        variant="layout.menu"
        sx={{
          py: [null, null, null, null, '40px'],
          px: [0, null, null, null, '15px'],
          height: ['100vh', null, null, null, 'auto'],
          maxHeight: [
            'none',
            null,
            null,
            null,
            `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))`,
          ],
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
          webkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {/* Collection Menu Selector + Collection Product Links */}
        {menuDrawer && header?.collectionLinks && menuDrawerIndex == 0 ? (
          <MountAfter delay={100}>
            <CollectionsMenu collectionLinks={header?.collectionLinks} />
          </MountAfter>
        ) : null}

        {/* Explore links */}
        {menuDrawer && header?.exploreLinks && menuDrawerIndex == 1 ? (
          <MountAfter delay={100}>
            <ExploreLinks exploreLinks={header?.exploreLinks} />
          </MountAfter>
        ) : null}

        {(menuDrawer && header?.collectionLinks) || header?.exploreLinks ? (
          <MobileMenu
            collectionLinks={header?.collectionLinks}
            exploreLinks={header?.exploreLinks}
          />
        ) : null}
      </Container>
    </Box>
  );
};
