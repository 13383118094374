import { BackpackImage, VideoPlayer } from '@snippets';
import { Box, Flex, Paragraph, AspectRatio } from 'theme-ui';

export const ArticleHero = ({ hero, ...props }) => {
  const haveVideo = hero?.video?.id !== '';
  const haveMobileImage = hero?.image?.mobile;
  const haveDesktopImage = hero?.image?.desktop;
  const withTags = hero?.tags?.enabled && hero?.tags?.list?.length;

  return (
    <Box
      data-comp="ArticleHero"
      {...props}
      sx={{
        position: 'relative',
        ...props.sx,
      }}
    >
      {/* Video has top priority */}
      {haveVideo ? (
        <AspectRatio
          ratio={16 / 9}
          sx={{
            bg: 'white',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            borderTopWidth: '6px',
            overflow: 'hidden',
            iframe: {
              position: 'absolute',
              border: 'none',
            },
          }}
        >
          <VideoPlayer
            video={{ videoId: hero?.video?.id }}
            mediaType={{ main: 'video', mobile: 'video' }}
          />
        </AspectRatio>
      ) : null}

      {/* Mobile Image (or both if no desktop) */}
      {haveMobileImage && !haveVideo ? (
        <Box sx={{ display: ['block', null, null, haveDesktopImage ? 'none' : 'block'], }}>
          <BackpackImage
            src={hero?.image?.mobile?.src || hero?.image?.mobile}
            alt={hero?.image?.altMobile || 'Article hero mobile image.'}
            ratio={16 / 9}
            sx={{
              borderRadius: '6px 6px 0px 0px',
              minWidth: '100%',
            }}
          />
        </Box>
      ) : null}

      {/* Desktop Image (or both if no mobile) */}
      {haveDesktopImage && !haveVideo ? (
      <Box sx={{ display: [haveMobileImage ? 'none' : 'block', null, null, 'block'] }}>
        <BackpackImage
          src={hero?.image?.desktop?.src || hero?.image?.desktop}
          alt={hero?.image?.alt || 'Article hero desktop image.'}
          ratio={16 / 9}
          sx={{
            borderRadius: '6px 6px 0px 0px',
            minWidth: '100%',
          }}
        />
      </Box>
      ) : null}

      {withTags ? (
        <Flex
          variant="flex.row"
          data-comp="heroTags"
          sx={{
            position: 'absolute',
            left: 0,
            top: '20px',
            zIndex: 1,
          }}
        >
          {hero?.tags?.list?.map((tag, index) => {
            const isFirst = index === 0;
            return (
              <Flex
                variant="flex.row.center"
                key={tag}
                sx={{
                  bg: 'gold',
                  height: '32px',
                  color: 'black',
                  ml: isFirst ? 0 : ['8px'],
                  pl: isFirst ? 7 : 6,
                  pr: 6,
                  textTransform: 'uppercase',
                }}
              >
                <Paragraph
                  variant="text.label.2"
                  sx={{
                    lineHeight: '32px',
                    mt: 1,
                  }}
                >
                  {tag}
                </Paragraph>
              </Flex>
            );
          })}
        </Flex>
      ) : null}
    </Box>
  );
};
