const imageTinaSettings = {
  label: 'Image Settings',
  name: 'imageSettings',
  description: 'Image, Image Position.',
  component: 'group',
  fields: [
    {
      name: 'image',
      label: 'Image',
      component: 'image',
    },
    {
      name: 'imagePosition',
      component: 'select',
      label: 'Image Position',
      options: [
        {
          value: 'left top',
          label: 'Left Top',
        },
        {
          value: 'left center',
          label: 'Left Center',
        },
        {
          value: 'left bottom',
          label: 'Left Bottom',
        },
        {
          value: 'right top',
          label: 'Right Top',
        },
        {
          value: 'right center',
          label: 'Right Center',
        },
        {
          value: 'right bottom',
          label: 'Right Bottom',
        },
        {
          value: 'center top',
          label: 'Center Top',
        },
        {
          value: 'center center',
          label: 'Center Center',
        },
        {
          value: 'center bottom',
          label: 'Center Bottom',
        },
      ],
      defaultValue: 'center center',
    },
    {
      name: 'mobileImage',
      label: 'Mobile Image',
      component: 'image',
      description: 'If empty, mobile will use main image.',
    },
    {
      name: 'mobileImagePosition',
      component: 'select',
      label: 'Mobile Image Position',
      options: [
        {
          value: 'left top',
          label: 'Left Top',
        },
        {
          value: 'left center',
          label: 'Left Center',
        },
        {
          value: 'left bottom',
          label: 'Left Bottom',
        },
        {
          value: 'right top',
          label: 'Right Top',
        },
        {
          value: 'right center',
          label: 'Right Center',
        },
        {
          value: 'right bottom',
          label: 'Right Bottom',
        },
        {
          value: 'center top',
          label: 'Center Top',
        },
        {
          value: 'center center',
          label: 'Center Center',
        },
        {
          value: 'center bottom',
          label: 'Center Bottom',
        },
      ],
      defaultValue: 'center center',
    },
    {
      name: 'overlay',
      label: 'Show Overlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const videoTinaSettings = {
  label: 'Video Settings',
  name: 'videoSettings',
  description: 'Video URL, Video Thumbnail.',
  component: 'group',
  fields: [
    {
      name: 'video',
      component: 'text',
      label: 'Direct Video URL',
      description: `A direct link ends in or contains '.mp4'.`,
    },
    {
      name: 'poster',
      label: 'Video Thumbnail',
      component: 'image',
      description: 'First frame of the video.',
    },
    {
      name: 'mobileVideo',
      component: 'text',
      label: 'Mobile Direct Video URL',
      description: `If empty, mobile will use main video URL.`,
    },
    {
      name: 'mobilePoster',
      label: 'Mobile Video Thumbnail',
      component: 'image',
      description: 'First frame of the mobile video.',
    },
    {
      name: 'overlay',
      label: 'Show Overlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

export const mediaTinaSettings = [
  {
    name: 'activeMediaType',
    component: 'radio-group',
    direction: 'horizontal',
    variant: 'radio',
    label: 'Active Media Type',
    options: [
      {
        value: 'image',
        label: 'Image',
      },
      {
        value: 'video',
        label: 'Video',
      },
    ],
    defaultValue: 'image',
  },
  imageTinaSettings,
  videoTinaSettings,
];
