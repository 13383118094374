import { Flex, Heading } from 'theme-ui';
import { UnderlineLink } from '@snippets';
import { InstagramBlockItem } from './InstagramBlockItem';

export function InstagramBlock({ cms }) {
  const { heading, cta, images } = cms;

  return (
    <Flex
      data-comp="InstagramBlock"
      variant="flex.section"
      sx={{
        bg: 'white',
        py: [13, null, null, 17],
      }}
    >
      <Flex
        variant="flex.column.center"
        sx={{
          width: '100%',
          maxWidth: '1360px',
          px: [6, null, null, 9],
        }}
      >
        <Flex
          variant="flex.row.center.center"
          sx={{
            maxWidth: '710px',
            flexWrap: 'wrap',
            mb: [11, null, null, 13],
          }}
        >
          {heading && (
            <Heading
              variant="32.r"
              sx={{
                textAlign: 'center',
                mb: [-3, -6],
              }}
            >
              {heading}
            </Heading>
          )}
          {cta?.text && (
            <UnderlineLink
              text={cta?.text}
              href={cta?.url}
              newTab={cta?.newTab}
              sx={{
                ml: [5, null, null, 7],
              }}
              headingSx={{
                color: 'black',
                fontSize: [11, null, null, 14],
                lineHeight: [38, null, null, 54],
                underlineHeight: [0],
                underlineMarginTop: [0],
                fontWeight: 400,
                letterSpacing: 0.02,
                arrowWidth: ['30px', null, null, '45px'],
                arrowWrapperWidth: ['40px', null, null, '60px'],
                translateX: '20%',
              }}
            />
          )}
        </Flex>
        <Flex
          sx={{
            flexDirection: ['column', null, null, null, 'row'],
            width: '100%',
          }}
        >
          <Flex
            sx={{
              width: ['100%', null, null, null, '50%'],
            }}
          >
            {[0, 1].map((imageIndex, index) => (
              <InstagramBlockItem
                key={index}
                image={images?.[imageIndex]}
                index={index}
                sx={{
                  mr: index === 0 ? [6, null, null, 9] : 0,
                  mb: [6, null, null, 9, 0],
                }}
              />
            ))}
          </Flex>
          <Flex
            sx={{
              justifyContent: ['flex-start', null, null, null, 'flex-end'],
              width: ['100%', null, null, null, '50%'],
            }}
          >
            {[2, 3].map((imageIndex, index) => (
              <InstagramBlockItem
                key={index}
                image={images?.[imageIndex]}
                index={index}
                sx={{
                  mr: index === 0 ? [6, null, null, 9] : 0,
                }}
                isSecond
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

InstagramBlock.Schema = {
  label: 'Instagram Block',
  key: 'instagram-block',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading',
    },
    {
      name: 'cta',
      component: 'link',
      label: 'Heading Inline Hyperlink',
    },
    {
      name: 'images',
      label: 'Images',
      description: 'There should be 4 images at all times.',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      validate(list) {
        if (list?.length > 4) {
          alert(`Max of 4 Images`);
          return list.slice(0, 4);
        }
      },
      fields: [
        {
          name: 'name',
          component: 'text',
          label: 'Item Name',
        },
        {
          name: 'image',
          label: 'Image',
          component: 'image',
        },
        {
          name: 'instagramUrl',
          component: 'text',
          label: 'Instagram Post URL',
        },
        {
          label: 'Hide Instagram Logo',
          name: 'hideInsta',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          name: 'cta',
          component: 'link',
          label: 'Product CTA',
        },
      ],
    },
  ],
};
