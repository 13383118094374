import { useCallback, useEffect, useRef, useState } from 'react';

import { mapProductPageVariant, trackKlaviyoEvent } from './utils';

export function useDataLayerProduct({ DEBUG, userDataEvent, userProperties }) {
  const productHandleRef = useRef(null);

  const [viewedProductVariant, setViewedProductVariant] = useState(null);

  const viewProductEvent = useCallback(
    ({ variant, userProperties: _userProperties }) => {
      if (!variant) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'dl_view_item',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: variant.priceV2?.currencyCode,
          detail: {
            actionField: { list, action: 'detail' },
            products: [variant].map(mapProductPageVariant(list)),
          },
        },
      };

      trackKlaviyoEvent({
        email: _userProperties?.customer_email,
        event: 'Viewed Product',
        properties: [variant].map(mapProductPageVariant(list))?.[0],
      });

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  // Subscribe to PubSub topic for 'dl_view_item' event
  useEffect(() => {
    const viewProduct = PubSub.subscribe(
      'VIEW_PRODUCT_PAGE',
      async (event, variant) => {
        setViewedProductVariant(variant);
      }
    );
    return () => {
      if (viewProduct) {
        PubSub.unsubscribe(viewProduct);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and 'dl_view_item' events on viewedProductVariant change after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.split('/').pop();
    if (
      !userProperties ||
      !viewedProductVariant ||
      productHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ userProperties });
    viewProductEvent({ variant: viewedProductVariant, userProperties });
    productHandleRef.current = pageHandle;
  }, [viewedProductVariant?.product?.id, !!userProperties]);
}
