import { Box, Paragraph, Flex } from 'theme-ui';

import { SocialLinks } from '@snippets';

import { FooterCopyright } from './FooterCopyright';

export const FooterSocial = (props) => {
  return (
    <Flex
      data-comp="FooterSocial"
      variant="flex.column.start.between"
      {...props}
    >
      <Flex variant="flex.column" sx={{ width: '100%' }}>
        <Flex variant="flex.row.start.center" sx={{ width: '100%', mb: 6 }}>
          <Paragraph variant="label.3" sx={{ color: 'white' }}>
            Social
          </Paragraph>
          <Box
            sx={{
              display: ['block', null, 'none'],
              height: '1px',
              flex: 1,
              bg: 'gray.6',
              ml: 2,
            }}
          />
        </Flex>
        <SocialLinks sx={{ width: '100%' }} />
      </Flex>
      <FooterCopyright
        sx={{ display: ['block', null, 'none', null, null, 'block'] }}
      />
    </Flex>
  );
};
