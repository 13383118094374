import { Image } from 'theme-ui';

export function SubCollectionFilter({
  index,
  toggleFilterDrawer,
  filterDrawerIndex,
  setFilterDrawerIndex,
  stopProp,
  intersectFilterOptionsGrouped,
  toggleSelectedFilter,
  isSelected,
  checkmarkSVG,
}) {
  return (
    <>
      <div
        data-comp="SubCollectionFilterWrap"
        sx={{
          backgroundColor: '#fff',
          border: '1px solid currentColor',
          color: '#1a1a1a',
          position: 'relative',
          minWidth: [null, null, null, null, '210px'],
          width: ['calc(50% - 8px)', null, null, null, 'auto'],
          maxWidth: ['210px', null, null, null, 'none'],
          display: [null, null, null, null, 'none'],
        }}
      >
        <div
          data-comp="SubCollectionMobileFilterToggle"
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 20px',
            cursor: 'pointer',
          }}
          onClick={() => toggleFilterDrawer(index, 'mobilefilter')}
        >
          <div
            sx={{
              fontSize: '15px',
              fontFamily: 'heading',
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
              fontWeight: '700',
              lineHeight: '1.0667',
            }}
          >
            Filters
          </div>
          <Image
            src={`/svg/chevron-down.svg`}
            sx={{
              width: '12px',
              transform:
                filterDrawerIndex == index + '-mobilefilter'
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </div>
        <div
          onClick={() => setFilterDrawerIndex(null)}
          sx={{
            height: '100%',
            width: '100%',
            overflow:
              filterDrawerIndex == index + '-mobilefilter' ? null : 'hidden',
            position:
              filterDrawerIndex == index + '-mobilefilter'
                ? 'fixed'
                : 'relative',
            left: '0',
            top: '0',
            backgroundColor: 'rgba(26, 26, 26, 0.6)',
            zIndex: '1',
          }}
        >
          <div
            onClick={stopProp}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              backgroundColor: '#fff',
              borderColor: 'currentColor',
              borderStyle: 'solid',
              borderWidth: ['0', null, null, null, '0 1px 1px'],
              padding: ['20px 12px 30px', null, null, null, '20px'],
              margin: '0',
              position: 'absolute',
              zIndex: '1',
              top: [null, null, null, null, '100%'],
              bottom: ['0', null, null, null, 'auto'],
              left: ['0', null, null, null, '-1px'],
              width: ['100%', null, null, null, 'calc(100% + 2px)'],
              backgroundColor: ['#000', null, null, null, '#fff'],
              color: ['#fff', null, null, null, 'inherit'],
              '&:before': {
                content: [null, null, null, null, '""'],
                display: 'block',
                height: '1px',
                background: 'currentcolor',
                position: 'absolute',
                top: '0',
                left: '20px',
                right: '20px',
              },
            }}
          >
            <div
              sx={{
                display: [null, null, null, null, 'none'],
                width: '12px',
                height: '12px',
                position: 'relative',
                marginLeft: 'auto',
                cursor: 'pointer',
                '&:before, &:after': {
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#cab683',
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  transformOrigin: 'center',
                },
                '&:before': {
                  transform: 'rotate(45deg)',
                },
                '&:after': {
                  transform: 'rotate(-45deg)',
                },
              }}
              onClick={() => toggleFilterDrawer(index, 'mobilefilter')}
            ></div>
            <div
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                rowGap: '32px',
              }}
            >
              {Object.keys(intersectFilterOptionsGrouped).map((key, idx) => {
                return (
                  <div
                    key={idx}
                    data-comp="SubCollectionFilterOptionGroupMobile"
                    sx={{
                      width: 'calc(50% - 8px)',
                    }}
                  >
                    <div
                      sx={{
                        display: 'flex',
                        gap: '14px',
                        alignItems: 'flex-end',
                        marginBottom: '16px',
                      }}
                    >
                      <div
                        sx={{
                          fontFamily: 'heading',
                          fontSize: '18px',
                          lineHeight: '1.22',
                          letterSpacing: '0.02em',
                          textTransform: 'uppercase',
                        }}
                      >
                        {key}
                      </div>
                      <div
                        sx={{
                          height: '1px',
                          flexGrow: '1',
                          flexShrink: '1',
                          backgroundColor: 'currentColor',
                        }}
                      ></div>
                    </div>
                    <div
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                      }}
                    >
                      {intersectFilterOptionsGrouped[key].map((filter, idx) => {
                        return (
                          <div key={idx}>
                            <button
                              onClick={() => toggleSelectedFilter(filter)}
                              sx={{
                                color: '#fff',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                position: 'relative',
                                background: 'none',
                                border: '0',
                                width: '100%',
                                textAlign: 'left',
                                padding: '0',
                                fontSize: '15px',
                                lineHeight: '1.47',
                                letterSpacing: '0.02em',
                                cursor: 'pointer',
                                '&:before, &:after': {
                                  content: '""',
                                  display: 'block',
                                  width: '16px',
                                  height: '16px',
                                },
                                '&:before': {
                                  border: isSelected(filter)
                                    ? null
                                    : '1px solid currentColor',
                                  background: isSelected(filter)
                                    ? '#cab683'
                                    : 'transparent',
                                },
                                '&:after': {
                                  position: 'absolute',
                                  left: '0',
                                  top: '50%',
                                  transform: 'translate(0, -50%)',
                                  backgroundImage: isSelected(filter)
                                    ? `url('${checkmarkSVG}')`
                                    : null,
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: '10px',
                                },
                              }}
                            >
                              {filter.option}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {Object.keys(intersectFilterOptionsGrouped).map((key,idx) => {
        return (
          <div
            key={idx}
            data-comp="SubCollectionFilterOptionGroup"
            sx={{
              backgroundColor: '#fff',
              border: '1px solid currentColor',
              color: '#1a1a1a',
              position: 'relative',
              minWidth: '210px',
              display: ['none', null, null, null, 'block'],
            }}
          >
            <div
              data-comp="SubCollectionFilterOptionGroupToggle"
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px 20px',
                cursor: 'pointer',
              }}
              onClick={() => toggleFilterDrawer(index, key)}
            >
              <div
                sx={{
                  fontSize: '15px',
                  fontFamily: 'heading',
                  letterSpacing: '0.02em',
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  lineHeight: '1.0667',
                }}
              >
                {key}
              </div>
              <Image
                src={`/svg/chevron-down.svg`}
                sx={{
                  width: '12px',
                  transform:
                    filterDrawerIndex == index + '-' + key
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
            </div>
            <div
              sx={{
                height: '0',
                overflow:
                  filterDrawerIndex == index + '-' + key ? null : 'hidden',
                position:
                  filterDrawerIndex == index + '-' + key ? null : 'relative',
              }}
            >
              <div
                onClick={() => setFilterDrawerIndex(null)}
                sx={{
                  display:
                    filterDrawerIndex == index + '-' + key ? null : 'none',
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
              ></div>
              <div
                data-comp="SubCollectionFilterOptionGroupList"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  backgroundColor: '#fff',
                  borderColor: 'currentColor',
                  borderStyle: 'solid',
                  borderWidth: '0 1px 1px',
                  padding: '20px',
                  margin: '0',
                  position: 'absolute',
                  zIndex: '1',
                  top: '100%',
                  left: '-1px',
                  width: 'calc(100% + 2px)',
                  '&:before': {
                    content: '""',
                    display: 'block',
                    height: '1px',
                    background: 'currentcolor',
                    position: 'absolute',
                    top: '0',
                    left: '20px',
                    right: '20px',
                  },
                }}
              >
                {intersectFilterOptionsGrouped[key].map((filter,idx) => {
                  return (
                    <div key={idx}>
                      <button
                        onClick={() => toggleSelectedFilter(filter)}
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          position: 'relative',
                          background: 'none',
                          border: '0',
                          width: '100%',
                          textAlign: 'left',
                          padding: '0',
                          fontSize: '15px',
                          lineHeight: '1.47',
                          letterSpacing: '0.02em',
                          cursor: 'pointer',
                          '&:before, &:after': {
                            content: '""',
                            display: 'block',
                            width: '16px',
                            height: '16px',
                          },
                          '&:before': {
                            border: isSelected(filter)
                              ? null
                              : '1px solid #d7d9db',
                            background: isSelected(filter) ? '#cab683' : '#fff',
                          },
                          '&:after': {
                            position: 'absolute',
                            left: '0',
                            top: '50%',
                            transform: 'translate(0, -50%)',
                            backgroundImage: isSelected(filter)
                              ? `url('${checkmarkSVG}')`
                              : null,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '10px',
                          },
                        }}
                      >
                        {filter.option}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
