import { Paragraph, Flex, Box } from 'theme-ui';

export function HalfBlockNutrition({ block, index, isInvisible, ...props }) {
  return (
    <Flex
      data-comp="HalfBlockNutrition"
      variant="flex.column.center.center"
      sx={{
        display: block?.hideMobile ? ['none', null, null, 'flex'] : 'flex',
        height: '100%',
        minHeight: ['auto', null, null, 'calc(35vw - 10px)'],
        width: '100%',
        borderRadius: [
          '6px',
          null,
          null,
          index === 0 ? '0 6px 6px 0' : '6px 0 0 6px',
          null,
          null,
          null,
          '6px',
        ],
        position: 'relative',
        overflow: 'hidden',
        bg: 'black',
        color: 'white',
        px: [6, 11],
        py: [11, 14],
      }}
      {...props}
    >
      <Box
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          width: '1px',
          height: '1px',
          left: 0,
          top: '-100px',
        }}
      />
      <Flex
        variant="flex.column"
        sx={{
          width: '100%',
          maxWidth: '475px',
        }}
      >
        {block?.productName && (
          <Paragraph variant="label.1" sx={{ mb: 4 }}>
            {block?.productName}
          </Paragraph>
        )}
        <Flex
          variant="flex.row.start.center"
          sx={{
            width: '100%',
            mb: [5, null, null, 8],
          }}
        >
          <Paragraph
            variant="label.3"
            sx={{
              // color: 'white',
              mr: 4,
            }}
          >
            Nutritional Facts
          </Paragraph>
          <Box
            sx={{
              flex: 1,
              height: '1px',
              bg: 'gold',
            }}
          />
        </Flex>

        <Box
          sx={{
            fontSize: ['10px', 4],
            lineHeight: ['16px', '21px'],
            mb: [8, 5],
          }}
        >
          <Paragraph
            sx={{
              mb: [0, 5],
            }}
          >
            Serving Size: {block?.servingSize}
          </Paragraph>
          <Paragraph sx={{}}>
            Serving Per Container: {block?.servingPerContainer}
          </Paragraph>
        </Box>

        <Box
          sx={{
            width: '100%',
            mb: [8, 9],
          }}
        >
          {block?.facts?.map((fact, index) => (
            <Flex
              variant="flex.row.between.start"
              key={index}
              sx={{
                py: 5,
                borderBottom: '1px solid',
                borderTop: index === 0 ? '1px solid' : 0,
                borderColor: 'gray.6',
              }}
            >
              <Paragraph variant="label.3">{fact.name}</Paragraph>
              <Paragraph variant="label.3" sx={{ textAlign: 'right' }}>
                {fact.value}
              </Paragraph>
            </Flex>
          ))}
        </Box>
        <Box
          sx={{
            fontSize: '10px',
            lineHeight: '14px',
          }}
        >
          {block?.footnote && (
            <Paragraph sx={{ mb: 4 }}>{block?.footnote}</Paragraph>
          )}
          {block?.ingredients && (
            <Paragraph>Ingredients: {block?.ingredients}</Paragraph>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
