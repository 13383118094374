import { Box } from 'theme-ui';
import { useEffect, cloneElement } from 'react';
import { useInView } from 'react-intersection-observer';

import store, { useSetRecoilState } from '@store';

const defaultOptions = {
  rootMargin: '0px 0px 0px 0px',
  threshold: 1,
  trackVisibility: true,
  delay: 200,
};

export const withInView = (SectionComp, options) => {
  return (sectionProps) => {
    const { ref, inView, entry } = useInView(options || defaultOptions);
    const updateSectionInView = useSetRecoilState(
      store.sections(sectionProps?.cms?.id || '')
    );

    useEffect(() => {
      updateSectionInView({
        id: sectionProps.cms.id,
        inView,
        entry,
        selector: `section[data-comp-id="${sectionProps.cms.id}"]`,
      });
    }, [inView, sectionProps?.cms?.id]);

    return (
      <Box
        data-comp="section-inview"
        ref={ref}
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        {cloneElement(<SectionComp />, { ...sectionProps, inView, entry })}
      </Box>
    );
  };
};
