import { useEffect } from 'react';
import { Heading, Flex, Box } from 'theme-ui';

import { Svg, InlineHeading } from '@snippets';
import store, { useRecoilState } from '@store';

import { ProductSellingPlanOnetime } from './ProductSellingPlanOnetime';
import { ProductSellingPlanSubscribe } from './ProductSellingPlanSubscribe';

export function ProductSellingPlans({
  sellingPlans,
  selectedVariant,
  isSubscriptionOnly,
  isSingleBottlePdp,
}) {
  // onetime or subscribe
  const [selectedPlan, setSelectedPlan] = useRecoilState(
    store.selectedPlan(selectedVariant.product.handle)
  );

  // set default plan to onetime
  useEffect(() => {
    if (!sellingPlans?.length || isSubscriptionOnly) return;

    const defaultPlan = sellingPlans.find((plan) => plan.type === 'onetime');
    setSelectedPlan(defaultPlan);
  }, [
    sellingPlans?.length,
    selectedVariant?.product?.handle,
    isSubscriptionOnly,
  ]);

  if (!sellingPlans?.length || !selectedVariant) return null;

  return (
    <Box
      data-comp={ProductSellingPlans.displayName}
      sx={{
        display: isSubscriptionOnly ? 'none' : 'block',
        mb: 10,
      }}
    >
      <InlineHeading text="FREQUENCY" theme="dark" />

      {/* onetime and subscribe radio button rows */}
      <Flex variant="flex.column" sx={{ mt: 7 }}>
        {sellingPlans.map((plan) => {
          const isSelected = plan.type === selectedPlan?.type;
          const isSubscribe = plan.type === 'subscribe';

          return isSubscribe ? (
            <ProductSellingPlanSubscribe
              isSelected={isSelected}
              isSubscribe={isSubscribe}
              selectedVariant={selectedVariant}
              plan={plan}
              key={plan.type}
              isSingleBottlePdp={isSingleBottlePdp}
            />
          ) : (
            <ProductSellingPlanOnetime
              isSelected={isSelected}
              selectedVariant={selectedVariant}
              plan={plan}
              key={plan.type}
              isSingleBottlePdp={isSingleBottlePdp}
            />
          );
        })}
      </Flex>
    </Box>
  );
}

ProductSellingPlans.displayName = 'ProductSellingPlans';
