/* eslint-disable no-nested-ternary */
import { Paragraph, Flex, Button, Image, Box } from "theme-ui";
import { useProductInventoryByHandle } from "@backpackjs/storefront";

export function ProductItemQuantitySelect({
  selectedVariant,
  quantity,
  setQuantity,
  isCollectionPage = null,
  ...props
}) {
  const disabled = quantity === 1;

  // use live inventory data
  const { inventory, ...status } = useProductInventoryByHandle({
    handle: selectedVariant.product.handle,
  });

  const variantInventory = inventory
    ? inventory?.variants?.[selectedVariant.storefrontId]
    : null;

  const isSoldOut = variantInventory
    ? variantInventory.quantityAvailable <= 0 &&
      variantInventory.product?.handle !== "truff-gift-card"
    : false;

  const handleKeyDown = (event, callback) => {
    if (event.key === "Enter" || event.key === " " || event.key === "Space") {
      event.preventDefault();
      callback();
    }
  };

  return (
    <Flex
      tabIndex={0}
      data-comp="ProductItemQuantitySelect"
      variant="flex.row.center.center"
      sx={{
        minWidth: "90px",
        border: isCollectionPage ? null : "1px solid black",
        display: isSoldOut
          ? "none"
          : isCollectionPage
          ? ["none", null, null, null, "flex"]
          : "",
        backgroundColor: isCollectionPage ? "white" : null,
      }}
      {...props}
    >
      <Button
        role="button"
        tabIndex={0}
        aria-label="Decrease quantity"
        sx={{
          position: "relative",
          flex: 1,
          pl: 4,
          height: "100%",
          width: "100%",
          userSelect: "none",
          display: "block",
          cursor: disabled ? "default" : "pointer",
        }}
        onClick={() => {
          if (!disabled && quantity > 1) {
            setQuantity(quantity - 1);
          }
        }}
        onKeyDown={(event) =>
          handleKeyDown(event, () => {
            if (!disabled && quantity > 1) {
              setQuantity(quantity - 1);
            }
          })
        }
      >
        <Box
          sx={{
            position: "relative",
            height: "16px",
            width: "16px",
            backgroundColor: ["#cab683", "white"],
            borderRadius: "50%",
            mx: "auto",
          }}
        >
          <Image
            alt="Decrease quantity"
            draggable="false"
            src="/svg/minus-black.svg"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Box>
      </Button>

      <Paragraph
        variant="label.2"
        sx={{
          flex: 1,
          textAlign: "center",
          color: "black",
          pt: 1,
        }}
      >
        {quantity}
      </Paragraph>

      <Button
        role="button"
        tabIndex={0}
        aria-label="Increase quantity"
        sx={{
          flex: 1,
          pr: 4,
          height: "100%",
          width: "100%",
          userSelect: "none",
          display: "block",
        }}
        onClick={() => setQuantity(quantity + 1)}
        onKeyDown={(event) =>
          handleKeyDown(event, () => setQuantity(quantity + 1))
        }
      >
        <Box
          sx={{
            position: "relative",
            height: "16px",
            width: "16px",
            backgroundColor: ["#cab683", "white"],
            borderRadius: "50%",
            mx: "auto",
          }}
        >
          <Image
            draggable="false"
            alt="Increase quantity"
            src="/svg/plus-black.svg"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Box>
      </Button>
    </Flex>
  );
}
