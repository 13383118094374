import { useMemo } from 'react';
import { Flex, Paragraph, Box, Button } from 'theme-ui';

import { LinkWrapper, StyledButton } from '@snippets';

import { OrderDetailsItem } from './OrderDetailsItem';
import { OrderDetailsPrices } from './OrderDetailsPrices';

export const OrderDetailsMain = ({ order }) => {
  const date = useMemo(() => {
    if (!order) return '';
    console.log('order', order);
    const dateArr = new Date(Date.parse(order?.date)).toString().split(' ');
    return `${dateArr[0]}, ${dateArr[1]} ${dateArr[2]}, ${dateArr[3]}`;
  }, [order]);

  const address = useMemo(() => {
    const shippingAddress = order?.shippingAddress;
    if (!shippingAddress) return null;
    return {
      firstName: shippingAddress?.firstName || '',
      lastName: shippingAddress?.lastName || '',
      address1: shippingAddress?.address1 || '',
      address2: shippingAddress?.address2 || '',
      city: shippingAddress?.city || '',
      province: shippingAddress?.province || '',
      provinceCode: shippingAddress?.provinceCode || '',
      country: shippingAddress?.country || '',
      zip: shippingAddress?.zip || '',
      phone: shippingAddress?.phone || '',
    };
  }, [order]);

  const prices = useMemo(() => {
    if (!order) return null;
    const subtotal = order.lineItems?.edges?.reduce((sum, { node: item }) => {
      sum += parseFloat(item.fallbackPrice.amount) * item.quantity;
      return sum;
    }, 0);
    let discount = null;
    if (order.discounts?.edges?.length) {
      const diff = subtotal - parseFloat(order.subtotalPrice?.amount);
      discount = '$-' + diff.toFixed(2);
    }
    const parse = (v) => '$' + parseFloat(v).toFixed(2);
    return {
      total: parse(order.totalPrice.amount),
      subtotal: parse(subtotal),
      tax: parse(order.totalTax.amount),
      shipping: parse(order.shippingPrice.amount),
      discount,
    };
  }, [order]);

  if (!order) return null;

  return (
    <Flex data-comp="OrderDetailsMain" variant="flex.column" sx={{}}>
      <Flex
        variant="flex.column"
        sx={{
          pb: 13,
          borderBottom: '1px solid',
          borderColor: 'gray.3',
        }}
      >
        <Paragraph
          variant="text.sm"
          sx={{
            alignSelf: ['flex-start', null, null, 'flex-end'],
            textAlign: ['left', null, null, 'right'],
            mt: [2, null, null, 0],
          }}
        >
          {date}
        </Paragraph>
        <Flex
          sx={{
            mt: [9, 11],
            flexDirection: ['column', 'row'],
            justifyContent: ['flex-start', 'space-between'],
          }}
        >
          <Box sx={{ flexBasis: ['auto', 'calc(50% - 10px)'] }}>
            <Paragraph variant="label.2">Shipping Address</Paragraph>
            {address ? (
              <Box sx={{ p: { variant: 'text.sm' } }}>
                <Paragraph>
                  {address.firstName} {address.lastName}
                </Paragraph>
                <Paragraph>{address.company}</Paragraph>
                <Paragraph>{address.address1}</Paragraph>
                <Paragraph>{address.address2}</Paragraph>
                <Paragraph>
                  {address.city + ', '}
                  {(address.provinceCode || address.province) + ', '}
                  {address.zip}
                </Paragraph>
                <Paragraph>{address.country}</Paragraph>
                <Paragraph>{address.phone}</Paragraph>
              </Box>
            ) : (
              <Paragraph variant="sm" sx={{ mt: 4 }}>
                Not Available
              </Paragraph>
            )}
          </Box>
          <Flex
            variant="flex.column.start.end"
            sx={{
              flexBasis: ['auto', 'calc(50% - 10px)'],
              mt: [6, 0],
            }}
          >
            <LinkWrapper
              href={order.statusUrl}
              newTab={true}
              variant="buttons.underlineLink"
              sx={{
                color: 'black',
                '&:before, &:after': {
                  height: '1px',
                },
              }}
            >
              Track Order
            </LinkWrapper>
          </Flex>
        </Flex>
      </Flex>

      <Flex variant="flex.column">
        {order?.lineItems.edges.map(({ node: item }, index) => (
          <OrderDetailsItem key={index} item={item} />
        ))}
      </Flex>
      <OrderDetailsPrices prices={prices} />
      <StyledButton
        text="Back to Order History"
        variant="outline"
        href="/account/orders"
        sx={{ maxWidth: '300px', mt: 13 }}
      />
    </Flex>
  );
};
