export default {
  label: 'Account Hero',
  name: 'hero',
  component: 'group',
  fields: [
    {
      component: 'image',
      name: 'image',
      label: 'Background Image',
    },
    {
      component: 'image',
      name: 'mobileImage',
      label: 'Mobile Background Image',
    },
    {
      component: 'text',
      name: 'heading',
      label: 'Heading',
      defaultValue: 'Welcome to Truff',
    },
    {
      component: 'text',
      name: 'subheading',
      label: 'Subheading',
    },
  ],
};
