import { Box, Paragraph, Image, Flex } from 'theme-ui';

import { Picture, ShopifyImage } from '@snippets';
import { shopifySrcFilter } from '@utils';

export function DropdownItem({
  overrideStyles = false,
  isSelected = false,
  isActive = false,
  item,
  title,
  onClick,
}) {
  const imageSrc = item?.image
    ? shopifySrcFilter(
        item?.image?.originalSrc || item?.image?.previewImage?.originalSrc,
        { width: 40, ratio: 1 }
      )
    : '/svg/default-image.svg';

  shopifySrcFilter;

  return isActive ? null : item ? (
    <Flex
      data-comp="DropdownItem"
      variant="flex.row.start.center"
      sx={{
        width: '100%',
        pl: '12px',
        py: '8px',
        '&:hover': {
          bg: overrideStyles ? 'gray.2' : 'gray.8',
          cursor: 'pointer',
        },
        color: overrideStyles ? 'black' : 'white',
      }}
      onClick={onClick}
    >
      {item?.image ? (
        item?.image?.ratio ? (
          <Picture
            alt={item.label}
            images={[
              {
                src: item?.image?.originalSrc || '/svg/default-image.svg',
                ratio: 1,
                width: '40px',
              },
            ]}
            sx={{
              width: 40,
              mr: 8,
              border: (t) => `.5px solid ${t.colors.gray[4]}`,
              borderRadius: '50%',
            }}
          />
        ) : (
          <Box
            sx={{
              width: '40px',
              height: '40px',
              border: (t) => `1px solid ${t.colors.gray[6]}`,
              borderRadius: '50%',
              overflow: 'hidden',
              mr: '10px',
              backgroundColor: 'black',
            }}
          >
            <Image
              width={40}
              height={40}
              alt={item.label}
              src={imageSrc?.src || imageSrc}
            />
          </Box>
        )
      ) : null}

      <Flex variant="flex.column">
        {/* Pick your flavor */}
        {isSelected ? (
          <Paragraph
            sx={{
              fontSize: '11px',
              textTransform: 'uppercase',
              color: overrideStyles ? 'black' : 'white',
              mb: 2,
              lineHeight: '11px',
            }}
          >
            {title}
          </Paragraph>
        ) : null}

        <Paragraph
          variant="text.13"
          sx={{
            textTransform: 'uppercase',
            color: overrideStyles ? 'black' : 'white',
            mt: 0,
          }}
        >
          {item.label}
        </Paragraph>
      </Flex>
    </Flex>
  ) : null;
}
