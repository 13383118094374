import { useState, useEffect, useCallback } from 'react';
import { Container, Box, Heading, Paragraph, Input } from 'theme-ui';
import { useCustomer, useSettings } from '@backpackjs/storefront';

import { StyledButton } from '@snippets';
import { useModal } from '@hooks';

import { useBackInStock } from './useBackInStock';
import { themed } from './BackInStockModal.theme';

export const BackInStockModal = themed(
  ({ legacyResourceId, title, variantTitle, theme }) => {
    const settings = useSettings();
    const customer = useCustomer();
    const { subscribeToBackInStock, ...status } = useBackInStock();
    const { closeModal } = useModal()[1];
    const [email, setEmail] = useState(customer?.email || '');
    const { description, modalButtonText } =
      settings?.product?.backInStock || {};

    const handleSubscribe = useCallback(
      async (e) => {
        e.preventDefault();
        if (!email || !legacyResourceId) return;
        await subscribeToBackInStock({ email, variantId: legacyResourceId });
      },
      [email, legacyResourceId]
    );

    useEffect(() => {
      if (status.success) {
        setTimeout(() => closeModal(), 2000);
      }
    }, [status.success]);

    return (
      <Container data-comp={BackInStockModal.displayName} sx={theme.wrapper}>
        <Heading sx={theme.title}>{title}</Heading>
        {variantTitle && (
          <Heading as="h3" sx={theme.variant}>
            {variantTitle}
          </Heading>
        )}
        {description && (
          <Paragraph sx={theme.description}>{description}</Paragraph>
        )}

        <Box as="form" mt={10} onSubmit={handleSubscribe}>
          <Input
            aria-label="Enter email address to sign up."
            aria-required="true"
            name="email"
            placeholder="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={theme.input}
          />

          <StyledButton
            {...status}
            showErrors={false}
            isStateful
            variant="solid"
            statefulText={{
              started: modalButtonText,
              success: 'Email Subscribed!',
              error: 'Please Try Again Later',
              default: modalButtonText,
            }}
            sx={theme.button}
          />
        </Box>
      </Container>
    );
  }
);

BackInStockModal.displayName = 'BackInStockModal';
