import { useState, useEffect } from 'react';
import { Box, Paragraph, Grid } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { StyledButton } from '@snippets';

import { AddressBookItem } from './AddressBookItem';
import { AddressCreateForm } from './AddressCreateForm';

export const AddressBookMain = () => {
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [showCreateAddress, setShowCreateAddress] = useState(false);
  const [activeEditIndex, setActiveEditIndex] = useState(null);
  const customer = useCustomer();

  useEffect(() => {
    if (customer && customer.accessToken && customer.customer) {
      setDefaultAddress(customer.customer?.defaultAddress);
      setAddresses(customer.customer?.addresses?.edges);
    }
  }, [customer]);

  return (
    <Box data-comp="AddressBookMain">
      {showCreateAddress ? (
        <AddressCreateForm
          customer={customer}
          setShowCreateAddress={setShowCreateAddress}
        />
      ) : (
        <>
          {addresses.length > 0 ? (
            <Grid
              sx={{
                gridGap: [8, null, null, 10],
                gridTemplateColumns: ['1fr', null, null, null, null, '1fr 1fr'],
                alignItems: 'start',
              }}
            >
              {addresses
                .sort(({ node: { id } }) => {
                  return defaultAddress.id == id ? -1 : 1;
                })
                .map(({ node: address }, index) => {
                  const isActiveAddress = index === activeEditIndex;
                  return (
                    <AddressBookItem
                      key={index}
                      customer={customer}
                      address={address}
                      defaultAddress={defaultAddress}
                      isActiveAddress={isActiveAddress}
                      onEditClick={() => {
                        setActiveEditIndex(isActiveAddress ? null : index);
                      }}
                    />
                  );
                })}
            </Grid>
          ) : (
            <Paragraph variant="base" sx={{ py: 15 }}>
              No addresses saved yet.
            </Paragraph>
          )}

          <StyledButton
            text="Add New Address"
            variant="outline"
            onClick={() => setShowCreateAddress(true)}
            sx={{
              maxWidth: ['100%', '300px'],
              mt: 11,
            }}
          />
        </>
      )}
    </Box>
  );
};
