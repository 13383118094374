import { store } from '@backpackjs/storefront';
import effects from './effects';

// instant un-debounced search term (not used)
const rawSearchTerm = store.recoil.atom({
  key: 'search/rawSearchTerm',
  default: '',
  effects_UNSTABLE: [effects.logStateChange('rawSearchTerm')],
});

// debounced term used
const searchTerm = store.recoil.atom({
  key: 'search/searchTerm',
  default: '',
  effects_UNSTABLE: [
    effects.logStateChange('searchTerm'),
    // [todo]: fire GTM searched event
  ],
});

// true when search input is focused
const searchIsFocused = store.recoil.atom({
  key: 'search/searchIsFocused',
  default: false,
  effects_UNSTABLE: [effects.logStateChange('searchIsFocused')],
});

// holds both results
const searchResults$ = store.recoil.selector({
  key: 'search/searchResults$',
  get: async ({ get }) => {
    const _searchTerm = get(searchTerm);
    if (!_searchTerm) return null;
    const response = await searchShopify(_searchTerm);
    if (response && response.error) {
      throw response.error;
    }
    return response;
  },
});

// returns product results
const foundProducts$ = store.recoil.selector({
  key: 'search/foundProducts$',
  get: ({ get }) => {
    const _searchResults = get(searchResults$);
    return _searchResults?.products?.length ? _searchResults?.products : null;
  },
});

// returns collection results
const foundCollections$ = store.recoil.selector({
  key: 'menu/foundCollections$',
  get: ({ get }) => {
    const _searchResults = get(searchResults$);
    return _searchResults?.collections?.length
      ? _searchResults?.collections
      : null;
  },
});

// search fetch function
const searchShopify = async (term) => {
  try {
    const response = await fetch(`/api/shopify-search?term=${term}`);
    const rawData = await response.json();
    return rawData?.data || null;
  } catch (e) {
    console.error('searchShopify:error', e);
    return [];
  }
};

export default {
  // atoms
  rawSearchTerm,
  searchTerm,
  searchIsFocused,

  // selectors
  searchResults$,
  foundProducts$,
  foundCollections$,
};
