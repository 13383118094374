import { useState, useEffect } from "react";
import { Flex, Heading, Box } from "theme-ui";
import { isIE, isOpera, isSafari, isDesktop } from "react-device-detect";
import "swiper/css";

import { isBrowser } from "@utils";
import { ProductSwiper, ImageButton } from "@snippets";

import { ProductGroupTabs } from "./ProductGroupTabs";

export function ProductCarousel({ cms }) {
  const { heading, productGroups, hidePrices, hideReviews } = cms;

  const [activeIndex, setActiveIndex] = useState(0);
  const [disableHover, setDisableHover] = useState(false);

  useEffect(() => {
    // const disable = !isBrowser ||
    //   (isBrowser && (isIE || isOpera || isSafari || !isDesktop));
    const disable = !isBrowser || (isBrowser && !isDesktop);
    setDisableHover(disable);
  }, [isBrowser, isIE, isOpera, isSafari, isDesktop]);

  const button = productGroups?.[activeIndex]?.button;

  return (
    <Flex
      data-comp="ProductCarousel"
      variant="flex.section"
      sx={{
        pt: [10, null, null, 16],
        pb: button?.text ? [10, null, null, 18] : [10, null, null, 16],
        width: "100%",
        position: "relative",
        mx: "auto",
        overflowX: "clip",
        ".swiper": {
          overflow: "hidden",
        },
      }}
    >
      <Flex
        variant="flex.column.center.start"
        sx={{
          width: "100%",
          maxWidth: "1230px",
          mx: "auto",
          position: "relative",
        }}
      >
        <Heading variant="32.r" sx={{ mb: [10, null, null, 11] }}>
          {heading}
        </Heading>

        <ProductGroupTabs
          productGroups={productGroups}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />

        {productGroups?.map((group, index) => {
          if (index !== activeIndex) return null;
          return (
            <Box
              key={index}
              id={`tabpanel-${index}`}
              role="tabpanel"
              tabIndex={index}
              ariaLabelledBy={`tab-${index}`}
              hidden={activeIndex !== index}
              sx={{
                width: "100%",
              }}
            >
              <ProductSwiper
                products={group.products}
                hidePrices={hidePrices}
                hideReviews={hideReviews}
                disableHover={disableHover}
              />
            </Box>
          );
        })}
      </Flex>
      <Flex variant="flex.row.center.center" sx={{ flexWrap: "wrap" }}>
        {button?.text && (
          <Box sx={{ mx: 6, my: 5 }}>
            <ImageButton
              text={button?.text}
              href={button?.url}
              newTab={button?.newTab}
              sx={{ width: "200px" }}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

ProductCarousel.Schema = {
  label: "Product Carousel",
  key: "products-carousel",
  fields: [
    {
      name: "heading",
      label: "Heading",
      component: "text",
    },
    {
      name: "productGroups",
      label: "Product Groups",
      component: "group-list",
      itemProps: {
        label: "{{item.name}}",
      },
      defaultItem: () => ({
        name: "New Category",
        button: {
          text: "View All Products",
          url: "/collections/all",
        },
      }),
      fields: [
        {
          name: "name",
          component: "text",
          label: "Product Group Name",
        },
        {
          name: "products",
          label: "Products",
          component: "group-list",
          description: "Max of three products.",
          itemProps: {
            label: "{{item.product.handle}}",
          },
          defaultItem: () => ({
            product: {
              handle: "best-seller-pack",
            },
          }),
          fields: [
            {
              name: "product",
              component: "productSearch",
              label: "Product",
            },
          ],
          defaultValue: [
            {
              product: {
                handle: "best-seller-pack",
              },
            },
            {
              product: {
                handle: "white-truffle-hot-sauce",
              },
            },
            {
              product: {
                handle: "variety-pack",
              },
            },
          ],
        },
        {
          name: "button",
          component: "link",
          label: "Button",
        },
      ],
      defaultValue: [
        {
          name: "Gifts",
          products: [
            {
              product: { handle: "best-seller-pack" },
            },
            {
              product: { handle: "white-truffle-hot-sauce" },
            },
            {
              product: { handle: "variety-pack" },
            },
          ],
          button: {
            text: "Shop All Gifts",
            url: "/collections/gifts",
          },
        },
        {
          name: "Hot Sauce",
          products: [
            {
              product: { handle: "truff" },
            },
            {
              product: { handle: "hotter-truff-hot-sauce" },
            },
            {
              product: { handle: "white-truffle-hot-sauce" },
            },
          ],
          button: {
            text: "Shop All Hot Sauce",
            url: "/collections/sauce",
          },
        },
        {
          name: "Mayo",
          products: [
            {
              product: { handle: "spicy-truff-mayo" },
            },
            {
              product: { handle: "truff-mayo" },
            },
          ],
          button: {
            text: "Shop All Mayo",
            url: "/collections/Mayo",
          },
        },
        {
          name: "Pasta Sauce",
          products: [
            {
              product: { handle: "black-truffle-arrabbiata" },
            },
            {
              product: { handle: "black-truffle-pomodoro-starter-product" },
            },
          ],
          button: {
            text: "Shop All Pasta Sauce",
            url: "/collections/pasta-sauce",
          },
        },
        {
          name: "Oil",
          products: [
            {
              product: { handle: "truff-black-truffle-oil" },
            },
          ],
          button: {
            text: "Shop All Oil",
            url: "/collections/oil",
          },
        },
      ],
    },
    // {
    //   name: 'hideReviews',
    //   label: 'Hide Reviews',
    //   component: 'toggle',
    //   toggleLabels: {
    //     true: 'On',
    //     false: 'Off',
    //   },
    // },
    // {
    //   name: 'hidePrices',
    //   label: 'Hide Prices',
    //   component: 'toggle',
    //   toggleLabels: {
    //     true: 'On',
    //     false: 'Off',
    //   }
    // }
  ],
};
