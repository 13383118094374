/* eslint-disable no-nested-ternary */
import { Box, Button, Paragraph } from "theme-ui";
import { useRouter } from "@backpackjs/storefront";

import { LinkWrapper } from "@snippets";

export function UnderlineTab({
  index = 0,
  id,
  isActive = false,
  controls,
  isLast = true,
  setActive = () => {},
  text = "Need Text",
  link = null,
  collectionIndex = null,
  theme = "light", // light means over light background which renders black or gold text and line..
  size = "large",
  ...props
}) {
  const router = useRouter();
  const isLightBg = theme === "light";
  const isLarge = size === "large";

  const handleNavigation = (e) => {
    setActive(index);
    if (link) {
      router.push(link);
    } else if (collectionIndex != null) {
      const scrollTarget = e.target
        .closest('[data-comp="collection-grid"]')
        .querySelector(
          `[data-comp="SubCollection"][data-collection-index="${collectionIndex}"]`
        );
      const navBar = e.target
        .closest('[data-comp="collection-grid"]')
        .querySelector('[data-comp="CollectionScrollNav"]');
      if (scrollTarget) {
        window.scrollTo({
          top:
            scrollTarget.getBoundingClientRect().top +
            window.scrollY -
            getComputedStyle(scrollTarget)
              .getPropertyValue("--header-height")
              .replace("px", "") -
            getComputedStyle(scrollTarget)
              .getPropertyValue("--promoBar-height")
              .replace("px", "") -
            navBar.offsetHeight -
            16,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " " || e.key === "Enter" || e.key === "Space") {
      e.preventDefault();
      handleNavigation(e);
    }
  };
  return (
    <Button
      role="tab"
      aria-selected={isActive}
      aria-controls={controls}
      id={id}
      tabIndex={0}
      data-comp="UnderlineTab"
      variant="buttons.plain"
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        // minHeight: '24px',
        justifyContent: "space-between",
        zIndex: 1,
        mr: isLast
          ? 0
          : isLarge
          ? collectionIndex != null
            ? ["8px", null, null, 13]
            : [9, null, null, 13]
          : collectionIndex != null
          ? ["8px", null, null, 12]
          : [8, null, null, 12],
        backgroundColor:
          collectionIndex != null
            ? isActive
              ? ["gold", null, null, "transparent"]
              : ["#333", null, null, "transparent"]
            : null,
        padding: collectionIndex != null ? ["10px", null, null, "0"] : null,
        ...props.sx,
      }}
      onKeyDown={(e) => handleKeyDown(e)}
      onClick={(e) => handleNavigation(e)}
    >
      {/* text */}
      <Paragraph
        aria-label={`Show fan favorite ${text || null}.`}
        variant={isLarge ? "label.3.r" : "text.14.r"}
        sx={{
          fontFamily: collectionIndex != null ? "heading" : null,
          fontSize: collectionIndex != null ? ["9px", null, null, ""] : null,
          color: isActive
            ? isLightBg
              ? collectionIndex != null
                ? ["white", null, null, "black"]
                : "black"
              : collectionIndex != null
              ? ["black", null, null, "gold"]
              : "white"
            : isLightBg
            ? collectionIndex != null
              ? ["white", null, null, "black"]
              : "black"
            : collectionIndex != null
            ? ["white", null, null, "white"]
            : "white",
          transition: "all 0.15s ease-in-out",
          cursor: "pointer",
          ":hover": {
            color:
              collectionIndex != null ? [null, null, null, "gold"] : "gold",
          },
        }}
      >
        {link && <LinkWrapper href={link} hiddenForSeo />}
        {text}
      </Paragraph>

      {/* line */}
      <Box sx={underlineSx(isActive, isLarge, collectionIndex)} />
    </Button>
  );
}

const transition = "all 0.15s ease-in-out";

const underlineSx = (isActive, isLightBg, collectionIndex) => ({
  display: collectionIndex != null ? ["none", null, null, "block"] : null,
  height: "3px",
  width: "100%",
  opacity: isActive ? 1 : 0,
  backgroundColor: isLightBg ? "black" : "white",
  // backgroundImage: 'linear-gradient(#cab683, #cab683)',
  position: "relative",
  transition,
  zIndex: 1,
  overflow: "hidden",
  "&:before": {
    backgroundColor:
      collectionIndex != null ? "gold" : isLightBg ? "black" : "white",
    // backgroundImage: `linear-gradient(
    //   87.65deg,
    //   #CDAF62 0.99%,
    //   #EEDBAB 18%,
    //   #B58D28 50%
    // )`,
    content: '""',
    height: "100%",
    left: 0,
    opacity: isActive ? 1 : 0,
    position: "absolute",
    top: 0,
    transform: `translateX(${isActive ? "0" : "-50%"})`,
    transition,
    width: "200%",
    zIndex: -100,
  },
});
