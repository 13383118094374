import { useState } from 'react';
import { Box, Heading } from 'theme-ui';
import { NutritionFactsProduct } from './NutritionFactsProduct';

export const NutritionFacts = ({ cms }) => {
  const isMulti = cms?.products?.length > 1;
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeMobileIndex, setActiveMobileIndex] = useState(null);

  if (!cms?.products) return null;

  return (
    <Box
      className="product-nutrition"
      sx={{
        background: '#1a1a1a',
        color: 'white',
        py: [13, null, null, '80px'],
        px: [6, 11],
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '80em',
          mx: 'auto',
        }}
      >
        {cms?.heading && (
          <Heading
            sx={{
              fontFamily: 'heading',
              fontSize: ['32px', null, null, '48px'],
              fontWeight: '400',
              lineHeight: '1.12',
              letterSpacing: '0.02em',
              textTransform: 'uppercase',
              textAlign: 'center',
              mb: [10, null, null, 11],
            }}
          >
            {cms?.heading}
          </Heading>
        )}
        {cms?.products?.map((productsItem, index, arr) => (
          <Box key={index}>
            {productsItem?.product && (
              <NutritionFactsProduct
                nutritionData={productsItem}
                isMulti={isMulti}
                productIndex={index}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                activeMobileIndex={activeMobileIndex}
                setActiveMobileIndex={setActiveMobileIndex}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

NutritionFacts.Schema = {
  label: 'Nutrition Facts',
  key: 'nutrition-facts',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      component: 'group-list',
      name: 'products',
      label: 'Products',
      fields: [
        {
          component: 'image',
          name: 'image',
          label: 'Featured Image',
        },
        {
          component: 'image',
          name: 'imageProduct',
          label: 'Product Image',
          description: 'Overrides image from product selected below',
        },
        {
          component: 'productSearch',
          name: 'product',
          label: 'Product',
        },
        {
          component: 'text',
          name: 'whatsIncluded',
          label: "What's Included Text",
        },
        {
          component: 'text',
          name: 'servingSize',
          label: 'Serving Size',
        },
        {
          component: 'text',
          name: 'servingSizePerContainer',
          label: 'Serving Size Per Container',
        },
        {
          component: 'group-list',
          name: 'nutritionFieldsInfo',
          label: 'Nutrition Table Fields',
          fields: [
            {
              component: 'text',
              name: 'label',
              label: 'Label',
            },
            {
              component: 'text',
              name: 'value',
              label: 'Value',
            },
          ],
        },
        {
          component: 'markdown',
          name: 'description',
          label: 'Description',
        },
      ],
    },
  ],
};
