import { Box } from 'theme-ui';

export const ImageComponent = ({
  image,
  display,
  isMobile,
  children,
  sx,
  ...props
}) => {
  return (
    <Box
      data-comp="HeroImage"
      alt={image?.alt ? image.alt : ''}
      sx={{
        display,
        backgroundPosition: isMobile ? image.mobilePosition : image.position,
        backgroundImage: isMobile
          ? `url(${image.mobileSrc || image.src})`
          : `url(${image.src})`,
        ...heroImageSx,
        ...sx,
      }}
      {...props}
    >
      <Box sx={overlaySx(image)} />
      {children}
    </Box>
  );
};

const heroImageSx = {
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: 'ghostwhite',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const overlaySx = (image) => ({
  display: image.overlay ? 'block' : 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  bg: '#000',
  opacity: 0.55,
});
