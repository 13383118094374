import { useState, useEffect } from 'react';
import { Flex, Image, Paragraph } from 'theme-ui';

import { DropdownItem } from './DropdownItem';

export function Dropdown({
  overrideStyles = false,
  isSoldOut,
  title,
  items,
  onClick,
  initialIndex = 0,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const activeItem = items?.[activeIndex];

  useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  return (
    <>
    <Flex
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        display: isOpen ? 'block' : 'none',
        zIndex: '11',
      }}
      onClick={(e) => {
        e.preventDefault();
        setIsOpen(false);
      }}
    >
    </Flex>
    <Flex
      data-comp="Dropdown"
      variant="flex.column"
      {...props}
      sx={{
        width: 'auto',
        minWidth: '80px',
        position: 'relative',
        ...props.sx,
      }}
    >
      <Flex
        variant="flex.row.between.center"
        sx={{
          justifyContent: isSoldOut ? 'center' : 'space-between',
          cursor: isSoldOut ? 'default' : 'pointer',
          bg: overrideStyles ? 'white' : 'black',
          border: overrideStyles
            ? (t) => `1px solid ${t.colors.black}`
            : (t) => `1px solid ${t.colors.gold}`,
          color: overrideStyles ? 'black' : 'white',
          width: '100%',
          minHeight: '56px',
        }}
        onClick={isSoldOut ? null : () => setIsOpen(!isOpen)}
      >
        {isSoldOut ? (
          <Flex variant="flex.row.center.center">
            <Paragraph
              variant="text.label.2"
              sx={{
                textAlign: 'center',
                overflowWrap: 'break-word',
                px: 8,
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              Sold Out
            </Paragraph>
          </Flex>
        ) : (
          <>
            <DropdownItem
              isSelected
              overrideStyles={overrideStyles}
              isActive={false}
              title={title}
              item={activeItem}
            />

            <Image
              src={
                overrideStyles
                  ? '/svg/chevron-down.svg'
                  : '/svg/chevron-down-gold.svg'
              }
              sx={{
                width: '12px',
                // mr: '20px',
                position: 'absolute',
                right: '20px',
                top: 'calc(50% - 4px)',
                transform: isOpen ? 'rotate(180deg)' : null,
              }}
            />
          </>
        )}
      </Flex>

      <Flex
        variant="flex.column.end"
        sx={{
          position: 'absolute',
          border: overrideStyles
            ? (t) => `1px solid ${t.colors.black}`
            : (t) => `1px solid ${t.colors.gold}`,
          right: 0,
          bg: overrideStyles ? 'white' : 'black',
          top: '115%',
          width: '100%',
          display: isOpen ? 'block' : 'none',
          opacity: isOpen ? 1 : 0,
          transition: 'opacity 0.1s ease-in',
          py: 4,
        }}
      >
        {isOpen &&
          items?.map((item, index, arr) => (
            <DropdownItem
              key={item.value}
              isSelected={false}
              isActive={item === activeItem}
              item={item}
              overrideStyles={overrideStyles}
              onClick={() => {
                setActiveIndex(index);
                setIsOpen(false);
                onClick(item.value);
              }}
            />
          ))}
      </Flex>
    </Flex>
    </>
  );
}
