import { Box, Flex, Paragraph } from 'theme-ui';

import { useMounted } from '@hooks';

export const InlineHeading = ({
  text = 'A heading text',
  theme = 'light', // light mean over light background which renders dark text and line..
  size = 'small',
  onClick = () => {},
  ...props
}) => {
  const [mounted] = useMounted();

  const isLightTheme = theme === 'light';
  const isGoldTheme = theme === 'gold';
  const isMediumSize = size === 'medium';
  const isLargeSize = size === 'large';
  const isSmallSize = size === 'small';
  const hasText = text !== null;

  return (
    <Flex
      data-comp="InlineHeading"
      variant="flex.row.start.center"
      mb={5}
      {...props}
      sx={{
        mb: isSmallSize ? 5 : isMediumSize ? 6 : 8,
        ...props.sx,
      }}
      onClick={onClick}
    >
      <Paragraph
        variant={
          isLargeSize ? 'label.1.r' : isMediumSize ? 'label.2' : 'label.3' // isSmall
        }
        sx={{
          flexShrink: 0,
          color: isGoldTheme ? 'gold' : isLightTheme ? 'black' : 'white',
        }}
      >
        {text}
      </Paragraph>

      {/* line */}
      <Box
        sx={{
          bg: isLightTheme ? 'gray.3' : 'gray.6',
          height: '1px',
          transform: mounted ? 'scaleX(1)' : 'scaleX(0)',
          transition: 'transform .33s ease-in-out',
          transformOrigin: 'left center',
          ml: hasText ? 4 : 0,
          width: '100%',
          mb: 1,
        }}
      />
    </Flex>
  );
};
