import { Box, Flex, Paragraph } from 'theme-ui';

export const ReferHistory = ({ referralData }) => {
  return (
    <Flex data-comp="ReferHistory" variant="flex.column" sx={{ width: '100%' }}>
      <Paragraph variant="18" sx={{ textAlign: 'center' }}>
        Your Referrals
      </Paragraph>
      <Flex
        variant="flex.column"
        sx={{
          width: '100%',
        }}
      >
        <Flex
          sx={{
            py: 4,
            borderBottom: '1px solid',
            borderColor: 'gray.3',
          }}
        >
          <Paragraph
            variant="label.2"
            sx={{
              flex: 1,
            }}
          >
            Email
          </Paragraph>
          <Paragraph
            variant="label.2"
            sx={{
              width: ['auto', null, '100px'],
              ml: 4,
            }}
          >
            Status
          </Paragraph>
        </Flex>
        <Box
          sx={{
            maxHeight: '500px',
            overflowY: 'scroll',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {referralData?.referral_receipts?.map((referral, index, arr) => (
            <Flex
              key={index}
              sx={{
                justifyContent: 'space-between',
                py: 4,
                borderBottom: index !== arr.length - 1 ? '1px solid' : null,
                borderColor: 'gray.3',
                wordWrap: 'break-word',
              }}
            >
              <Paragraph
                variant="sm"
                sx={{
                  flex: 1,
                }}
              >
                {referral.email}
              </Paragraph>
              <Paragraph
                variant="sm"
                sx={{
                  width: ['auto', null, '100px'],
                  ml: 4,
                }}
              >
                {referral.completed_at
                  ? 'Completed'
                  : referral.signed_up_at
                  ? 'Signed Up'
                  : 'Invited'}
              </Paragraph>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};
