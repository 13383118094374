import { Box, Button, Flex, Paragraph } from 'theme-ui';
import { AddressForm } from './AddressForm';

export const AddressCreateForm = ({
  customer,
  setShowCreateAddress,
  ...props
}) => {
  return (
    <Box
      data-comp="AddressCreateForm"
      sx={{
        border: '1px solid',
        borderColor: 'gray.3',
        p: 9,
        position: 'relative',
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          mb: 9,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Paragraph
            variant="14"
            sx={{
              fontSize: 2,
              fontWeight: 600,
              mb: 5,
            }}
          >
            Create New Address
          </Paragraph>
        </Box>

        <Flex
          sx={{
            justifyContent: ['space-between', 'flex-end'],
            mt: [10, 0],
            ml: [0, 8],
          }}
        >
          <Button
            variant="underlineLink"
            sx={{
              color: 'black',
              fontSize: 1,
              '&:before, &:after': {
                height: '1px',
              },
            }}
            onClick={() => setShowCreateAddress(false)}
          >
            Cancel
          </Button>
        </Flex>
      </Flex>

      <AddressForm
        action={customer.createAddress}
        onSuccess={() => setShowCreateAddress(false)}
        isCreateAddressForm={true}
      />
    </Box>
  );
};
