const baseBodySx = {
  letterSpacing: 0,
  fontWeight: 400,
  fontFamily: 'body',
};

const baseLabelSx = {
  letterSpacing: 0,
  fontWeight: 700,
  fontFamily: 'heading',
  textTransform: 'uppercase',
};

const baseHeadingSx = {
  letterSpacing: 0.02,
  fontWeight: 400,
  fontFamily: 'heading',
  textTransform: 'uppercase',
};

export const text = {
  // BODY
  default: {
    fontSize: 4, // 15px
    lineHeight: 20,
    ...baseBodySx,
  },
  xxs: {
    fontSize: '10px',
    lineHeight: 16,
    ...baseBodySx,
    r: {
      fontSize: ['10px', null, null, 3],
      lineHeight: [16, null, null, 20],
      ...baseBodySx,
    },
  },
  xs: {
    fontSize: 1, // 12px
    lineHeight: 18,
    ...baseBodySx,
  },
  sm: {
    fontSize: 3, // 14px
    lineHeight: 20,
    ...baseBodySx,
  },
  base: {
    fontSize: 4, // 15px
    lineHeight: 20,
    ...baseBodySx,
    r: {
      fontSize: [4, null, null, 6],
      lineHeight: [20, null, null, 26],
      ...baseBodySx,
    },
    r2: {
      fontSize: [4, null, null, 7],
      lineHeight: [20, null, null, 28],
      ...baseBodySx,
    },
  },
  lg: {
    fontSize: 6, // 18px
    lineHeight: 26,
    ...baseBodySx,
    r: {
      fontSize: [6, null, null, 7],
      lineHeight: [26, null, null, 28],
      ...baseBodySx,
    },
  },
  xl: {
    fontSize: 7, // 20px
    lineHeight: 28,
    ...baseBodySx,
  },

  // LABEL & LINKS
  label: {
    1: {
      fontSize: 6, // 18px
      lineHeight: 24,
      ...baseLabelSx,
      r: {
        ...baseHeadingSx,
        fontSize: [3, null, null, 6],
        lineHeight: [11, null, null, '22px'],
      },
    },
    2: {
      fontSize: 2, // 13px
      lineHeight: 18,
      fontWeight: 700,
      ...baseLabelSx,
    },
    3: {
      fontSize: 0, // 11px
      lineHeight: 11,
      ...baseLabelSx,
      r: {
        fontSize: [0, null, null, 6],
        lineHeight: [11, null, null, 24],
        ...baseLabelSx,
      },
    },
    4: {
      fontSize: 4, // 15px
      lineHeight: '1',
      ...baseLabelSx,
    },
  },
  link: {
    fontSize: 2, // 13px
    lineHeight: 18,
    letterSpacing: 0.02,
    textDecoration: 'underline',
    textTransform: 'uppercase',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  // HEADINGS
  10: {
    fontSize: '10px',
    lineHeight: '10px',
    ...baseHeadingSx,
    fontWeight: 600,
  },
  11: {
    fontSize: [0, null, null, 2], // 11px to 13px
    lineHeight: ['13px', null, null, '24px'],
    ...baseHeadingSx,
    fontWeight: 700,
  },
  14: {
    fontSize: 3, // 14px
    lineHeight: 1,
    ...baseLabelSx,
    fontWeight: 700,
    letterSpacing: '0.02em',
    r: {
      ...baseHeadingSx,
      fontSize: ['11px', null, null, 3],
      fontWeight: 700,
    },
  },
  18: {
    fontSize: 6, // 18px
    lineHeight: 24,
    letterSpacing: '0.03em',
    ...baseHeadingSx,
    // H6
    r: {
      fontSize: [6, null, null, 7], // 18px - 20px
      lineHeight: [24, null, null, 28],
      ...baseHeadingSx,
    },
  },
  20: {
    fontSize: 7, // 20px
    lineHeight: 28,
    ...baseHeadingSx,
    r: {
      fontSize: 7, // 20px
      lineHeight: 28,
      ...baseHeadingSx,
      fontWeight: 700,
    },
  },
  24: {
    fontSize: 8, // 24px
    lineHeight: 30,
    ...baseHeadingSx,
    // H5
    r: {
      fontSize: [8, null, null, 10], // 24px - 30px
      lineHeight: [30, null, null, 36],
      ...baseHeadingSx,
    },
    r2: {
      fontSize: [8, null, null, 14], // 24px - 48px
      lineHeight: 1,
      ...baseHeadingSx,
    },
  },
  28: {
    fontSize: 9, // 28px
    lineHeight: 34,
    ...baseHeadingSx,
    // H4
    r: {
      fontSize: [9, null, null, 12], // 28px - 36px
      lineHeight: [34, null, null, 40],
      ...baseHeadingSx,
    },
  },
  30: {
    fontSize: 10, // 30px
    lineHeight: 36,
    ...baseHeadingSx,
    r: {
      fontSize: [10, null, null, 14], // 32px - 48px
      lineHeight: [36, null, null, 54],
      ...baseHeadingSx,
    },
  },
  32: {
    fontSize: 11, // 32px
    lineHeight: 38,
    ...baseHeadingSx,
    // H3
    r: {
      fontSize: [11, null, null, 14], // 32px - 48px
      lineHeight: [38, null, null, 54],
      ...baseHeadingSx,
    },
    r2: {
      fontSize: [11, null, null, 14, null, null, 16], // 32px - 60px
      lineHeight: [38, null, null, 54, null, null, 68],
      ...baseHeadingSx,
    },
  },
  36: {
    fontSize: 12, // 36px
    lineHeight: 40,
    ...baseHeadingSx,
    r: {
      fontSize: [11, null, null, 12], // 36px
      lineHeight: 40,
      ...baseHeadingSx,
      fontWeight: 700,
    },
  },
  40: {
    fontSize: 13, // 40px
    lineHeight: 46,
    ...baseHeadingSx,
    // H2
    r: {
      fontSize: [13, null, null, 16], // 40px - 60px
      lineHeight: [46, null, null, 68],
      ...baseHeadingSx,
    },
  },
  48: {
    fontSize: 14, // 48px
    lineHeight: 56,
    ...baseHeadingSx,
    // H1
    r: {
      fontSize: [14, null, null, 18], // 48px - 80px
      lineHeight: [56, null, null, 84],
      ...baseHeadingSx,
      letterSpacing: [0.02, null, null, 0.01],
    },
  },
  56: {
    fontSize: 15, // 56px
    lineHeight: 64,
    ...baseHeadingSx,
    letterSpacing: 0.04,
    color: 'white',
    webkitTextStroke: '2px #1A1A1A',
    // DISPLAY OUTLINE
    r: {
      fontSize: [15, null, null, 19], // 56px - 88px
      lineHeight: [64, null, null, 94],
      ...baseHeadingSx,
      letterSpacing: [0.04, null, null, 0.02],
      color: 'white',
      webkitTextStroke: '2px #1A1A1A',
    },
  },
  60: {
    fontSize: 16, // 60px
    lineHeight: 68,
    ...baseHeadingSx,
  },
  64: {
    fontSize: 17, // 64px
    lineHeight: 72,
    ...baseHeadingSx,
    // DISPLAY
    r: {
      fontSize: [17, null, null, 20], // 64px - 96px
      lineHeight: [72, null, null, 104],
      ...baseHeadingSx,
    },
  },
  80: {
    fontSize: 18, // 80px
    lineHeight: 84,
    ...baseHeadingSx,
    letterSpacing: 0.01,
  },
  88: {
    fontSize: 19, // 88px
    lineHeight: 94,
    ...baseHeadingSx,
    color: 'white',
    webkitTextStroke: '2px #1A1A1A',
  },
  96: {
    fontSize: 20, // 96px
    lineHeight: 104,
    ...baseHeadingSx,
  },
};
