import React from 'react';
import { jsx, Box } from 'theme-ui';

import store, { useRecoilValue } from '@store';

import { ArticleHero } from './ArticleHero';
import { ArticleSidebar } from './ArticleSidebar';
import { ArticleContent } from './ArticleContent';

export function Article({ cms }) {
  const blogType = useRecoilValue(store.blogType);
  const isTruffKitchen = blogType === 'truff-kitchen';

  return cms?.resourceType !== 'article' ? (
    <Box sx={{ color: 'red' }}>
      The article section can only be used inside an article
    </Box>
  ) : (
    <Box
      data-comp="Article"
      sx={{
        minHeight: '100vh',
        bg: 'white',
        pb: [10, 12, null, 15, 16],
      }}
    >
      {/* Hero  */}
      <Box>
        <ArticleHero
          hero={cms.hero}
          sx={{
            width: '100%',
          }}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          px: [7, 8, 10, 12, 14, 16],
          display: 'flex',
          flexDirection: ['column-reverse', null, null, 'row'],
        }}
      >
        {/* SideBar */}
        {isTruffKitchen && (
          <ArticleSidebar
            sidebar={cms.sidebar}
            sx={{
              minWidth: ['100%', null, null, '252px'],
              maxWidth: ['100%', null, null, '252px'],
              mr: [null, null, null, 10],
            }}
          />
        )}

        {/* Body */}
        <ArticleContent
          content={cms.content}
          sx={{
            width: isTruffKitchen ? '100%' : '90%',
            mx: 'auto',
          }}
        />
      </Box>
    </Box>
  );
}

Article.Schema = (data) => {
  const tags = data?.article?.article?.articleTags?.length
    ? data.article.article.articleTags
    : [];

  const HERO_OPTIONS = {
    label: 'Hero',
    description: 'The article top hero. It supports video (YouTube) or images',
    name: 'hero',
    component: 'group',
    fields: [
      {
        label: 'Tags',
        description:
          'Article tags displayed over the hero on the top left corner',
        name: 'tags',
        component: 'group',
        fields: [
          {
            name: 'enabled',
            label: 'Display tags',
            description: 'Display tags',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            name: 'list',
            label: 'Tags to show',
            description: 'Display article tags on top left corner of the hero',
            component: 'list',
            field: {
              component: 'select',
              options: tags,
            },
            defaultValue: tags.slice(0, 2),
          },
        ],
      },
      {
        label: 'Video Hero',
        description: 'If set, it will take priority over an image',
        name: 'video',
        component: 'group',
        fields: [
          {
            label: 'YouTube Video ID',
            description: `The sequence of numbers and letters at the end of the URL, immediately after the string "?v="`,
            name: 'id',
            component: 'text',
            defaultValue: 'acY1MRR82zY',
          },
        ],
      },
      {
        label: 'Image Hero',
        description: 'Display ',
        name: 'image',
        component: 'group',
        fields: [
          {
            name: 'altMobile',
            label: 'Alt (mobile)',
            description:
              'Please provide a brief description of the mobile image for accessibility compliance',
            component: 'text',
          },
          {
            label: 'Image (mobile)',
            name: 'mobile',
            component: 'image',
            defaultValue: data?.seo?.image || '',
          },
          {
            name: 'alt',
            label: 'Alt (desktop)',
            description:
              'Please provide a brief description of the desktop image for accessibility compliance',
            component: 'text',
          },
          {
            label: 'Image (Desktop)',
            name: 'desktop',
            component: 'image',
            defaultValue: '/images/truffPinterestDefault.jpeg',
          },
        ],
      },
    ],
  };

  const SIDEBAR_OPTIONS = {
    label: 'Sidebar',
    description:
      'Article cook time, prep time, servings, ingredients and shop the recipe',
    name: 'sidebar',
    component: 'group',
    fields: [
      {
        label: 'Show / Hide "Cook Time"',
        name: 'cookTimeDisable',
        component: 'toggle',
        toggleLabels: {
          true: 'Show',
          false: 'Hide',
        },
        defaultValue: true,
      },
      {
        name: 'cookTime',
        label: 'Cook Time',
        component: 'text',
        defaultValue: '15 minutes',
      },
      {
        label: 'Show / Hide "Prep Time"',
        name: 'prepTimeDisable',
        component: 'toggle',
        toggleLabels: {
          true: 'Show',
          false: 'Hide',
        },
        defaultValue: true,
      },
      {
        name: 'prepTime',
        label: 'Prep Time',
        component: 'text',
        defaultValue: '10 minutes',
      },
      {
        label: 'Show / Hide "Servings"',
        name: 'servingsDisable',
        component: 'toggle',
        toggleLabels: {
          true: 'Show',
          false: 'Hide',
        },
        defaultValue: true,
      },
      {
        name: 'servings',
        label: 'Servings',
        component: 'select',
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' },
          { value: '6', label: '6' },
          { value: '7', label: '7' },
          { value: '8', label: '8' },
          { value: '10', label: '10' },
        ],
        defaultValue: '2',
      },
      {
        label: 'Show / Hide "Ingredients"',
        name: 'ingredientsDisable',
        component: 'toggle',
        toggleLabels: {
          true: 'Show',
          false: 'Hide',
        },
        defaultValue: true,
      },
      {
        name: 'ingredients',
        label: 'Ingredients list',
        description: 'Displays a list of ingredients',
        component: 'list',
        field: {
          component: 'text',
        },
        defaultValue: [
          '1/2 cup - Risus Ornare',
          '1 tsp - Pharetra Ultricies',
          '4 - Risus Ligula',
        ],
      },
      {
        name: 'shopRecipe',
        label: 'Shop the recipe',
        description:
          'Displays an optional list of products featured in the article',
        component: 'group-list',
        itemProps: {
          label: '{{item.product.handle}}' || 'Search For Product',
        },
        fields: [
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
        ],
        defaultValue: [],
      },
    ],
  };

  const CONTENT_OPTIONS = {
    label: 'Content',
    description: 'The articles title, subtitle and content body',
    name: 'content',
    component: 'group',
    fields: [
      {
        name: 'title',
        label: 'Title',
        component: 'text',
        defaultValue:
          data?.seo?.title || 'Amet Risus Pellentesque Etiam Malesuada',
      },
      {
        name: 'subTitle',
        label: 'Sub title',
        component: 'markdown',
        defaultValue:
          data?.article?.excerpt ||
          'Nullam quis risus eget urna mollis ornare vel eu leo. Vivamus sagittis lacus vel augue laoreet rutrum faucibus auctor.',
      },
      {
        label: 'Body',
        name: 'body',
        component: 'blocks',
        description:
          'Group of paragraph, image or video blocks describing the content',
        templates: {
          'markdown-block': {
            label: 'Paragraph Block',
            key: 'markdown-block',
            fields: [
              {
                name: 'paragraph',
                component: 'markdown',
                label: 'Paragraph',
                // defaultValue: data?.seo?.description || 'Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.'
              },
            ],
          },
          'image-block': {
            label: 'Image Block',
            key: 'image-block',
            fields: [
              {
                name: 'alt',
                label: 'Alt (desktop)',
                description:
                  'Please provide a brief description of the image for accessibility compliance',
                component: 'text',
              },
              {
                name: 'image',
                component: 'image',
                label: 'Image',
                clearable: true,
                parse: (media) => {
                  if (!media) return '';
                  // by default: component:image returns just the src as a string
                  // by defining: parse: image => image (the user can opt-in to the full image metadata object)
                  return media.src;
                },
                previewSrc: (media) => {
                  if (!media) return '';

                  if (typeof media === 'string') {
                    return media; // default when component: 'image'
                  }

                  return media?.thumbnail || media?.src;
                },
              },
            ],
          },
          'video-block': {
            label: 'Video Block',
            key: 'video-block',
            fields: [
              {
                name: 'video',
                component: 'text',
                label: 'Video',
                defaultValue: 'acY1MRR82zY',
              },
            ],
          },
        },
        defaultValue: [
          {
            _template: 'markdown-block',
            paragraph:
              data?.seo?.description ||
              'Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.',
          },
          {
            _template: 'markdown-block',
            paragraph:
              'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur.',
          },
          {
            _template: 'image-block',
            image: {
              filename:
                'https://images.unsplash.com/photo-1591285687558-632ddc5e026a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80',
            },
          },
          {
            _template: 'video-block',
            video: 'acY1MRR82zY',
          },
          {
            _template: 'markdown-block',
            paragraph:
              'Nulla vitae elit libero, a pharetra augue. Sed posuere consectetur est at lobortis. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.',
          },
        ],
      },
    ],
  };

  return {
    label: 'Article',
    key: 'article',
    fields: [HERO_OPTIONS, SIDEBAR_OPTIONS, CONTENT_OPTIONS],
  };
};
