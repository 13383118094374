import { useEffect, useMemo } from 'react';
import {
  useCartAddItem,
  useCart,
  useProductByHandle,
} from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';

export const useFondueCashback = () => {
  const setCashbackInCart = useSetRecoilState(store.cashbackInCart);
  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const cart = useCart();

  const { product: cashbackProduct } = useProductByHandle({
    handle: '20-cashback',
  });

  const hasCashbackInCart = useMemo(() => {
    // wait for cart to be ready
    if (cart === null) return true;
    const hasItem = cart?.lines?.some(
      (item) => item.variant.id == cashbackProduct?.variants?.[0].id
    );
    setCashbackInCart(hasItem);
    return hasItem;
  }, [cart?.updatedAt, cashbackProduct]);

  const cartIsValidForCashBack = useMemo(() => {
    // wait for cart to be ready
    if (cart === null) return true;
    const hasNonCashBackItem = cart?.lines?.some(
      (item) => item.variant.id !== cashbackProduct?.variants?.[0].id
    );
    return hasNonCashBackItem;
  }, [cart?.updatedAt, cashbackProduct]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cashback =
      params?.get('cashback') ||
      window.sessionStorage?.getItem('cashback') ||
      null;

    if (params?.get('cashback')) {
      window.sessionStorage?.setItem('cashback', true);
    }

    if (
      cashback &&
      !hasCashbackInCart &&
      cashbackProduct &&
      cartIsValidForCashBack
    ) {
      cartAddItem({
        merchandiseId: cashbackProduct?.variants?.[0].id,
        quantity: 1,
      });
    }
  }, [
    cart?.updatedAt,
    hasCashbackInCart,
    cartIsValidForCashBack,
    cashbackProduct,
  ]);
};
