import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { Dropdown } from '@snippets';

export function ProductDropdownFlavors({
  groupingProducts,
  isSubscriptionOnly,
  selectedVariant,
  selectedProductIndex,
}) {
  const router = useRouter();

  const handleFlavorChange = useCallback((handle) => {
    router.push(`/products/${handle}`);
  }, []);

  return (
    <Dropdown
      id="product-flavors-dropdown"
      onClick={handleFlavorChange}
      title={
        selectedVariant?.product?.productType === 'Fashion' ||
        selectedVariant?.product?.productType === 'Spatulas'
          ? ''
          : 'Pick your flavor'
      }
      items={groupingProducts}
      initialIndex={selectedProductIndex}
      sx={{
        zIndex: 20,
        mt: isSubscriptionOnly ? 0 : [8, null, null, 11],
        mb: [8, null, null, 11],
        '[data-comp="Picture"]': {
          border: 0,
          borderRadius: 0,
        },
      }}
    />
  );
}
