/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Box, Button, Flex, Image, Paragraph } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useInView } from 'react-intersection-observer';
import SwiperCore, { Autoplay, Virtual, EffectFade, Navigation } from 'swiper';
import { useSettings } from '@backpackjs/storefront';
import { useUpdateSwiperOnSidebarToggle } from '@hooks';
import { LinkWrapper, Svg } from '@snippets';
import { Countdown } from './Countdown';

SwiperCore.use([EffectFade, Autoplay, Virtual, Navigation]);

const variablePromoMessageSx = (countdownPosition) => ({
  px: ['calc(34px + 1.5em)', null, null, '3em'],
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: countdownPosition === 'top' ? 'column' : 'row',
  gap:
    countdownPosition === 'top'
      ? [null, null, null, '4px']
      : ['10px', null, null, '14px'],
  fontFamily: 'heading',
  fontSize: ['10px', null, null, '12px'],
  lineHeight: ['1.8', null, null, '1.17'],
});

const variablePromoMessageContentSx = (carouselType) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection:
    carouselType === 'smooth' ? null : ['column', null, null, 'row'],
  gap: [carouselType === 'smooth' ? '10px' : '3px', null, null, '14px'],
});

const linkWrapperSx = (linkType, textLinkColor) => ({
  ...(linkType === 'button' && {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '2px',
    padding: '6px 24px',
  }),
  ...(linkType === 'textlink' && {
    color: textLinkColor || 'white',
    fontWeight: 'bold',
    textDecoration: 'underline',
  }),
});

const countdownBoxSx = (countdownPosition) => ({
  minWidth: 'auto',
  ...(countdownPosition === 'top' && {
    order: '-1',
    '.countdown-inner': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    '.countdown-timeleft': {
      display: 'flex',
      gap: '.25em',
    },
    '.countdown-timeleft-value:after': {
      display: 'none',
    },
  }),
});

const buttonSx = (position, margin) => ({
  position: 'absolute',
  top: '50%',
  [position]: '0%',
  height: 12,
  width: 12,
  zIndex: 2,
  transform: 'translateY(-50%)',
  margin: position === 'left' ? `0 0 0 ${margin}` : `0 ${margin} 0 0`,
  '@media only screen and (max-width: 767px)': {
    margin: '0 12px',
  },
  '&:after': {
    content: '""',
  },
});

const imageSx = (rotation) => ({
  height: '100%',
  position: 'absolute',
  objectFit: 'contain',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
  filter: 'brightness(0) saturate(100%) invert(1)',
});

const PromoBarNavigationButtons = () => (
  <>
    <Button
      className="promo-button-prev"
      variant="buttons.plain"
      aria-label="Move to previous slide"
      sx={buttonSx('left', '40px')}
    >
      <Image src="/svg/chevron-up.svg" alt="Arrow Previous" sx={imageSx(-90)} />
    </Button>
    <Button
      className="promo-button-next"
      variant="buttons.plain"
      aria-label="Move to next slide"
      sx={buttonSx('right', '40px')}
    >
      <Image src="/svg/chevron-up.svg" alt="Arrow Next" sx={imageSx(90)} />
    </Button>
  </>
);

const VariablePromoMessage = ({ message, carouselType }) => (
  <Flex
    data-comp="VariablePromoMessage"
    sx={variablePromoMessageSx(message?.countdownPosition)}
  >
    <Flex
      data-comp="VariablePromoMessageContentBlock"
      sx={variablePromoMessageContentSx(carouselType)}
    >
      <Box>{message?.content}</Box>
      {message?.link && (
        <LinkWrapper
          href={message.link.url}
          linkIsCode={message?.linkIsCode}
          sx={linkWrapperSx(message.linkType, message.textLinkColor)}
          ariaLabel={`Go to ${message.link.url} page`}
        >
          {message.link.text}
        </LinkWrapper>
      )}
    </Flex>
    {message?.isCountdown && (
      <Box sx={countdownBoxSx(message?.countdownPosition)}>
        <Countdown setTime={message.countdownTimestamp || 86400000} />
      </Box>
    )}
  </Flex>
);
const promoMessageSx = {
  height: 24,
  color: 'white',
  width: '100%',
};

const PromoMessage = ({ url, text }) => (
  <Flex
    data-comp="PromoBarMessage"
    variant="flex.row.center"
    sx={promoMessageSx}
  >
    {url ? (
      <Paragraph sx={{ textAlign: 'center' }} variant="text.label.2">
        <LinkWrapper
          ariaLabel={`${text || url || 'Promo bar message'}`}
          href={url}
        >
          {text}
        </LinkWrapper>
      </Paragraph>
    ) : (
      <Paragraph sx={{ textAlign: 'center', pt: 4 }} variant="text.label.2">
        {text}
      </Paragraph>
    )}
  </Flex>
);

const promoBarLayoutSx = {
  backgroundColor: '#333',
  color: 'white',
  py: '5px',
  zIndex: 59,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minHeight: '34px',
  position: 'sticky',
  top: 'var(--header-height)',
};
const defaultSwiperSx = {
  width: '100%',
  '.swiper-wrapper': { height: 24 },
  '.swiper-slide': { height: 24 },
};

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

const swiperSx = (carouselType) => ({
  width: '100%',
  px: [null, null, null, '64px'],
  '.swiper-wrapper': {
    alignItems: 'center',
    ...(carouselType === 'smooth' && {
      transitionTimingFunction: 'linear !important',
    }),
  },
  '.swiper-slide': {
    width: 'auto',
  },
});
const setPromoBarHeightEffect = () => {
  const updatePromoBarHeight = () => {
    setTimeout(() => {
      const promoBarEl = document.querySelector('.promoBarWrap');
      const promoBarHeight = promoBarEl?.clientHeight;
      document.documentElement.style.setProperty(
        '--promoBar-height',
        `${promoBarHeight}px`
      );
    }, 100);
  };
  window.addEventListener('resize', updatePromoBarHeight);
  updatePromoBarHeight();

  return () => {
    window.removeEventListener('resize', updatePromoBarHeight);
  };
};

const PromoBarLayout = React.forwardRef(
  ({ currentPromoBar, promoBar, setSwiper, swiper }, ref) => {
    const [isAutoplay, setIsAutoplay] = useState(true);

    const toggleAutoplay = useCallback(() => {
      if (isAutoplay) {
        swiper.autoplay.stop();
      } else {
        swiper.autoplay.start();
      }
      setIsAutoplay(!isAutoplay);
    }, [isAutoplay, swiper]);

    return (
      <Flex ref={ref} className="promoBarWrap" sx={promoBarLayoutSx}>
        {currentPromoBar.messages?.length > 1 ? (
          <>
            <Box
              sx={{
                mx: [2, 8],
                mt: [16, 0],
                display: ['none', 'none', 'none', 'block'],
                color: 'white',
                fill: 'white',
                minHeight: 18,
                minWidth: 18,
              }}
            />
            <Swiper
              data-comp="VariablePromoBarMessages"
              init
              autoplay={{
                delay:
                  currentPromoBar.carouselType === 'smooth'
                    ? 1
                    : promoBar.duration * 1000,
                disableOnInteraction: false,
                waitForTransition: true,
              }}
              centeredSlides
              cssMode={false}
              fadeEffect={{ crossFade: true }}
              freeMode={currentPromoBar.carouselType === 'smooth'}
              loop
              loopedSlides={
                currentPromoBar.carouselType === 'smooth'
                  ? currentPromoBar.messages?.length
                  : null
              }
              navigation={
                currentPromoBar.carouselType !== 'smooth' && {
                  nextEl: '.promo-button-next',
                  prevEl: '.promo-button-prev',
                }
              }
              onSwiper={setSwiper}
              slidesPerView={
                currentPromoBar.carouselType === 'smooth' ? 'auto' : 1
              }
              spaceBetween={0}
              speed={
                currentPromoBar.carouselType === 'smooth'
                  ? 6000
                  : promoBar.speed * 1000
              }
              watchOverflow
              sx={swiperSx(currentPromoBar.carouselType)}
            >
              {currentPromoBar.messages.map((message, index) => (
                <SwiperSlide key={index}>
                  <VariablePromoMessage
                    message={message}
                    carouselType={currentPromoBar.carouselType}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {currentPromoBar.carouselType !== 'smooth' && (
              <PromoBarNavigationButtons />
            )}
            <Button
              onClick={toggleAutoplay}
              aria-label={isAutoplay ? 'Pause autoplay' : 'Resume autoplay'}
              sx={{
                mx: [2, 8],
                mt: [16, 0],
                display: ['none', 'none', 'none', 'block'],
                color: 'white',
                fill: 'white',
              }}
            >
              {isAutoplay ? (
                <Svg
                  alt="pause"
                  src="/svgs/pause.svg#pause"
                  sx={{ height: 18, width: 18, color: 'white' }}
                  viewBox="0 0 25 25"
                />
              ) : (
                <Svg
                  alt="play"
                  src="/svgs/play.svg#play"
                  sx={{ height: 18, width: 18, color: 'white' }}
                  viewBox="0 0 25 25"
                />
              )}
            </Button>
          </>
        ) : (
          <VariablePromoMessage
            message={currentPromoBar.messages?.[0]}
            carouselType={currentPromoBar.carouselType}
          />
        )}
      </Flex>
    );
  }
);

const DefaultPromoBar = React.forwardRef(
  ({ haveMessages, singleMessage, promoBar, setSwiper }, ref) => (
    <Flex
      data-comp="PromoBar"
      variant="flex.row.center"
      ref={ref}
      className="promoBarWrap"
      sx={promoBarLayoutSx}
    >
      {haveMessages ? (
        singleMessage ? (
          <PromoMessage {...promoBar.messages[0].message} />
        ) : (
          <Swiper
            data-comp="PromoBarMessages"
            init
            autoplay={{
              delay: promoBar.duration * 1000,
              disableOnInteraction: false,
              waitForTransition: true,
            }}
            fadeEffect={{ crossFade: true }}
            speed={promoBar.speed * 1000}
            onSwiper={setSwiper}
            loop
            sx={defaultSwiperSx}
          >
            {promoBar.messages.map((message, index) => (
              <SwiperSlide key={index}>
                <PromoMessage {...message.message} />
              </SwiperSlide>
            ))}
          </Swiper>
        )
      ) : null}
    </Flex>
  )
);

export const PromoBar = () => {
  const router = useRouter();
  const { header } = useSettings();
  const { promoBar } = header;
  const havePromoBars = promoBar?.promobarItems?.length;
  const haveMessages = promoBar?.messages?.length;
  const singleMessage = haveMessages === 1;
  const [swiper, setSwiper] = useState(null);
  const [mounted, setMounted] = useState(false);
  const { ref, inView, entry } = useInView();
  const [isReturnVisitor, setIsReturnVisitor] = useState(false);

  useEffect(() => {
    setPromoBarHeightEffect();
    setMounted(true);

    const promoCookie = getCookie('pbc-visited');
    if (promoCookie !== '' && promoCookie !== null) {
      const cookieDate = new Date(parseInt(promoCookie, 10));
      const curDate = new Date();
      const diff = curDate - cookieDate;
      if (diff > 10000) {
        setIsReturnVisitor(true);
      }
    }

    if (!isReturnVisitor) {
      setCookie('pbc-visited', new Date().getTime(), 7);
    }
  }, [isReturnVisitor]);

  useUpdateSwiperOnSidebarToggle(swiper);

  const currentPromoBar = promoBar?.promobarItems?.find((item) => {
    let pageMatch = true;
    if (item.pages?.length > 0) {
      pageMatch = false;
      item.pages.forEach((pageLink) => {
        if (pageLink.link?.url === router.asPath.split(/[?#]/)[0]) {
          pageMatch = true;
        }
      });
    }

    if (item.forReturnVisitors && pageMatch && !isReturnVisitor) {
      pageMatch = false;
    }

    return pageMatch;
  });

  return (
    mounted &&
    promoBar?.enabled && (
      <>
        {havePromoBars && currentPromoBar ? (
          <PromoBarLayout
            ref={ref}
            currentPromoBar={currentPromoBar}
            promoBar={promoBar}
            setSwiper={setSwiper}
            swiper={swiper}
          />
        ) : (
          <DefaultPromoBar
            ref={ref}
            haveMessages={haveMessages}
            singleMessage={singleMessage}
            promoBar={promoBar}
            setSwiper={setSwiper}
          />
        )}
      </>
    )
  );
};
