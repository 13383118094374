import { useMemo, useEffect, useState } from 'react';
import { Flex } from 'theme-ui';
import { isDesktop } from 'react-device-detect';
import {
  mediaCmsSettings,
  primaryButtonCmsSettings,
  secondaryButtonCmsSettings,
  isBrowser,
} from '@utils';
import { HeroImage, HeroVideo } from '@snippets';
import { PrimaryHeroContent } from './PrimaryHeroContent';

export function PrimaryHero({ cms }) {
  const {
    activeMediaType,
    activeMobileMediaType,
    imageSettings,
    videoSettings,
    textSettings,
    primaryButtonSettings,
    secondaryButtonSettings,
  } = cms;

  const [innerHeight, setInnerHeight] = useState(0);
  const [innerHeightMobile, setInnerHeightMobile] = useState(0);

  const mediaType = useMemo(
    () => ({
      main: activeMediaType || 'image',
      mobile: activeMobileMediaType || 'image',
    }),
    [activeMediaType, activeMobileMediaType]
  );

  const image = useMemo(
    () => ({
      alt: imageSettings?.alt || '',
      src: imageSettings?.image?.src || imageSettings?.image || '',
      position: imageSettings?.imagePosition || 'center center',
      height: imageSettings?.imageHeight || 0.9,
      mobileSrc:
        imageSettings?.mobileImage?.src || imageSettings?.mobileImage || '',
      mobilePosition: imageSettings?.mobileImagePosition || 'center center',
      mobileHeight: imageSettings?.mobileImageHeight || 0.7,
      overlay: imageSettings?.overlay || false,
    }),
    [imageSettings]
  );

  const video = useMemo(
    () => ({
      src: videoSettings?.video || '',
      mobileSrc: videoSettings?.mobileVideo || '',
      poster: videoSettings?.poster?.src || videoSettings?.poster || '',
      mobilePoster:
        videoSettings?.mobilePoster?.src || videoSettings?.mobilePoster || '',
      height: videoSettings?.videoHeight || 0.9,
      mobileHeight: videoSettings?.mobileVideoHeight || 0.7,
      overlay: videoSettings?.overlay || false,
    }),
    [videoSettings]
  );

  const contentProps = {
    textSettings,
    secondaryButtonSettings,
    primaryButtonSettings,
  };

  useEffect(() => {
    if (!isBrowser) return;
    const mobileMultiplier =
      mediaType.mobile === 'image' ? image.mobileHeight : video.mobileHeight;
    const multiplier = mediaType.main === 'image' ? image.height : video.height;
    if (isDesktop) {
      setInnerHeightMobile(
        `calc(var(--viewport-height) * ${mobileMultiplier})`
      );
      setInnerHeight(`calc(var(--viewport-height) * ${multiplier})`);
    } else {
      const _innerHeight = window.innerHeight;
      setInnerHeightMobile(
        _innerHeight
          ? `${_innerHeight * mobileMultiplier}px`
          : `calc(100vh * ${mobileMultiplier})`
      );
      setInnerHeight(
        _innerHeight
          ? `${_innerHeight * multiplier}px`
          : `calc(100vh * ${multiplier})`
      );
    }
  }, [isBrowser, isDesktop, mediaType, image, video]);

  return (
    <Flex
      data-comp="PrimaryHero"
      variant="flex.section"
      sx={{
        height: [innerHeightMobile, null, null, innerHeight],
        position: 'relative',
      }}
    >
      <HeroImage
        image={image}
        contentProps={contentProps}
        mediaType={mediaType}
        lazy={false}
      />

      <HeroVideo
        video={video}
        contentProps={contentProps}
        mediaType={mediaType}
        lazy={false}
      />

      <PrimaryHeroContent {...contentProps} />
    </Flex>
  );
}

PrimaryHero.Schema = {
  label: 'Primary Hero',
  key: 'primary-hero',
  fields: [
    ...mediaCmsSettings({
      overlay: true,
      showHeightSettings: true,
    }),
    {
      label: 'Text Settings',
      name: 'textSettings',
      description: 'Heading, Text Color.',
      component: 'group',
      fields: [
        {
          name: 'heading',
          component: 'text',
          label: 'Heading',
          defaultValue: 'Primary Hero Heading',
        },
        {
          name: 'textSize',
          component: 'select',
          label: 'Text size',
          options: [
            {
              value: '14.r',
              label: '11px/14px',
            },
            {
              value: '18.r',
              label: '18px/20px',
            },
            {
              value: '24.r',
              label: '24px/30px',
            },
            {
              value: '28.r',
              label: '28px/36px',
            },
            {
              value: '32.r',
              label: '32px/48px',
            },
            {
              value: '48.r',
              label: '48px/80px',
            },
            {
              value: '56.r',
              label: '56px/88px',
            },
          ],
          defaultValue: '48.r',
        },
        {
          name: 'textColor',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          label: 'Text Color',
          options: [
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
          ],
          defaultValue: 'white',
        },
        {
          name: 'mobileTextColor',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          label: 'Text Color on Mobile',
          options: [
            {
              value: 'black',
              label: 'Black',
            },
            {
              value: 'white',
              label: 'White',
            },
          ],
          defaultValue: 'white',
        },
      ],
    },
    primaryButtonCmsSettings,
    secondaryButtonCmsSettings('primary'),
  ],
};
