import React, { useState } from 'react';
import { Paragraph, Flex, Box, Image } from 'theme-ui';

import { Markdown } from '@snippets';

export const AccordionItem = ({
  item,
  index,
  indexOpen,
  setIndexOpen,
  onePanelOpen,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const activeIndex = index === indexOpen;
  const active = (!onePanelOpen && activeIndex) || (onePanelOpen && isOpen);

  return (
    <Flex
      variant="flex.column"
      data-comp="AccordionItem"
      key={index}
      sx={{
        height: 'auto',
        width: '100%',
        maxHeight: active ? '600px' : '60px',
        overflow: 'hidden',
        transition: 'max-height 0.4s ease-in-out',
        borderBottom: '1px solid',
        borderTop: index === 0 ? '1px solid' : 0,
        borderColor: 'gray.3',
      }}
      {...props}
    >
      <Flex
        variant="flex.row.between.center"
        sx={{
          cursor: 'pointer',
          minHeight: '60px',
        }}
        onClick={() => {
          onePanelOpen
            ? setIsOpen(!isOpen)
            : setIndexOpen(activeIndex ? null : index);
        }}
      >
        <Paragraph variant="label.2">{item.heading}</Paragraph>
        <Image
          src={active ? '/svg/minus-black.svg' : '/svg/plus-black.svg'}
          draggable="false"
          sx={{
            ml: 4,
            userSelect: 'none',
            minWidth: '12px',
            width: '12px',
          }}
        />
      </Flex>
      <Markdown text={item.body} style={{ mb: 8 }} />
    </Flex>
  );
};
